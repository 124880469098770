import React from 'react'
import { compose } from 'redux'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { updateCompanyAdvertisementAnonymizeToggle } from '../actions'
import {
  fetchCompanyProspect,
  fetchCompanyIsPublishable,
  fetchCompanyAdvertisementAnonymizeToggle,
} from 'actions'
import {
  companyProspectSelector,
  companyProspectIsPendingSelector,
  companyAdvertisementSelector,
  companyAdvertisementIsPendingSelector,
  companyIsPublishableSelector,
  companiesAdvertisementAnonymizeToggleSelector,
  claimedCompaniesIsPendingSelector,
  claimedCompanyIdsSelector,
} from 'selectors'
import { colors, contentWidth } from 'styles'
import { Body } from 'styles/text'

import withFetchedData from 'components/withFetchedData'
import withSpinner from 'components/withSpinner'
import CompanyAdvertisement from 'components/CompanyAdvertisement'
import Link from 'components/Link'
import withLogin from '../components/withLogin'
import NoAccess from '../components/NoAccess'
import { Checkbox } from '../components/form'
import { Label } from '../styles/text'

const Wrapper = styled.div`
  background-color: ${colors.alabasterBackground};
  border-bottom: 2px solid ${colors.primary};
  margin-bottom: 12px;
`

const Content = styled.div`
  padding: 20px;
  max-width: ${contentWidth};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const CheckboxField = styled.div`
  display: flex;

  > label {
    margin: 0 !important;
  }

  > input {
    margin-left: 8px;
    margin-top: -4px !important;
  }
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const PreviewBanner = props => (
  <Wrapper>
    <Content>
      <FlexRow>
        <Body>Forhåndsvinsingsmodus</Body>
      </FlexRow>
      <FlexRow>
        <Link to={`/advertisement/create/${props.orgNr}`}>
          Avslutt forhåndsvisning
        </Link>
        <CheckboxField>
          <Label>Anonymiser: </Label>
          <Checkbox
            checked={props.previewAnonymized}
            onChange={() =>
              props.dispatch(
                updateCompanyAdvertisementAnonymizeToggle(
                  props.orgNr,
                  !props.previewAnonymized
                )
              )
            }
          />
        </CheckboxField>
      </FlexRow>
    </Content>
  </Wrapper>
)

const PreviewAdvertisement = props => {
  const dispatch = useDispatch()
  if (props.error) {
    return <NoAccess />
  } else {
    return (
      <>
        <PreviewBanner orgNr={props.companyId} {...props} dispatch={dispatch} />
        <CompanyAdvertisement
          {...props}
          previewAdvertisement={true}
          previewAnonymized={props.previewAnonymized}
        />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  claimedCompanyIds: claimedCompanyIdsSelector(state),
  ...companyProspectSelector(state, ownProps),
  ...companyAdvertisementSelector(state, ownProps),
  publishable: companyIsPublishableSelector(state, ownProps),
  advertisementForSale: true,
  forSale: true,
  previewAnonymized: companiesAdvertisementAnonymizeToggleSelector(
    state,
    ownProps
  ),
})

// const mapDispatchToProps = {
//   updateCompanyAdvertisementAnonymizeToggle,
// }

export default compose(
  withLogin,
  withSpinner(claimedCompaniesIsPendingSelector),
  withFetchedData(props =>
    fetchCompanyAdvertisementAnonymizeToggle(props.companyId)
  ),
  withFetchedData(props => fetchCompanyProspect(props.companyId)),
  withFetchedData(props => fetchCompanyIsPublishable(props.companyId)),
  withSpinner(companyAdvertisementIsPendingSelector),
  withSpinner(companyProspectIsPendingSelector),
  connect(mapStateToProps)
)(PreviewAdvertisement)
