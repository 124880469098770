import {
  FETCH_COMPANY_ADVERTISEMENT_ANONYMIZED,
  UPDATE_COMPANY_ADVERTISEMENT_ANONYMIZED,
  RESET_COMPANY_ADVERTISEMENT_ANONYMIZED,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_ADVERTISEMENT_ANONYMIZED:
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
        },
      }
    case UPDATE_COMPANY_ADVERTISEMENT_ANONYMIZED:
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
          anonymized: action.anonymized,
        },
      }
    case RESET_COMPANY_ADVERTISEMENT_ANONYMIZED:
      return {
        ...state,
        [action.companyId]: {},
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
