import { compose } from 'redux'
import { connect } from 'react-redux'

import { fetchCompany } from 'actions'
import {
  companySelector,
  companyIsPendingSelector,
  companyEstimatedValueSelector,
  companyAdvertisementForSaleSelector,
  companyIsPublishableSelector,
} from 'selectors'

import withFetchedData from 'components/withFetchedData'
import withSpinner from 'components/withSpinner'
import Company from 'components/Company'

const mapStateToProps = (state, ownProps) => ({
  ...companySelector(state, ownProps),
  advertisementForSale: companyAdvertisementForSaleSelector(state, ownProps),
  claimed: false,
  estimatedValue: companyEstimatedValueSelector(state, ownProps),
  publishable: companyIsPublishableSelector(state, ownProps),
})

export default compose(
  withFetchedData(props => fetchCompany(props.companyId), false),
  withSpinner(companyIsPendingSelector),
  connect(mapStateToProps)
)(Company)
