import { call, put, takeEvery } from 'redux-saga/effects'

import * as api from 'api'
import {
  FETCH_BOUGHT_PRODUCTS_SUCCESS,
  FETCH_BOUGHT_PRODUCTS_ERROR,
  FETCH_BOUGHT_PRODUCTS,
} from 'actionTypes'

function* fetchBoughtProducts() {
  try {
    const boughtProducts = yield call(api.fetchBoughtProducts)
    const basicLicence = yield call(api.fetchBasicLicnences)
    yield put({
      type: FETCH_BOUGHT_PRODUCTS_SUCCESS,
      boughtProducts,
      basicLicence,
    })
  } catch ({ message }) {
    yield put({ type: FETCH_BOUGHT_PRODUCTS_ERROR, message, pending: false })
  }
}

function* saga() {
  yield takeEvery(FETCH_BOUGHT_PRODUCTS, fetchBoughtProducts)
}

export default saga
