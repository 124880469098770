import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Link from 'components/Link'
import ModalButton from '../../components/ModalButton'
import BuyModal from './BuyModal'

import {
  fetchCompany,
  fetchCompanyAdvertisement,
  fetchCompanyIsEditable,
  fetchCompanyIsPublishable,
} from 'actions'
import {
  companySelector,
  companyIsPendingSelector,
  companyAdvertisementIsPendingSelector,
  companyAdvertisementSelector,
  companyIsEditableSelector,
  companyIsPublishableSelector,
} from 'selectors'
import { border, colors } from 'styles'
import { h4, body } from 'styles/text'

import withSpinner from 'components/withSpinner'
import withFetchedData from 'components/withFetchedData'
import DeleteProspectModal from './DeleteProspectModal'

const Wrapper = styled.div`
  border-radius: 0.4rem;
  border: ${border};
  background-color: ${colors.white};
  padding: 0 3rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Name = styled.div`
  ${h4};
`

const Status = styled.div`
  ${body};
  flex-grow: 1;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`

const DeleteButton = styled(ModalButton)`
  height: 3rem;
  width: 8rem;
`

const RequestButton = styled(ModalButton)`
  margin: 0 1rem;
  max-height: 2rem;
`

const status = ({ published, editable, publishable }) => {
  if (published) {
    return 'Publisert'
  } else if (publishable) {
    return 'Annonse upublisert'
  } else if (editable) {
    return 'Prospektutarbeidelse'
  } else {
    return 'Ikke publisert'
  }
}

const ClaimedCompany = ({
  companyId,
  name,
  editable,
  publishable,
  published,
  unboughtCompanies,
}) => (
  <Wrapper>
    <div>
      <Name>{name ?? companyId}</Name>
      <Status>Status: {status({ published, editable, publishable })}</Status>
    </div>
    <Buttons>
      {unboughtCompanies.includes(companyId) ? (
        <RequestButton
          title={`Org.nr: ${companyId}`}
          renderModalContent={props => (
            <BuyModal
              {...props}
              companyId={'companyId'}
              buttonText={'Kom i gang helt gratis'}
              buyerUserId={'buyer'}
              orgNr={companyId}
              status={true}
              removed={false}
            />
          )}
        >
          Start prosjekt
        </RequestButton>
      ) : (
        <Link to={`/prospect/${companyId}`}>Åpne prosjekt</Link>
      )}
      <DeleteButton
        title={`Slett prosjekt: ${name ?? companyId}`}
        renderModalContent={props => (
          <DeleteProspectModal {...props} orgNumber={companyId} />
        )}
      >
        Slett
      </DeleteButton>
    </Buttons>
  </Wrapper>
)

const mapStateToProps = (state, ownProps) => ({
  ...companySelector(state, ownProps),
  ...companyAdvertisementSelector(state, ownProps),
  editable: companyIsEditableSelector(state, ownProps),
  publishable: companyIsPublishableSelector(state, ownProps),
})

export default compose(
  withFetchedData(props => fetchCompany(props.companyId)),
  withFetchedData(props => fetchCompanyAdvertisement(props.companyId)),
  withFetchedData(props => fetchCompanyIsEditable(props.companyId)),
  withFetchedData(props => fetchCompanyIsPublishable(props.companyId)),
  withSpinner(companyIsPendingSelector),
  withSpinner(companyAdvertisementIsPendingSelector),
  connect(mapStateToProps)
)(ClaimedCompany)
