import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fetchCompany } from 'actions'
import { companySelector, companyIsPendingSelector } from 'selectors'

import withSpinner from 'components/withSpinner'
import withFetchedData from 'components/withFetchedData'
import { h3, h4 } from '../../../styles/text'
import { border, colors } from 'styles'

import ModalButton from '../../../components/ModalButton'
import RequestModal from './RequestModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const RequestRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  border: ${border};
  padding: 3rem;
  border-radius: 0.5rem;
  height: 12rem;
  justify-content: space-between;
  background-color: ${colors.white};
  align-items: center;
`

const RequestButton = styled(ModalButton)`
  margin: 0 1rem;
`

const Heading = styled.div`
  ${h4}
`

const BuyerName = styled.div`
  ${h4}
  width: 28rem;
`

const ApprovedRequest = ({ companyId, buyerInfo, name }) => {
  const numberOfRequests = Object.keys(buyerInfo).reduce((prev, buyer) => {
    if (buyerInfo[buyer].accepted) {
      return prev + 1
    } else {
      return prev
    }
  }, 0)
  return (
    <Wrapper>
      {numberOfRequests > 0 ? <Heading>{name}</Heading> : null}
      {Object.keys(buyerInfo).map(buyer => {
        if (buyerInfo[buyer].accepted) {
          return (
            <RequestRow key={buyer}>
              <BuyerName>{buyerInfo[buyer].buyerName}</BuyerName>
              <div>
                <RequestButton
                  title={`Fjern tilgang til forespørsel for: ${buyerInfo[buyer].buyerName}`}
                  renderModalContent={props => (
                    <RequestModal
                      {...props}
                      companyId={companyId}
                      message={
                        'Du fjerner nå tilgang til prospektet. Er du sikker?'
                      }
                      buttonText={'Fjern tilgang'}
                      buyerUserId={buyer}
                      status={false}
                      removed={true}
                    />
                  )}
                >
                  Fjern tilgang
                </RequestButton>
              </div>
            </RequestRow>
          )
        } else {
          return null
        }
      })}
    </Wrapper>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...companySelector(state, ownProps),
})

export default compose(
  withFetchedData(props => fetchCompany(props.companyId)),
  withSpinner(companyIsPendingSelector),
  connect(mapStateToProps)
)(ApprovedRequest)
