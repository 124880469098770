import React from 'react'
import styled from 'styled-components'

import { border } from 'styles'
import { small } from 'styles/text'

const Table = styled.table`
  display: block;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: ${border};
    ${small};
    width: 20%;
    text-align: left;
    font-weight: normal;
    vertical-align: top;
    padding: 3px;

    @media (min-width: 768px) {
      padding: 7px 5px;
    }
  }

  td {
    @media (min-width: 768px) {
      padding: 10px 5px;
    }
  }
`

const Th = styled.th`
  &:nth-child(1) {
    ${props => props.active && 'background-color: #DE4D5F;'};
    ${props => props.active && 'color: white;'};
    ${props => props.active && 'border-bottom-color: white;'};
  }

  &:nth-child(2) {
    ${props => props.active && 'background-color: #F9EE60;'};
    ${props => props.active && 'border-bottom-color: white;'};
  }

  &:nth-child(3) {
    ${props => props.active && 'background-color: #AEDFBF;'};
    ${props => props.active && 'border-bottom-color: white;'};
  }

  &:nth-child(4) {
    ${props => props.active && 'background-color: #7BD199;'};
    ${props => props.active && 'border-bottom-color: white;'};
  }

  &:nth-child(5) {
    ${props => props.active && 'background-color: #49C474;'};
    ${props => props.active && 'color: white;'};
    ${props => props.active && 'border-bottom-color: white;'};
  }
`

const Td = styled.td`
  &:nth-child(1) {
    ${props => props.active && 'background-color: #DE4D5F;'};
    ${props => props.active && 'color: white;'};
  }

  &:nth-child(2) {
    ${props => props.active && 'background-color: #F9EE60;'};
  }

  &:nth-child(3) {
    ${props => props.active && 'background-color: #AEDFBF;'};
  }

  &:nth-child(4) {
    ${props => props.active && 'background-color: #7BD199;'};
  }

  &:nth-child(5) {
    ${props => props.active && 'background-color: #49C474;'};
    ${props => props.active && 'color: white;'};
  }
`

const colors = ["#FA7383", "#F9EE60", "#AEDFBF", "#7BD199", "#49C474"]
export default ({ value, active, labels }) => (
  <Table>
    <thead>
      <tr>
        {[
          'Ikke tilfreds- stillende',
          'Svak',
          'Tilfreds- stillende',
          'God',
          'Meget god',
        ].map((text, index) => (
          <Th key={index} active={index === active} style={index === active ? {background: colors[index]} : {background: "#fff"}}>
            {text}
          </Th>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        {labels.map((label, index) => (
          <Td key={index} active={index === active} style={index === active ? {background: colors[index]} : {background: "#fff"}}>
            {label}
          </Td>
        ))}
      </tr>
    </tbody>
  </Table>
)
