import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { updateCompanyAdvertisement } from 'actions'
import {
  companyAdvertisementIsAnonymizedSelector,
  companyAdvertisementIsPendingSelector,
} from 'selectors'
import { colors, border } from 'styles'

import withSpinner from 'components/withSpinner'
import { AnonLabel, Checkbox } from 'components/form'
import InfoBoxComponent, { Heading } from './InfoBox'

const Wrapper = styled(InfoBoxComponent)`
  background-color: ${colors.white};
  border: ${border};
`

class Anonymize extends React.Component {
  state = {
    anonymized: this.props.anonymized || false,
  }

  render() {
    return this.props.publishable ? (
      <Wrapper>
        <Heading>Anonymiser annonse</Heading>
        <p>
          Ved anonymisering blir sensitiv informasjon, som kan bidra til å
          identifisere bedriften, automatisk skjult. I tillegg kan du skjule
          ytterligere informasjon etter eget ønske.
        </p>
        <AnonLabel>
          <Checkbox
            checked={this.state.anonymized}
            onChange={e => {
              this.setState({ anonymized: e.target.checked })
              this.props.updateCompanyAdvertisement(this.props.companyId, {
                anonymized: e.target.checked,
              })
            }}
          />
          Anonymiser annonsen
        </AnonLabel>
      </Wrapper>
    ) : null
  }
}

const mapStateToProps = (state, ownProps) => ({
  anonymized: companyAdvertisementIsAnonymizedSelector(state, ownProps),
})

const mapDispatchToProps = { updateCompanyAdvertisement }

export default compose(
  withSpinner(companyAdvertisementIsPendingSelector),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Anonymize)
