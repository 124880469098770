import {
  FETCH_EXCLUSIVITIES,
  FETCH_EXCLUSIVITIES_ERROR,
  FETCH_EXCLUSIVITIES_SUCCESS,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXCLUSIVITIES: {
      return { ...state, pending: true }
    }
    case FETCH_EXCLUSIVITIES_SUCCESS:
      return { ...state, exclusivities: action.exclusivities, pending: false }
    case FETCH_EXCLUSIVITIES_ERROR:
      return { ...state, pending: false, error: true, message: action.message }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
