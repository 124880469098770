import {
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOG_IN,
  LOG_IN_WITH_TOKEN,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  CHECK_AUTH_STATUS_SUCCESS,
} from 'actionTypes'

export default (state = { pending: true }, action) => {
  switch (action.type) {
    case REGISTER:
    case LOG_IN:
    case LOG_OUT:
      return {
        ...state,
        email: action.email || state.email,
        pending: true,
      }
    case LOG_IN_WITH_TOKEN:
      return {
        ...state,
        pending: true,
      }
    case REGISTER_SUCCESS:
    case LOG_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        user: action.user,
        authenticated: true,
      }
    case REGISTER_ERROR:
      switch (action.message) {
        case 'The email address is badly formatted.':
          return {
            ...state,
            pending: false,
            authenticated: false,
            registerError: {
              email: 'Ugyldig e-post',
            },
          }
        case 'Password should be at least 6 characters':
          return {
            ...state,
            authenticated: false,
            pending: false,
            registerError: {
              password: 'Passordet må inneholde minst 6 bokstaver',
            },
          }
        case 'The email address is already in use by another account.':
          return {
            ...state,
            authenticated: false,
            pending: false,
            registerError: {
              email: 'Denne e-posten er allerede i bruk',
            },
          }
        default:
          return {
            ...state,
            pending: false,
            authenticated: false,
            registerError: {
              general: action.message,
            },
          }
      }
    case LOG_IN_ERROR:
      switch (action.message) {
        case 'The email address is badly formatted.':
          return {
            ...state,
            pending: false,
            authenticated: false,
            logInError: {
              email: 'Ugyldig e-post',
            },
          }
        case 'There is no user record corresponding to this identifier. The user may have been deleted.':
          return {
            ...state,
            authenticated: false,
            pending: false,
            logInError: {
              email: 'Det finnes ingen bruker med den e-posten',
            },
          }
        case 'The password is invalid or the user does not have a password.':
          return {
            ...state,
            authenticated: false,
            pending: false,
            logInError: {
              password: 'Ugyldig passord',
            },
          }
        default:
          return {
            ...state,
            authenticated: false,
            pending: false,
            logInError: {
              general: action.message,
            },
          }
      }
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
        pending: false,
        user: undefined,
      }
    case CHECK_AUTH_STATUS_SUCCESS:
      if (action.user) {
        return {
          ...state,
          user: action.user,
          authenticated: true,
          pending: false,
        }
      } else {
        return {
          ...state,
          authenticated: false,
          pending: false,
        }
      }
    default:
      return state
  }
}
