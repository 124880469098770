import { FETCH_CONTENT_SUCCESS } from 'actionTypes'
import { SERVICES_PAGE } from 'contentTypes'

export default (state = { help: {} }, action) => {
  switch (action.type) {
    case FETCH_CONTENT_SUCCESS:
      switch (action.contentType) {
        case SERVICES_PAGE:
          return {
            ...state,
            servicesPage: action.content,
          }
        default:
          return state
      }
    default:
      return state
  }
}
