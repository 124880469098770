import React from 'react'
import styled from 'styled-components'

import { border } from 'styles'
import { h5, h3 } from 'styles/text'

import Expandable from 'components/Expandable'
import Likviditetsgrad1Table from './Likviditetsgrad1Table'
import OverskuddsprosentTable from './OverskuddsprosentTable'
import EgenkapitalandelTable from './EgenkapitalandelTable'
import SellersComment from 'components/SellersComment'
import File from 'components/File'

const Heading = styled.div`
  ${h5};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${border};
  padding-top: 10px;
  margin-top: 10px;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const Content = styled.div`
  margin-top: 14px;
`

const ContentHeading = styled.h3`
  ${h3};
`

const KeyFigures = props => {
  return (
    <React.Fragment>
      <Heading>Nøkkeltall ({props.RegnskapsAvAr})</Heading>
      <Expandable
        renderHeader={({ open }) => (
          <Header open={open}>
            <div>
              <div>Likviditetsgrad 1</div>
              <div>
                {props.Likviditetsgrad1 &&
                  props.Likviditetsgrad1.toLocaleString('nb')}
              </div>
            </div>
            <img src="/toggle_down.svg" alt="" />
          </Header>
        )}
        renderContent={() => (
          <Content>
            <Likviditetsgrad1Table value={props.Likviditetsgrad1} />
          </Content>
        )}
      />
      <Expandable
        renderHeader={({ open }) => (
          <Header open={open}>
            <div>
              <div>Resultat av driften</div>
              <div>
                {props.Overskuddsprosent &&
                  props.Overskuddsprosent.toLocaleString('nb')}{' '}
                %
              </div>
            </div>
            <img src="/toggle_down.svg" alt="" />
          </Header>
        )}
        renderContent={() => (
          <Content>
            <OverskuddsprosentTable value={props.Overskuddsprosent} />
          </Content>
        )}
      />
      <Expandable
        renderHeader={({ open }) => (
          <Header open={open}>
            <div>
              <div>Egenkapitalandel</div>
              <div>
                {props.Egenkapitalandel &&
                  props.Egenkapitalandel.toLocaleString('nb')}{' '}
                %
              </div>
            </div>
            <img src="/toggle_down.svg" alt="" />
          </Header>
        )}
        renderContent={() => (
          <Content>
            <EgenkapitalandelTable value={props.Egenkapitalandel} />
          </Content>
        )}
      />
      {props.keyFiguresFiles && Object.keys(props.keyFiguresFiles).length > 0 && (
        <div>
          <ContentHeading>Vedlegg</ContentHeading>
          <ul>
            {Object.entries(props.keyFiguresFiles || {}).map(([path, name]) => (
              <li key={path}>
                <File path={path} name={name} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <SellersComment {...props}>{props.keyFiguresComment}</SellersComment>
    </React.Fragment>
  )
}

export default KeyFigures
