import { database, currentUserId } from './firebase'

const getProduct = licenceType =>
  database
    .ref(`/users/${currentUserId()}/${licenceType}`)
    .once('value')
    .then(snapshot => snapshot.val() || {})

export const fetchBoughtProducts = () =>
  Promise.all([getProduct('fullService'), getProduct('basicLicence')]).then(
    ([full, basic]) => ({ full, basic })
  )

export const fetchBasicLicnences = () =>
  database
    .ref(`/users/${currentUserId()}/basicLicence`)
    .once('value')
    .then(snapshot => snapshot.val() || {})

export const fetchBoughtProductByOrgNr = orgNr => {
  database
    .ref(`/users/${currentUserId()}/basicLicence/${orgNr}`)
    .once('value')
    .then(snapshot => snapshot.val() || false)
}
