import React from 'react'
import styled from 'styled-components'
import { colors, contentWidth, border } from 'styles'
import { h2, body } from 'styles/text'
import Button from '../../components/Button'

const Wrapper = styled.div`
  padding: 30px 20px 0;

  @media (min-width: 500px) {
    background-color: ${colors.whiteHover};
  }

  @media (min-width: 700px) {
    padding: 100px 20px 0;
  }
`

const Heading = styled.h1`
  ${h2};
  max-width: ${contentWidth};

  margin: 0 auto 10px;

  @media (min-width: 700px) {
    margin: 0 auto 40px;
  }
`

const Services = ({}) => (
  <Wrapper>
    <Heading>Kjøp Nobema</Heading>
    <Button onClick={() => {}}>kjøpt tjenste for masse penger</Button>
  </Wrapper>
)

export default Services
