import qs from 'qs'

import fetch from './fetch'
import { capitalize } from 'utils'

export const search = async ({ q, filter, sort, from }) => {
  const response = await fetch(
    `/api/search?${qs.stringify({ q, filter, sort, from })}`,
    {
      mode: 'cors',
    }
  )

  const json = await response.json()
  return {
    companies: json.companies.hits,
    advertisements: json.advertisements.hits,
  }
}

export const fetchSuggestions = async query => {
  const response = await fetch(`/api/search/autocomplete?prefix=${query}`, {
    mode: 'cors',
  })
  const json = await response.json()

  return Object.keys(json)
    .filter(name => json[name] && json[name].length)
    .map(name => ({
      name,
      suggestions: json[name].map(capitalize),
    }))
}
