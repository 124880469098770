import axios from 'axios'
import { backendUrl } from '../../environment'
import { getIdToken } from '../../api/firebase'

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    mode: 'cors',
  },
}

// Returns a preview of files
export const uploadFiles = (token, orgNumber, files, path) => {
  const formData = new FormData()
  const filesToUpload = [...files]

  //Add files to formdata
  filesToUpload.forEach(file => {
    formData.append('uploadedFiles', file, file.name)
  })

  formData.append('path', path)
  return axios
    .post(
      `${backendUrl}/api/files/${orgNumber}?token=${token}`,
      formData,
      config
    )
    .then(response => {
      const filepaths = response.data.map(img => ({
        filepath: img.filepath,
        url: img.url,
      }))
      return filepaths
    })
    .catch(error => {
      console.log('Upload error:', error)
    })
}

export const uploadFilesFromList = (orgNumber, files) => {
  const formData = new FormData()
  const filesToUpload = [...files]

  //Add files to formdata
  filesToUpload.forEach(file => {
    formData.append('uploadedFiles', file, file.name)
  })
  formData.append('path', 'prospect')
  return getIdToken()
    .then(gottenToken =>
      axios.post(
        `${backendUrl}/api/files/${orgNumber}?token=${gottenToken}`,
        formData,
        config
      )
    )
    .then(response => {
      const filepaths = response.data.map(img => img.filepath)
      return filepaths
    })
    .catch(e => console.log(e))
}

export const getFilesFromStorage = (token, orgNumber, filepaths, path) => {
  return axios
    .post(`${backendUrl}/api/getfiles/${orgNumber}?token=${token}`, {
      requestedFiles: filepaths,
      path: path,
    })
    .then(res => res)
    .catch(e => console.log(e))
}

export const getFilesFromStorage2 = (orgNumber, filepaths, path) => {
  return getIdToken()
    .then(gottenToken =>
      axios.post(
        `${backendUrl}/api/getfiles/${orgNumber}?token=${gottenToken}`,
        {
          requestedFiles: filepaths,
          path: path,
        }
      )
    )
    .then(res => res)
    .catch(e => console.log(e))
}

export const deleteFileFromStorage = (orgNumber, file, path) => {
  return getIdToken().then(gottenToken => {
    return axios
      .post(`${backendUrl}/api/deletefiles/${orgNumber}?token=${gottenToken}`, {
        fileToDelete: file,
        path: path,
      })
      .then(res => {
        console.log(res)
        res.json().then(jsonRes => console.log('JSON Response', jsonRes))
      })
      .catch(e => console.log(e))
  })
}
