import fetch from './fetch'
import { getIdToken } from './firebase'

export const askForAccess = (advertisementId, data) =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/askForAccess/${advertisementId}`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ data, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in askForAccess, status code: ${res.status}`
        )
      }
      return res
    })

export const requestQuote = form =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/requestQuote`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ form, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in requestQuote, status code: ${res.status}`
        )
      }
      return res
    })

export const buyService = data =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/buyService`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ data, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in buyService, status code: ${res.status}`
        )
      }
      return res
    })

export const buyRepresentation = data =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/buyRepresentation`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ data, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in buyRepresentation, status code: ${res.status}`
        )
      }
      return res
    })

export const inviteHelper = data =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/inviteHelper`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ data, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in inviteHelper, status code: ${res.status}`
        )
      }
      return res
    })

export const contactNobema = data =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/contactNobema`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ data, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in contactNobema, status code: ${res.status}`
        )
      }
      return res
    })

export const contactSeller = data => {
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/contactSeller`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ data, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in contactSeller, status code: ${res.status}`
        )
      }
      return res
    })
}

export const initiateSale = data =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/initiateSale`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ data, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in contactSeller, status code: ${res.status}`
        )
      }
      return res
    })

export const notifyBuyer = data =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/services/notifyBuyer`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ data, token: idToken }),
      })
    )
    .then(res => {
      if (res.status !== 200) {
        throw new Error(
          `Request error in notifyBuyer, status code: ${res.status}`
        )
      }
      return res
    })

export const sendWelcomeEmail = (email, name) => {
  getIdToken().then(idToken =>
    fetch(`/api/services/welcome`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ email: email, name: name, token: idToken }),
    })
  )
}

export const getOwnedCompanies = async () => {
  const token = await getIdToken()
  const result = await fetch(`/api/companies/owned?token=${token}`)
  return result
}
