import React from 'react'
import styled from 'styled-components'
import { h6, subtitle } from 'styles/text'
import { wrapper, padding, content } from './styles'
import Expandable from 'components/Expandable'
import SellersComment from 'components/SellersComment'
import File from 'components/File'

const Wrapper = styled.div`
  ${wrapper};
`

const Heading = styled.h6`
  ${h6};
  ${padding};
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const Content = styled.div`
  ${content};

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Label = styled.span`
  font-weight: bold;
`

const ContentHeading = styled.h5`
  ${subtitle};
  margin: 0;
`
const KeyInfoList = styled.li`
  list-style-type: none;
  margin-bottom: 16px;
`
const KeyInfoItem = styled.div`
  margin: 0.5rem 0;
`

const fields = [
  {
    label: 'Antall Ansatte',
    value: props => props.employees,
  },
  {
    label: 'Hvorav over 60 år',
    value: props => props.eployeeOverSixtyAmount,
  },
  {
    label: 'Årsverk',
    value: props => props.manYears,
  },
  {
    label: 'Daglig leder',
    value: props => props.dagligLeder,
  },
  {
    label: 'Pensjon',
    value: props => props.pension,
  },
  {
    label: 'Særlige pensjonsopplysninger',
    value: props => props.extraPersonalInfo,
  },
]

const Employees = props => (
  <Wrapper>
    <Expandable
      renderHeader={({ open }) => (
        <Heading open={open}>
          <span>Personal</span>
          <img src="/toggle_down.svg" alt="" />
        </Heading>
      )}
      renderContent={() => (
        <Content>
          <ul>
            {fields.map(({ label, value }) =>
              value(props) || value(props) === 0 ? (
                <KeyInfoList key={label}>
                  <KeyInfoItem>
                    <Label>{label}: </Label>
                    {value(props)}
                  </KeyInfoItem>
                </KeyInfoList>
              ) : null
            )}
          </ul>
          {props.employees_Documents && (
            <div>
              <ContentHeading>Vedlegg</ContentHeading>
              <ul>
                {(props.employees_Documents || []).map(name => (
                  <KeyInfoList key={name}>
                    <KeyInfoItem>
                      <File
                        path={'prospect'}
                        name={name}
                        orgNumber={props.orgnr}
                      />
                    </KeyInfoItem>
                  </KeyInfoList>
                ))}
              </ul>
            </div>
          )}
          <SellersComment {...props}>{props.employeeComment}</SellersComment>
        </Content>
      )}
    />
  </Wrapper>
)

export default Employees
