import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMutation, useQueryClient } from 'react-query'
import { updateTerms, updateUserSettings } from '../api/users'
import { userNameSelector, userSettingsErrorSelector } from 'selectors'
import { validateEmail } from 'utils'

import { Form, Field, Label, Input, Select, Checkbox } from 'components/form'
import ButtonComponent from 'components/Button'
import InfoMessage from 'components/InfoMessage'
import Error from 'components/Error'
import { body, Body, h2 } from 'styles/text'
import { sendWelcomeEmail, getOwnedCompanies } from 'api/services'
import { setTermsStatus } from '../api/firebase'

const Wrapper = styled.div`
  width: calc(100% - 40px);
  max-width: 600px;
  margin: 40px auto;
`

const Welcome = styled.p`
  text-align: center;
  ${body}
`

const Button = styled(ButtonComponent)`
  margin-top: 20px;
  align-self: flex-end;
`

const H2 = styled.h2`
  ${h2}
  text-align: center;
`

const TermsText = styled.body`
  font-size: 1.8rem;
`

const validate = state => {
  let errors = {}
  if (!state.email) {
    errors.email = 'Epost er påkrevd'
  } else if (!validateEmail(state.email)) {
    errors.email = 'Eposten er ikke riktig formatert'
  }
  if (!state.phoneNumber) {
    errors.phoneNumber = 'Telefonnummer er påkrevd'
  }

  return errors
}

const PersonalInfoForm = props => {
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [termsOfService, setTermsOfService] = useState(false)

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const errors = validate({ email, phoneNumber, termsOfService })

  const queryClient = useQueryClient()

  const setPersonalInfo = useMutation(async () => {
    await updateUserSettings({ phoneNumber, email })
    await setTermsStatus(termsOfService)

    await sendWelcomeEmail(email, props.name)
    await getOwnedCompanies()
    queryClient.invalidateQueries('terms')
  })

  return (
    <Wrapper>
      <H2>Registrering</H2>
      <Welcome>
        Velkommen{' '}
        {props.name ? (
          <b>{props.name.split(',').reverse().join(' ')}</b>
        ) : (
          'til Nobema'
        )}
        !
      </Welcome>
      <Welcome>
        For at du skal kunne bruke Nobema trenger vi å vite litt om deg.
      </Welcome>
      {props.error && <Error>{props.error}</Error>}
      {setPersonalInfo.error && (
        <Error>{setPersonalInfo.error.toString()}</Error>
      )}
      <Form
        onSubmit={e => {
          e.preventDefault()

          if (Object.keys(errors).length !== 0) {
            window.scrollTo(0, 0)
            return
          }

          setPersonalInfo.mutate()
        }}
      >
        <Field>
          <Label>E-post</Label>
          <Input
            value={email}
            error={hasSubmitted && errors.email}
            onChange={e => setEmail(e.target.value)}
            placeholder="E-post"
          />
        </Field>
        <Field>
          <Label>Telefonnummer</Label>
          <Input
            value={phoneNumber}
            error={hasSubmitted && errors.phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            placeholder="Telefonnummer"
          />
        </Field>
        <Field>
          <Label>Brukervilkår</Label>
          <Body>
            Får å bruke Nobema må du godta Nobema sine brukervilkår. Ved å huke
            av bekrefter du Nobema sine brukervilkår.
            <a href="http://www.nobema.no/brukervilkaar" target="_blank">
              Ler mer om Nobema sine brukervilkår
            </a>
          </Body>
          <TermsText>
            Jeg aksepterer Nobema sin brukervilkår:{' '}
            <Checkbox
              style={{ marginLeft: '8px', transform: 'scale(2)' }}
              value={termsOfService}
              onChange={() => {
                setTermsOfService(!termsOfService)
              }}
            />
          </TermsText>
        </Field>
        <InfoMessage>
          Din info er trygg hos oss. Vi tar din sikkerthet på alvor, og lover at
          din informasjon ikke blir delt med tredjepart.{' '}
          <a href="http://www.nobema.no/personvern" target="_blank">
            Les mer om hvordan vi lagerer data.
          </a>
        </InfoMessage>
        <Button
          disabled={!termsOfService || setPersonalInfo.isLoading}
          pending={setPersonalInfo.isLoading}
        >
          Lagre
        </Button>
      </Form>
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  name: userNameSelector(state),
  error: userSettingsErrorSelector(state),
})

export default connect(mapStateToProps)(PersonalInfoForm)
