import {
  INVITE_HELPER,
  INVITE_HELPER_SUCCESS,
  CONTACT_NOBEMA,
  CONTACT_NOBEMA_SUCCESS,
} from 'actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case INVITE_HELPER:
      return {
        ...state,
        inviteHelper: 'pending',
      }
    case INVITE_HELPER_SUCCESS:
      return {
        ...state,
        inviteHelper: 'success',
      }
    case CONTACT_NOBEMA:
      return {
        ...state,
        contactNobema: 'pending',
      }
    case CONTACT_NOBEMA_SUCCESS:
      return {
        ...state,
        contactNobema: 'success',
      }
    default:
      return state
  }
}
