import { call, put, takeEvery } from 'redux-saga/effects'

import {
  FETCH_CONTENT,
  FETCH_CONTENT_SUCCESS,
  FETCH_CONTENT_ERROR,
} from 'actionTypes'
import * as api from 'api'

function* fetchContent({ contentType }) {
  try {
    const content = yield call(api.fetchContent, contentType)
    yield put({ type: FETCH_CONTENT_SUCCESS, contentType, content })
  } catch ({ message }) {
    yield put({ type: FETCH_CONTENT_ERROR, contentType, message })
  }
}

function* saga() {
  yield takeEvery(FETCH_CONTENT, fetchContent)
}

export default saga
