import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'
import { h4 } from 'styles/text'

const Wrapper = styled.div`
  position: absolute;
  background-color: ${colors.white};
  border-radius: 4px;
  width: 100%;
  top: calc(100% + 2px);
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 20px 10px;
  max-height: 500px;
  overflow-y: auto;
`

const Heading = styled.div`
  ${h4};
  color: ${colors.subtleText};
  margin-left: 10px;
`

const Suggestion = styled.div`
  background-color: ${props =>
    props.active ? colors.infoBackground : colors.white};
  margin: 1px 0;
  padding: 8px 20px;
  cursor: pointer;
`

const categoryTitles = {
  name: 'Bedrift',
  industry: 'Bransje',
  location: 'Sted',
}

const SuggestionList = ({
  suggestions,
  active = {},
  setHover,
  unsetHover,
  select,
}) => (
  <Wrapper>
    {suggestions.map(({ name, suggestions }, categoryIndex) => (
      <React.Fragment key={name}>
        <Heading>{categoryTitles[name]}</Heading>
        {suggestions.map((suggestion, suggestionIndex) => (
          <Suggestion
            key={suggestion}
            active={
              active.category === categoryIndex &&
              active.suggestion === suggestionIndex
            }
            onMouseEnter={() =>
              setHover({ category: categoryIndex, suggestion: suggestionIndex })
            }
            onMouseLeave={unsetHover}
            onMouseDown={e => {
              e.preventDefault()
              select(suggestion)
            }}
          >
            {suggestion}
          </Suggestion>
        ))}
      </React.Fragment>
    ))}
  </Wrapper>
)

export default SuggestionList
