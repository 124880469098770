import {
  FETCH_ADVERTISEMENT,
  FETCH_ADVERTISEMENT_SUCCESS,
  FETCH_ADVERTISEMENT_ERROR,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADVERTISEMENT:
      return {
        ...state,
        [action.advertisementId]: {
          ...state[action.advertisementId],
          pending: true,
        },
      }
    case FETCH_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        [action.advertisementId]: {
          ...state[action.advertisementId],
          ...action.advertisement,
          pending: false,
        },
      }
    case FETCH_ADVERTISEMENT_ERROR:
      return {
        ...state,
        [action.advertisementId]: {
          ...state[action.advertisementId],
          error: true,
          pending: false,
        },
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
