import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { Body, buttonText, body } from 'styles/text'
import { getIdToken } from '../../api/firebase'
import {
  uploadFiles,
  getFilesFromStorage,
  deleteFileFromStorage,
} from './FileUploaderCore'
import Spinner from '../Spinner'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const StyledInput = styled.input`
  padding: 0.75rem 1rem;
  cursor: pointer;
  opacity: 0;
  width: 100%;
`

const UploadButton = styled.button`
  ${buttonText};
  background-color: ${colors.black};
  border: none;
  color: ${colors.white};
  width: 100%;
  border-radius: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: -1;
  cursor: pointer;
`

const FileView = styled.div`
  width: 100%;
  background: ${colors.alabasterBackground};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #f0ede5;
`

const FileText = styled.a`
  ${body};
  word-break: break-all;
`

const DeleteButton = styled.button`
  border: none;
  border-radius: 50px;
  color: ${colors.white};
  background: ${colors.black};
  margin-left: auto;
`
const Img = styled.img`
  margin: 5px;
`

// Assumes file on the form = fileName.type
const getFileTypeImage = filename => {
  const fileType = filename.split('.')
  const len = filename.split('.').length

  if (['jpg', 'png', 'gif', 'webp', 'svg'].includes(fileType[len - 1])) {
    return '/file-image.svg'
  } else if (['doc', 'docx', 'pdf', 'txt'].includes(fileType[len - 1])) {
    return '/file-text.svg'
  } else {
    return '/file-unknown.svg'
  }
}

const DocumentUploader = props => {
  // Combines local files and files from store
  // Local state continas file objects while store only has filepaths to storage
  const [documents, setDocuments] = useState([])
  const [pending, setPending] = useState(false)
  const [token, setToken] = useState('')
  const { dispatcher, orgNumber, fpFromStore, viewOnly, path } = props

  useEffect(() => {
    getIdToken().then(gottenToken => setToken(gottenToken))
  }, [])

  // Create "file object" from any filepaths in store and add them to state.
  useEffect(() => {
    if (fpFromStore && token) {
      // Get Files from storage
      setPending(true)
      Promise.resolve(
        getFilesFromStorage(token, orgNumber, fpFromStore, path)
      ).then(res => {
        setPending(false)
        setDocuments(
          res.data.map(file => {
            return {
              name: file.filepath,
              ...file,
            }
          })
        )
      })
    }
  }, [token, fpFromStore, orgNumber, path])

  return (
    <Wrapper>
      {!viewOnly && (
        <>
          <UploadButton>
            {props.label ? props.label : 'Last opp dokument'}{' '}
            <img src="/small_plus.svg" alt="" />
          </UploadButton>
          <StyledInput
            type="file"
            multiple
            onChange={e => {
              setDocuments([...e.target.files, ...documents])
              // Upload files to storage
              setPending(true)
              Promise.resolve(
                uploadFiles(token, orgNumber, e.target.files, path)
              ).then(res => {
                // Dispatch filepaths to store
                if (fpFromStore) {
                  dispatcher([
                    ...fpFromStore,
                    ...res.map(document => document.filepath),
                  ])
                } else {
                  dispatcher(res.map(document => document.filepath))
                }
                setPending(false)
              })
            }}
          />
        </>
      )}
      {documents.map((document, index) => (
        <FileView key={document.name}>
          <Img src={getFileTypeImage(document.name)} alt="" />
          <FileText
            target="_blank"
            rel="noopener noreferrer"
            href={document.url}
          >
            {document.name.split('.').slice(3).join('.')}
          </FileText>
          {!viewOnly && (
            <DeleteButton
              key={`${document.name}_delete`}
              onClick={() => {
                const fileToDelete = documents[index]
                const updatedDocuments = [
                  ...documents.slice(0, index),
                  ...documents.slice(index + 1),
                ]
                setDocuments(updatedDocuments)
                dispatcher(updatedDocuments.map(document => document.filepath))

                deleteFileFromStorage(orgNumber, fileToDelete.filepath, path)
              }}
            >
              x
            </DeleteButton>
          )}
        </FileView>
      ))}
      {pending && <Spinner />}
    </Wrapper>
  )
}

export default DocumentUploader
