import React from 'react'
import styled from 'styled-components'

import { body } from 'styles/text'
import { getFilesFromStorage2 } from '../components/form/FileUploaderCore'

const StyledLink = styled.a`
  ${body};
  word-break: break-all;
`

class File extends React.Component {
  state = {
    url: '',
  }

  // Assume that this does only receive one file to show
  componentDidMount() {
    getFilesFromStorage2(
      this.props.orgNumber,
      [this.props.name],
      this.props.path
    ).then(res => {
      if (res) {
        this.setState({ url: res.data[0].url })
      }
    })
  }

  render() {
    return (
      <StyledLink href={this.state.url} target="_blank">
        {this.props.name.split('.').slice(3).join('.')}
      </StyledLink>
    )
  }
}

export default File
