import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { contactSeller } from 'actions'
import { link } from 'styles/text'

import withLogin from 'components/withLogin'
import { Form, Field, Label, Textarea } from 'components/form'
import Button from 'components/Button'

const Cancel = styled.div`
  ${link};
  text-align: center;
  margin: 18px 0 0;
  cursor: pointer;
`

const validate = state => {
  let errors = {}
  if (!state.message) {
    errors.message = 'Du må skrive en melding'
  }
  return errors
}

class ContactSellerForm extends React.Component {
  state = {
    message: '',
    errors: {},
    success: false,
  }

  render() {
    return (
      <Form
        onSubmit={e => {
          e.preventDefault()

          let errors = validate(this.state)
          this.setState({ errors })

          if (Object.keys(errors).length !== 0) {
            return
          }

          this.props.contactSeller({
            advertisementId: this.props.advertisementId,
            orgNr: this.props.orgnr,
            message: this.state.message,
          })
          this.setState({ success: true })
        }}
      >
        <Field>
          Denne meldingen blir sendt til selgeren av bedriften, sammen med ditt
          navn og epost adresse.
        </Field>
        <Field>
          {this.state.success ? (
            <Field>Din melding er sendt.</Field>
          ) : (
            <>
              <Label>Hva lurer du på?</Label>
              <Textarea
                value={this.state.message}
                error={this.state.errors.message}
                onChange={e => this.setState({ message: e.target.value })}
              />
            </>
          )}
        </Field>
        {!this.state.success && <Button>Send melding</Button>}
        <Cancel onClick={() => this.props.closeModal()}>
          {this.state.success ? 'Lukk' : 'Avbryt'}
        </Cancel>
      </Form>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = { contactSeller }

export default compose(
  withLogin,
  connect(mapStateToProps, mapDispatchToProps)
)(ContactSellerForm)
