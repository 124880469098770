import React, { useState } from 'react'

import styled from 'styled-components'

import { border, colors } from 'styles'
import { h4, body, Body, subtitle } from 'styles/text'

import Button from 'components/Button'
import { signNDA, removeNDAForBuyer } from '../../api/agreements'
import Link from 'components/Link'
import { formatDate } from '../../utils'

const Wrapper = styled.div`
  border-radius: 0.4rem;
  border: ${border};
  background-color: ${colors.white};
  padding: 0 3rem;
  height: 8rem;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`

const Name = styled.h4`
  ${h4};
  font-size: 1.8rem;
  width: 36rem;
  font-weight: 500;
`

const Label = styled.div`
  width: 24rem;
`

const ErrorText = styled.div`
  ${subtitle};
  color: ${colors.error};
`

const SuccessText = styled.div`
  ${subtitle};
  color: ${colors.success};
`

const ButtonGroup = styled.div`
  padding: 0 8px;
  width: 34rem;
  display: flex;
  justify-content: space-between;
`

const NDA = ({ status, companyId, seller, label, signedDate }) => {
  const [pending, setPending] = useState(false)
  const [warning, setWarning] = useState('')
  const [success, setSuccess] = useState('')

  const handleNDASign = () => {
    setPending(true)
    signNDA(companyId, seller)
    setSuccess('Videresender til signeringsportalen')
  }

  const handleRject = () => {
    removeNDAForBuyer(companyId, seller).then(status => {
      if (status === 200) {
        setWarning('')
        setSuccess('Forespørsel slettet')
      } else {
        setSuccess('')
        setWarning('Noe gikk galt...')
      }
    })
  }

  return (
    <>
      <Wrapper>
        <Name>
          <b>Erklæring:</b> {label || companyId}
        </Name>
        <Label>
          {signedDate ? (
            <Name>
              <b>Signert dato:</b> {formatDate(new Date(signedDate))}
            </Name>
          ) : (
            <Link to={`/advertisements/${companyId}`} target="_blank">
              Se annonse
            </Link>
          )}
        </Label>
        {status === 'pending' ? (
          <ButtonGroup>
            <Button pending={pending} onClick={() => handleNDASign()}>
              Les og signer
            </Button>
            <Button warning onClick={() => handleRject()}>
              Slett
            </Button>
          </ButtonGroup>
        ) : (
          <Body>Signert dokument sendt per epost</Body>
        )}
      </Wrapper>
      {warning && <ErrorText>{warning}</ErrorText>}
      {success && <SuccessText>{success}</SuccessText>}
    </>
  )
}

export default NDA
