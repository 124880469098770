import { call, put, takeEvery } from 'redux-saga/effects'

import * as api from 'api'
import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_PROSPECT,
  FETCH_COMPANY_PROSPECT_SUCCESS,
  FETCH_COMPANY_PROSPECT_ERROR,
  ADD_COMPANY_TO_FAVORITES,
  ADD_COMPANY_TO_FAVORITES_SUCCESS,
  ADD_COMPANY_TO_FAVORITES_ERROR,
  REMOVE_COMPANY_FROM_FAVORITES,
  REMOVE_COMPANY_FROM_FAVORITES_SUCCESS,
  REMOVE_COMPANY_FROM_FAVORITES_ERROR,
  FETCH_FAVORITED_COMPANIES,
  FETCH_FAVORITED_COMPANIES_SUCCESS,
  FETCH_FAVORITED_COMPANIES_ERROR,
  FETCH_CLAIMED_COMPANIES,
  FETCH_CLAIMED_COMPANIES_SUCCESS,
  FETCH_CLAIMED_COMPANIES_ERROR,
  FETCH_PROSPECT_READ_REQUESTS,
  FETCH_PROSPECT_READ_REQUESTS_SUCCESS,
  FETCH_PROSPECT_READ_REQUESTS_ERROR,
  FETCH_COMPANY_IS_EDITABLE,
  FETCH_COMPANY_IS_EDITABLE_SUCCESS,
  FETCH_COMPANY_IS_EDITABLE_ERROR,
  FETCH_COMPANY_IS_PUBLISHABLE,
  FETCH_COMPANY_IS_PUBLISHABLE_SUCCESS,
  FETCH_COMPANY_IS_PUBLISHABLE_ERROR,
  FETCH_ACCESSIBLE_PROSPECTS,
  FETCH_ACCESSIBLE_PROSPECTS_SUCCESS,
  FETCH_ACCESSIBLE_PROSPECTS_ERROR,
  FETCH_MARKETING,
  FETCH_MARKETING_ERROR,
  FETCH_MARKETING_SUCCESS,
} from 'actionTypes'

function* fetchCompany({ companyId }) {
  try {
    const company = yield call(api.fetchCompany, companyId)
    yield put({ type: FETCH_COMPANY_SUCCESS, companyId, company })
  } catch ({ message }) {
    yield put({ type: FETCH_COMPANY_ERROR, companyId, message })
  }
}

function* fetchCompanyProspect({ companyId }) {
  try {
    const companiesProspect = yield call(api.fetchCompanyProspect, companyId)
    yield put({
      type: FETCH_COMPANY_PROSPECT_SUCCESS,
      companyId,
      companiesProspect,
    })
  } catch ({ message }) {
    yield put({ type: FETCH_COMPANY_PROSPECT_ERROR, companyId, message })
  }
}

function* addCompanyToFavorites({ companyId }) {
  try {
    yield call(api.addCompanyToFavorites, companyId)
    yield put({ type: ADD_COMPANY_TO_FAVORITES_SUCCESS, companyId })
  } catch ({ message }) {
    yield put({ type: ADD_COMPANY_TO_FAVORITES_ERROR, companyId, message })
  }
}

function* removeCompanyFromFavorites({ companyId }) {
  try {
    yield call(api.removeCompanyFromFavorites, companyId)
    yield put({ type: REMOVE_COMPANY_FROM_FAVORITES_SUCCESS, companyId })
  } catch ({ message }) {
    yield put({ type: REMOVE_COMPANY_FROM_FAVORITES_ERROR, companyId, message })
  }
}

function* fetchFavoritedCompanies() {
  try {
    const favoritedCompanies = yield call(api.fetchFavoritedCompanies)
    yield put({ type: FETCH_FAVORITED_COMPANIES_SUCCESS, favoritedCompanies })
  } catch ({ message }) {
    yield put({ type: FETCH_FAVORITED_COMPANIES_ERROR, message })
  }
}

function* fetchClaimedCompanies() {
  try {
    const claimedCompanies = yield call(api.fetchClaimedCompanies)
    yield put({ type: FETCH_CLAIMED_COMPANIES_SUCCESS, claimedCompanies })
  } catch ({ message }) {
    yield put({ type: FETCH_CLAIMED_COMPANIES_ERROR, message })
  }
}

function* fetchProspectRequests() {
  try {
    const prospectRequests = yield call(api.fetchProspectRequests)
    yield put({ type: FETCH_PROSPECT_READ_REQUESTS_SUCCESS, prospectRequests })
  } catch ({ message }) {
    yield put({ type: FETCH_PROSPECT_READ_REQUESTS_ERROR, message })
  }
}

function* fetchAccessibleProspects() {
  try {
    const accessibleProspectRequests = yield call(api.fetchAccessibleProspects)
    yield put({
      type: FETCH_ACCESSIBLE_PROSPECTS_SUCCESS,
      accessibleProspectRequests,
    })
  } catch ({ message }) {
    yield put({ type: FETCH_ACCESSIBLE_PROSPECTS_ERROR, message })
  }
}

function* fetchCompanyIsEditable({ companyId }) {
  try {
    const companyIsEditable = yield call(api.fetchCompanyIsEditable, companyId)
    yield put({
      type: FETCH_COMPANY_IS_EDITABLE_SUCCESS,
      companyId,
      companyIsEditable,
    })
  } catch ({ message }) {
    yield put({ type: FETCH_COMPANY_IS_EDITABLE_ERROR, companyId, message })
  }
}

function* fetchCompanyIsPublishable({ companyId }) {
  try {
    const companyIsPublishable = yield call(
      api.fetchCompanyIsPublishable,
      companyId
    )
    yield put({
      type: FETCH_COMPANY_IS_PUBLISHABLE_SUCCESS,
      companyId,
      companyIsPublishable,
    })
  } catch ({ message }) {
    yield put({ type: FETCH_COMPANY_IS_PUBLISHABLE_ERROR, companyId, message })
  }
}

function* fetchMarketing() {
  try {
    const marketing = yield call(api.fetchMarketing)
    yield put({
      type: FETCH_MARKETING_SUCCESS,
      marketing,
    })
  } catch (error) {
    yield put({ type: FETCH_MARKETING_ERROR, message })
  }
}

function* saga() {
  yield takeEvery(FETCH_COMPANY, fetchCompany)
  yield takeEvery(FETCH_COMPANY_PROSPECT, fetchCompanyProspect)
  yield takeEvery(ADD_COMPANY_TO_FAVORITES, addCompanyToFavorites)
  yield takeEvery(REMOVE_COMPANY_FROM_FAVORITES, removeCompanyFromFavorites)
  yield takeEvery(FETCH_FAVORITED_COMPANIES, fetchFavoritedCompanies)
  yield takeEvery(FETCH_CLAIMED_COMPANIES, fetchClaimedCompanies)
  yield takeEvery(FETCH_COMPANY_IS_EDITABLE, fetchCompanyIsEditable)
  yield takeEvery(FETCH_COMPANY_IS_PUBLISHABLE, fetchCompanyIsPublishable)
  yield takeEvery(FETCH_PROSPECT_READ_REQUESTS, fetchProspectRequests)
  yield takeEvery(FETCH_ACCESSIBLE_PROSPECTS, fetchAccessibleProspects)
  yield takeEvery(FETCH_MARKETING, fetchMarketing)
}

export default saga
