import { average, median, lastYear } from './utils'
import projectedRevenue from './projectedRevenue'

export const workingCapitalOverRevenue = data =>
  median(
    data
      .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
      .map(({ SumOmlopsmidler, SumKortsiktigGjeld, Salgsinntekter }) => ({
        workingCapital: SumOmlopsmidler - SumKortsiktigGjeld,
        revenue: Salgsinntekter,
      }))
      .map(({ workingCapital, revenue }) => workingCapital / revenue)
      .map((_, index, array) => average(array.slice(index)))
  )

export default (data, year) => {
  if (data.length === 0) return

  const { RegnskapsAvAr, SumOmlopsmidler, SumKortsiktigGjeld } = lastYear(data)

  let workingCapital = SumOmlopsmidler - SumKortsiktigGjeld
  let diff, nextWorkingCapital

  for (let count = RegnskapsAvAr + 1; count <= year; count = count + 1) {
    nextWorkingCapital =
      projectedRevenue(data, count) * workingCapitalOverRevenue(data)
    diff = nextWorkingCapital - workingCapital
    workingCapital = nextWorkingCapital
  }

  return diff
}
