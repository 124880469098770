import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  addAdvertisementToFavorites,
  removeAdvertisementFromFavorites,
} from 'actions'
import {
  advertisementIsFavoritedSelector,
  favoritedAdvertisementsIsPendingSelector,
  isAuthenticatedSelector,
} from 'selectors'
import { colors } from 'styles'
import { subtitle } from 'styles/text'

import withLogin from 'components/withLogin'
import Modal from 'components/Modal'
import Heart from 'components/icons/Heart'

const LogInModal = withLogin(() => null)

const Wrapper = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  ${subtitle};

  &:hover,
  &:focus {
    color: ${colors.action};
  }
`

const Text = styled.span`
  margin-right: 5px;
`

class FavoriteAdvertisementButton extends React.Component {
  state = {
    hover: false,
  }

  render() {
    const {
      pending,
      favorited,
      removeAdvertisementFromFavorites,
      addAdvertisementToFavorites,
      noText,
      advertisementId,
      isAuthenticated,
    } = this.props

    if (pending) return null

    return (
      <React.Fragment>
        <Wrapper
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          onClick={e => {
            if (isAuthenticated) {
              favorited
                ? removeAdvertisementFromFavorites(advertisementId)
                : addAdvertisementToFavorites(advertisementId)
            } else {
              this.setState({ showModal: true })
            }
          }}
          onBlur={() => this.setState({ hover: false })}
          onFocus={() => this.setState({ hover: true })}
        >
          {!noText && <Text>{favorited ? 'Lagret' : 'Lagre'}</Text>}
          <Heart hover={this.state.hover} active={favorited} />
        </Wrapper>
        {this.state.showModal && (
          <Modal
            isOpen={true}
            onRequestClose={() => this.setState({ showModal: false })}
            title="Du må logge inn for å lagre bedriften"
          >
            <LogInModal />
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  favorited: advertisementIsFavoritedSelector(state, ownProps),
  pending: favoritedAdvertisementsIsPendingSelector(state, ownProps),
  isAuthenticated: isAuthenticatedSelector(state, ownProps),
})

const mapDispatchToProps = {
  addAdvertisementToFavorites,
  removeAdvertisementFromFavorites,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FavoriteAdvertisementButton
)
