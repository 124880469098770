import qs from 'qs'

import { urlQuerySelector } from './index'

export const searchIsPendingSelector = (state, props) => {
  let res = state.search.results[qs.stringify(props.query)] || {}
  return res.companies === undefined || res.advertisements === undefined
}

export const companiesSearchResultsSelector = (state, props) => {
  return (state.search.results[qs.stringify(props.query)] || {}).companies
}

export const advertisementsSearchResultsSelector = (state, props) =>
  (state.search.results[qs.stringify(props.query)] || {}).advertisements

export const sortSelector = state => urlQuerySelector(state).sort

export const urlQueryFilterSelector = state =>
  urlQuerySelector(state).filter || {}

export const suggestionsSelector = (state, props, type) => {
  if (type) {
    const suggestions = state.search.suggestions[props.value]
    return suggestions && suggestions.filter(({ name }) => name === type)
  } else {
    return state.search.suggestions[props.value]
  }
}

export const showAdvertisements = state => state.search.showAdvertisementView
export const showCompanies = state => state.search.showCompanyView
