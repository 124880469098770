import { lastYear } from './utils'
import cashFlowValue from './cashFlowValue'

export const equity = data => lastYear(data).SumEgenkapital

export const balance = data => {
  const { Bankinnskudd, SumInvesteringer, SumGjeld } = lastYear(data)

  return Bankinnskudd - SumInvesteringer - SumGjeld
}

export default (data, options) => {
  if (!data || !data.length) return NaN
  return Math.max(equity(data), cashFlowValue(data, options)) + balance(data)
}
