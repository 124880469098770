import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Input from 'components/form/Input'
import { link } from 'styles/text'

import withLogin from 'components/withLogin'
import ButtonComponent from 'components/Button'
import { deleteCompanyProspect } from '../../api/companies'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Cancel = styled.div`
  ${link};
  text-align: center;
  margin: 18px 0 0;
  cursor: pointer;
`

const Button = styled(ButtonComponent)`
  margin-top: 12px;
  align-self: center;
`

const ModalMessage = styled.p`
  text-align: center;
`

const DeleteProspectModal = ({ closeModal, orgNumber }) => {
  const [inputOrgNr, setInputOrgNr] = useState('')
  return (
    <Wrapper>
      <ModalMessage>
        Du er på vei til å slette dette prosjektet, er du sikker på at du vil
        gjøre dette? Dette medfører at all data inkludert, prospekt, annonse og
        tilganger vil forsvinne. Innbetalt brukerlisens refunders ikke.
      </ModalMessage>
      <Input
        placeholder="Skriv inn organisasjonsnummeret til bedriften du ønsker å slette"
        value={inputOrgNr}
        onChange={e => setInputOrgNr(e.target.value)}
      />
      <Button
        disabled={inputOrgNr.split(' ').join('') !== orgNumber}
        onClick={() => {
          deleteCompanyProspect(orgNumber).then(() => {
            window.location.reload()
            closeModal()
          })
        }}
      >
        Slett prosjekt
      </Button>
      <Cancel onClick={closeModal}>Avbryt</Cancel>
    </Wrapper>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default compose(
  withLogin,
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteProspectModal)
