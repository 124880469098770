import React from 'react'
import Switch from 'react-switch'

export const StyledSwitch = props => {

  return (
    <Switch
      checked={props.checked}
      onChange={props.onChange}
      uncheckedIcon={false}
      checkedIcon={false}
      height={props.height ?? 20}
      width={props.width ?? 40}
      onColor={"#296455"}
    />
  )
}
