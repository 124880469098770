import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { recordedYearsSelector } from 'selectors'

import Table from 'components/Table'

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const TableWrapper = styled.div`
  overflow-x: auto;
`

const resultatregnskapRows = [
  { name: 'Totalinntekt', bold: true },
  { name: 'Driftsinntekter', prop: 'Salgsinntekter' },
  { name: 'Annen driftsinntekt', prop: 'AnnenDriftsinntekt' },
  { name: 'Varekostnad' },
  { name: 'Beholdningsendring' },
  { name: 'Lønnskostnader', prop: 'Lonnskostnad' },
  { name: 'Avskrivninger' },
  { name: 'Nedskrivninger' },
  { name: 'Annen driftskostnad', prop: 'AnnenDriftskostnad' },
  { name: 'Driftsresultat', prop: 'DriftsResultat', bold: true },
  { name: 'Inntekt på investering i datterselskap', prop: 'InntektInvDatter' },
  {
    name: 'Inntekt på investering i annet foretak i samme konsern',
    prop: 'InntektInvKonsern',
  },
  {
    name: 'Inntekt på investering i tilknyttet selskap',
    prop: 'InntektInvAnnen',
  },
  {
    name: 'Renteinntekt fra foretak i samme konsern',
    prop: 'RenteinntektKonsern',
  },
  { name: 'Annen renteinntekt', prop: 'RenteinntektAnnen' },
  { name: 'Annen finansinntekt', prop: 'FinansinntektAnnen' },
  {
    name: 'Verdiendring av markedsbaserte finansielle omløpsmidler',
    prop: 'VerdiendringMarFinOmlopsmidler',
  },
  {
    name: 'Nedskrivning av andre finansielle omløpsmidler',
    prop: 'NedskrivningMarFinOmlopsmidler',
  },
  {
    name: 'Nedskrivning av finansielle anleggsmidler',
    prop: 'NedskrivningFinAnleggsmidler',
  },
  {
    name: 'Rentekostnad til foretak i samme konsern',
    prop: 'RentekostnadKonsern',
  },
  { name: 'Annen rentekostnad', prop: 'AnnenRentekostnad' },
  { name: 'Annen finanskostnad', prop: 'AnnenFinanskostnad' },
  {
    name: 'Ordinært resultat før skattekostnad',
    prop: 'OrdResultatForSkatt',
    bold: true,
  },
  { name: 'Skattekostnad på ordinært resultat', prop: 'SkattOrdResultat' },
  { name: 'Ordinært resultat', prop: 'OrdResultat', bold: true },
  { name: 'Ekstraordinær inntekt', prop: 'EkstraordInntekt' },
  { name: 'Ekstraordinær kostnad', prop: 'EkstraordKostnad' },
  {
    name: 'Skattekostnad på ekstraordinært resultat',
    prop: 'SkattEkstraordResultat',
  },
  { name: 'Sum skatter', prop: 'SumSkatt' },
  { name: 'Minoritetsinteresser', prop: 'ArsResultat' },
  { name: 'Årsresultat', prop: 'ArsResultat', bold: true },
  { name: 'Konsernbidrag' },
  { name: 'Utbytte' },
  { name: 'Overført fond for vurderingsforskjeller', prop: 'TilFondVurdFor' },
  { name: 'Overført annen egenkapital', prop: 'TilAnnenEgenkapital' },
]

const eiendelerRows = [
  { name: 'ANLEGGSMIDLER', prop: 'SumAnleggsmidler', bold: true },
  {
    name: 'Immaterielle eiendeler',
    prop: 'SumImmatrielleEiendeler',
    bold: true,
  },
  { name: '--Forskning og utvikling', prop: 'ForskningUtvikling' },
  { name: '--Konsesjoner, patenter, lisenser', prop: 'Konsesjoner' },
  { name: '--Utsatt skattefordel', prop: 'UtsattSkattefordel' },
  { name: '--Goodwill', prop: 'Goodwill' },
  { name: 'Varige driftsmidler', prop: 'SumVarigeDriftsmidler', bold: true },
  { name: '--Tomter bygninger og annen fast eiendom', prop: 'FastEiendom' },
  { name: '--Maskiner og anlegg', prop: 'MaskinerAnlegg' },
  { name: '--Skip, rigger, fly og lignende', prop: 'SkipRiggFly' },
  { name: '--Driftsløsøre, inventar, verktøy etc', prop: 'DriftsLosore' },
  {
    name: 'Finansielle anleggsmidler',
    prop: 'SumFinansielleAnleggsmilder',
    bold: true,
  },
  { name: '--Investeringer i datterselskap', prop: 'InvestDatter' },
  {
    name: '--Investeringer i annet foretak i samme konsern',
    prop: 'InvestAnnetKonsern',
  },
  { name: '--Lån til foretak i samme konsern', prop: 'Konsernfordring' },
  { name: '--Investeringer i tilknyttet selskap', prop: 'InvestTilknyttet' },
  { name: '--Lån til tilknyttet selskap', prop: 'LanTilknyttet' },
  { name: '--Investeringer i aksjer og andeler', prop: 'InvestAksjer' },
  { name: '--Obligasjoner og andre fordringer', prop: 'Obligasjoner' },
  { name: '--Pensjonsmidler', prop: 'Pensjonsmidler' },
  { name: 'Andre anleggsmidler', prop: 'AndreAnleggsmidler', bold: true },
  { name: 'OMLØPSMIDLER', prop: 'SumOmlopsmidler', bold: true },
  { name: 'Varer', prop: 'SumVarer', bold: true },
  { name: '--Lager råvarer', prop: 'LagerRavarer' },
  { name: '--Lager tilvirkede varer', prop: 'LagerUferdigeVarer' },
  { name: '--Lager ferdige varer', prop: 'LagerFerdigeVarer' },
  { name: 'Fordringer', prop: 'SumFordringer', bold: true },
  { name: '--Kundefordringer', prop: 'FordringerKunder' },
  { name: '--Andre fordringer', prop: 'FordringerAndre' },
  { name: '--Konsernfordringer', prop: 'FordringerKonsern' },
  {
    name: '--Krav på innbetaling av selskapskapital',
    prop: 'KravInnbetSelskapskapital',
  },
  { name: 'Investeringer', prop: 'SumInvesteringer', bold: true },
  {
    name: '--Aksjer og andeler i foretak i samme konsern',
    prop: 'AksjerKonsern',
  },
  { name: '--Markedsbaserte aksjer', prop: 'AksjerMarked' },
  { name: '--Markedsbaserte obligasjoner', prop: 'ObligasjonerMarked' },
  {
    name: '--Andre markedsbaserte finansielle investeringer',
    prop: 'AndreMarkedFinInv',
  },
  { name: '--Andre finansielle instrumenter', prop: 'AndreFinInst' },
  {
    name: 'Bankinnskudd, kontanter og lignende',
    prop: 'Bankinnskudd',
    bold: true,
  },
  { name: 'Andre omløpsmidler', prop: 'AndreOmlopsmidler', bold: true },
  { name: 'SUM EIENDELER', prop: 'SumEiendeler', bold: true },
]

const gjeldEgenkapitalRows = [
  { name: 'EGENKAPITAL', prop: 'SumEgenkapital', bold: true },
  { name: 'Innskutt egenkapital', prop: 'InnskuttEgenkapital', bold: true },
  { name: '--Selskapskapital', prop: 'Selskapskapital' },
  { name: '--Egne aksjer', prop: 'EgneAksjer' },
  { name: '--Overkursfond', prop: 'Overkursfond' },
  { name: '--Annen inskutt egenkapital', prop: 'SkyldKonsernbidrag' },
  { name: 'Opptjent egenkapital', prop: 'OpptjentEgenkapital', bold: true },
  { name: '--Fond for vurderingsforskjeller', prop: 'FondForVurd' },
  { name: '--Annen egenkapital', prop: 'AnnenEgenkapital' },
  { name: '--Minoritetsinteresser', prop: 'Minoritetsinteresser' },
  { name: 'GJELD', prop: 'SumGjeld', bold: true },
  { name: 'Langsiktig gjeld', prop: 'SumLangsiktigGjeld', bold: true },
  { name: '--Avsetninger for forpliktelser', prop: 'AvsetningForpliktelser' },
  { name: '----Pensjonsforpliktelser', prop: 'PensjonForpliktelser' },
  { name: '----Utsatt skatt', prop: 'UtsattSkatt' },
  { name: '----Andre avsetninger og forpliktelser', prop: 'AndreAvsetninger' },
  { name: '--Annen langsiktig gjeld', prop: 'AnnenLangsiktigGjeld' },
  { name: '----Konvertible lån', prop: 'KonvertibleLanLang' },
  { name: '----Obligasjonslån', prop: 'ObligasjonsLan' },
  {
    name: '----Gjeld til kredittinstitusjoner (langsiktig)',
    prop: 'GjeldKredittLang',
  },
  { name: '----Konserngjeld (langsiktig)', prop: 'GjeldKonsernLang' },
  { name: '----Ansvarlig lånekapital', prop: 'AnsvarligLanekapital' },
  { name: '----Øvrig langsiktig gjeld', prop: 'OvrigLangsiktigGjeld' },
  { name: 'Kortsiktig gjeld', prop: 'SumKortsiktigGjeld', bold: true },
  { name: '--Konvertible lån', prop: 'KonvertibleLanKort' },
  { name: '--Sertifikatlån', prop: 'SertifikatLan' },
  {
    name: '--Gjeld til kredittinstitusjoner (kortsiktig)',
    prop: 'GjeldKredittKort',
  },
  { name: '----Kassekreditt', prop: 'Kassekredittlimit' },
  { name: '--Leverandørgjeld', prop: 'LeverandorGjeld' },
  { name: '--Betalbar skatt', prop: 'BetalbarSkatt' },
  { name: '--Skyldig offentlige avgifter', prop: 'SkyldOffentligAvgift' },
  { name: '--Konserngjeld (kortsiktig)', prop: 'GjeldKonsernKort' },
  { name: '--Utbytte', prop: 'Utbytte' },
  { name: '--Annen kortsiktig gjeld', prop: 'AnnenKortsiktigGjeld' },
  { name: 'GJELD OG EGENKAPITAL', prop: 'SumGjeldEgenkapital', bold: true },
]

const Accounts = props => (
  <Wrapper>
    <TableWrapper>
      <Table
        small
        headers={[
          'Resultatregnskap (i 1000 NOK)',
          ...props.years.sort((a, b) => b - a),
        ]}
        data={resultatregnskapRows.map(({ name, prop, bold }) => ({
          bold,
          data: [
            name,
            ...props['okonomiDetaljerForetak']
              .sort((a, b) => b.RegnskapsAvAr - a.RegnskapsAvAr)
              .map(props => props[prop || name]),
          ],
        }))}
      />
    </TableWrapper>
    <TableWrapper>
      <Table
        small
        headers={['Eiendeler (i 1000 NOK)', ...props.years]}
        data={eiendelerRows.map(({ name, prop, bold }) => ({
          bold,
          data: [
            name,
            ...props['eiendelerForetak']
              .sort((a, b) => b.RegnskapsAvAr - a.RegnskapsAvAr)
              .map(props => props[prop || name]),
          ],
        }))}
      />
    </TableWrapper>
    <TableWrapper>
      <Table
        small
        headers={['Gjeld og egenkapital (i 1000 NOK)', ...props.years]}
        data={gjeldEgenkapitalRows.map(({ name, prop, bold }) => ({
          bold,
          data: [
            name,
            ...props['gjeldEgenkapitalForetak']
              .sort((a, b) => b.RegnskapsAvAr - a.RegnskapsAvAr)
              .map(props => props[prop || name]),
          ],
        }))}
      />
    </TableWrapper>
  </Wrapper>
)

const mapStateToProps = (state, ownProps) => ({
  years: recordedYearsSelector(state, ownProps),
})

export default connect(mapStateToProps)(Accounts)
