import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { openMenu, closeMenu } from 'actions'
import { isMenuOpenSelector } from 'selectors'
import { border, colors } from 'styles'

import MenuItem from '../MenuItem'
import Search from './Search'

const height = '40px'

const Wrapper = styled.div`
  border-bottom: ${border};
  position: fixed;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  z-index: 1;
`

const Offset = styled.div`
  height: ${height};
`

const Content = styled.div`
  height: ${height};
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
`

const Logo = styled.a`
  margin-right: auto;

  & > img {
    width: 93px;
  }
`

const SearchButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px;
  line-height: 0;
  margin-right: 10px;
`

const SearchIcon = styled.img`
  height: 16px;
`

const Hamburger = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px;
  line-height: 0;
`

const Icon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
`

const Menu = styled.ul`
  background-color: ${colors.white};
  margin: 0;
  padding: 20px;
  list-style: none;
  position: absolute;
  border-bottom: ${border};
  right: 0;
  left: 0;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

class SmallHeader extends React.Component {
  state = {
    search: false,
  }

  render() {
    return (
      <React.Fragment>
        <Wrapper>
          <Content>
            <Logo href="http://nobema.no">
              <img src="/logo.svg" alt="nobema" />
            </Logo>
            <Hamburger
              onClick={
                this.props.isMenuOpen
                  ? this.props.closeMenu
                  : this.props.openMenu
              }
            >
              <Icon
                src={this.props.isMenuOpen ? '/x_green.svg' : '/hamburger.svg'}
              />
            </Hamburger>
          </Content>
          {this.props.isMenuOpen && (
            <Menu>
              {this.props.menuItems.map((menuItem, index) => (
                <li key={index}>
                  <MenuItem {...menuItem} />
                </li>
              ))}
            </Menu>
          )}
          {this.state.search && (
            <Search close={() => this.setState({ search: false })} />
          )}
        </Wrapper>
        <Offset />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isMenuOpen: isMenuOpenSelector(state),
})

const mapDispatchToProps = { openMenu, closeMenu }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmallHeader)
