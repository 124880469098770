import {
  LOG_OUT,
  FETCH_MARKETING,
  FETCH_MARKETING_SUCCESS,
  FETCH_MARKETING_ERROR,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MARKETING:
      return {
        ...state,
        pending: true,
      }
    case FETCH_MARKETING_SUCCESS:
      return {
        ...state,
        ...action.marketing,
        pending: false,
      }
    case FETCH_MARKETING_ERROR:
      return {
        ...state,
        message: action.message,
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
