import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { body, h6 } from 'styles/text'
import KeyInformation from './KeyInformation'
import SalesArguments from './SalesArguments'
import Property from './Property'
import Price from './Price'
import Assets from './Assets'
import FinancialStructure from './FinancialStructure'
import Products from './Products'
import Marketing from './Marketing'
import Market from './Market'
import Agreements from './Agreements'
import Leasing from './Leasing'
import Employees from './Employees'
import { formatDate, prettyPrice } from '../../../utils'
import DocumentUploader from '../../form/DocumentUploader'
import { H3 } from '../../../styles/text'

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`

const EconBorder = styled.hr`
  border: 2px solid ${colors.primary};
  width: 100%;
`

const Label = styled.div`
  margin: 0 0 5px 5px;
  ${h6};
`

const Value = styled.div`
  margin-left: 0.5rem;
  ${body}
  color: ${colors.primaryGray};
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const Main = props => {
  const handleEmployees = () => {
    if (props.employeeBoolean) {
      return props.employees
    } else if (props.AnsatteData) {
      const lastIndex = props.AnsatteData.length - 1
      if (lastIndex <= 0) {
        return 0
      }
      return props.AnsatteData[lastIndex].AnsatteAntall
    }
    return false
  }

  return (
    <Wrapper className={props.className}>
      <Price {...props} />
      {props.shareForSale && (
        <div>
          <Label>Andel til salgs</Label>
          <Value>
            {props.shareForSale}
            {props.shareForSale.includes('%') ? '' : '%'}
          </Value>
        </div>
      )}
      {props.salesReason && (
        <div>
          <Label>Salgsårsak</Label>
          <Value>{props.salesReason}</Value>
        </div>
      )}
      <div>
        <Label>Selskapsnavn</Label>
        <Value>{props.name}</Value>
      </div>
      {props.companyWebsite && (
        <div>
          <Label>Selskapets nettside</Label>
          <Value>
            {props.companyWebsite.includes('http://') ||
            props.companyWebsite.includes('https://') ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.companyWebsite}
              >
                Åpne selskapets nettside
              </a>
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`http://${props.companyWebsite}`}
              >
                Åpne selskapets nettside
              </a>
            )}
          </Value>
        </div>
      )}
      <div>
        <Label>Org.nr</Label>
        <Value>{props.orgnr}</Value>
      </div>
      {!props.forSale && (
        <div>
          <Label>Stiftet</Label>
          <Value>{formatDate(props.founded)}</Value>
        </div>
      )}
      <div>
        <Label>Bransje</Label>
        <Value>
          {props.industryCode
            ? props.industryCode
            : `${props.grunnfakta.BransjeData.BransjeKode} ${props.grunnfakta.BransjeData.BransjeTekst}`}
        </Value>
      </div>
      {props.companyIngress && (
        <div>
          <Label>Om Bedriften</Label>
          <Value>{props.companyIngress}</Value>
        </div>
      )}
      <div>
        <Value>{props.companyDescriptionBody}</Value>
      </div>
      {props.purchaseArguments && props.purchaseArguments.arguments && (
        <SalesArguments {...props} />
      )}
      {/* information for /company goes here: */}
      {!props.forSale && (
        <>
          <div>
            <Label>Adresse</Label>
            <Value>{props.address.address[0]}</Value>
          </div>
          <div>
            <Label>Sted</Label>
            <Value>
              {props.address.postnumber}, {props.address.postplace}
            </Value>
          </div>
          <div>
            <Label>Kommune</Label>
            <Value>{props.address.municipality}</Value>
          </div>
          <div>
            <Label>Fylke</Label>
            <Value>{props.address.fylke}</Value>
          </div>
          <div>
            <Label>Ansatte</Label>
            <Value>{handleEmployees(props)}</Value>
          </div>
          {props.okonomiDetaljerForetak.length > 0 ? (
            <div>
              <Label>Omsetning</Label>
              <Value>
                {prettyPrice(
                  props.okonomiDetaljerForetak[
                    props.okonomiDetaljerForetak.length - 1
                  ].Totalinntekt * 1000
                )}{' '}
                NOK
              </Value>
            </div>
          ) : (
            <div>
              <Label>Omsetning</Label>
              <Value>0 NOK</Value>
            </div>
          )}
        </>
      )}
      {props.forSale && <KeyInformation {...props} />}
      {props.products && (
        <Products
          {...props.products}
          orgNumber={props.orgnr}
          attatchments={props.products_Documents}
        />
      )}
      <Market {...props} />
      {props.employeeBoolean && (
        <Employees
          {...props}
          attatchments={props.employee_Documents}
          orgNumber={props.orgnr}
        />
      )}
      {props.agreements && (
        <Agreements
          {...props.agreements}
          creditGurantee={props.creditGuarantee}
          debitGuarantee={props.debitGuarantee}
          orgNumber={props.orgnr}
          attatchments={props.agreements_Documents}
          comment={props.agreement_Comment}
        />
      )}
      {props.properties && (
        <Property
          orgNumber={props.orgnr}
          {...props.properties}
          attatchments={props.properties_Documents}
        />
      )}
      {props.assets && (
        <Assets
          orgNumber={props.orgnr}
          {...props.assets}
          attatchments={props.assets_Documents}
        />
      )}
      {props.leases && (
        <Leasing
          orgNumber={props.orgnr}
          {...props.leases}
          attatchments={props.lease_Documents}
        />
      )}
      {props.finances && (
        <FinancialStructure
          orgNumber={props.orgnr}
          {...props.finances}
          attatchments={props.finances_Documents}
        />
      )}
      {(props.marketingMethods ||
        props.salesMethods ||
        props.distributionMethods ||
        props.marketing_Documents ||
        props.marketingComment) && <Marketing {...props} />}
      <H3>Økonomi</H3>
      <EconBorder />
      {props.forSale && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {props.turnover ? (
            <div>
              <Label>
                {props.customEconomyYear
                  ? `Omsetning (${props.customEconomyYear})`
                  : `Omsetning`}
              </Label>
              <Value>{prettyPrice(props.turnover)} NOK</Value>
            </div>
          ) : null}
          {props.ek ? (
            <div>
              <Label>
                {props.customEconomyYear
                  ? `Egenkapital (${props.customEconomyYear})`
                  : `Egenkapital`}
              </Label>
              <Value>{prettyPrice(props.ek)} NOK</Value>
            </div>
          ) : null}
        </div>
      )}
      {props.customAccountYear && (
        <>
          <Label>Regnskap</Label>
          <DocumentUploader
            viewOnly
            orgNumber={props.orgnr}
            fpFromStore={props.customAccountYear}
            path={'prospect'}
          />
        </>
      )}
      {props.projectNumber && (
        <div style={{ alignItems: 'flex-end', marginTop: 120 }}>
          <Label>Prosjektnummer</Label>
          <Value>{props.projectNumber}</Value>
        </div>
      )}
    </Wrapper>
  )
}

export default Main
