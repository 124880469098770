import React from 'react'

import { Textarea, UploadFiles } from 'components/form'
import EditListOfLists, { mapToState } from './EditListOfLists'

import { uploadFilesFromList } from 'components/form/FileUploaderCore'

export const lists = {
  arguments: {
    heading: 'Kjøpsargument',
    buttonLabel: '+ Legg til kjøpsargument',
    fields: [
      {
        prop: 'name',
        label: 'Navn/betegnelse',
        placeholder: 'Tittel på argument',
      },
      {
        prop: 'body',
        label: 'Brødtekst',
        placeholder: 'Utdyp argumentet ditt',
        Component: ({ value, onChange }) => (
          <Textarea value={value} onChange={value => onChange(value)} />
        ),
      },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
}

class EditPurchaseArgumentsStructure extends React.Component {
  componentDidMount() {
    if (this.props.formValues.purchaseArguments) {
      this.setState({ ...this.props.formValues.purchaseArguments })
    } else {
      this.props.dispacth('purchaseArguments', {
        ...mapToState(lists, this.props),
        ...Object.keys(lists).reduce(
          (state, key) => ({
            ...state,
            [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
          }),
          {}
        ),
        purchaseArgumentsComment: this.props.purchaseArgumentsComment || '',
      })
    }
  }

  // Set initial state
  // Will be overwritten by componentDidMount() if arguments are found in store
  state = {
    ...mapToState(lists, this.props),
    ...Object.keys(lists).reduce(
      (state, key) => ({
        ...state,
        [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
      }),
      {}
    ),
    purchaseArgumentsComment: this.props.purchaseArgumentsComment || '',
  }

  removeItem = (list, index) => {
    const updatedArguments = {
      [list]: this.state[list].filter((_, i) => i !== index),
    }
    this.setState(updatedArguments)
    this.props.dispacth('purchaseArguments', {
      ...this.state,
      ...updatedArguments,
    })
  }

  addItem = (list, item) =>
    this.setState({
      [list]: [...this.state[list], item],
    })

  setData = (arg, index, key, value, rm) => {
    let oldFiles = []
    if (key === 'files' && this.state[arg][index][key] && !rm) {
      oldFiles = this.state[arg][index][key]
    }
    return {
      [arg]: this.state[arg].map((list, i) =>
        i === index
          ? {
              ...list,
              [key]: key === 'files' ? [...oldFiles, ...value] : value,
            }
          : list
      ),
    }
  }

  setValue = (arg, index, key, value) => {
    if (
      key === 'files' &&
      value.length > 0 &&
      typeof value[0].name === 'string'
    ) {
      uploadFilesFromList(this.props.orgNumber, value).then(res => {
        const purchaseArguments = this.setData(arg, index, key, res)
        this.props.dispacth('purchaseArguments', {
          ...this.state,
          ...purchaseArguments,
        })
        this.setState(purchaseArguments)
      })
    } else if (
      key === 'files' &&
      (value.length === 0 || typeof value[0].name !== 'string')
    ) {
      const purchaseArguments = this.setData(arg, index, key, value, true)
      this.props.dispacth('purchaseArguments', {
        ...this.state,
        ...purchaseArguments,
      })
      this.setState(purchaseArguments)
    } else {
      const purchaseArguments = this.setData(arg, index, key, value)
      this.props.dispacth('purchaseArguments', {
        ...this.state,
        ...purchaseArguments,
      })
      this.setState(purchaseArguments)
    }
  }

  setComment = value => {
    this.setState({ purchaseArgumentsComment: value })
    this.props.dispacth('purchaseArguments', {
      ...this.state,
      purchaseArgumentsComment: value,
    })
  }

  render() {
    return (
      <EditListOfLists
        {...this.props}
        lists={lists}
        state={this.state}
        removeItem={this.removeItem}
        addItem={this.addItem}
        setValue={this.setValue}
        setAnonymized={(key, value) => this.setState({ [key]: value })}
        required={true}
      />
    )
  }
}

export default EditPurchaseArgumentsStructure
