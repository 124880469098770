import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'

import { colors, border } from 'styles'
import { h5 } from 'styles/text'
import noScroll from 'no-scroll'

ReactModal.setAppElement('#root')

const Wrapper = styled.div`
  background-color: ${colors.white};
  border: ${border};
  border-radius: 4px;
  width: calc(100vw - 40px);
  max-width: 700px;
`

const Heading = styled.div`
  text-align: center;
  ${h5};
  padding: 20px 50px;
  border-bottom: ${border};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;

  @media (min-width: 768px) {
    padding: 40px 60px;
  }
`

const X = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`

class Modal extends React.Component {
  componentDidMount() {
    if (this.props.isOpen) {
      noScroll.on()
    }
  }

  componentWillUnmount() {
    noScroll.off()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.props.isOpen ? noScroll.on() : noScroll.off()
    }
  }

  render() {
    const { children, isOpen, onRequestClose, title, ...rest } = this.props

    if (!isOpen) return null

    return (
      <ReactModal
        style={{
          overlay: {
            backgroundColor: 'rgba(72, 72, 72, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
            zIndex: 1,
          },
          content: {
            backgroundColor: 'transparent',
            position: 'relative',
            top: 'auto',
            right: 'auto',
            bottom: 'auto',
            left: 'auto',
            border: 'none',
            borderRadius: 0,
            margin: '60px 0 20px',
            padding: 0,
            overflow: 'visible',
          },
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
        {...rest}
      >
        <Wrapper>
          <X onClick={onRequestClose}>
            <img src="/x.svg" alt="x" />
          </X>
          <Heading>{title}</Heading>
          <Content>{children}</Content>
        </Wrapper>
      </ReactModal>
    )
  }
}

export default Modal
