import React from 'react'

import connectAdvertisementForm from './connectAdvertisementForm'
import { Field, Label, Textarea, UploadFiles } from 'components/form'
import EditAdvertisementForm from './EditAdvertisementForm'

class EditEconomicFigures extends React.Component {
  state = {
    economicFiguresComment: this.props.economicFiguresComment || '',
    economicFiguresFiles: this.props.economicFiguresFiles
      ? Object.entries(this.props.economicFiguresFiles).map(([path, name]) => ({
          path,
          name,
        }))
      : [],
  }

  render() {
    return (
      <EditAdvertisementForm
        {...this.props}
        onSubmit={e => {
          e.preventDefault()
          this.props.updateCompanyAdvertisement(
            this.props.companyId,
            this.state
          )
        }}
      >
        <Field>
          <Label>Legg til vedlegg</Label>
          <UploadFiles
            value={this.state.economicFiguresFiles}
            onChange={economicFiguresFiles =>
              this.setState({ economicFiguresFiles })
            }
          />
        </Field>
        <Field>
          <Label>Legg til en kommentar</Label>
          <Textarea
            value={this.state.economicFiguresComment}
            onChange={e =>
              this.setState({ economicFiguresComment: e.target.value })
            }
          />
        </Field>
      </EditAdvertisementForm>
    )
  }
}

export default connectAdvertisementForm(EditEconomicFigures)
