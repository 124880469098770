import { call, put, takeEvery } from 'redux-saga/effects'
import * as api from 'api'
import {
  FETCH_PENDING_NDAS_ERROR,
  FETCH_PENDING_NDAS_SUCCESS,
  FETCH_PENDING_NDAS,
  FETCH_EXCLUSIVITIES_ERROR,
  FETCH_EXCLUSIVITIES_SUCCESS,
  FETCH_EXCLUSIVITIES
} from 'actionTypes'

function* fetchPendingNDAs() {
  try {
    const ndaResult = yield call(api.fetchPendingNDAs)
    yield put({ type: FETCH_PENDING_NDAS_SUCCESS, ndas: ndaResult })
  } catch ({ message }) {
    yield put({ type: FETCH_PENDING_NDAS_ERROR, message })
  }
}

function* fetchExclusivities() {
  try {
    const exclusivitiesResult = yield call(api.fetchExclusivities)
    yield put({ type: FETCH_EXCLUSIVITIES_SUCCESS, exclusivities: exclusivitiesResult }) 
  } catch ({message}) {
    yield put({ type: FETCH_EXCLUSIVITIES_ERROR })
  }
}

function* saga() {
  yield takeEvery(FETCH_PENDING_NDAS, fetchPendingNDAs)
  yield takeEvery(FETCH_EXCLUSIVITIES, fetchExclusivities)
}

export default saga
