import {
  FETCH_BOUGHT_PRODUCTS,
  FETCH_BOUGHT_PRODUCTS_SUCCESS,
  FETCH_BOUGHT_PRODUCTS_ERROR,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOUGHT_PRODUCTS:
      return {
        ...state,
        pending: true,
      }
    case FETCH_BOUGHT_PRODUCTS_SUCCESS:
      return { ...action.boughtProducts, pending: false }
    case FETCH_BOUGHT_PRODUCTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.message,
      }
    default:
      return state
  }
}
