import fetch from './fetch'
import { getIdToken } from './firebase'
import axios from 'axios'
import { backendUrl } from '../environment'
import { fetchAdvertisement } from './advertisements'

export const fetchPendingNDAs = () =>
  getIdToken().then(token =>
    fetch(`/api/document/nda/all?token=${token}`, {
      mode: 'cors',
      method: 'GET',
    }).then(response => {
      return response.json().then(jsonres => {
        Object.keys(jsonres).map(adverisementId =>
          fetchAdvertisement(adverisementId).then(ad => {
            const label = ad.name ? ad.name : ad.prospectTitle
            jsonres[adverisementId].label = label
          })
        )
        return jsonres
      })
    })
  )

export const signNDA = (companyId, sellerUuid) =>
  getIdToken()
    .then(token =>
      axios.post(`${backendUrl}/api/document/nda`, {
        token: token,
        companyId: companyId,
        seller: sellerUuid,
      })
    )
    .then(res => {
      window.location.assign(res.data.signers[0].url)
    })

export const removeNDAForBuyer = (companyId, sellerUuid) =>
  getIdToken()
    .then(token =>
      axios.post(`${backendUrl}/api/services/removeNda`, {
        token: token,
        companyId: companyId,
        seller: sellerUuid,
      })
    )
    .then(res => res.status)

export const fetchExclusivities = () =>
  getIdToken().then(token =>
    fetch(`/api/services/exclusivities?token=${token}`, {
      mode: 'cors',
      method: 'GET',
    })
      .then(response => {
        return response.json().then(jsonresponse => jsonresponse)
      })
      .catch(err => {
        console.log(err)
      })
  )

export const setExclusivity = (orgNr, buyerUuid, exclusivityStatus) =>
  getIdToken().then(token =>
    axios
      .post(`${backendUrl}/api/services/setBuyerExclusivity`, {
        token: token,
        orgNr: orgNr,
        buyerId: buyerUuid,
        exclusivityStatus: exclusivityStatus,
      })
      .then(response => response.status)
  )

export const removeExclusivityRequest = (orgNr, buyerUuid) =>
  getIdToken()
    .then(token =>
      axios.post(`${backendUrl}/api/services/removeBuyerExclusivity`, {
        token: token,
        orgNr: orgNr,
        buyerId: buyerUuid,
      })
    )
    .then(res => res.status)
