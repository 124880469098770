import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { contactSeller } from 'actions'
import { initiateSale } from '../api/services'
import { link } from 'styles/text'

import withLogin from 'components/withLogin'
import { Form, Field, Label, Textarea } from 'components/form'
import Button from 'components/Button'

const Cancel = styled.div`
  ${link};
  text-align: center;
  margin: 18px 0 0;
  cursor: pointer;
`

const validate = state => {
  let errors = {}
  if (!state.message) {
    errors.message = 'Du må skrive en melding'
  }
  return errors
}

class StartNegotiationForm extends React.Component {
  state = {
    message: '',
    errors: {},
    success: false,
  }

  render() {
    return (
      <Form
        onSubmit={e => {
          e.preventDefault()

          let errors = validate(this.state)
          this.setState({ errors })

          if (Object.keys(errors).length !== 0) {
            return
          }
          // Success
          initiateSale({
            orgNr: this.props.orgNr,
            message: this.state.message,
          })
          this.setState({ success: true })
        }}
      >
        <Field>
          Denne meldingen blir sendt til eierien av bedriften, du vil få svar på
          epost.
        </Field>
        {this.state.success ? (
          <Field>Din melding er sendt til bedriftseier</Field>
        ) : (
          <Field>
            <Label>Skriv melding til selger</Label>
            <Textarea
              value={this.state.message}
              error={this.state.errors.message}
              onChange={e => this.setState({ message: e.target.value })}
              placeholder={'Skriv en melding til selger'}
            />
          </Field>
        )}
        {!this.state.success && <Button>Start forhandling</Button>}
        <Cancel onClick={() => this.props.closeModal()}>
          {this.state.success ? 'Lukk' : 'Avbryt'}
        </Cancel>
      </Form>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = { contactSeller }

export default compose(
  withLogin,
  connect(mapStateToProps, mapDispatchToProps)
)(StartNegotiationForm)
