import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'
import { link, body } from 'styles/text'

import InfoBoxComponent, { Heading as HeadingComponent } from './InfoBox'
import ModalButtonComponent from 'components/ModalButton'
import StartNegotiationForm from '../../StartNegotiationForm'

const InfoBox = styled(InfoBoxComponent)`
  background-color: ${colors.activeBackground};
`

const Heading = styled(HeadingComponent)``

const Buttons = styled.div`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`

const ModalButton = styled(ModalButtonComponent)`
  width: 100%;
  text-align: center;
`

const Link = styled.a`
  ${link};
  display: block;
  margin: 16px 0;
`
const Info = styled.p`
  ${body};
  color: ${colors.primary}
`

const InitiateNegotiation = ({
  advertisementId,
  contactName,
  contactPhone,
  contactEmail,
  orgNr,
  ...props
}) => (
  <InfoBox>
    <Heading>Innled forhandling med selger</Heading>
    <Info>Bli enig om eksklusiv forhandlingsperiode, og signer</Info>
    <Buttons>
      <ModalButton
        title="Start Forhandling"
        renderModalContent={props => (
          <StartNegotiationForm {...props} orgNr={orgNr} advertisementId={advertisementId} />
        )}
      >
        Start Forhandling
      </ModalButton>
    </Buttons>
  </InfoBox>
)

export default InitiateNegotiation
