import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { companyAdvertisementSelector } from 'selectors'
import { h6, subtitle, body } from 'styles/text'
import { wrapper, padding, content } from './styles'

import Expandable from 'components/Expandable'
import File from 'components/File'
import SellersComment from 'components/SellersComment'

const Wrapper = styled.div`
  ${wrapper};
`

const Heading = styled.h6`
  ${h6};
  ${padding};
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const Content = styled.div`
  ${content};

  & > :not(:last-child) {
    margin-bottom: 4.2rem;
  }
`

const ContentHeading = styled.h5`
  ${subtitle};
  margin: 0;
  font-weight: bold;
`

const Value = styled.p`
  ${body}
  overflow-wrap: break-word;
  margin-left: 2.4rem;
`

const Marketing = props => {
  return (
    <Wrapper>
      <Expandable
        renderHeader={({ open }) => (
          <Heading open={open}>
            <span>Markedsføring, salg og distribusjon</span>
            <img src="/toggle_down.svg" alt="" />
          </Heading>
        )}
        renderContent={() => (
          <Content>
            {props.marketingMethods && props.marketingMethods.length > 0 && (
              <div>
                <ContentHeading>Markedsføringsmetoder</ContentHeading>
                {props.marketingMethods.map(method => (
                  <Value key={method}>
                    {method.split(':')[method.split(':').length - 1]}
                  </Value>
                ))}
              </div>
            )}
            {props.salesMethods && props.salesMethods.length > 0 && (
              <div>
                <ContentHeading>Salgsmetoder</ContentHeading>
                {props.salesMethods.map(method => (
                  <Value key={method}>
                    {method.split(':')[method.split(':').length - 1]}
                  </Value>
                ))}
              </div>
            )}
            {props.distributionMethods && props.distributionMethods.length > 0 && (
              <div>
                <ContentHeading>Distribusjonsmetoder</ContentHeading>
                {props.distributionMethods.map(method => (
                  <Value key={method}>
                    {method.split(':')[method.split(':').length - 1]}
                  </Value>
                ))}
              </div>
            )}
            {props.marketing_Documents && (
              <div>
                <ContentHeading>Vedlegg</ContentHeading>
                <ul>
                  {(props.marketing_Documents || []).map(name => (
                    <li key={name}>
                      <File
                        path={'prospect'}
                        name={name}
                        orgNumber={props.orgNumber}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <SellersComment {...props}>{props.marketingComment}</SellersComment>
          </Content>
        )}
      />
    </Wrapper>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...companyAdvertisementSelector(state, ownProps),
})

export default connect(mapStateToProps)(Marketing)
