import React from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'

const StyledDropzone = styled(Dropzone)`
  min-width: 100px;
  min-height: 100px;
  border: 1px solid #ececec;
`

const ImagePreview = styled.img`
  max-width: 300px;
  width: 100%;
  display: block;
`

const ImageUpload = ({ onChange, value }) => (
  <StyledDropzone
    accept="image/*"
    multiple={false}
    onDrop={([image]) => onChange(image)}
    style={{ position: 'relative' }}
  >
    Last opp bilde
    <ImagePreview src={value.preview} />
  </StyledDropzone>
)

export default ImageUpload
