import {
  FETCH_PENDING_NDAS_ERROR,
  FETCH_PENDING_NDAS_SUCCESS,
  FETCH_PENDING_NDAS,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PENDING_NDAS:
      return {
        ...state,
        pending: true,
      }
    case FETCH_PENDING_NDAS_SUCCESS:
      return { ...state, ndas: action.ndas, pending: false }

    case FETCH_PENDING_NDAS_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
        message: action.message,
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
