import { lastYear } from './utils'
import projectedRevenue from './projectedRevenue'
import projectedCost from './projectedCost'
import projectedDepreciation from './projectedDepreciation'
import projectedInvestments from './projectedInvestments'
import projectedWorkingCapitalDiff from './projectedWorkingCapitalDiff'

const tax = 0.23

export const projectedBalance = (data, year) => {
  if (data.length === 0) return

  const { RegnskapsAvAr, Bankinnskudd, SumInvesteringer, SumGjeld } = lastYear(
    data
  )

  let balance = Bankinnskudd - SumInvesteringer - SumGjeld

  for (let count = RegnskapsAvAr + 1; count <= year; count = count + 1) {
    const c1 =
      projectedDepreciation(data, count) -
      projectedInvestments(data, count) -
      projectedWorkingCapitalDiff(data, count)
    const c2 = tax
    const c3 = projectedEBIT(data, count)
    const c4 = balance > 0 ? 0.005 : 0.03
    // WolframAlpha magic
    // Calculated from equation set:
    // cashFlow = net + c1
    // net = gross - gross * c2
    // gross = fin + c3
    // fin = balance * c4
    // balance = balance_old + cashFlow
    //
    // Simplified to:
    // balance = balance_old + balance * c4 + c3 - (balance * c4 + c3) * c2 + c1
    //
    // http://www.wolframalpha.com/input/?i=y+%3D+b+%2B+y+*+c4+%2B+c3-+(y*c4+%2B+c3)+*+c2+%2B+c1
    balance = (balance + c1 - c2 * c3 + c3) / (c2 * c4 - c4 + 1)
  }

  return balance
}

export const projectedFinancialCost = (data, year) => {
  const balance = projectedBalance(data, year)
  return balance > 0 ? balance * 0.005 : balance * 0.03
}

export const projectedEBIT = (data, year, options) =>
  projectedRevenue(data, year, options) -
  projectedCost(data, year, options) -
  projectedDepreciation(data, year)

export const projectedGrossProfit = (data, year, options) =>
  projectedEBIT(data, year, options) + projectedFinancialCost(data, year)

export default (data, year, options) =>
  projectedGrossProfit(data, year, options) -
  projectedGrossProfit(data, year, options) * tax
