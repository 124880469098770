import React from 'react'
import styled from 'styled-components'

import { border } from 'styles'
import { subtitle2 } from 'styles/text'

const Flipped = styled.div`
  transform: rotateX(180deg);
  overflow-x: auto;
  white-space: nowrap;
`

const Tbl = styled.table`
  transform: rotateX(180deg);
  display: block;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  thead {
    background: #F8F7F5;
    border-bottom: 1px solid #061708;
    ${subtitle2};
    text-align: left;
  }

  
  td {
    border-bottom: ${border};
    border-rigt: ${border};
    border-left ${border};

    ${subtitle2};
    text-align: left;
    font-weight: normal;
    vertical-align: top;
    padding: ${props => (props.small ? '1px 2px' : '3px')};

    @media (min-width: 768px) {
      padding: ${props => (props.small ? '2px 4px' : '7px 5px')};
    }
  }

  td {
    ${props => (props.small ? '' : 'white-space: nowrap')};

    @media (min-width: 768px) {
      padding: ${props => (props.small ? '2px 4px' : '10px 5px')};
    }
  }

  th:first-child {
    ${props => (props.small ? 'width: 43%' : '')};
  }

  th:not(:first-child),
  td:not(:first-child) {
    ${props => (props.small ? 'text-align: right' : '')};
  }
`

const Table = ({ headers, data, small }) => (
  <Flipped>
    <Tbl small={small}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map(({ data, bold }, index) => (
          <tr
            key={index}
            style={{ background: index % 2 === 0 ? '#FFFFFF' : '#F8F7F5' }}
          >
            {data.map((column, index) => (
              <td key={index} style={{ fontWeight: bold && 'bold' }}>
                {column}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Tbl>
  </Flipped>
)

export default Table
