import React from 'react'
import styled from 'styled-components'

const Header = styled.div`
  cursor: pointer;
`

class Expandable extends React.Component {
  state = {
    open: this.props.defaultOpen || false,
  }

  render() {
    return (
      <div>
        <Header onClick={() => this.setState(state => ({ open: !state.open }))}>
          {this.props.renderHeader(this.state)}
        </Header>
        {this.state.open && <div>{this.props.renderContent(this.state)}</div>}
      </div>
    )
  }
}

export default Expandable
