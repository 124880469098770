import React from 'react'

import LogIn from 'components/LogIn'

const LogInPage = () => (
  <div>
    <h1>Logg inn</h1>
    <LogIn />
  </div>
)

export default LogInPage
