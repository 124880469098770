import React from 'react'
import { connect } from 'react-redux'

const withFetchedData = (fetch, shouldRefetch) => WrappedComponent => {
  const mapStateToProps = () => ({})

  const mapDispatchToProps = { fetch }

  class WithFetchedData extends React.Component {
    componentDidMount() {
      this.props.fetch(this.props)
    }

    componentDidUpdate(prevProps) {
      if (shouldRefetch && shouldRefetch(prevProps, this.props)) {
        this.props.fetch(this.props)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithFetchedData)
}

export default withFetchedData
