import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'
import { body, h5, h6 } from 'styles/text'
import { prettyPrice } from 'utils'
import { wrapper, padding } from '../styles'

import File from 'components/File'
import SellersComment from 'components/SellersComment'
import Expandable from 'components/Expandable'

const Wrapper = styled.div`
  ${wrapper};
`

const CustomInfoMessage = styled.div`
  ${body}
  background-color: ${colors.infoBackground};
  padding: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const ContentWrapper = styled.div`
  ${padding};

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const ObjectHeading = styled.h4`
  ${h6};
  margin: 12px 0;
`

const HeaderWrapper = styled.div`
  ${padding};
  cursor: pointer;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Header = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const ExpandableContent = styled.div`
  padding: 0 15px 15px;

  @media (min-width: 768px) {
    padding: 0 40px 40px;
  }
`

const Content = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
`

const Heading = styled.h2`
  ${h5};
  margin: 0;
`

const Value = styled.div`
  ${h5};
  color: ${colors.action};
  margin: 0;

  @media (min-width: 768px) {
    text-align: right;
  }
`

const Icon = styled.img`
  margin-left: 5px;
`

const SeeMore = styled.button`
  ${body};
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    color: ${colors.action};
  }

  & > img {
    ${props => (props.active ? 'transform: rotate(180deg);' : '')};
  }
`

const SeeMoreLabel = styled.span`
  white-space: nowrap;
`

const AskingPrice = props =>
  props.priceFiles && Object.keys(props.priceFiles).length > 0 ? (
    <Wrapper>
      <Expandable
        renderHeader={({ open }) => (
          <HeaderWrapper>
            <Header>
              <div>
                <Heading>Prisantydning</Heading>
                <SeeMore active={open}>
                  <SeeMoreLabel>{open ? 'Skjul' : 'Se mer'}</SeeMoreLabel>
                  <Icon src="/toggle_down.svg" alt="" />
                </SeeMore>
              </div>
              <Value>
                {props.noPrice
                  ? 'Etter avtale'
                  : `${prettyPrice(props.userValueEstimate)}\u00a0NOK`}
              </Value>
            </Header>
          </HeaderWrapper>
        )}
        renderContent={() => (
          <ExpandableContent>
            <SellersComment {...props}>
              {props.userValueEstimateComment}
            </SellersComment>
            {props.priceFiles && Object.keys(props.priceFiles).length > 0 && (
              <div>
                <ObjectHeading>Vedlegg</ObjectHeading>
                <ul>
                  {(props.priceFiles || []).map((name, i) => (
                    <li key={i}>
                      <File
                        path={'prospect'}
                        name={name}
                        orgNumber={props.orgnr}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </ExpandableContent>
        )}
      />
    </Wrapper>
  ) : (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Heading>Prisantydning</Heading>
          <Value>
            {props.noPrice
              ? 'Etter avtale'
              : `${prettyPrice(props.userValueEstimate)}\u00a0NOK`}
          </Value>
        </Content>
      </ContentWrapper>
      {props.userValueEstimateComment && (
        <CustomInfoMessage {...props}>
          <div style={{ marginLeft: '24px' }}>
            <b>Ytterligere informasjon: </b>
            {props.userValueEstimateComment}
          </div>
        </CustomInfoMessage>
      )}
    </Wrapper>
  )

export default AskingPrice
