import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { updateUserSettings, fetchUserSettings } from 'actions'
import {
  userSettingsSelector,
  userSettingsIsPendingSelector,
  authIsPendingSelector,
  userSettingsErrorSelector,
  userNameSelector,
} from 'selectors'
import { colors, border } from 'styles'
import { H3 } from 'styles/text'

import withFetchedData from 'components/withFetchedData'
import withSpinner from 'components/withSpinner'
import { Form, Field, Label, Input, Select } from 'components/form'
import ButtonComponent from 'components/Button'
import Error from 'components/Error'

const Wrapper = styled.div`
  border-radius: 4px;
  border: ${border};
  background-color: ${colors.white};
  padding: 40px 60px;
`

const ContactInformation = styled.div`
  display: flex;

  & > * {
    flex-grow: 1;
  }

  & > :not(:last-child) {
    margin-right: 20px;
  }
`

const Button = styled(ButtonComponent)`
  align-self: flex-end;
`

class UserSettings extends React.Component {
  state = {
    phoneNumber: this.props.phoneNumber || '',
    email: this.props.email || '',
    emailNotifications: this.props.emailNotifications || '',
    name: this.props.name || '',
  }

  showName() {
    if (this.state.name) {
      const name = this.state.name.split(',')
      return ` - ${name[1]} ${name[0]}`
    }
  }

  render() {
    return (
      <Wrapper>
        <Form
          onSubmit={e => {
            e.preventDefault()
            this.props.updateUserSettings(
              Object.keys(this.state).reduce((prev, curr) => {
                if (
                  this.state[curr] !== undefined &&
                  this.props[curr] !== this.state[curr]
                ) {
                  return {
                    ...prev,
                    [curr]: this.state[curr],
                  }
                } else {
                  return prev
                }
              }, {})
            )
          }}
        >
          <H3>Kontaktinformasjon {this.showName()}</H3>
          {this.props.error && <Error>{this.props.error}</Error>}
          <ContactInformation>
            <Field>
              <Label htmlFor="phoneNumber">Telefonnummer</Label>
              <Input
                id="phoneNumber"
                value={this.state.phoneNumber}
                onChange={e => this.setState({ phoneNumber: e.target.value })}
                placeholder="Telefonnummer"
              />
            </Field>
            <Field>
              <Label htmlFor="email">Epost</Label>
              <Input
                id="email"
                type="email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                placeholder="Epost"
              />
            </Field>
          </ContactInformation>
       
          {/*<Field>
            <Checkbox
              id="emailNotifications"
              checked={this.state.emailNotifications || false}
              onChange={() =>
                this.setState(state => ({
                  emailNotifications: !state.emailNotifications,
                }))
              }
            />
            <label htmlFor="emailNotifications">
              Varsle meg på e-post hver gang noen lagrer min bedrift.
            </label>
          </Field>*/}
          <Button
            disabled={[
              'phoneNumber',
              'email',
            ].every(prop => this.props[prop] === this.state[prop])}
          >
            Lagre
          </Button>
        </Form>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => ({
  ...userSettingsSelector(state),
  name: userNameSelector(state),
  error: userSettingsErrorSelector(state),
})

const mapDispatchToProps = { updateUserSettings }

export default compose(
  withSpinner(authIsPendingSelector),
  withFetchedData(fetchUserSettings),
  withSpinner(userSettingsIsPendingSelector),
  connect(mapStateToProps, mapDispatchToProps)
)(UserSettings)
