import React, { useState } from 'react'
import Modal from 'components/Modal'
import Button from 'components/Button'

const ModalButton = props => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button {...props} onClick={() => setShowModal(true)}>
        {props.children}
      </Button>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        title={props.title}
      >
        {props.renderModalContent({
          closeModal: () => setShowModal(false),
        })}
      </Modal>
    </>
  )
}

export default ModalButton
