import { combineReducers } from 'redux'

import auth from './auth'
import search from './search'
import companies from './companies'
import favoritedCompanies from './favoritedCompanies'
import claimedCompanies from './claimedCompanies'
import companyAdvertisements from './companyAdvertisements'
import content from './content'
import overlay from './overlay'
import menu from './menu'
import userSettings from './userSettings'
import advertisements from './advertisements'
import favoritedAdvertisements from './favoritedAdvertisements'
import calculatorOverrides from './calculatorOverrides'
import services from './services'
import editableCompanies from './editableCompanies'
import publishableCompanies from './publishableCompanies'
import { connectRouter } from 'connected-react-router'
import prospectGenerator from './prospectGenerator'
import prospectRequests from './prospectRequests'
import agreements from './agreements'
import accessibleProspects from './accessibleProspects'
import boughtProducts from './boughtProducts'
import companiesProspect from './companiesProspect'
import companiesAdvertisementAnonymizeToggle from './companiesAdvertisementAnonymizeToggle'
import exclusivities from './exclusivities'
import terms from './terms'
import marketing from './marketing'

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    search,
    companies,
    favoritedCompanies,
    content,
    claimedCompanies,
    companyAdvertisements,
    overlay,
    menu,
    userSettings,
    advertisements,
    favoritedAdvertisements,
    calculatorOverrides,
    services,
    editableCompanies,
    publishableCompanies,
    prospectGenerator,
    prospectRequests,
    agreements,
    accessibleProspects,
    boughtProducts,
    companiesProspect,
    companiesAdvertisementAnonymizeToggle,
    exclusivities,
    terms,
    marketing,
  })

export default createRootReducer
