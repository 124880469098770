import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import { setSearchQuery } from 'actions'
import { urlQuerySelector } from 'selectors'
import { colors } from 'styles'
import { body } from 'styles/text'
import noScroll from 'no-scroll'

import SuggestionList from './SuggestionList'
import XComponent from 'components/icons/X'

const Form = styled.form`
  background-color: ${colors.white};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  position: relative;
`

const Input = styled.input`
  ${body};
  width: 100%;
  padding: 15px 30px 15px 15px;
`

const X = styled(XComponent)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
`

class Search extends React.Component {
  state = {
    q: this.props.urlQuery.q || '',
  }

  componentDidMount() {
    noScroll.on()
  }

  componentWillUnmount() {
    noScroll.off()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.urlQuery !== prevProps.urlQuery) {
      this.setState(this.props.urlQuery)
    }
  }

  search = () => {
    if (this.props.history.location.pathname !== '/search') {
      this.props.history.push('/search')
    }
    if (this.props.urlQuery.q !== this.state.q) {
      this.props.setSearchQuery(this.state.q, this.props.urlQuery)
    }
    this.props.close()
  }

  render() {
    return (
      <Form
        onSubmit={e => {
          e.preventDefault()
          this.search()
        }}
      >
        <InputWrapper>
          <Input
            type="text"
            value={this.state.q}
            onChange={e => this.setState({ q: e.target.value })}
            placeholder="Søk firmanavn, annonse, org.nr., bransje eller sted"
            autoFocus
          />
          <X onClick={this.props.close} small />
        </InputWrapper>
        <SuggestionList
          value={this.state.q}
          select={q => this.setState({ q }, this.search)}
        />
      </Form>
    )
  }
}

const mapStateToProps = state => ({
  urlQuery: urlQuerySelector(state),
})

const mapDispatchToProps = { setSearchQuery }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
