import React from 'react'
import Label from '../../../components/form/Label'
import styled from 'styled-components'
import Checkbox from '../../../components/form/Checkbox'

const AdFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const CheckContainer = styled.div`
  display: flex;
  margin-right: 8px;
  > input {
    margin-top: -8px !important;
  }
`

const AdvertisementLabelFilter = ({ filter, setFilter }) => {
  const { advertisementLabels } = filter

  return (
    <AdFilter>
      {[
        'Til salgs',
        'Vurderes solgt',
        'Medeier / partner søkes',
        'Investeringsmulighet',
        'Solgt',
      ].map((label, i) => (
        <CheckContainer key={i}>
          <Label>{label}: </Label>
          <Checkbox
            checked={advertisementLabels.includes(label)}
            onChange={e => {
              if (e.target.checked) {
                setFilter({
                  advertisementLabels: [...advertisementLabels, label],
                })
              } else {
                setFilter({
                  advertisementLabels: advertisementLabels.filter(
                    l => l !== label
                  ),
                })
              }
            }}
            style={{ margin: '2px 6px' }}
          />
        </CheckContainer>
      ))}
    </AdFilter>
  )
}

export default AdvertisementLabelFilter
