import {
  FETCH_FAVORITED_COMPANIES,
  FETCH_FAVORITED_COMPANIES_SUCCESS,
  ADD_COMPANY_TO_FAVORITES,
  REMOVE_COMPANY_FROM_FAVORITES,
  LOG_OUT,
} from 'actionTypes'

const initialState = { pending: true }

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAVORITED_COMPANIES:
      return {
        pending: true,
      }
    case FETCH_FAVORITED_COMPANIES_SUCCESS:
      return action.favoritedCompanies
    case ADD_COMPANY_TO_FAVORITES:
      return {
        ...state,
        [action.companyId]: true,
      }
    case REMOVE_COMPANY_FROM_FAVORITES:
      return {
        ...state,
        [action.companyId]: false,
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
