import React from 'react'
import { Chart, Line } from 'react-chartjs-2'
import annotation from 'chartjs-plugin-annotation'

import { colors } from 'styles'

const graphColors = [colors.action, colors.secondary]

class Graph extends React.Component {
  componentDidMount() {
    Chart.pluginService.register(annotation)
  }

  render() {
    const d = this.props.data.reduce(
      (data, { dataset }, index) => {
        data.labels = dataset.map(({ x }) => x)
        data.datasets[index].data = dataset.map(({ y }) => y)
        return data
      },
      {
        labels: [],
        datasets: this.props.data.map(({ label }, i) => ({
          data: [],
          fill: false,
          lineTension: 0,
          label: label,
          borderColor: graphColors[i % graphColors.length],
        })),
      }
    )
    return (
      <div className={this.props.className}>
        <Line
          data={d}
          options={{
            legend: {
              display: this.props.data.length > 1,
              position: 'bottom',
            },
            scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
            annotation: {
              events: [],
              annotations:
                this.props.line !== undefined
                  ? [
                      {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        xMax: this.props.line,
                        backgroundColor: 'rgba(200, 200, 200, 0.5)',
                      },
                      {
                        drawTime: 'beforeDatasetsDraw',
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x-axis-0',
                        value: this.props.line,
                        label: {
                          backgroundColor: 'transparent',
                          fontColor: colors.primaryGray,
                          position: 'top',
                          xAdjust: 32,
                          enabled: true,
                          content: 'Historikk',
                        },
                      },
                      {
                        drawTime: 'beforeDatasetsDraw',
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x-axis-0',
                        value: this.props.line,
                        label: {
                          backgroundColor: 'transparent',
                          fontColor: colors.primaryGray,
                          position: 'top',
                          xAdjust: -34,
                          enabled: true,
                          content: 'Prognose',
                        },
                      },
                    ]
                  : [],
            },
          }}
          width={600}
          height={250}
        />
      </div>
    )
  }
}

export default Graph
