import React from 'react'
import styled, { css } from 'styled-components'
import TextareaComponent from './Textarea'
import ErrorComponent from 'components/Error'
import Label from './Label'

const Options = styled.div`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  ${props =>
    props.horizontal &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      flex-wrap: wrap;
    `}
`

const Option = styled.div`
  display: flex;
  align-items: flex-start;
`

const Checkbox = styled.input`
  margin: 6px 10px 6px 0;
`

const CustomLabel = styled(Label)`
  margin: 4px 0;
`

const InputWrapper = styled.div`
  width: 100%;
`

const Input = styled(TextareaComponent)`
  margin-top: 10px;
`

const Error = styled(ErrorComponent)`
  margin-bottom: 5px;
`

const remove = (array, element) => array.filter(value => value !== element)

const add = (array, element) => [].concat(array).concat(element)

class Checkboxes extends React.Component {
  state = {
    other: '',
  }

  render() {
    const {
      horizontal,
      options,
      other,
      value,
      otherPlaceholder,
      onChange = () => {},
      name = Math.random() + '',
    } = this.props

    const isOther = value => (value + '').startsWith('other:')

    return (
      <React.Fragment>
        {this.props.error && <Error>{this.props.error}</Error>}
        <Options horizontal={horizontal}>
          {options.map(option => (
            <Option key={option.value}>
              <Checkbox
                type="checkbox"
                id={option.value + name}
                name={name}
                checked={value.includes(option.value)}
                onChange={() =>
                  value.includes(option.value)
                    ? onChange(remove(value, option.value))
                    : onChange(add(value, option.value))
                }
              />
              <CustomLabel htmlFor={option.value + name}>
                {option.label}
              </CustomLabel>
            </Option>
          ))}
          {other && (
            <Option>
              <Checkbox
                type="checkbox"
                id={'other' + name}
                name={name}
                checked={value.some(element => isOther(element))}
                onChange={() =>
                  value.some(element => isOther(element))
                    ? onChange(value.filter(element => !isOther(element)))
                    : onChange(add(value, `other:${this.state.other}`))
                }
              />
              <InputWrapper>
                <CustomLabel htmlFor={'other' + name}>{other}</CustomLabel>
                <Input
                  placeholder={otherPlaceholder || other}
                  value={
                    value.some(element => isOther(element))
                      ? value.find(element => isOther(element)).split(':')[1]
                      : this.state.other
                  }
                  onChange={e => {
                    if (value.some(element => isOther(element))) {
                      onChange(
                        add(
                          value.filter(element => !isOther(element)),
                          `other:${e.target.value}`
                        )
                      )
                    }
                    this.setState({ other: e.target.value })
                  }}
                />
              </InputWrapper>
            </Option>
          )}
        </Options>
      </React.Fragment>
    )
  }
}
export default Checkboxes
