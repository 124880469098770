import React from 'react'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'
import withAccessToEdit from 'components/withAccessToEdit'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Icon = styled.img`
  margin-left: 5px;
`

class EditButton extends React.Component {
  state = {
    showModal: false,
  }

  Form = withAccessToEdit(this.props.companyId)(this.props.formComponent)

  render() {
    if (!this.props.claimed) return null

    const Form = this.Form

    return (
      <Wrapper
        className={this.props.className}
        onClick={e => e.stopPropagation()}
      >
        <Button subtle onClick={() => this.setState({ showModal: true })}>
          {this.props.label || 'Rediger'} <Icon src="/edit.svg" alt="" />
        </Button>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={() => this.setState({ showModal: false })}
          title={this.props.title}
        >
          <Form
            {...this.props}
            closeModal={() => this.setState({ showModal: false })}
          />
        </Modal>
      </Wrapper>
    )
  }
}

export default EditButton
