import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Image from './Image'

import { prettyPrice, capitalize, roundToInterval } from 'utils'
import { border, colors } from 'styles'
import { h4, h5, h6, body, subtle } from 'styles/text'

import Anonymized from 'components/Anonymized'
import FavoriteAdvertisementButton from 'components/FavoriteAdvertisementButton'

const Wrapper = styled.div`
  position: relative;
  max-width: 326px;
  margin: 0 ${props => (props.preview ? '0' : 'auto')};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
`

const Card = styled.div`
  position: relative;
  background-color: ${colors.white};
  border: ${border};
  border-radius: 0.6rem;
  overflow: hidden;
  width: 326px;
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // Compensate for the tag
  ${props => (props.addExtraPadding ? 'padding-top: 280px' : '')}
  &:hover {
    border: 0.2rem solid ${colors.action};
  }
`

const Tag = styled.div`
  position: absolute;
  left: 0;
  top: 20px;
  ${body};
  background-color: ${props =>
    props.anonymized ? colors.anonymize : colors.action};
  color: ${colors.white};
  padding: 10px 20px;
`

const FavoriteWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
`

const Top = styled.div`
  padding: 8px 8px 0px 8px;
`

const CompanyInformation = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 4px 0px 4px;
`

const Bottom = styled.div`
  padding: 8px;
  background-color: ${colors.alabasterBackground};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  height: 60px;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid ${colors.borderColor};
`

const Title = styled.div`
  ${h6};
  font-weight: 300;
  margin: 4px 4px 0px 4px;
  font-size: 1.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const Name = styled.div`
  ${h4};
  position: absolute;
  top: 130px;
  left: 12px;
  color: white;
  font-weight: 600;
`

const Label = styled.div`
  ${subtle};
  font-size: 1.4rem;
`

const EstimatedValue = styled.div`
  ${h5};
  font-size: 2.4rem;
  color: ${colors.action};
`

const Value = styled.div`
  ${body};
  margin-bottom: 4px;
  overflow-wrap: break-word;
  font-size: 1.4rem;
`

const ImageShadow = styled.div`
  box-sizing: border-box;
  box-shadow: inset 0px -30px 20px -20px rgba(0, 0, 0, 0.5);
  width: 326px;
  height: 164px;
  position: absolute;
`

const MainImage = styled(Image)`
  width: 100%;
  height: auto;
`

const SellerLogo = styled(Image)``

const DefaultCard = props => {
  return (
    <Card addExtraPadding={!props.headerImage}>
      <Tag anonymized={props.anonymized}>
        {props.advertisementCardLabel || 'Til salgs'}
      </Tag>
      {props.headerImage && (
        <>
          {!props.anonymized && <ImageShadow useShadow={!props.anonymized} />}
          <MainImage
            src={props.headerImage}
            imgixParams={{ fit: 'crop' }}
            width={326}
            height={164}
          />
          <Name>{props.anonymized ? '' : capitalize(props.name)}</Name>
        </>
      )}
      <Top>
        {props.anonymized && <Anonymized>Bedriften er anonymisert</Anonymized>}
        <Title>{props.prospectTitle}</Title>
        <CompanyInformation>
          <div>
            <div>
              <Label>Andel til salgs</Label>
              <Value>
                {props.shareForSale
                  ? `${props.shareForSale}${
                      props.shareForSale.endsWith('%') ? '' : '%'
                    }`
                  : 'Etter avtale'}
              </Value>
            </div>

            {props.turnover ? (
              <div>
                <Label>Omsetning</Label>
                <Value style={{ maxWidth: '128px' }}>
                  {props.turnoverString
                    ? `${props.turnoverString} ${
                        props.turnoverString === 'Mangler Grunnlag' ? '' : 'NOK'
                      }`
                    : props.anonymized
                    ? `${roundToInterval(props.turnover).label} NOK`
                    : `${prettyPrice(props.turnover)} NOK`}
                </Value>
              </div>
            ) : null}
          </div>
          <div>
            <div>
              <Label>Sted</Label>
              <Value>{props.place}</Value>
            </div>
            {props.ek ? (
              <div>
                <Label>Egenkapital</Label>
                <Value style={{ maxWidth: '128px' }}>
                  {props.ekString
                    ? `${props.ekString} ${
                        props.ekString === 'Mangler Grunnlag' ? '' : 'NOK'
                      }`
                    : props.anonymized
                    ? `${roundToInterval(props.ek).label} NOK`
                    : `${prettyPrice(props.ek)} NOK`}
                </Value>
              </div>
            ) : null}
          </div>
        </CompanyInformation>
      </Top>
      <Bottom>
        <div>
          <Label>Prisantydning andel</Label>
          <EstimatedValue>
            {props.userValueEstimate
              ? `${prettyPrice(props.userValueEstimate)}\u00a0NOK`
              : `Etter avtale`}
          </EstimatedValue>
        </div>
        {props.contactLogo && (
          <SellerLogo
            height={48}
            imgixParams={{ fit: 'crop' }}
            src={props.contactLogo}
          />
        )}
      </Bottom>
    </Card>
  )
}

const AdvertisementCard = props => {
  const { disabled } = props || false
  return (
    <Wrapper preview={props.preview}>
      {disabled ? (
        <DefaultCard {...props} />
      ) : (
        <>
          <StyledLink to={`/advertisements/${props.advertisementId}`}>
            <DefaultCard {...props} />
          </StyledLink>
          <FavoriteWrapper>
            <FavoriteAdvertisementButton
              noText
              advertisementId={props.advertisementId}
            />
          </FavoriteWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default AdvertisementCard
