export const backendUrl = (() => {
  if (typeof window === 'undefined') {
    return process.env.BACKEND_URL
  }

  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3002'
  } else if (window.location.hostname.endsWith('.test.iterate.no')) {
    return 'https://nobema.test.iterate.no'
  } else if (
    window.location.hostname.endsWith('app.iterate.no') ||
    window.location.hostname.endsWith('.nobema.no')
  ) {
    return 'https://nobema.app.iterate.no'
  }
})()

//Sjekk tilgang til denne
export const firebaseApiKey = (() => {
  if (typeof window === 'undefined') {
    return process.env.FIREBASE_API_KEY
  }

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.endsWith('.test.iterate.no')
  ) {
    return 'AIzaSyBxpV9nILVThMArtYSdr3s3G4xJWrsg5GU'
  } else if (
    window.location.hostname.endsWith('app.iterate.no') ||
    window.location.hostname.endsWith('.nobema.no')
  ) {
    return 'AIzaSyBZAA5jvYF4Yn3lJxYqdmXEwvkpEnKLgIw'
  }
})()

export const firebaseStorageBucket = (() => {
  if (typeof window === 'undefined') {
    return process.env.FIREBASE_STORAGE_BUCKET
  }

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.endsWith('.test.iterate.no')
  ) {
    return 'nobema-771bb.appspot.com'
  } else if (
    window.location.hostname.endsWith('app.iterate.no') ||
    window.location.hostname.endsWith('.nobema.no')
  ) {
    return ''
  }
})()

export const firebaseProjectId = (() => {
  if (typeof window === 'undefined') {
    return process.env.FIREBASE_PROJECT_ID
  }

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.endsWith('.test.iterate.no')
  ) {
    return 'nobema-771bb'
  } else if (
    window.location.hostname.endsWith('app.iterate.no') ||
    window.location.hostname.endsWith('.nobema.no')
  ) {
    return 'nobema-prod'
  }
})()

export const firebaseDbUrl = (() => {
  if (typeof window === 'undefined') {
    return process.env.FIREBASE_DB_URL
  }

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.endsWith('.test.iterate.no')
  ) {
    return 'https://nobema-771bb.firebaseio.com'
  } else if (
    window.location.hostname.endsWith('app.iterate.no') ||
    window.location.hostname.endsWith('.nobema.no')
  ) {
    return 'https://nobema-prod.firebaseio.com/'
  }
})()

export const signicatUrl = (() => {
  if (typeof window === 'undefined') {
    return process.env.SIGNICAT_URL
  }

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.endsWith('.test.iterate.no')
  ) {
    return 'https://oauth2test.signere.com'
  } else if (
    window.location.hostname.endsWith('.app.iterate.no') ||
    window.location.hostname.endsWith('.nobema.no')
  ) {
    return 'https://oauth.signere.no'
  } else {
    throw new Error('Invalid hostURL')
  }
})()

export const signicatClientId = (() => {
  if (typeof window === 'undefined') {
    return process.env.SIGNICAT_CLIENT_URL
  }

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.endsWith('.test.iterate.no')
  ) {
    return 'tb093eb4d29c2485abd3642622be88eed'
  } else if (
    window.location.hostname.endsWith('.app.iterate.no') ||
    window.location.hostname.endsWith('.nobema.no')
  ) {
    return 'pa204befdbde6418698ec180693141d95'
  } else {
    throw new Error('Invalid client ID')
  }
})()

export const imgixUrl = (() => {
  if (typeof window === 'undefined') {
    return process.env.IMGIX_URL
  }

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.endsWith('.test.iterate.no')
  ) {
    return 'https://nobema.imgix.net'
  } else if (
    window.location.hostname.endsWith('.app.iterate.no') ||
    window.location.hostname.endsWith('.nobema.no')
  ) {
    return 'https://nobema-prod.imgix.net'
  } else {
    throw new Error('Invalid client ID')
  }
})()

export const frontendUrl = () => {
  if (typeof window === 'undefined') {
    return process.env.FRONTEND_URL
  }

  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3000'
  } else if (window.location.hostname.endsWith('.test.iterate.no')) {
    return 'https://nobema-frontend.test.iterate.no'
  } else {
    return 'https://marked.nobema.no'
  }
}
