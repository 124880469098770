import React from 'react'
import styled from 'styled-components'

import Anonymized from 'components/Anonymized'

const Wrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  max-width: 100%;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`

const Preview = styled.span`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
`

const AnonymizedField = props => {
  const anonymized = props.anonymized || props.previewAnonymized
  if (anonymized && props.forSale) {
    return <Anonymized />
  } else {
    return props.children || props.children === 0 ? (
      <Wrapper>
        <Preview>{props.children}</Preview>
      </Wrapper>
    ) : null
  }
}

export default AnonymizedField
