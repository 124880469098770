import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'
import { link, h4, body, overline } from 'styles/text'

import InfoBoxComponent, { Heading as HeadingComponent } from './InfoBox'
import ModalButtonComponent from 'components/ModalButton'
import ContactSellerForm from 'components/ContactSellerForm'
import Image from '../../Image'

const InfoBox = styled(InfoBoxComponent)`
  background-color: ${colors.white};
  color: ${colors.secondary};
  border: 1px solid ${colors.secondary};
`

const Heading = styled.h4`
  ${h4};
  color: ${colors.secondary};
`

const Info = styled.p`
  ${body};
  color: ${colors.secondary};
`

const Overline = styled.p`
  ${overline};
  color: ${colors.secondary};
`

const Buttons = styled.div`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`

const ModalButton = styled(ModalButtonComponent)`
  width: 100%;
  text-align: center;
`

const HeaderAndImage = styled.div`
  display: flex;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  max-height: 36px;
  margin-top: 12px;
`

const Link = styled.a`
  ${link};
  display: block;
  margin: 16px 0;
`
const ContactSeller = ({
  showSellerInAd,
  anonymized,
  advertisementId,
  contactTitle,
  contactFirstName,
  contactLastName,
  contactPhone,
  contactCompany,
  contactWebsite,
  contactLogo,
  ownerFirstName,
  ownerLastName,
  ownerPhone,
  orgnr,
}) => (
  <InfoBox>
    {!!showSellerInAd && !!contactLogo ? (
      <HeaderAndImage>
        <Heading>Kontakt</Heading>
        <ImageWrapper>
          <Image src={contactLogo} imgixParams={{ fit: 'crop' }} width={120} />
        </ImageWrapper>
      </HeaderAndImage>
    ) : (
      <Heading>Kontakt</Heading>
    )}
    {anonymized && !showSellerInAd ? (
      <Info>Selger har valgt å være anonym</Info>
    ) : (
      <>
        {!!showSellerInAd &&
          (contactWebsite ? (
            <Link
              href={
                showSellerInAd && contactWebsite.startsWith('http')
                  ? contactWebsite
                  : `http://${contactWebsite}`
              }
              target="_blank"
            >
              <Overline>{contactCompany}</Overline>
            </Link>
          ) : (
            <Overline>{contactCompany}</Overline>
          ))}
        {((contactFirstName && !!showSellerInAd) || ownerFirstName) && (
          <>
            <Info>
              {!!showSellerInAd
                ? `${contactFirstName} ${contactLastName}`
                : `${ownerFirstName} ${ownerLastName}`}
            </Info>
          </>
        )}
        <Info>{showSellerInAd && contactTitle}</Info>
        {((contactFirstName && !!showSellerInAd) || ownerPhone) && (
          <Info>Tlf: {!!showSellerInAd ? contactPhone : ownerPhone}</Info>
        )}
      </>
    )}
    <Buttons>
      <ModalButton
        secondary
        title="Melding til selger"
        renderModalContent={props => (
          <ContactSellerForm
            {...props}
            advertisementId={advertisementId}
            orgnr={orgnr}
          />
        )}
      >
        Send melding
      </ModalButton>
    </Buttons>
  </InfoBox>
)

export default ContactSeller
