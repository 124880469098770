import { fork, takeEvery, select, put } from 'redux-saga/effects'

import { isMenuOpenSelector } from 'selectors'
import { CLOSE_MENU } from 'actionTypes'

import auth from './auth'
import search from './search'
import companies from './companies'
import content from './content'
import companyAdvertisements from './companyAdvertisements'
import users from './users'
import advertisements from './advertisements'
import services from './services'
import agreements from './agreements'
import boughtProducts from './boughtProducts'
import terms from './terms'

function* closeMenu() {
  const isMenuOpen = yield select(isMenuOpenSelector)
  if (isMenuOpen) {
    yield put({ type: CLOSE_MENU })
  }
}

function* saga() {
  yield fork(auth)
  yield fork(search)
  yield fork(companies)
  yield fork(content)
  yield fork(companyAdvertisements)
  yield fork(users)
  yield fork(advertisements)
  yield fork(services)
  yield fork(agreements)
  yield fork(boughtProducts)
  yield fork(terms)

  yield takeEvery('@@router/LOCATION_CHANGE', closeMenu)
}

export default saga
