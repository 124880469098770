import * as moment from 'moment'

export const prettyPrice = n => {
  if (n === 'Mangler Grunnlag') {
    return '0'
  }

  if (isNaN(n)) {
    return '0'
  }

  return String(n)
    .split('')
    .reverse()
    .reduce(
      (price, n, i) =>
        i && i % 3 === 0 ? `${n}\u00a0${price}` : `${n}${price}`,
      ''
    )
}

export const valueRange = value => {
  if (!value) return 'Kan ikke beregnes'
  if (value <= 100000) {
    return `0\u00a0- ${prettyPrice(100000)}\u00a0NOK`
  } else {
    const n = Number(Math.round(value).toString().slice(0, 2))
    const zeroes = Math.round(value)
      .toString()
      .split('')
      .slice(2)
      .map(n => '0')
      .join('')

    if (n < 25) {
      return `${prettyPrice(10 + zeroes)}\u00a0- ${prettyPrice(
        25 + zeroes
      )}\u00a0NOK`
    } else if (n < 50) {
      return `${prettyPrice(25 + zeroes)}\u00a0- ${prettyPrice(
        50 + zeroes
      )}\u00a0NOK`
    } else {
      return `${prettyPrice(50 + zeroes)}\u00a0- ${prettyPrice(
        100 + zeroes
      )}\u00a0NOK`
    }
  }
}

const capitalizeWord = (s = '') => {
  if (['AS', 'VVS'].includes(s)) {
    return s
  } else if (s.includes('&')) {
    return s.split('&').map(capitalizeWord).join('&')
  } else if (s.includes('-')) {
    return s.split('-').map(capitalizeWord).join('-')
  } else {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
  }
}

export const capitalize = (s = '') =>
  s
    .split(' ')
    .map(w => capitalizeWord(w))
    .join(' ')

export const formatDate = date => moment(date).format('DD.MM.YYYY')

export const calculateTurnover = economyData => {
  if (!economyData || economyData.length <= 0) {
    return 0
  }

  const latestYearTurnover = economyData[economyData.length - 1]['Totalinntekt']
  const result = parseFloat(latestYearTurnover) * 1000
  return result
}

export const calculateEk = economyData => {
  if (!economyData || !economyData.SumEgenkapital) return 0
  const latestEk = economyData['SumEgenkapital']
  return parseFloat(latestEk) * 1000
}

export const calculateLaborCostsDivTotalIncome = economyData => {
  if (!economyData) {
    return '0%'
  }
  const totalLaborCosts = parseFloat(economyData['Lonnskostnad'])
  const totalIncome = parseFloat(economyData['Totalinntekt'])

  if (totalIncome === 0) {
    return '0%'
  }

  return `${((totalLaborCosts / totalIncome) * 100).toFixed(2)}%`
}

// Assume total costs = revenue - result
export const calculateLaborCostDivTotalCost = economyData => {
  if (!economyData) {
    return '0%'
  }
  const totalLaborCosts = parseFloat(economyData['Lonnskostnad'])
  const totalInntekt = parseFloat(economyData['Totalinntekt'])
  const ordResult = parseFloat(economyData['OrdResultat'])

  const totalCost = totalInntekt - ordResult

  if (totalCost === 0) {
    return '0%'
  }

  return `${((totalLaborCosts / totalCost) * 100).toFixed(2)}%`
}

export const getEmployees = employeeData => {
  if (!employeeData || employeeData.length <= 0) {
    return '0'
  }
  // Need to check if this value is not empty string
  return employeeData[employeeData.length - 1].AnsatteAntall
    ? employeeData[employeeData.length - 1].AnsatteAntall
    : '0'
}

export const calculateCompanyLifePhase = date =>
  moment(date).isBefore(moment().subtract(5, 'years'))
    ? 'Etablert selskap'
    : 'Ungt selskap'

export const get = (o, p) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : undefined), o)

export const validateEmail = email => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const encode = string =>
  encodeURIComponent(string).replace(/\./g, '%2E').replace(/\//g, '%2F')

export const decode = decodeURIComponent

export const roundToInterval = amountStr => {
  const amount = parseInt(amountStr)

  if (amount < 0) {
    return { label: `Negativ - ${prettyPrice(0)}`, lower: -100000000, upper: 0 }
  }

  const interval = [
    [0, 500000],
    [500000, 1000000],
    [1000000, 2000000],
    [2000000, 3000000],
    [3000000, 4000000],
    [4000000, 5000000],
    [5000000, 10000000],
    [10000000, 20000000],
    [20000000, 30000000],
    [30000000, 40000000],
    [40000000, 50000000],
    [50000000, 100000000],
  ]

  for (let i = 0; i < interval.length; i++) {
    const lower = interval[i][0]
    const upper = interval[i][1]

    if (amount >= lower && amount < upper) {
      return {
        label: `${prettyPrice(lower)} - ${prettyPrice(upper)}`,
        lower,
        upper,
      }
    }
  }

  return { label: `> ${prettyPrice(100000000)}`, upper: 100000000 }
}

export const MARKETING_NONE = 'NO'
export const MARKETING_SMALL = 'S'
export const MARKETING_MEDIUM = 'M'
export const MARKETING_LARGE = 'L'

export const MARKETING_LEVELS = {
  [MARKETING_NONE]: 'Ingen markedsføring',
  [MARKETING_SMALL]: 'Liten markedsføring',
  [MARKETING_MEDIUM]: 'Medium markedsføring',
  [MARKETING_LARGE]: 'Stor markedsføring',
}

export const getMarketingKeyFromValue = label => {
  const k = Object.keys(MARKETING_LEVELS).find(
    key => MARKETING_LEVELS[key] === label
  )
  return k ? k : MARKETING_NONE
}
