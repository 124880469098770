import React from 'react'
import styled from 'styled-components'

import Image from 'components/Image'

const Placeholder = styled.div`
  margin-top: 30px;

  @media (min-width: 768px) {
    margin-top: 100px;
  }
`

const ImageWrapper = styled.div`
  margin: 0 -20px;

  @media (min-width: 768px) {
    margin: 0;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
`

const Logo = styled(Image)`
  width: 33%;
`

const HeaderImage = props => {
  if (props.claimed) {
    return (
      <div>
        <ImageWrapper>
          <StyledImage src={props.headerImage} width={1035} />
        </ImageWrapper>
      </div>
    )
  } else if (props.headerImage) {
    return (
      <ImageWrapper>
        {props.logoUpload && !props.previewAnonymized && (
          <Logo src={props.logoUpload} width={346} />
        )}
        <StyledImage src={props.headerImage} width={1035} />
      </ImageWrapper>
    )
  } else {
    return <Placeholder />
  }
}

export default HeaderImage
