import {
  SET_REVENUE_GROWTH_RATE_OVERRIDE,
  SET_COST_OVER_REVENUE_OVERRIDE,
} from 'actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_REVENUE_GROWTH_RATE_OVERRIDE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          revenueGrowthRate: action.value,
        },
      }
    case SET_COST_OVER_REVENUE_OVERRIDE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          costOverRevenue: action.value,
        },
      }
    default:
      return state
  }
}
