import { authIsPendingSelector, isAuthenticatedSelector } from './index'

export const boughtProductsSelector = state => {
  return state.boughtProducts
}

export const boughtProductsIsPendingSelector = state => {
  if (authIsPendingSelector(state)) {
    return true
  } else if (isAuthenticatedSelector(state)) {
    return state.boughtProducts.pending
  } else {
    return false
  }
}
