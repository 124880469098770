import { compose } from 'redux'
import { connect } from 'react-redux'
import withLogin from '../components/withLogin'


import { fetchCompanyProspect } from 'actions'
import {
  companyProspectSelector,
  companyProspectIsPendingSelector,
} from 'selectors'

import withFetchedData from 'components/withFetchedData'
import withSpinner from 'components/withSpinner'
import Company from 'components/Company'

const mapStateToProps = (state, ownProps) => ({
  ...companyProspectSelector(state, ownProps),
  advertisementForSale: true,
  forSale: true,
  fullProspect: true,
})

export default compose(
  withLogin,
  withFetchedData(props => fetchCompanyProspect(props.orgNr), false),
  withSpinner(companyProspectIsPendingSelector),
  connect(mapStateToProps)
)(Company)
