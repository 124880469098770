import React from 'react'

import {
  Field,
  Label,
  Select,
  RadioButtons,
  UploadFiles,
  Textarea,
} from 'components/form'
import EditAdvertisementForm from './EditAdvertisementForm'
import EditListOfLists, { mapToState } from './EditListOfLists'
import { uploadFilesFromList } from 'components/form/FileUploaderCore'
import { H6 } from 'styles/text'

export const lists = {
  machines: {
    heading: 'Maskiner/anlegg/kjøretøy',
    buttonLabel: '+ Legg til maskiner/anlegg/kjøretøy',
    fields: [
      {
        prop: 'type',
        label: 'Type',
        Component: ({ value, onChange }) => (
          <Select
            options={['Maskin', 'Anlegg', 'Kjøretøy'].map(value => ({
              value,
              label: value,
            }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'name',
        label: 'Navn/kjennemerke',
      },
      {
        prop: 'hoursUse',
        label: 'Brukstimer',
      },
      {
        prop: 'kmUse',
        label: 'Km',
      },
      {
        prop: 'acquisitionValue',
        label: 'Anskaffelsesverdi',
        placeholder: 'kr',
      },
      {
        prop: 'dateOfAcquisition',
        label: 'Anskaffelsestidspunkt',
        placeholder: 'dd.mm.åååå',
      },
      {
        prop: 'marketValue',
        label: 'Vurdert markedspris',
      },
      {
        prop: 'collateralised',
        label: 'Pantsettelse',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              { value: 'Pantsatt', label: 'Pantsatt' },
              { value: 'Ikke pantsatt', label: 'Ikke pantsatt' },
            ]}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  inventory: {
    heading: 'Driftsløsøre/inventar/verktøy',
    buttonLabel: '+ Legg til driftsløsøre/inventar/verktøy',
    fields: [
      {
        prop: 'type',
        label: 'Type',
        Component: ({ value, onChange }) => (
          <Select
            options={[
              'Driftsløsøre',
              'Inventar',
              'Håndverktøy',
              'Elektroverktøy',
            ].map(value => ({
              value,
              label: value,
            }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'name',
        label: 'Navn/kjennemerke',
      },
      {
        prop: 'amount',
        label: 'Antall',
      },
      {
        prop: 'acquisitionValue',
        label: 'Anskaffelsesverdi',
        placeholder: 'kr',
      },

      {
        prop: 'standard',
        label: 'Standard',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={['Nytt', 'Brukt', 'Eldre'].map(value => ({
              value,
              label: value,
            }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'collateralised',
        label: 'Pantsettelse',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              { value: 'Pantsatt', label: 'Pantsatt' },
              { value: 'Ikke pantsatt', label: 'Ikke pantsatt' },
            ]}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  stocks: {
    heading: 'Aksjer/Investeringer i datterselskap/andeler',
    buttonLabel: '+ Legg til aksjer/Investeringer i datterselskap/andeler',
    fields: [
      { prop: 'name', label: 'Navn på selskapet' },
      { prop: 'orgnr', label: 'Organisasjonsnummer' },
      {
        prop: 'equity',
        label: 'Selskapets egenkapital',
        placeholder: 'kr',
      },
      { prop: 'stake', label: 'Eierandel', placeholder: '%' },
      {
        prop: 'marketValue',
        label: 'Vurdert markedsverdi',
        placeholder: 'kr',
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },

      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  intangibleAssets: {
    heading: 'Immatriell eiendel',
    buttonLabel: '+ Legg til immatrielle eiendeler',
    fields: [
      { prop: 'type', label: 'Type' },
      { prop: 'marketValue', label: 'Markedsverdi' },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },

      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  tangibleAssets: {
    heading: 'Varelager',
    buttonLabel: '+ Legg til varelager',
    fields: [
      { prop: 'type', label: 'Type' },
      { prop: 'marketValue', label: 'Markedsverdi' },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },

      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
}

class EditAssets extends React.Component {
  componentDidMount() {
    if (this.props.formValues.assets) {
      this.setState({ ...this.props.formValues.assets })
    } else {
      this.props.dispacth('assets', {
        ...mapToState(lists, this.props),
        ...Object.keys(lists).reduce(
          (state, key) => ({
            ...state,
            [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
          }),
          {}
        ),
        assetsComment: this.props.assetsComment || '',
      })
    }
  }

  // Set initial state
  // Will be overwritten by componentDidMount() if assets are found in store
  state = {
    ...mapToState(lists, this.props),
    ...Object.keys(lists).reduce(
      (state, key) => ({
        ...state,
        [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
      }),
      {}
    ),
    assetsComment: this.props.assetsComment || '',
  }

  removeItem = (list, index) => {
    const updatedAssets = {
      [list]: this.state[list].filter((_, i) => i !== index),
    }
    this.setState(updatedAssets)
    this.props.dispacth('assets', { ...this.state, ...updatedAssets })
  }

  addItem = (list, item) => {
    this.setState({
      [list]: [...this.state[list], item],
    })
  }

  setData = (property, index, key, value, rm) => {
    let oldFiles = []
    if (key === 'files' && this.state[property][index][key] && !rm) {
      oldFiles = this.state[property][index][key]
    }
    return {
      [property]: this.state[property].map((list, i) =>
        i === index
          ? {
              ...list,
              [key]: key === 'files' ? [...oldFiles, ...value] : value,
            }
          : list
      ),
    }
  }

  setValue = (property, index, key, value) => {
    if (
      key === 'files' &&
      value.length > 0 &&
      typeof value[0].name === 'string'
    ) {
      uploadFilesFromList(this.props.orgNumber, value).then(res => {
        const assets = this.setData(property, index, key, res)
        this.props.dispacth('assets', { ...this.state, ...assets })
        this.setState(assets)
      })
    } else if (
      key === 'files' &&
      (value.length === 0 || typeof value[0].name !== 'string')
    ) {
      const assets = this.setData(property, index, key, value, true)
      this.props.dispacth('assets', { ...this.state, ...assets })
      this.setState(assets)
    } else {
      const assets = this.setData(property, index, key, value)
      this.props.dispacth('assets', { ...this.state, ...assets })
      this.setState(assets)
    }
  }

  setComment = value => {
    this.setState({ assetsComment: value })
    this.props.dispacth('assets', { ...this.state, assetsComment: value })
  }

  render() {
    return (
      <>
        <EditListOfLists
          {...this.props}
          lists={lists}
          state={this.state}
          removeItem={this.removeItem}
          addItem={this.addItem}
          setValue={this.setValue}
          setAnonymized={(key, value) => this.setState({ [key]: value })}
        />
        <Field>
          <H6>Ytterligere informasjon om Eiendeler</H6>
          <Textarea
            value={this.state.assetsComment}
            onChange={e => this.setComment(e.target.value)}
            placeholder={'Eventuell tilleggsinformasjon'}
          />
        </Field>
      </>
    )
  }
}

export default EditAssets
