import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { contactNobema } from 'actions'
import { isAuthenticatedSelector, authIsPendingSelector } from 'selectors'

import { Form, Field, Label, Textarea, Buttons, Input } from 'components/form'
import withSpinner from 'components/withSpinner'
import Button from 'components/Button'

class ContactNobemaForm extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    success: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.contactNobemaStatus === 'pending' &&
      this.props.contactNobemaStatus === 'success'
    ) {
      this.setState({ success: true })
    }
  }

  render() {
    return this.state.success ? (
      <div>
        <p>Takk for forespørselen! Vi tar kontakt med deg på e-post.</p>
        <Button onClick={this.props.closeModal} subtle>
          Lukk
        </Button>
      </div>
    ) : (
      <Form
        onSubmit={e => {
          e.preventDefault()

          this.props.contactNobema({ message: this.state.message })
        }}
      >
        {!this.props.isAuthenticated && (
          <React.Fragment>
            <Field>
              <Label>Fornavn</Label>
              <Input
                value={this.state.firstName}
                onChange={e => this.setState({ firstName: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Etternavn</Label>
              <Input
                value={this.state.lastName}
                onChange={e => this.setState({ lastName: e.target.value })}
              />
            </Field>
            <Field>
              <Label>E-post</Label>
              <Input
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
              />
            </Field>
            <Field>
              <Label>Telefonnummer</Label>
              <Input
                value={this.state.phone}
                onChange={e => this.setState({ phone: e.target.value })}
              />
            </Field>
          </React.Fragment>
        )}
        <Field>
          <Label>{this.props.description ?? 'Hva lurer du på?'}</Label>
          <Textarea
            value={this.state.message}
            onChange={e => this.setState({ message: e.target.value })}
            placeholder="Skriv her"
          />
        </Field>
        <Buttons>
          <Button>Send melding</Button>
        </Buttons>
      </Form>
    )
  }
}

const mapStateToProps = state => ({
  contactNobemaStatus: state.services.contactNobema,
  isAuthenticated: isAuthenticatedSelector(state),
})

const mapDispatchToProps = { contactNobema }

export default compose(
  withSpinner(authIsPendingSelector),
  connect(mapStateToProps, mapDispatchToProps)
)(ContactNobemaForm)
