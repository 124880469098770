import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import styled from 'styled-components'
import SideNavigationBar from '../components/SideNavigation'
import { fetchClaimedCompanies } from '../api/companies'
import withLogin from '../components/withLogin'
import Button from '../components/Button'
import Spinner from '../components/Spinner'
import NoAccess from '../components/NoAccess'
import { H2, Body, body } from 'styles/text'
import { contentWidth } from 'styles'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  padding: 0 48px;
  max-width: ${contentWidth};
`

const StyledButton = styled(Button)`
  margin: 4rem 2rem 2rem 0;
  height: 5rem;
  text-align: center;
`

const I = styled.p`
  ${body};
  font-style: italic;
`

const ProspectPreview = ({ orgNumber }) => {
  const [loading, setLoading] = useState(true)
  const [access, setAccess] = useState(false)

  const licenceType = useSelector(state => {
    const licences = state.boughtProducts
    if (licences.basic?.[orgNumber]) {
      return 'basis'
    } else if (licences.full?.[orgNumber]) {
      return 'full'
    } else {
      return 'pending'
    }
  })

  useEffect(() => {
    fetchClaimedCompanies(orgNumber).then(res => {
      if (res[orgNumber]) {
        setAccess(true)
      } else {
        setAccess(false)
      }
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Spinner />
  } else if (access) {
    return (
      <SideNavigationBar active={'salgsprospekt'} orgNumber={orgNumber}>
        <Wrapper>
          <H2>Salgsprospekt - Informasjon</H2>
          <Body>
            Ditt salgsprospekt viser all informasjon om foretaket som er
            innhentet fra offentlige register og lagt til av deg.
          </Body>
          <Body>
            Interessenter kan be om lesetilgang til ditt salgsprospekt via din
            salgsannonse. Lesetilgang åpnes kun etter godkjennelse fra deg og
            etter at interessenten har signert på en konfidensialitetserklæring
            med BankID.
          </Body>
          <Body>
            Når noen ber om lesetilgang vil vi sender deg en epost, med
            informasjon om hvem som forespør og hva som er bakgrunnen for
            interessen. Du godkjenner eller avslår forespørselen på MIN SIDE
            under MINE PROSJEKTER.
          </Body>

          {licenceType === 'full' ? (
            <StyledButton to={`/prospect/${orgNumber}/preview`}>
              Vis salgsprospekt
            </StyledButton>
          ) : (
            <I>
              Din lisens for {orgNumber} inneholder ikke et salgsprospekt. Du
              kan oppgradere dine lisenser under{' '}
              <Link to="/profile/agreements">Min Profil &gt; Avtaler</Link>
            </I>
          )}
        </Wrapper>
      </SideNavigationBar>
    )
  } else {
    return <NoAccess />
  }
}

export default compose(withLogin)(ProspectPreview)
