import React from 'react'

import styled from 'styled-components'

import { colors } from 'styles'
import { h6, H6 } from 'styles/text'
import { uploadFilesFromList } from 'components/form/FileUploaderCore'

import {
  Field,
  Label,
  Input,
  Textarea,
  RadioButtons,
  Checkbox,
  AnonLabel,
  UploadFiles,
} from 'components/form'
import ButtonComponent from 'components/Button'
import PropertyTypeSelector from './PropertyTypeSelector'

const PropertyList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const PropertyHeading = styled.div`
  ${h6};
`

const Button = styled(ButtonComponent)`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const DeleteButton = styled.button`
  ${h6};
  color: ${colors.subtleText};
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
`

const emptyProperty = {
  ownershipStatus: '',
  type: '',
  address: '',
  area: '',
  marketValue: '',
  rent: '',
  employeeParking: false,
  employeeParkingAmount: '',
  customerParking: false,
  customerParkingAmount: '',
  rentGuarantee: '',
  leaseExpirationDate: '',
  leaseExtension: '',
  sharedCosts: '',
  info: '',
}

class EditProperty extends React.Component {
  state = {
    properties: [],
  }

  componentDidMount() {
    if (this.props.formValues.properties) {
      this.setState({
        properties: this.props.formValues.properties.properties,
        propertyComment: this.props.formValues.properties.propertyComment,
      })
    } else {
      this.state = {
        properties: Object.values(this.props.properties || {}).map(value =>
          Object.entries(value)
            .map(([key, value]) =>
              key === 'files'
                ? [
                    key,
                    Object.entries(value).map(([path, name]) => ({
                      path,
                      name,
                    })),
                  ]
                : [key, value]
            )
            .reduce(
              (obj, [key, value]) => ({
                ...obj,
                [key]: value,
              }),
              {}
            )
        ),

        anonymize_properties: this.props.anonymize_properties || false,
        propertyComment: this.props.propertyComment || '',
      }
    }
  }

  setData = (index, key, value, rm) => {
    let oldFiles = []
    if (key === 'files' && this.state.properties[index][key] && !rm) {
      oldFiles = this.state.properties[index][key]
    }
    return {
      properties: this.state.properties.map((list, i) =>
        i === index
          ? {
              ...list,
              [key]: key === 'files' ? [...oldFiles, ...value] : value,
            }
          : list
      ),
    }
  }

  setPropertyValue = (index, key, value) => {
    // console.log('IM used', index, key, value)
    if (
      key === 'files' &&
      value.length > 0 &&
      typeof value[0].name === 'string'
    ) {
      uploadFilesFromList(this.props.orgNumber, value).then(res => {
        const properties = this.setData(index, key, res)
        this.setState(properties)
        this.props.dispacth('properties', properties)
      })
    } else if (
      key === 'files' &&
      (value.length === 0 || typeof value[0].name !== 'string')
    ) {
      const properties = this.setData(index, key, value, true)
      this.setState(properties)
      this.props.dispacth('properties', properties)
    } else {
      const properties = this.setData(index, key, value)
      this.setState(properties)
      this.props.dispacth('properties', properties)
    }
  }

  setComment = value => {
    this.setState({ propertyComment: value })
    this.props.dispacth('properties', {
      ...this.state,
      propertyComment: value,
    })
  }

  render() {
    return (
      <>
        <PropertyList>
          {this.state.properties &&
            this.state.properties.map((property, index) => (
              <li key={index}>
                <Header>
                  <PropertyHeading>Lokale {index + 1}</PropertyHeading>
                  <DeleteButton
                    type="button"
                    onClick={() => {
                      const properties = this.state.properties.filter(
                        (_, i) => i !== index
                      )
                      this.setState({ properties: properties })
                      this.props.dispacth('properties', {
                        properties: properties,
                      })
                    }}
                  >
                    Slett lokale
                  </DeleteButton>
                </Header>
                <Field>
                  <Label>Eier du, eller leier du lokalene?</Label>
                  <RadioButtons
                    options={[
                      { value: 'rent', label: 'Leier eiendom / lokale' },
                      { value: 'own', label: 'Eier eiendom / lokale' },
                    ]}
                    value={property.ownershipStatus}
                    onChange={ownershipStatus =>
                      this.setPropertyValue(
                        index,
                        'ownershipStatus',
                        ownershipStatus
                      )
                    }
                  />
                </Field>
                <Field>
                  <Label>Velg type eiendom/lokale</Label>
                  <PropertyTypeSelector
                    value={property.type}
                    onChange={type =>
                      this.setPropertyValue(index, 'type', type)
                    }
                  />
                </Field>
                <Field>
                  <Label>Adresse</Label>
                  <Input
                    value={property.address}
                    onChange={e =>
                      this.setPropertyValue(index, 'address', e.target.value)
                    }
                    placeholder={'Gatenavn og nummer'}
                  />
                </Field>
                <Field>
                  <Label>Angi bygningsmassens/lokalets areal</Label>
                  <Input
                    value={property.buildingArea}
                    onChange={e =>
                      this.setPropertyValue(
                        index,
                        'buildingArea',
                        e.target.value
                      )
                    }
                    placeholder={'Eks: 450 kvm'}
                  />
                </Field>
                <Field>
                  <Label>Angi tomtens areal</Label>
                  <Input
                    value={property.propertyArea}
                    onChange={e =>
                      this.setPropertyValue(
                        index,
                        'propertyArea',
                        e.target.value
                      )
                    }
                    placeholder={'Eks: 980 kvm'}
                  />
                </Field>
                <Field>
                  <Label>
                    Er det tilknyttede parkeringsplasser for ansatte? Ja:{' '}
                    <Checkbox
                      checked={property.employeeParking}
                      onChange={e => {
                        this.setPropertyValue(
                          index,
                          'employeeParking',
                          e.target.checked
                        )
                      }}
                    />
                  </Label>
                  {property.employeeParking && (
                    <>
                      <Label>Angi antall</Label>
                      <Input
                        value={property.employeeParkingAmount}
                        onChange={e =>
                          this.setPropertyValue(
                            index,
                            'employeeParkingAmount',
                            e.target.value
                          )
                        }
                        placeholder={'Eks: 8'}
                      />
                    </>
                  )}
                </Field>
                <Field>
                  <Label>
                    Er det tilknyttede parkeringsplasser for kunder? Ja:{' '}
                    <Checkbox
                      checked={property.customerParking}
                      onChange={e => {
                        this.setPropertyValue(
                          index,
                          'customerParking',
                          e.target.checked
                        )
                      }}
                    />
                  </Label>
                  {property.customerParking && (
                    <>
                      <Label>Angi antall</Label>
                      <Input
                        value={property.customerParkingAmount}
                        onChange={e =>
                          this.setPropertyValue(
                            index,
                            'customerParkingAmount',
                            e.target.value
                          )
                        }
                        placeholder={'Eks: 8'}
                      />
                    </>
                  )}
                </Field>
                <Field>
                  <Label>Informasjon om eiendommen</Label>
                  <Textarea
                    value={property.info}
                    onChange={e =>
                      this.setPropertyValue(index, 'info', e.target.value)
                    }
                    placeholder={'Kort beskrivelse av eiendommen/lokale'}
                  />
                </Field>
                {property.ownershipStatus === 'own' && (
                  <React.Fragment>
                    <Field>
                      <Label>Markedsverdi</Label>
                      <Input
                        value={property.marketValue}
                        onChange={e =>
                          this.setPropertyValue(
                            index,
                            'marketValue',
                            e.target.value
                          )
                        }
                        placeholder={'Eks: Kr 3 500 000,-'}
                      />
                    </Field>
                  </React.Fragment>
                )}

                {property.ownershipStatus === 'rent' && (
                  <React.Fragment>
                    {[
                      {
                        prop: 'rent',
                        label: 'Husleie per år',
                        placeholder: 'Eks: kr 180 000,-',
                      },
                      {
                        prop: 'rentGuarantee',
                        label: 'Husleiegaranti / depositum',
                        placeholder:
                          'Eks: Depositum kr 45 000,- / Husleiegaranti kr 45 000,- ',
                      },
                      {
                        prop: 'leaseExpirationDate',
                        label: 'Leieperiodens slutt',
                        placeholder: 'YYYY-MM-DD',
                      },
                      {
                        prop: 'leaseExtension',
                        label: 'Forlengelse',
                        placeholder:
                          'Beskriv eventuelle avtaler som regulerer videreføring av leieforhold',
                      },
                      {
                        prop: 'sharedCosts',
                        label: 'Felleskostnader per år',
                        placeholder:
                          'Oppgi eventuelle felleskostnader som kommer i tillegg til husleie',
                      },
                    ].map(({ prop, label, placeholder }) => (
                      <Field key={prop}>
                        <Label>{label}</Label>
                        <Input
                          value={property[prop]}
                          onChange={e =>
                            this.setPropertyValue(index, prop, e.target.value)
                          }
                          placeholder={placeholder}
                        />
                      </Field>
                    ))}
                  </React.Fragment>
                )}
                <Field>
                  <Label>Vedlegg eiendomsinformasjon</Label>
                  <UploadFiles
                    value={property.files || []}
                    onChange={files =>
                      this.setPropertyValue(index, 'files', files)
                    }
                  />
                </Field>
              </li>
            ))}
        </PropertyList>
        {this.props.anonymized &&
          this.state.properties &&
          this.state.properties.length > 0 && (
            <Field>
              <AnonLabel>
                <Checkbox
                  checked={this.state.anonymize_properties}
                  onChange={e =>
                    this.setState({ anonymize_properties: e.target.checked })
                  }
                />
                Anonymiser eiendommer
              </AnonLabel>
            </Field>
          )}
        <Button
          subtle
          type="button"
          onClick={() =>
            this.setState({
              properties: [...this.state.properties, emptyProperty],
            })
          }
        >
          + Legg til lokale eller eiendom
        </Button>
        <Field>
          <H6>Ytterligere informasjon om Eiendom og Lokaler</H6>
          <Textarea
            value={this.state.propertyComment}
            onChange={e => this.setComment(e.target.value)}
            placeholder={'Eventuell tilleggsinformasjon'}
          />
        </Field>
      </>
    )
  }
}

export default EditProperty
