import { authIsPendingSelector, isAuthenticatedSelector } from './index'

export const prospectRequestsIsPendingSelector = state => {
  if (authIsPendingSelector(state)) {
    return true
  } else if (isAuthenticatedSelector(state)) {
    return state.prospectRequests.pending
  } else {
    return false
  }
}

export const prospectRequestsSelector = state => state.prospectRequests

export const accessibleProspectsIsPendingSelector = state => {
  if (authIsPendingSelector(state)) {
    return true
  } else if (isAuthenticatedSelector(state)) {
    return state.accessibleProspects.pending
  } else {
    return false
  }
}

export const accessibleProspectsSelector = state => {
  return state.accessibleProspects
}