import React, { useState, useEffect } from 'react'
import { getIdToken } from '../../api/firebase'
import styled from 'styled-components'
import EmptyImagePreview from '../EmptyImagePlaceholder'
import { colors } from 'styles'
import { buttonText } from 'styles/text'
import RadioButtons from './RadioButtons'
import {
  uploadFiles,
  getFilesFromStorage,
  deleteFileFromStorage,
} from './FileUploaderCore'

const Wrapper = styled.div`
  height: 16.8rem;
  width: 100%;
  position: relative;
`

const StyledInput = styled.input`
  padding: 7.5rem 1rem;
  position: absolute;
  cursor: pointer;
  width: 100%;
  opacity: 0;
`
const StyledButton = styled.button`
  ${buttonText}
  position: absolute;
  transform: translate(-105%, 10%);
  background-color: ${colors.white};
  border-radius: 50px;
  padding: 1rem 1.5rem;
  border: none;
`

const DeleteButton = styled.button`
  background-color: ${colors.white};
  position: absolute;
  z-index: 2;
`

const RadioWrapper = styled.div`
  position: absolute;
  transform: translateY(-125%);
  width: 100%;
  display: flex;
  justify-content: center;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 168px;
`

const FileUploader = props => {
  const { dispatcher, single, fpFromStore, orgNumber, path, required } = props

  // Combines local files and files from store
  // Local state continas file objects while store only has filepaths to storage
  const [images, setImages] = useState([])
  const [previewUrl, setPreviewUrl] = useState('')
  const [checked, setChecked] = useState('')
  const [token, setToken] = useState('')

  useEffect(() => {
    getIdToken().then(gottenToken => setToken(gottenToken))
  }, [])

  useEffect(() => {
    if (fpFromStore && token) {
      if (fpFromStore.length === 0) return
      Promise.resolve(
        getFilesFromStorage(token, orgNumber, fpFromStore, path)
      ).then(res => {
        // Set preview of first image from storage
        setPreviewUrl(res.data[0].url)
        setImages(res.data)
      })
    }
  }, [token])

  return (
    <Wrapper>
      {!single && (
        <DeleteButton onClick={() => dispatcher('delete')}>X</DeleteButton>
      )}
      <StyledInput
        type="file"
        multiple
        onChange={e => {
          const filesToUpload = [...e.target.files]
          filesToUpload.forEach(
            image => (image.url = URL.createObjectURL(image))
          )

          setImages([...e.target.files, ...images])

          // Upload files to storage
          Promise.resolve(uploadFiles(token, orgNumber, e.target.files, path))
            .then(res => {
              // Dispatch filepaths to store
              if (fpFromStore) {
                dispatcher([
                  ...fpFromStore,
                  ...res.map(image => image.filepath),
                ])
              } else {
                dispatcher(res.map(image => image.filepath))
              }
              setPreviewUrl(filesToUpload[0].url)
            })
            .catch(err => console.log(err))
        }}
      />

      {previewUrl ? (
        <>
          <Image src={previewUrl} />
          {!single && (
            <RadioWrapper>
              <div>
                <RadioButtons
                  horizontal
                  options={images.map(img => ({
                    label: '',
                    value: img,
                  }))}
                  value={checked}
                  onChange={value => {
                    setPreviewUrl(value.url)
                    setChecked(value)
                  }}
                />
              </div>
            </RadioWrapper>
          )}
        </>
      ) : (
        <EmptyImagePreview upload required={required}/>
      )}
      {images &&
        images.map((file, index) => (
          <StyledButton
            key={file.name + 'delete' + index}
            onClick={() => {
              const fileToDelte = images[index]
              const updatedFiles = [
                ...images.slice(0, index),
                ...images.slice(index + 1),
              ]
              setImages(updatedFiles)
              setPreviewUrl(
                updatedFiles.length === 0 ? '' : updatedFiles[0].url
              )
              dispatcher(updatedFiles.map(image => image.filepath))
              deleteFileFromStorage(orgNumber, fileToDelte.filepath, path)
            }}
          >
            Slett
          </StyledButton>
        ))}
    </Wrapper>
  )
}

export default FileUploader
