import React from 'react'

import { colors } from 'styles'

const Heart = ({ hover, active }) => (
  <svg width="24px" height="21px" viewBox="0 0 24 21">
    {active ? (
      <path
        d="M12.0000143,20.7337265 C11.7857283,20.7337265 11.5714424,20.6533693 11.4107279,20.4926548 L3.05357507,12.4301452 C2.94643208,12.3363951 0,9.64442761 0,6.43013807 C0,2.50602625 2.39732429,0.162273453 6.40179335,0.162273453 C8.74554614,0.162273453 10.9419773,2.01048994 12.0000143,3.05513404 C13.0580513,2.01048994 15.2544825,0.162273453 17.5982353,0.162273453 C21.6027043,0.162273453 24.0000286,2.50602625 24.0000286,6.43013807 C24.0000286,9.64442761 21.0535965,12.3363951 20.9330607,12.456931 L12.5893007,20.4926548 C12.4285862,20.6533693 12.2143003,20.7337265 12.0000143,20.7337265 Z"
        fill={colors.action}
      />
    ) : (
      <path
        stroke="none"
        strokeWidth="1"
        fill={hover ? colors.action : colors.primaryGray}
        fillRule="evenodd"
        d="M22.2857409,6.26786461 C22.2857409,2.50446727 19.741095,1.71428776 17.5982353,1.71428776 C15.6026972,1.71428776 13.3526945,3.87054033 12.6562651,4.70089846 C12.3348361,5.08929178 11.6651925,5.08929178 11.3437635,4.70089846 C10.6473341,3.87054033 8.39733144,1.71428776 6.40179335,1.71428776 C4.25893365,1.71428776 1.71428776,2.50446727 1.71428776,6.26786461 C1.71428776,8.71876039 4.19196928,10.9955488 4.21875503,11.0223346 L12.0000143,18.5223435 L19.7678807,11.0357274 C19.8080593,10.9955488 22.2857409,8.71876039 22.2857409,6.26786461 Z M24.0000286,6.26786461 C24.0000286,9.48215416 21.0535965,12.1741217 20.9330607,12.2946575 L12.5893007,20.3303814 C12.4285862,20.4910959 12.2143003,20.5714531 12.0000143,20.5714531 C11.7857283,20.5714531 11.5714424,20.4910959 11.4107279,20.3303814 L3.05357507,12.2678718 C2.94643208,12.1741217 0,9.48215416 0,6.26786461 C0,2.34375279 2.39732429,0 6.40179335,0 C8.74554614,0 10.9419773,1.84821649 12.0000143,2.89286059 C13.0580513,1.84821649 15.2544825,0 17.5982353,0 C21.6027043,0 24.0000286,2.34375279 24.0000286,6.26786461 Z"
      />
    )}
  </svg>
)

export default Heart
