import { FETCH_SUGGESTIONS_SUCCESS } from 'actionTypes'

export default (
  state = {
    suggestions: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          [action.query]: action.suggestions,
        },
      }

    default:
      return state
  }
}
