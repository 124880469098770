import React from 'react'
import styled from 'styled-components'

import { h1 } from 'styles/text'
import { colors } from 'styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.action};
  color: ${colors.white};
  padding: 40px;
`

const Heading = styled.h1`
  ${h1};
  color: ${colors.white};
`

const MailLink = styled.a`
  color: ${colors.white};
`

const Success = () => (
  <Wrapper>
    <Heading>Din forespørsel er sendt</Heading>
    <div>
      Du vil få svar på epost når forespørselen er behandlet.Ved aksept vil
      tilgang åpnes etter signeringing av fortrolighetserklæringer med BankID
    </div>
    <div>Telefon: +47 928 95 485</div>
    <div>(Mandag til fredag 08:00 - 16:00)</div>
    <div>
      E-post: <MailLink href="mailto:post@nobema.no">post@nobema.no</MailLink>
    </div>
  </Wrapper>
)

export default Success
