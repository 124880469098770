import React from 'react'

import InfoBox, { Heading, Button } from './InfoBox'
import { Body } from 'styles/text'
import { colors } from 'styles'

const IsThisYourCompany = ({ companyId }) => (
  <InfoBox beeStyling>
    <Heading beeStyling>Er dette din bedrift?</Heading>
    <Body style={{ color: colors.black }}>
      Nobema hjelper deg med å med å komme i kontakt med potensielle kjøpere. Du
      velger selv om du vil være åpen eller anonym.
    </Body>
    <Button beeStyling href="http://www.nobema.no/din_bedrift">
      Les mer
    </Button>
  </InfoBox>
)

export default IsThisYourCompany
