import { average, median, lastYear } from './utils'
import projectedInvestments from './projectedInvestments'

export const depreciationOverAssets = data =>
  median(
    data
      .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
      .map(
        ({
          Avskrivninger,
          Nedskrivninger,
          SumImmatrielleEiendeler,
          SumVarigeDriftsmidler,
        }) => ({
          depreciation: Avskrivninger + Nedskrivninger,
          assets: SumImmatrielleEiendeler + SumVarigeDriftsmidler,
        })
      )
      .map(({ depreciation }, index, array) => {
        const assets = (array[index - 1] || {}).assets
        return assets === 0 ? 0 : depreciation / assets
      })
      .map((_, index, array) =>
        average(array.slice(index).filter(n => !isNaN(n)))
      )
  )

export default (data, year) => {
  if (data.length === 0) return

  const {
    RegnskapsAvAr,
    SumImmatrielleEiendeler,
    SumVarigeDriftsmidler,
  } = lastYear(data)

  let assets = SumImmatrielleEiendeler + SumVarigeDriftsmidler
  let depreciation

  for (let count = RegnskapsAvAr + 1; count <= year; count += 1) {
    depreciation = assets * depreciationOverAssets(data)
    assets = assets - depreciation + projectedInvestments(data, count)
  }

  return depreciation
}
