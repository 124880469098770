import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import { ScrollContext } from 'react-router-scroll-4'
import qs from 'qs'
import { Helmet } from 'react-helmet'
import { ErrorBoundary } from 'react-error-boundary'

import { closeMenu } from 'actions'
import { overlaySelector } from 'selectors'
import { colors } from 'styles'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Search from 'pages/Search'
import Company from 'pages/Company'
import PreviewCompany from 'pages/PreviewCompany'
import PreviewAdvertisement from 'pages/PreviewAdvertisement'
import Advertisement from 'pages/Advertisement'
import LogIn from 'pages/LogIn'
import Register from 'pages/Register'
import Profile from 'pages/Profile'
import StyleGuide from 'pages/StyleGuide'
import Services from 'pages/Services'
import Success from 'pages/Success'
import ProspectGenerator from 'pages/ProspectGenerator'
import NoSuchRoute from 'pages/NoSuchRoute'
import ProspectAdvertisement from 'pages/ProspectAdvertisement'
import Prospect from './pages/Prospect'
import ProspectPreview from './pages/ProspectPreview'
import { frontendUrl } from './environment'
import { useIdleTimer } from 'react-idle-timer'
import { isAdmin } from './api/firebase'
import { isLoggedIn } from './api/users'
import { logOut } from './api/auth'
import TagManager from 'react-gtm-module'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      role="alert"
      style={{
        fontSize: '20px',
        margin: '20vh auto',
        textAlign: 'center',
      }}
    >
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1 0 auto;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.overlay};
`

const NoopComponent = ({ children }) => children

const App = ({ overlay, closeMenu, isClient }) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-NMB2RD9',
    }
    TagManager.initialize(tagManagerArgs)
  }, [])

  let ScrollContextImpl = isClient ? ScrollContext : NoopComponent

  // Handle idle user
  const handleOnIdle = () => {
    let isLogeddIn = false
    isLoggedIn()
      .then(status => {
        isLogeddIn = status
      })
      .then(() => {
        if (isLogeddIn) {
          isAdmin().then(admin => {
            if (admin) {
              return
            } else {
              logOut()
            }
          })
        }
      })
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    // Default time out is 30 min
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={(queryErrorResetBoundary) => {
          queryErrorResetBoundary.reset()
        }}
      >
        <Helmet>
          <title>Nobema</title>
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Nobema - Bedrifter til salgs" />
          <meta
            property="og:description"
            content="Nasjonal markedsplass for omsetning av bedrifter."
          />
          <meta name="theme-color" content="#000000" />
          <meta
            property="og:image"
            content={`${frontendUrl()}/general_header.jpg`}
          />

          <link rel="manifest" href="/favicons/site.webmanifest" />
          <link rel="shortcut icon" href="/favicons/favicon.ico" />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&family=Petrona:ital,wght@0,400;0,700;0,800;1,400;1,600&display=swap"
            rel="stylesheet"
          />
          {/* Start of HubSpot Embed Code */}
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/8278034.js"
          ></script>
          {/* <!-- End of HubSpot Embed Code --> */}
        </Helmet>
        <ScrollContextImpl>
          <Body>
            {/* <!-- Google Tag Manager (noscript) --> */}
            <noscript>
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-NMB2RD9"
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              ></iframe>
            </noscript>
            {/* <!-- End Google Tag Manager (noscript) --> */}
            <Content>
              <Header />
              <Switch>
                <Route
                  path="/"
                  exact
                  render={props => (
                    <Search
                      query={qs.parse(props.location.search.replace(/\?/, ''))}
                    />
                  )}
                />
                <Route
                  path="/search"
                  render={props => (
                    <Search
                      query={qs.parse(props.location.search.replace(/\?/, ''))}
                    />
                  )}
                />
                <Route
                  path="/prospect/:orgNumber/information"
                  component={props => (
                    <ProspectPreview
                      {...props}
                      orgNumber={props.match.params.orgNumber}
                    />
                  )}
                />
                <Route
                  path="/prospect/:companyId/preview"
                  component={props => (
                    <PreviewCompany
                      {...props}
                      companyId={props.match.params.companyId}
                    />
                  )}
                />
                <Route
                  path="/companies/:companyId"
                  component={props => (
                    <Company
                      {...props}
                      companyId={props.match.params.companyId}
                    />
                  )}
                />
                <Route
                  path="/advertisement/:companyId/preview"
                  component={props => (
                    <PreviewAdvertisement
                      {...props}
                      companyId={props.match.params.companyId}
                    />
                  )}
                />
                <Route
                  path="/advertisement/create/:orgNumber"
                  component={props => (
                    <ProspectAdvertisement
                      {...props}
                      orgNumber={props.match.params.orgNumber}
                    />
                  )}
                />
                <Route
                  path="/advertisements/:advertisementId"
                  component={props => (
                    <Advertisement
                      {...props}
                      advertisementId={props.match.params.advertisementId}
                    />
                  )}
                />
                <Route path="/services" component={Services} />
                <Route path="/logIn" component={LogIn} />
                <Route path="/register" component={Register} />
                <Route path="/profile" component={Profile} />
                <Route path="/success" component={Success} />
                <Route
                  path="/prospect/:orgNumber"
                  component={props => (
                    <ProspectGenerator
                      {...props}
                      orgNumber={props.match.params.orgNumber}
                    />
                  )}
                />
                <Route
                  path="/doc/prospect/:orgNr"
                  component={props => (
                    <Prospect {...props} orgNr={props.match.params.orgNr} />
                  )}
                />
                <Route path="/styleguide" component={StyleGuide} />
                <Route component={NoSuchRoute} />
              </Switch>
            </Content>
            <Footer />
            {overlay && <Overlay onClick={closeMenu} />}
          </Body>
        </ScrollContextImpl>
      </ErrorBoundary>
    </>
  )
}

const mapStateToProps = state => ({
  overlay: overlaySelector(state),
})

const mapDispatchToProps = { closeMenu }

export default compose(connect(mapStateToProps, mapDispatchToProps))(App)
