import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { colors } from '../styles'
import Button, { buttonStyle } from './Button'
import { withRouter } from 'react-router-dom'
import { resetCompanyAdvertisementAnonymizeToggle } from 'actions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`

const SideNav = styled.div`
  width: 8.4rem;
  background: ${colors.alabasterBackground};
  min-height: 100%;
  align-items: stretch;
  flex-shrink: 0;
  padding-top: 48px;
`

const Container = styled.div`
  width: calc(100% - 8.4rem);
`

const SideNavButton = styled(Button)`
  ${buttonStyle};
  color: ${colors.black};
  font-size: 1rem;
  width: 100%;
  border-radius: 0px;
  padding: 1.2rem 0;
  flex-direction: column;
  background-color: ${props =>
    props.active ? colors.white : colors.alabasterBackground};

  &:focus,
  &:hover {
    background-color: ${colors.whiteHover};
  }
`

const SideNavigationBar = props => {
  const menu = [
    {
      key: 'prospect',
      label: 'Rediger',
      imgSrc: '/edit-prospect.svg',
      link: `/prospect/${props.orgNumber}`,
    },
    {
      key: 'annonse',
      label: 'Annonse',
      imgSrc: '/graph-icon.svg',
      link: `/advertisement/create/${props.orgNumber}`,
    },
    {
      key: 'salgsprospekt',
      label: 'Prospekt',
      imgSrc: '/prospect-icon.svg',
      link: `/prospect/${props.orgNumber}/information`,
    },
    {
      key: 'consultant',
      label: 'Rådgiver',
      imgSrc: '/consultation.svg',
      href: 'https://www.nobema.no/personlig_bistand',
    },
  ]

  return (
    <Wrapper>
      <SideNav>
        {menu.map(menuButton => {
          if (menuButton.key === 'annonse') {
            return (
              <SideNavButton
                to={menuButton.link}
                active={props.active === menuButton.key}
                key={menuButton.key}
                onClick={() => {
                  props.resetCompanyAdvertisementAnonymizeToggle(
                    props.orgNumber
                  )
                }}
              >
                <img src={menuButton.imgSrc} />
                <p>{menuButton.label}</p>
              </SideNavButton>
            )
          }
          return (
            <SideNavButton
              to={menuButton.link}
              href={menuButton.href}
              target={menuButton.href && '_blank'}
              active={props.active === menuButton.key}
              key={menuButton.key}
            >
              <img src={menuButton.imgSrc} alt="" />
              <p>{menuButton.label}</p>
            </SideNavButton>
          )
        })}
      </SideNav>
      <Container>{props.children}</Container>
    </Wrapper>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = { resetCompanyAdvertisementAnonymizeToggle }

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideNavigationBar)
)
