import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { companyAdvertisementSelector } from 'selectors'
import { h6, subtitle, Body } from 'styles/text'
import { wrapper, padding, content } from './styles'

import Expandable from 'components/Expandable'
import SellersComment from 'components/SellersComment'
import File from 'components/File'
import Anonymized from 'components/Anonymized'

const Wrapper = styled.div`
  ${wrapper};
`

const Heading = styled.h6`
  ${h6};
  ${padding};
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const Content = styled.div`
  ${content};

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const PropertyList = styled.ul`
  & > :not(:last-child) {
    margin-bottom: 36px;
  }
`

const PropertyHeading = styled.h5`
  ${subtitle};
  font-size: 18px;
  margin: 0 0 12px 0;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`

const Li = styled.li`
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const NoBulletList = styled.li`
  list-style-type: none;
`

const NoBulletListInner = styled.li`
  list-style-type: none;
  margin-bottom: 4px;
`

const Property = props => (
  <>
    {props.properties || props.propertyComment || props.attatchments ? (
      <Wrapper>
        <Expandable
          renderHeader={({ open }) => (
            <Heading open={open}>
              <span>Eiendom og lokaler</span>
              <img src="/toggle_down.svg" alt="" />
            </Heading>
          )}
          renderContent={() => (
            <Content>
              {props.anonymized && props.forSale ? (
                <Anonymized />
              ) : (
                <React.Fragment>
                  <PropertyList>
                    {Object.values(props.properties || {}).map(
                      (property, index) => (
                        <NoBulletList key={index}>
                          <PropertyHeading>
                            {props.anonymized &&
                              props.anonymize_properties &&
                              props.claimed && (
                                <img src="/anonymized.svg" alt="" />
                              )}
                            <span>
                              Lokale {index + 1}{' '}
                              {property.ownershipStatus === 'own'
                                ? ' - Eid eiendom'
                                : ' - Leid eiendom'}
                            </span>
                          </PropertyHeading>

                          <ul>
                            {[
                              { label: 'Type', prop: 'type' },
                              { label: 'Adresse', prop: 'address' },
                              { label: 'Areal', prop: 'area' },
                              {
                                label: 'Informasjon om eiendommen',
                                prop: 'info',
                              },
                              ...(property.ownershipStatus === 'own'
                                ? [
                                    {
                                      prop: 'marketValue',
                                      label: 'Markedsverdi',
                                    },
                                  ]
                                : property.ownershipStatus === 'rent'
                                ? [
                                    {
                                      prop: 'rent',
                                      label: 'Husleie',
                                    },
                                    {
                                      prop: 'rentGuarantee',
                                      label: 'Husleiegaranti',
                                    },
                                    {
                                      prop: 'leaseExpirationDate',
                                      label: 'Løpetid i leiekontrakt',
                                    },
                                    {
                                      prop: 'leaseExtension',
                                      label: 'Forlengelse',
                                    },
                                    {
                                      prop: 'sharedCosts',
                                      label: 'Felleskostnader',
                                    },
                                  ]
                                : []),
                            ].map(
                              ({ label, prop }) =>
                                property[prop] && (
                                  <NoBulletListInner key={label}>
                                    <b>{label}</b>: {property[prop]}
                                  </NoBulletListInner>
                                )
                            )}
                            {property.files &&
                              Object.keys(property.files).length > 0 && (
                                <NoBulletListInner>
                                  <div>
                                    <div>Vedlegg</div>
                                    <ul>
                                      {Object.entries(property.files || {}).map(
                                        ([path, name]) => (
                                          <Li key={path}>
                                            <File
                                              path={'prospect'}
                                              name={name}
                                              orgNumber={props.orgNumber}
                                            />
                                          </Li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </NoBulletListInner>
                              )}
                          </ul>
                        </NoBulletList>
                      )
                    )}
                  </PropertyList>

                  {props.attatchments && (
                    <>
                      <Body>Andre vedlegg:</Body>
                      <ul>
                        {props.attatchments.map(attatchment => (
                          <li key={attatchment}>
                            <File
                              path={'prospect'}
                              name={attatchment}
                              orgNumber={props.orgNumber}
                            />
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </React.Fragment>
              )}
              <SellersComment {...props}>
                {props.propertyComment}
              </SellersComment>
            </Content>
          )}
        />
      </Wrapper>
    ) : null}
  </>
)

const mapStateToProps = (state, ownProps) => ({
  ...companyAdvertisementSelector(state, ownProps),
})

export default connect(mapStateToProps)(Property)
