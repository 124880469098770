import React from 'react'
import styled from 'styled-components'
import { Overline } from 'styles/text'

const Border = styled.div`
  height: 37.2rem;
  width: 100%;
  border: 2px dashed rgba(42, 50, 43, 0.2);
  display: flex; 
  align-items: center; 
  justify-content: center;
  text-align: center;
  cursor: pointer;
`


const AddItemShowcase = (props) => {
  const { onClick } = props

  return(
    <Border onClick={onClick}>
      <div>
        <img src="/plus.svg" />
        <Overline>Legg til</Overline>
      </div>
    </Border>
  )

}

export default AddItemShowcase


