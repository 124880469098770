import { OPEN_MENU, CLOSE_MENU } from 'actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        isOpen: true,
      }
    case CLOSE_MENU:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return state
  }
}
