import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Button from 'components/Button'

import { logOut } from 'actions'
import { isAuthenticatedSelector, authIsPendingSelector } from 'selectors'

import SmallHeader from './SmallHeader'
import LargeHeader from './LargeHeader'
import styled from 'styled-components'

const CustomButton = styled(Button)`
  padding: 12px 4px;
`

const Item = styled.div`
  padding: 10px 0;
`

const Header = ({ isAuthenticated, authIsPending, logOut }) => {
  let menuItems
  if (authIsPending) {
    menuItems = []
  } else if (isAuthenticated) {
    menuItems = [
      { children: <Item>Markedsplass</Item>, to: '/search' },
      { children: <Item>Priser</Item>, href: 'http://www.nobema.no/priser' },
      { children: <Item>Blogg</Item>, href: 'http://www.nobema.no/blogg' },
      { children: <Item>Om oss</Item>, href: 'http://www.nobema.no/om-oss' },
      { children: <Item>Min side</Item>, to: '/profile' },
      { children: <Item>Logg ut</Item>, onClick: logOut },
    ]
  } else {
    menuItems = [
      { children: <Item>Markedsplass</Item>, to: '/search' },
      { children: <Item>Priser</Item>, href: 'http://www.nobema.no/priser' },
      { children: <Item>Blogg</Item>, href: 'http://www.nobema.no/blogg' },
      { children: <Item>Om oss</Item>, href: 'http://www.nobema.no/om-oss' },
      { children: <Item>Min side</Item>, to: '/profile' },
      {
        children: <CustomButton>Prøv gratis</CustomButton>,
        href: 'http://www.nobema.no/prov_gratis',
      },
    ]
  }

  return (
    <>
      <div
        css={`
          display: none;
          @media (min-width: 1024px) {
            display: block;
          }
        `}
      >
        <LargeHeader menuItems={menuItems} />
      </div>
      <div
        css={`
          @media (min-width: 1024px) {
            display: none;
          }
        `}
      >
        <SmallHeader menuItems={menuItems} />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticatedSelector(state),
  authIsPending: authIsPendingSelector(state),
})

const mapDispatchToProps = { logOut }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
