import {
  FETCH_FAVORITED_ADVERTISEMENTS,
  FETCH_FAVORITED_ADVERTISEMENTS_SUCCESS,
  ADD_ADVERTISEMENT_TO_FAVORITES,
  REMOVE_ADVERTISEMENT_FROM_FAVORITES,
  LOG_OUT,
} from 'actionTypes'

const initialState = { pending: true }

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAVORITED_ADVERTISEMENTS:
      return {
        pending: true,
      }
    case FETCH_FAVORITED_ADVERTISEMENTS_SUCCESS:
      return action.favoritedAdvertisements
    case ADD_ADVERTISEMENT_TO_FAVORITES:
      return {
        ...state,
        [action.advertisementId]: true,
      }
    case REMOVE_ADVERTISEMENT_FROM_FAVORITES:
      return {
        ...state,
        [action.advertisementId]: false,
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
