import {
  UPDATE_TERMS_STATUS_SUCCESS,
  UPDATE_TERMS_STATUS_ERROR,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TERMS_STATUS_SUCCESS:
      return {
        ...state,
        ...action.state,
      }
    case UPDATE_TERMS_STATUS_ERROR:
      return action.message
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
