import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'

const Wrapper = styled.div`
  cursor: pointer;
`

class X extends React.Component {
  state = {
    hover: false,
  }

  render() {
    return (
      <Wrapper
        className={this.props.className}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={this.props.onClick}
      >
        <svg
          width={this.props.small ? '12px' : '18px'}
          height={this.props.small ? '12px' : '18px'}
          viewBox="0 0 18 18"
        >
          <polygon
            stroke="none"
            strokeWidth="1"
            fillRule="evenodd"
            fill={this.state.hover ? colors.action : colors.subtleText}
            points="18 1.82474227 10.8247423 9 18 16.1752577 16.1752577 18 9 10.8247423 1.82474227 18 0 16.1752577 7.17525773 9 0 1.82474227 1.82474227 0 9 7.17525773 16.1752577 0"
          />
        </svg>
      </Wrapper>
    )
  }
}

export default X
