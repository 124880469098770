import { call, put, takeEvery } from 'redux-saga/effects'

import {
  FETCH_USER_SETTINGS,
  FETCH_USER_SETTINGS_SUCCESS,
  FETCH_USER_SETTINGS_ERROR,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_ERROR,
} from 'actionTypes'
import * as api from 'api'

function* fetchUserSettings() {
  try {
    const settings = yield call(api.fetchUserSettings)
    yield put({ type: FETCH_USER_SETTINGS_SUCCESS, settings })
  } catch ({ message }) {
    yield put({ type: FETCH_USER_SETTINGS_ERROR, message })
  }
}

function* updateUserSettings({ settings }) {
  try {
    yield call(api.updateUserSettings, settings)
    yield put({ type: UPDATE_USER_SETTINGS_SUCCESS, settings })
  } catch ({ message }) {
    yield put({ type: UPDATE_USER_SETTINGS_ERROR, settings, message })
  }
}

function* saga() {
  yield takeEvery(FETCH_USER_SETTINGS, fetchUserSettings)
  yield takeEvery(UPDATE_USER_SETTINGS, updateUserSettings)
}

export default saga
