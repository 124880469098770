import React from 'react'
import styled from 'styled-components'

import { border, colors } from 'styles'
import { body } from 'styles/text'

import ErrorComponent from 'components/Error'

const Wrapper = styled.div``

const Textarea = styled.textarea`
  ${body};
  padding: 12px;
  color: ${colors.primaryGray};
  border: ${border};
  border-radius: 4px;
  width: 100%;
  background-color: ${props => {
    if (props.error) {
      return colors.errorBackground
    } else if (props.success) {
      return colors.successBackground
    } else {
      return colors.inputBackground
    }
  }};
  border-color: ${props => {
    if (props.error) {
      return colors.error
    } else if (props.success) {
      return colors.action
    } else {
      return colors.borderColor
    }
  }};

  &:focus {
    border-color: ${colors.action};
  }

  &:disabled {
    background: ${colors.disabled};
  }
`

const HelpText = styled.div``

const Error = styled(ErrorComponent)`
  margin-top: 5px;
`

export default props => (
  <Wrapper>
    <Textarea type="text" {...props} rows={4} />
    {props.error ? (
      <Error>{props.error}</Error>
    ) : (
      props.helpText && <HelpText>{props.helpText}</HelpText>
    )}
  </Wrapper>
)
