import { connect } from 'react-redux'

import { updateCompanyAdvertisement } from 'actions'
import {
  companySelector,
  companyAdvertisementSelector,
  companyAdvertisementIsAnonymizedSelector,
} from 'selectors'

const connectAdvertisementForm = WrappedComponent => {
  const mapStateToProps = (state, ownProps) => ({
    ...companySelector(state, ownProps),
    ...companyAdvertisementSelector(state, ownProps),
    anonymized: companyAdvertisementIsAnonymizedSelector(state, ownProps),
  })

  const mapDispatchToProps = { updateCompanyAdvertisement }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent)
}

export default connectAdvertisementForm
