import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import { v4 as uuid } from 'uuid'
import {
  firebaseApiKey,
  firebaseDbUrl,
  firebaseProjectId,
  firebaseStorageBucket,
} from '../environment'

export const firebaseApp = firebase.initializeApp({
  apiKey: firebaseApiKey,
  databaseURL: firebaseDbUrl,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
})

export const auth = firebase.auth()
export const database = firebase.database()

export const currentUserId = () => firebase.auth().currentUser.uid
export const timestamp = firebase.database.ServerValue.TIMESTAMP

export const authenticateWithGoogle = () =>
  firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider())

export const getIdToken = () =>
  auth.currentUser ? auth.currentUser.getIdToken(true) : Promise.resolve(null)

export const uploadImage = image =>
  firebaseApp
    .storage('gs://nobema-images')
    .ref(`images/${uuid()}`)
    .put(image)
    .then(snap => snap.ref.location.path)

export const uploadFile = file =>
  firebaseApp
    .storage('gs://nobema-files')
    .ref(`${uuid()}`)
    .put(file)
    .then(snap => snap.ref.location.path)

export const uploadSecretFile = (file, name) =>
  firebaseApp
    .storage('gs://nobema-company')
    .ref(`${uuid()}.${name}`)
    .put(file)
    .then(snap => snap.ref.location.path)

export const getDownloadURL = path =>
  firebaseApp.storage('gs://nobema-files').ref(path).getDownloadURL()

export const setTermsStatus = status =>
  firebase.database().ref(`/terms-and-service/${currentUserId()}`).set(status)

export const getTermsStatus = () =>
  firebase
    .database()
    .ref(`/terms-and-service/${currentUserId()}`)
    .once('value')
    .then(snapshot => snapshot.val() || false)

export const getOpenIdIdToken = () =>
  firebase
    .database()
    .ref(`/users/${currentUserId()}/userinfo/id_token`)
    .once('value')
    .then(snapshot => snapshot.val() || '')

export const isAdmin = () =>
  firebase
    .database()
    .ref(`users/${currentUserId()}/admin`)
    .once('value')
    .then(snapshot => snapshot.val() || false)
