import React from 'react'

import { Select } from 'components/form'

const types = {
  Boligeiendom: {
    'Med bygning': {
      'Frittliggende eneboliger': null,
      Tomannsboliger: null,
      'Rekke- og kjedehus': null,
      Blokkleiligheter: null,
      'Andre boligtyper': null,
    },
    'Uten bygning': null,
    'I et felleskap': null,
    Eget: null,
  },
  Fritidseiendom: { 'Med bygning': null, 'Uten bygning': null },
  'Landbruks-/fiskeeiendom': {
    'Med bygning': null,
    'Uten bygning': null,
    'I et felleskap': null,
    Eget: null,
  },
  'Forretnings-/kontoreiendom': {
    'Med bygning': null,
    'Uten bygning': null,
    'I et felleskap': null,
    Eget: null,
  },
  'Industri-/bergverkseiendom': {
    'Med bygning': null,
    'Uten bygning': null,
    'I et felleskap': null,
    Eget: null,
  },
  Kombinasjonseiendom: {
    'Med bygning': null,
    'Uten bygning': null,
    'I et felleskap': null,
    Eget: null,
  },
  Kommunikasjonseiendom: {
    'Offentlig vei': { 'Med bygning': null, 'Uten bygning': null },
    Kommunikasjonsareal: { 'Med bygning': null, 'Uten bygning': null },
  },
  'Annen eiendom': {
    Verneområde: { 'Med bygning': null, 'Uten bygning': null },
    Institusjon: { 'Med bygning': null, 'Uten bygning': null },
    'Friareal/idrettsanlegg': { 'Med bygning': null, 'Uten bygning': null },
  },
  Næringslokale: {
    'Med bygning': null,
    'Uten bygning': null,
    'I et felleskap': null,
    Eget: null,
  },
  'Behandlings- og aktivitetslokale': {
    'Med byggning': null,
    'Uten byggning': null,
    'I et felleskap': null,
    Eget: null,
  },
  Butikklokale: {
    'Med bygning': null,
    'Uten bygning': null,
    'I et felleskap': null,
    Eget: null,
  },
  Kontorlokale: {
    'Med byggning': null,
    'Uten byggning': null,
    'I et felleskap': null,
    Eget: null,
  },
  Lagerlokale: {
    'Med byggning': null,
    'Uten byggning': null,
    'I et felleskap': null,
    Eget: null,
  },
  Industrilokale: {
    'Med byggning': null,
    'Uten byggning': null,
  },
  Kombinasjonslokale: {
    'Med bygning': null,
    'Uten bygning': null,
  },
  'Annet lokale': {
    'Med bygning': null,
    'Uten bygning': null,
    'I et felleskap': null,
    Eget: null,
  },
  Uoppgitt: null,
}

const option = value => ({ label: value, value })

const PropertyTypeSelector = ({ value = '', onChange }) => {
  const split = value.split(',')
  return (
    <React.Fragment>
      <Select
        options={Object.keys(types).map(option)}
        value={split[0]}
        onChange={type => onChange(type)}
      />
      {types[split[0]] && (
        <React.Fragment>
          <Select
            options={Object.keys(types[split[0]]).map(option)}
            value={split[1]}
            onChange={type => onChange(`${split[0]},${type}`)}
          />
          {types[split[0]][split[1]] && (
            <Select
              options={Object.keys(types[split[0]][split[1]]).map(option)}
              value={split[2]}
              onChange={type => onChange(`${split[0]},${split[1]},${type}`)}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default PropertyTypeSelector
