import React from 'react'
import { colors } from '../styles'

export const StatusDot = props => {
  const { status, radius } = props

  const statusColor = {
    inactive: colors.error,
    pending: colors.yellow,
    active: colors.primary,
  }


  return (
    <svg
      width={radius ?? 16}
      height={radius ?? 16}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill={statusColor[status]} />
    </svg>
  )
}
