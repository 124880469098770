import React from 'react'

import Table from './Table'

export default props => {
  let index
  const value = parseFloat(props.value) 
  if (value < 0) {
    index = 0
  } else if (value >= 0 && value < 3) {
    index = 1
  } else if (value >= 3 && value < 5) {
    index = 2
  } else if (value >= 5 && value <= 7) {
    index = 3
  } else if (value > 7) {
    index = 4
  }

  return (
    <Table
      {...props}
      active={index}
      labels={['< 0%', '0-2%', '3-4%', '5-7%', '> 7%']}
    />
  )
}
