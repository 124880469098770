import React, { useEffect, useState } from 'react'
import DocumentUploader from './DocumentUploader'
import { H4, Overline } from 'styles/text'
import { getFilesFromStorage2 } from '../form/FileUploaderCore'

const FileOverview = props => {
  const { values, keys, orgNumber, dispatcher, path } = props
  const [logo, setLogo] = useState('')
  const [banner, setBanner] = useState('')
  const [contactLogo, setContactLogo] = useState('')

  useEffect(() => {
    if (values.logoUpload) {
      getFilesFromStorage2(orgNumber, values.logoUpload, 'public').then(res =>
        setLogo(res.data[0].url)
      )
    }
    if (values.headerImage) {
      getFilesFromStorage2(orgNumber, values.headerImage, 'public').then(res =>
        setBanner(res.data[0].url)
      )
    }
    if (values.contactLogo) {
      getFilesFromStorage2(orgNumber, values.contactLogo, 'public').then(res =>
        setContactLogo(res.data[0].url)
      )
    }
  }, [props])

  const translations = {
    products: 'Produkter',
    services: 'Tjenester',
    specialistAreas: 'Spesialistområder',
    agreements: 'Avtaler',
    procurementAndSupplierAgreements: 'Innkjøps-og leverandøravtaler',
    deliveryObligations: 'Leveranseforpliktelser',
    agencies: 'Agenturer',
    certificates: 'Sertifikater etc.',
    otherAgreements: 'Andre avtaler etc.',
    properties: 'Eiendom',
    assets: 'Eiendeler',
    machines: 'Maskiner etc.',
    inventory: 'Inventar etc.',
    stocks: 'Aksjer etc.',
    intangibleAssets: 'Immatriell eiendel',
    tangibleAssets: 'Varelager',
    finances: 'Finans og forsikring',
    longTermLoans: 'Langsiktige lån',
    shortTermLoans: 'Kortsiktige lån',
    guarantees: 'Garantileveranser',
    factoringfinances: 'Factoringavtaler',
    insurancefinances: 'Forsikringer',
    leases: 'Leasingavtaler',
    nonPropertyLeases: 'Leieavtaler',
    arguments: 'Kjøpsargument',
    purchaseArguments: 'Kjøpsargumenter',
    stockHolderFiles: 'Aksjebok og aksjonæravtaler',
    priceFiles: 'Grunnlag for prisantydning',
    projectInformation: 'Prosjektinforamsjon',
    companyInformation: 'Selskapsinformasjon',
    market: 'Marked',
    employees: 'Personal',
    marketing: 'Markedsføring og personal',
    economy: 'Økonomi',
    customAccountYear: 'Selvopplastet regnskap',
  }

  const bannerKeys = {
    projectInformation: {
      modalKeys: [],
      other: ['priceFiles'],
    },
    companyInformation: {
      modalKeys: [],
      other: ['stockHolderFiles', 'companyInformation_Documents'],
    },
    purchaseArguments: {
      modalKeys: ['arguments'],
      other: ['purchaseArguments_Documents'],
    },
    products: {
      modalKeys: ['products', 'services', 'specialistAreas'],
      other: ['products_Documents'],
    },
    market: {
      modalKeys: [],
      other: ['markets_Documents'],
    },
    employees: {
      modalKeys: [],
      other: ['employees_Documents'],
    },
    agreements: {
      modalKeys: [
        'procurementAndSupplierAgreements',
        'deliveryObligations',
        'agencies',
        'certificates',
        'otherAgreements',
      ],
      other: ['agreements_Documents'],
    },
    properties: {
      modalKeys: ['properties'],
      other: ['properties_Documents'],
    },
    assets: {
      modalKeys: [
        'machines',
        'inventory',
        'stocks',
        'intangibleAssets',
        'tangibleAssets',
      ],
      other: ['assets_Documents'],
    },
    leases: {
      modalKeys: ['leases', 'nonPropertyLeases'],
      other: ['leases_Documents'],
    },
    finances: {
      modalKeys: [
        'longTermLoans',
        'shortTermLoans',
        'guarantees',
        'factoringfinances',
        'insurancefinances',
      ],
      other: ['finances_Documents'],
    },
    marketing: {
      modalKeys: [],
      other: ['marketing_Documents'],
    },
    economy: {
      modalKeys: [],
      other: ['customAccountYear', 'economy_Documents'],
    },
  }

  const createFileStruct = () =>
    Object.entries(bannerKeys).map(([key, banner]) => {
      const modalFiles = getModalFiles(key, banner.modalKeys)
      const otherFiles = getOtherFiles(key, banner.other)
      return {
        label: translations[key],
        files: [...modalFiles, ...otherFiles],
      }
    })

  const getOtherFiles = (banner, key) => {
    return key.map(fileKey => ({
      label:
        fileKey.split('_')[1] === 'Documents'
          ? 'Øvrige dokumenter'
          : translations[fileKey],
      files: values[fileKey],
    }))
  }

  const getModalFiles = (banner, modalKeys) => {
    const files = []
    modalKeys.map(key => {
      if (values[banner] && values[banner][key]) {
        const l1 = values[banner][key]
        return l1.map((l2, index) => {
          if (l2 && l2.files) {
            files.push({
              label: `${translations[banner]}, ${translations[key]} ${
                index + 1
              }`,
              files: l2.files,
            })
          }
        })
      }
    })
    return files
  }

  // Avtaler, eiendom, assets, leasing, finans
  return (
    <>
      <H4>Bilder</H4>
      {logo && (
        <>
          <Overline>Logo</Overline>
          <img src={logo} width="526px" />
        </>
      )}
      {banner && (
        <>
          <Overline>Bannerbilde</Overline>
          <img src={banner} width="526px" />
        </>
      )}
      {contactLogo && (
        <>
          <Overline>Kontaktens logo</Overline>
          <img src={contactLogo} width="526px" />
        </>
      )}
      {createFileStruct().map((banner, bannerIndex) => (
        <div key={`banner_${bannerIndex}`}>
          <H4>{banner.label}</H4>
          {banner.files.map((fileGroup, fileGroupIndex) => (
            <div key={`${fileGroup.label}_${fileGroupIndex}`}>
              <Overline>{fileGroup.label}</Overline>
              <div>
                <DocumentUploader
                  viewOnly
                  orgNumber={orgNumber}
                  dispatcher={dispatcher}
                  fpFromStore={fileGroup.files}
                  path={path}
                />
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export default FileOverview
