import React from 'react'
import { connect } from 'react-redux'

import Spinner from 'components/Spinner'

const withSpinner = showSpinnerSelector => WrappedComponent => {
  const mapStateToProps = (state, ownProps) => ({
    showSpinner: showSpinnerSelector(state, ownProps),
  })

  const WithSpinner = ({ showSpinner, ...rest }) => {
    return showSpinner ? <Spinner /> : <WrappedComponent {...rest} />
  }

  return connect(mapStateToProps)(WithSpinner)
}

export default withSpinner
