import React, { useState } from 'react'

import styled from 'styled-components'

import { border, colors } from 'styles'
import { h5, h4, body, subtitle, Body } from 'styles/text'

import Button from 'components/Button'
import { setExclusivity, removeExclusivityRequest } from '../../api/agreements'

const Wrapper = styled.div`
  border-radius: 0.4rem;
  border: ${border};
  background-color: ${colors.white};
  padding: 0 3rem;
  height: 8rem;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`

const ButtonGroup = styled.div`
  padding: 0 8px;
  width: 34rem;
  display: flex;
  justify-content: space-between;
`

const Name = styled.div`
  ${h4};
  font-size: 1.8rem;
  width: 56rem;
  font-weight: 500;
`

const Status = styled.div`
  ${body};
  flex-grow: 1;
`

const ErrorText = styled.div`
  ${subtitle};
  color: ${colors.error};
`

const SuccessText = styled.div`
  ${subtitle};
  color: ${colors.success};
`

const Exclusivities = ({ orgNr, buyer, status, userinfo, companyName }) => {
  const [pending, setPending] = useState(false)
  const [warning, setWarning] = useState('')
  const [success, setSuccess] = useState('')

  const handleAccept = () => {
    setPending(true)
    setExclusivity(orgNr, buyer, true).then(status => {
      if (status === 200) {
        setWarning('')
        setSuccess(
          'Du blir snart kontaktet av Nobema på e-post for neste steg i forhandlingen'
        )
      } else {
        setSuccess('')
        setWarning('Noe gikk galt...')
      }
      setPending(false)
    })
  }

  const handleReject = () => {
    removeExclusivityRequest(orgNr, buyer).then(status => {
      if (status === 200) {
        setWarning('')
        setSuccess('Forespørsel slettet')
      } else {
        setSuccess('')
        setWarning('Noe gikk galt...')
      }
    })
  }

  return (
    <>
      <Wrapper>
        <Name>
          <b>Forhandlingsavtale: </b>
          {status
            ? `Du forhandler med ${userinfo.given_name} ${
                userinfo.family_name
              } om ${companyName ? companyName : orgNr}`
            : `${userinfo.given_name} ${
                userinfo.family_name
              } ønsker å forhandle om ${companyName ? companyName : orgNr}`}
        </Name>
        {status ? (
          <Body>Videre forhandlinger skjer per epost</Body>
        ) : (
          <ButtonGroup>
            <Button pending={pending} onClick={() => handleAccept()}>
              Aksepter
            </Button>
            <Button warning onClick={() => handleReject()}>
              Avslå
            </Button>
          </ButtonGroup>
        )}
      </Wrapper>
      {warning && <ErrorText>{warning}</ErrorText>}
      {success && <SuccessText>{success}</SuccessText>}
    </>
  )
}

export default Exclusivities
