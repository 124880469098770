import fetch from 'cross-fetch'
import { signicatUrl, backendUrl } from '../environment'
import { getOpenIdIdToken } from './firebase'

const base =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : backendUrl

// TODO: We should get this from the server in some way, and it should be tied to the session. So
//       it need to be in a cookie
const secretState = 'kh123lkasdf'

const fetchWithUrl = (url, options) => fetch(`${base}${url}`, options)

export const fetchWithTimeOut = (promise, ms) =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('TIMEOUT'))
    }, ms)

    promise
      .then(value => {
        clearTimeout(timer)
        resolve(value)
      })
      .catch(err => {
        clearTimeout(timer)
        reject(err)
      })
  })

export const authUrl = (next, params) => {
  const state = btoa(
    JSON.stringify({
      next,
      params,
      secret: secretState,
    })
  )
  return `${backendUrl}/api/login?state=${state}`
}

export const signicatLogOutUrl = async () => {
  const token = await getOpenIdIdToken()
  const redirectUri = window.location.host
  return `${signicatUrl}/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=http://${redirectUri}`
}

export default fetchWithUrl
