import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'

import InfoBoxComponent, { Heading as HeadingComponent } from './InfoBox'
import ModalButtonComponent from 'components/ModalButton'
import ContactNobemaForm from 'components/ContactNobemaForm'

const InfoBox = styled(InfoBoxComponent)`
  background-color: ${colors.white};
  color: ${colors.secondary};
  border: 1px solid ${colors.secondary};
`

const Heading = styled(HeadingComponent)`
  color: ${colors.secondary};
`

const ModalButton = styled(ModalButtonComponent)`
  width: 100%;
  text-align: center;
`

const ContactOwner = ({ companyId }) => (
  <InfoBox>
    <Heading>Trenger du hjelp?</Heading>
    <p>
      Lurer du på hvordan du skal fylle ut noen av punktene, eller er er det noe
      som er uklart. Spør oss om hjelp.
    </p>
    <ModalButton
      title="Kontakt Nobema"
      secondary
      renderModalContent={props => <ContactNobemaForm {...props} />}
    >
      Kontakt Nobema
    </ModalButton>
  </InfoBox>
)

export default ContactOwner
