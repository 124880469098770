import React from 'react'
import styled from 'styled-components'

import { h1 } from 'styles/text'

const NoAccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`

const Heading = styled.h2`
  ${h1};
`

const MailLink = styled.a``

const NoAccess = props => {
  const { invalidLicence } = props
  return (
    <NoAccessWrapper>
      {invalidLicence ? (
        <Heading>Din lisens inneholder ikke et salgsprospekt</Heading>
      ) : (
        <Heading>Siden er for tiden ikke tilgjengelig</Heading>
      )}
    </NoAccessWrapper>
  )
}

export default NoAccess
