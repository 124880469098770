import {
  FETCH_CLAIMED_COMPANIES,
  FETCH_CLAIMED_COMPANIES_SUCCESS,
  LOG_OUT,
} from 'actionTypes'

const initialState = { pending: true }

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLAIMED_COMPANIES:
      return {
        pending: true,
      }
    case FETCH_CLAIMED_COMPANIES_SUCCESS:
      return action.claimedCompanies
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
