import React from 'react'
import styled from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { companySelector, companyIsPendingSelector } from 'selectors'
import { fetchCompany } from 'actions'

import { border, colors } from 'styles'
import { h4, body, Body, subtitle } from 'styles/text'
import Button from 'components/Button'
import ModalButton from '../../components/ModalButton'
import EditLicence from './EditLicence'

import withSpinner from 'components/withSpinner'
import withFetchedData from 'components/withFetchedData'

const Wrapper = styled.div`
  border-radius: 0.4rem;
  border: ${border};
  background-color: ${colors.white};
  padding: 0 3rem;
  height: 8rem;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`

const LicenceCard = props => {
  const { companyId, licenceType, name } = props
  return (
    <Wrapper>
      <Body>{licenceType} for: </Body>
      <Body>{name}</Body>
      <ModalButton
        title={`${licenceType} for: ${name}`}
        renderModalContent={props => (
          <EditLicence
            {...props}
            companyId={companyId}
            licenceType={licenceType}
          />
        )}
      >
        Administrer lisens
      </ModalButton>
    </Wrapper>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...companySelector(state, ownProps),
})

export default compose(
  withFetchedData(props => fetchCompany(props.companyId)),
  withSpinner(companyIsPendingSelector),
  connect(mapStateToProps)
)(LicenceCard)
