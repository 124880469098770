import React from 'react'
import { H6 } from 'styles/text'
import { Field, Label, Textarea, UploadFiles } from 'components/form'
import EditListOfLists, { mapToState } from './EditListOfLists'
import { uploadFilesFromList } from 'components/form/FileUploaderCore'

export const lists = {
  leases: {
    heading: 'Løpende leasingavtaler',
    buttonLabel: '+ Legg til løpende leasingavtaler',
    fields: [
      { prop: 'name', label: 'Navn kredittinstitusjon' },
      { prop: 'leasingObject', label: 'Leasingobjekt' },
      { prop: 'rentPerAnnum', label: 'Leasingsum per år' },
      { prop: 'expiration', label: 'Utløp', placeholder: 'dd.mm.åååå' },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
    ],
  },
  nonPropertyLeases: {
    heading: 'Leieavtaler (ikke eiendom)',
    buttonLabel: '+ Legg til leieavtaler (ikke eiendom)',
    fields: [
      { prop: 'name', label: 'Navn/betegnelse' },
      { prop: 'leasingObject', label: 'Leieobjekt' },
      { prop: 'rentPerAnnum', label: 'Leiesum per år' },
      { prop: 'expiration', label: 'Utløp', placeholder: 'dd.mm.åååå' },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
    ],
  },
}

class EditLeasingStructure extends React.Component {
  componentDidMount() {
    if (this.props.formValues.leases) {
      this.setState({ ...this.props.formValues.leases })
    } else {
      this.props.dispacth('leases', {
        ...mapToState(lists, this.props),
        ...Object.keys(lists).reduce(
          (state, key) => ({
            ...state,
            [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
          }),
          {}
        ),
        leasingComment: this.props.leasingComment || '',
      })
    }
  }

  // Set initial state
  // Will be overwritten by componentDidMount() if finances are found in store
  state = {
    ...mapToState(lists, this.props),
    ...Object.keys(lists).reduce(
      (state, key) => ({
        ...state,
        [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
      }),
      {}
    ),
    leasingComment: this.props.leasingComment || '',
  }

  removeItem = (list, index) => {
    const updatedLeases = {
      [list]: this.state[list].filter((_, i) => i !== index),
    }
    this.setState(updatedLeases)
    this.props.dispacth('leases', { ...this.state, ...updatedLeases })
  }

  addItem = (list, item) =>
    this.setState({
      [list]: [...this.state[list], item],
    })

  setData = (property, index, key, value, rm) => {
    let oldFiles = []
    if (key === 'files' && this.state[property][index][key] && !rm) {
      oldFiles = this.state[property][index][key]
    }
    return {
      [property]: this.state[property].map((list, i) =>
        i === index
          ? {
              ...list,
              [key]: key === 'files' ? [...oldFiles, ...value] : value,
            }
          : list
      ),
    }
  }

  setValue = (property, index, key, value) => {
    if (
      key === 'files' &&
      value.length > 0 &&
      typeof value[0].name === 'string'
    ) {
      uploadFilesFromList(this.props.orgNumber, value).then(res => {
        const leases = this.setData(property, index, key, res)
        this.props.dispacth('leases', { ...this.state, ...leases })
        this.setState(leases)
      })
    } else if (
      key === 'files' &&
      (value.length === 0 || typeof value[0].name !== 'string')
    ) {
      const leases = this.setData(property, index, key, value, true)
      this.props.dispacth('leases', { ...this.state, ...leases })
      this.setState(leases)
    } else {
      const leases = this.setData(property, index, key, value)
      this.props.dispacth('leases', { ...this.state, ...leases })
      this.setState(leases)
    }
  }

  setComment = value => {
    this.setState({ leasingComment: value })
    this.props.dispacth('leases', {
      ...this.state,
      leasingComment: value,
    })
  }

  render() {
    return (
      <>
        <EditListOfLists
          {...this.props}
          lists={lists}
          state={this.state}
          removeItem={this.removeItem}
          addItem={this.addItem}
          setValue={this.setValue}
          setAnonymized={(key, value) => this.setState({ [key]: value })}
        />
        <Field>
          <H6>Ytterligere informasjon om Leie- og Leasingavtaler</H6>
          <Textarea
            value={this.state.leasingComment}
            onChange={e => this.setComment(e.target.value)}
            placeholder={'Eventuell tilleggsinformasjon'}
          />
        </Field>
      </>
    )
  }
}

export default EditLeasingStructure
