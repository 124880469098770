import React from 'react'

import Table from './Table'

export default props => {
  let index
  const value = parseFloat(props.value)
  if (value < 3) {
    index = 0
  } else if (value >= 3 && value < 10) {
    index = 1
  } else if (value >= 10 && value < 18) {
    index = 2
  } else if (value >= 18 && value <= 40) {
    index = 3
  } else if (value > 40) {
    index = 4
  }
  return (
    <Table
      {...props}
      active={index}
      labels={['< 3%', '3-9%', '10-17%', '18-40%', '> 40%']}
    />
  )
}
