import {
  FETCH_COMPANY_ADVERTISEMENT,
  FETCH_COMPANY_ADVERTISEMENT_SUCCESS,
  FETCH_COMPANY_ADVERTISEMENT_ERROR,
  UPDATE_COMPANY_ADVERTISEMENT,
  UPDATE_COMPANY_ADVERTISEMENT_SUCCESS,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_ADVERTISEMENT:
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
          pending: true,
        },
      }
    case FETCH_COMPANY_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        [action.companyId]: {
          ...action.companyAdvertisement,
        },
      }
    case FETCH_COMPANY_ADVERTISEMENT_ERROR:
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
          pending: false,
          error: action.message,
        },
      }
    case UPDATE_COMPANY_ADVERTISEMENT:
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
          updateIsPending: true,
        },
      }
    case UPDATE_COMPANY_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
          ...action.payload,
          updateIsPending: false,
        },
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
