import { compose } from 'redux'
import { connect } from 'react-redux'

import { fetchAdvertisement } from 'actions'
import {
  advertisementSelector,
  advertisementIsPendingSelector,
} from 'selectors'

import withFetchedData from 'components/withFetchedData'
import withSpinner from 'components/withSpinner'
import Company from 'components/Company'
import CompanyAdvertisement from 'components/CompanyAdvertisement'

const mapStateToProps = (state, ownProps) => ({
  ...advertisementSelector(state, ownProps),
  advertisementForSale: true,
  forSale: true,
})

export default compose(
  withFetchedData(props => fetchAdvertisement(props.advertisementId), false),
  withSpinner(advertisementIsPendingSelector),
  connect(mapStateToProps)
)(CompanyAdvertisement)
