import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fetchAdvertisement } from 'actions'
import {
  advertisementSelector,
  advertisementIsPendingSelector,
} from 'selectors'
import { border, colors } from 'styles'
import { capitalize } from 'utils'

import withSpinner from 'components/withSpinner'
import withFetchedData from 'components/withFetchedData'
import Heart from 'components/icons/Heart'
import Link from 'components/Link'

const Wrapper = styled(Link)`
  border-radius: 4px;
  border: ${border};
  background-color: ${colors.white};
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FavoritedAdvertisement = ({ advertisementId, error, name, title, prospectTitle }) =>
  !error ? (
    <li >
      <Wrapper to={`/advertisements/${advertisementId}`}>
        <div>{name ? capitalize(name) : (prospectTitle ? prospectTitle : title)}</div>
        <Heart active={true} />
      </Wrapper>
    </li>
  ) : null

const mapStateToProps = (state, ownProps) => ({
  ...advertisementSelector(state, ownProps),
})

export default compose(
  withFetchedData(props => fetchAdvertisement(props.advertisementId)),
  withSpinner(advertisementIsPendingSelector),
  connect(mapStateToProps)
)(FavoritedAdvertisement)
