import React from 'react'

import InfoMessage from 'components/InfoMessage'

const SellersComment = ({ children, forSale, claimed, className }) =>
  children ? (
    <InfoMessage className={className}>
      <b>Ytterlig informasjon: </b>
      {children}
    </InfoMessage>
  ) : null

export default SellersComment
