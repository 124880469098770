import React from 'react'
import styled from 'styled-components'

import { ContentWrapper } from 'styles'

import SearchField from 'components/SearchField'
import {
  Field,
  Label,
  Input,
  RadioButtons,
  Checkboxes,
  Textarea,
} from 'components/form'
import Button from 'components/Button'
import { H1, H2, H3, H4, Body, Subtle, Small } from 'styles/text'
import Link from 'components/Link'

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

class Styleguide extends React.Component {
  state = {
    radioButtons: undefined,
    checkboxes: [],
  }

  render() {
    return (
      <ContentWrapper>
        <H1>Style guide</H1>
        <section>
          <H2>Fonts</H2>
          <H1>H1 - 40px regular</H1>
          <H2>H2 - 30px Regular</H2>
          <H3>H3 - 20px Regular</H3>
          <H4>H4 - 16px Bold</H4>
          <Body>Body - 16px Regular</Body>
          <Subtle>Subtle - 16px Regular</Subtle>
          <Small>Small - 14px Regular</Small>
          <Link to="#">Link - 16px Regular, colored, underline</Link>
        </section>
        <section>
          <H2>Search</H2>
          <SearchField />
        </section>
        <section>
          <H2>Form</H2>
          <Field>
            <Label htmlFor="input">Input</Label>
            <Input id="input" placeholder="Input placeholder" />
          </Field>
          <Field>
            <Label htmlFor="input-success">Input success</Label>
            <Input
              id="input-success"
              success
              placeholder="Success placeholder"
            />
          </Field>
          <Field>
            <Label htmlFor="input-error">Input error</Label>
            <Input
              id="input-error"
              error="Error message"
              placeholder="Error placeholder"
            />
          </Field>
          <Field>
            <Label htmlFor="textarea">Textarea</Label>
            <Textarea id="textarea" placeholder="Textarea placeholder" />
          </Field>
          <Field>
            <Label>Radio buttons</Label>
            <RadioButtons
              options={[
                {
                  value: 0,
                  label: 'Option 1',
                },
                {
                  value: 1,
                  label: 'Option 2',
                },
                {
                  value: 2,
                  label: 'Option 3',
                },
              ]}
              other="Other"
              value={this.state.radioButtons}
              onChange={radioButtons => this.setState({ radioButtons })}
            />
          </Field>
          <Field>
            <Label>Radio buttons with error</Label>
            <RadioButtons
              error="Error message"
              options={[
                {
                  value: 0,
                  label: 'Option 1',
                },
                {
                  value: 1,
                  label: 'Option 2',
                },
                {
                  value: 2,
                  label: 'Option 3',
                },
              ]}
              other="Other"
              value={this.state.radioButtons}
              onChange={radioButtons => this.setState({ radioButtons })}
            />
          </Field>
          <Field>
            <Label>Checkboxes</Label>
            <Checkboxes
              options={[
                {
                  value: 0,
                  label: 'Option 1',
                },
                {
                  value: 1,
                  label: 'Option 2',
                },
                {
                  value: 2,
                  label: 'Option 3',
                },
              ]}
              other="Other"
              value={this.state.checkboxes}
              onChange={checkboxes => this.setState({ checkboxes })}
            />
          </Field>
          <Field>
            <Label>Checkboxes with error</Label>
            <Checkboxes
              error="Error message"
              options={[
                {
                  value: 0,
                  label: 'Option 1',
                },
                {
                  value: 1,
                  label: 'Option 2',
                },
                {
                  value: 2,
                  label: 'Option 3',
                },
              ]}
              other="Other"
              value={this.state.checkboxes}
              onChange={checkboxes => this.setState({ checkboxes })}
            />
          </Field>
        </section>
        <section>
          <H2>Buttons</H2>
          <div>
            <H3>Primary</H3>
            <Buttons>
              <Button>Button</Button>
              <Button pending={true}>Button</Button>
            </Buttons>
          </div>
          <div>
            <H3>Secondary</H3>
            <Button subtle>Button</Button>
          </div>
          <div>
            <H3>Disabled</H3>
            <Button disabled>Button</Button>
          </div>
        </section>
      </ContentWrapper>
    )
  }
}

export default Styleguide
