import { replace } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware, { END } from 'redux-saga'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers/index'
import saga from 'sagas'
import { checkAuthStatus, logInWithToken } from 'actions'
import { urlQuerySelector } from 'selectors'

export function createReduxStore({ isClient, path, preloadedState }) {
  let history

  if (isClient) {
    history = createBrowserHistory()
  } else {
    history = createMemoryHistory({ initialEntries: [path] })
  }

  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(
      compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    )
  )

  sagaMiddleware.run(saga)

  let urlQuery = urlQuerySelector(store.getState())

  let params = { ...urlQuery }
  delete params.loginToken
  const searchString = `?${Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`

  if (isClient) {
    if (urlQuery.loginToken) {
      store.dispatch(logInWithToken({ token: urlQuery.loginToken }))
      store.dispatch(replace({ search: searchString }))
    } else {
      store.dispatch(checkAuthStatus())
    }
  }

  return {
    store,
    history,
    runSaga: sagaMiddleware.run,
    endSaga: () => store.dispatch(END),
  }
}
