import projectedInvestments from './projectedInvestments'
import projectedWorkingCapitalDiff from './projectedWorkingCapitalDiff'
import projectedNetProfit from './projectedNetProfit'
import projectedDepreciation from './projectedDepreciation'

export const projectedCashFlow = (data, year, options) =>
  projectedNetProfit(data, year, options) +
  projectedDepreciation(data, year) -
  projectedInvestments(data, year) -
  projectedWorkingCapitalDiff(data, year)

const maxYear = data =>
  Math.max(...data.map(({ RegnskapsAvAr }) => RegnskapsAvAr))

export default (data, options) =>
  [1, 2, 3, 4, 5]
    .map(value => maxYear(data) + value)
    .map(year => projectedCashFlow(data, year, options))
    .reduce((sum, value) => sum + value, 0)
