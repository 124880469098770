export const median = arr => {
  const sort = arr.sort()
  if (arr.length % 2 === 1) {
    return sort[Math.floor(arr.length / 2)]
  } else {
    return (sort[arr.length / 2] + sort[arr.length / 2 - 1]) / 2
  }
}

export const average = arr =>
  arr.reduce((sum, value) => sum + value, 0) / arr.length

export const lastYear = data =>
  data.sort((a, b) => b.RegnskapsAvAr - a.RegnskapsAvAr)[0]
