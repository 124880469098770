import styled from 'styled-components'

import { colors } from 'styles'
import { body } from '../styles/text'

const Error = styled.div`
  ${body}
  color: ${colors.error};
`

export default Error
