import React from 'react'
import styled from 'styled-components'

import UploadButton from './UploadButton'
import X from 'components/icons/X'
import { body } from 'styles/text'
import { deleteFileFromStorage } from './FileUploaderCore'

const File = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 5px;
  }
`

const FileName = styled.span`
  ${body};
  word-break: break-all;
`

const Ul = styled.ul`
  margin-bottom: 12px;
  list-style: none;
  li {
    margin: 4px !important;
    padding: 0;
  }
`

const simpleName = fName => `${fName.split('.').slice(3).join('.')}`

const UploadFiles = ({ value, onChange }) => (
  <div>
    <Ul>
      {value.map((file, index) => (
        <li key={index}>
          <File key={index}>
            <FileName>{simpleName(file)}</FileName>
            <X
              small
              onClick={() => {
                const orgNr = file.split('.')[1]
                deleteFileFromStorage(orgNr, file, 'prospect')
                return onChange(value.filter((_, i) => i !== index))
              }}
            />
          </File>
        </li>
      ))}
    </Ul>
    <UploadButton value={value} onChange={files => onChange([...files])} />
  </div>
)

export default UploadFiles
