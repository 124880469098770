import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

import { colors } from 'styles'
import { buttonText, ribbonButtonText } from 'styles/text'

const primaryButtonStyle = props => `
  background-color: ${colors.action};
  color: ${colors.white};
  border: none;

  &:focus,
  &:hover {
    background-color: ${colors.actionHover};
  }

  &:active {
    background-color: ${colors.actionPressed};
  }
`

const secondaryButtonStyle = props => `
  background-color: ${colors.secondary};
  color: ${colors.white};
  border: none;

  &:focus,
  &:hover {
    background-color: ${colors.secondaryHover};
  }

  &:active {
    background-color: ${colors.secondaryPressed};
  }
`

const subtleButtonStyle = props => `
  background-color: ${colors.white};
  color: ${colors.primary};
  border: 2px solid ${colors.primary};

  &:focus,
  &:hover {
    background-color: ${colors.whiteHover};
  }

  &:active {
    background-color: ${colors.whitePressed};
  }
`

const warningButtonStyle = props => `
background-color: ${colors.error};
color: ${colors.white};
border: 2px solid ${colors.error};

&:focus,
&:hover {
  background-color: ${colors.errorHover};
}

&:active {
  background-color: ${colors.error};
}
`

export const buttonStyle = props => `
  ${buttonText};
  border-radius: 4px;
  padding: 2.4rem 3.6rem 2.4rem 3.6rem;
  transition: color 100ms, background-color 100ms;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  ${primaryButtonStyle(props)};
  ${props.secondary && secondaryButtonStyle(props)};
  ${props.subtle && subtleButtonStyle(props)};
  ${props.warning && warningButtonStyle(props)};

  &:disabled {
    background-color: ${colors.disabled};
    color: ${colors.primaryGray};
    cursor: default;
  }
`

const ribbonButton = props => `
  ${ribbonButtonText};
  border-radius: 1px;
  font-size: 1.4rem;
  padding: 8px 24px 8px 24px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;  
  background-color: ${props.active ? props.activeColor : props.inactiveColor};
  box-shadow: ${props.active ? `1px -2px 6px rgba(0, 0, 0, 0.1)` : ''};
  
`
const LinkButton = styled(Link)`
  ${buttonStyle};
`

const SimpleLinkButton = styled.a`
  ${buttonStyle};
`

const StyledButton = styled.button`
  ${buttonStyle};
`

const RibbonButton = styled.button`
  ${ribbonButton};
`

const mapBoolsToString = props =>
  Object.entries(props)
    .filter(([key, value]) => !value === false)
    .map(([key, value]) => [key, value === true ? 'true' : value])
    .reduce(
      (props, [key, value]) => ({
        ...props,
        [key]: value,
      }),
      {}
    )

const LoaderWrapper = styled.div`
  display: inline-block;
  margin-left: 10px;
  line-height: 0;
`

const Spinner = () => (
  <LoaderWrapper>
    <Loader type="Oval" color={colors.white} height="16" width="16" />
  </LoaderWrapper>
)

const Button = props => {
  if (props.to) {
    return <LinkButton {...mapBoolsToString(props)} />
  } else if (props.href) {
    return <SimpleLinkButton {...props} />
  } else if (props.ribbon) {
    return <RibbonButton {...props} />
  } else {
    if (props.pending) {
      return (
        <StyledButton {...props}>
          {props.children}
          <Spinner />
        </StyledButton>
      )
    } else {
      return <StyledButton {...props} />
    }
  }
}

export default Button
