import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { H3 } from 'styles/text'
import SearchFilter from './SearchFilter'
import AdvertisementCard from 'components/AdvertisementCard'
import Pagination from './Pagination'
import { search } from '../../api/search'
import { useQuery } from 'react-query'
import Spinner from "components/Spinner"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 1440px;
`

const SearchResults = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    // Width is decided here
    width: 326px;
    margin: 8px;
  }

  & > * > * {
    height: 100%;
  }

  & > * > * > * {
    height: 100%;
  }
`

const Search = () => {
  const [q, setQ] = useState('')
  const [filter, setFilter] = useState({ advertisementLabels: [] })

  const { refetch, data } = useQuery('advertisements', async () =>
    search({ q, filter })
  )
  useEffect(() => {
    refetch()
  }, [q, filter])

  if (!data) return <Spinner />

  const { advertisements } = data

  return (
    <>
      <SearchFilter {...{ q, setQ, filter, setFilter }} />
      <Wrapper>
        {advertisements.total && advertisements.hits.length ? (
          <SearchResults>
            {advertisements.hits.map(result => (
              <li key={result._id}>
                <AdvertisementCard
                  {...result._source}
                  forSale={true}
                  advertisementId={result._id}
                />
              </li>
            ))}
          </SearchResults>
        ) : (
          <H3>
            Det er for tiden ikke lagt ut bedrifter for salg som samsvarer med
            ditt søk.
          </H3>
        )}
      </Wrapper>
      {advertisements.total.value && <Pagination hits={advertisements} />}
    </>
  )
}

export default Search
