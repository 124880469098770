import React from 'react'
import AskingPrice from './AskingPrice'

const Price = props => {
  if (props.userValueEstimate) {
    return <AskingPrice {...props} />
  } else {
    return <AskingPrice {...props} noPrice={true} />
  }
}

export default Price
