import React from 'react'
import styled from 'styled-components'

import { h1 } from 'styles/text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`

const Heading = styled.h1`
  ${h1};
`

const MailLink = styled.a``

const Success = () => (
  <Wrapper>
    <Heading>Siden finnes ikke</Heading>
    <div>Siden du leter etter finnes dessverre ikke.</div>
    <div>Skulle du trenge hjelp er det bare å ta kontakt med oss.</div>
    <div>Telefon: +47 928 95 485</div>
    <div>(Mandag til fredag 08:00 - 16:00)</div>
    <div>
      E-post: <MailLink href="mailto:post@nobema.no">post@nobema.no</MailLink>
    </div>
  </Wrapper>
)

export default Success
