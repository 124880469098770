import React from 'react'
import styled from 'styled-components'

import Link from 'components/Link'
import { Body } from 'styles/text'
import InfoBox, { Heading, Button } from './InfoBox'

const Padder = styled.div`
  margin: 36px 0;
`

const BuyNobema = () => (
  <InfoBox>
    <Heading>Slik kjøper du en bedrift med Nobema</Heading>
    <Padder />
    <Button href={'https://www.nobema.no/kjop_bedrift'} subtle>
      Les hvordan her
    </Button>
  </InfoBox>
)

export default BuyNobema
