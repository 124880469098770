import fetch from './fetch'
import { SERVICES_PAGE } from 'contentTypes'

export const fetchContent = contentType => {
  switch (contentType) {
    case SERVICES_PAGE:
      return fetch('/api/content/servicesPage', {
        mode: 'cors',
      }).then(response => response.json())
    default:
      return Promise.resolve({})
  }
}
