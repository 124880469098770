import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY:
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
          pending: true,
        },
      }
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
          ...action.company,
          pending: false,
        },
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
