import React from 'react'

import Table from './Table'

export default props => {
  let index
  const value = parseFloat(props.value)
  if (value < 0.5) {
    index = 0
  } else if (value >= 0.5 && value < 1) {
    index = 1
  } else if (value >= 1 && value < 1.5) {
    index = 2
  } else if (value >= 1.5 && value <= 2) {
    index = 3
  } else if (value > 2) {
    index = 4
  }
  return (
    <Table
      {...props}
      active={index}
      labels={['< 0,5', '0,5-0,99', '1-1,49', '1,5-2', '> 2']}
    />
  )
}
