export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'
export const LOG_IN = 'LOG_IN'
export const LOG_IN_WITH_TOKEN = 'LOG_IN_WITH_TOKEN'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_ERROR = 'LOG_IN_ERROR'
export const LOG_OUT = 'LOG_OUT'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR'
export const CHECK_AUTH_STATUS = 'CHECK_AUTH_STATUS'
export const CHECK_AUTH_STATUS_SUCCESS = 'CHECK_AUTH_STATUS_SUCCESS'
export const CHECK_AUTH_STATUS_ERROR = 'CHECK_AUTH_STATUS_ERROR'
export const AUTHENTICATE_WITH_GOOGLE = 'AUTHENTICATE_WITH_GOOGLE'

export const FETCH_COMPANY = 'FETCH_COMPANY'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR'

export const ADD_COMPANY_TO_FAVORITES = 'ADD_COMPANY_TO_FAVORITES'
export const ADD_COMPANY_TO_FAVORITES_SUCCESS =
  'ADD_COMPANY_TO_FAVORITES_SUCCESS'
export const ADD_COMPANY_TO_FAVORITES_ERROR = 'ADD_COMPANY_TO_FAVORITES_ERROR'

export const REMOVE_COMPANY_FROM_FAVORITES = 'REMOVE_COMPANY_FROM_FAVORITES'
export const REMOVE_COMPANY_FROM_FAVORITES_SUCCESS =
  'REMOVE_COMPANY_FROM_FAVORITES_SUCCESS'
export const REMOVE_COMPANY_FROM_FAVORITES_ERROR =
  'REMOVE_COMPANY_FROM_FAVORITES_ERROR'

export const FETCH_FAVORITED_COMPANIES = 'FETCH_FAVORITED_COMPANIES'
export const FETCH_FAVORITED_COMPANIES_SUCCESS =
  'FETCH_FAVORITED_COMPANIES_SUCCESS'
export const FETCH_FAVORITED_COMPANIES_ERROR = 'FETCH_FAVORITED_COMPANIES_ERROR'

export const FETCH_CONTENT = 'FETCH_CONTENT'
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS'
export const FETCH_CONTENT_ERROR = 'FETCH_CONTENT_ERROR'

export const FETCH_CLAIMED_COMPANIES = 'FETCH_CLAIMED_COMPANIES'
export const FETCH_CLAIMED_COMPANIES_SUCCESS = 'FETCH_CLAIMED_COMPANIES_SUCCESS'
export const FETCH_CLAIMED_COMPANIES_ERROR = 'FETCH_CLAIMED_COMPANIES_ERROR'

export const FETCH_PROSPECT_READ_REQUESTS = 'FETCH_PROSPECT_READ_REQUESTS'
export const FETCH_PROSPECT_READ_REQUESTS_SUCCESS =
  'FETCH_PROSPECT_READ_REQUESTS_SUCCESS'
export const FETCH_PROSPECT_READ_REQUESTS_ERROR =
  'FETCH_PROSPECT_READ_REQUESTS_ERROR'

export const PUBLISH_COMPANY_ADVERTISEMENT = 'PUBLISH_COMPANY_ADVERTISEMENT'
export const PUBLISH_COMPANY_ADVERTISEMENT_SUCCESS =
  'PUBLISH_COMPANY_ADVERTISEMENT_SUCCESS'
export const PUBLISH_COMPANY_ADVERTISEMENT_ERROR =
  'PUBLISH_COMPANY_ADVERTISEMENT_ERROR'

export const UPDATE_COMPANY_ADVERTISEMENT = 'UPDATE_COMPANY_ADVERTISEMENT'
export const UPDATE_COMPANY_ADVERTISEMENT_SUCCESS =
  'UPDATE_COMPANY_ADVERTISEMENT_SUCCESS'
export const UPDATE_COMPANY_ADVERTISEMENT_ERROR =
  'UPDATE_COMPANY_ADVERTISEMENT_ERROR'

export const FETCH_COMPANY_ADVERTISEMENT = 'FETCH_COMPANY_ADVERTISEMENT'
export const FETCH_COMPANY_ADVERTISEMENT_SUCCESS =
  'FETCH_COMPANY_ADVERTISEMENT_SUCCESS'
export const FETCH_COMPANY_ADVERTISEMENT_ERROR =
  'FETCH_COMPANY_ADVERTISEMENT_ERROR'

export const FETCH_SUGGESTIONS = 'FETCH_SUGGESTIONS'
export const FETCH_SUGGESTIONS_SUCCESS = 'FETCH_SUGGESTIONS_SUCCESS'
export const FETCH_SUGGESTIONS_ERROR = 'FETCH_SUGGESTIONS_ERROR'

export const OPEN_MENU = 'OPEN_MENU'
export const CLOSE_MENU = 'CLOSE_MENU'

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS'
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS'
export const UPDATE_USER_SETTINGS_ERROR = 'UPDATE_USER_SETTINGS_ERROR'

export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS'
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS'
export const FETCH_USER_SETTINGS_ERROR = 'FETCH_USER_SETTINGS_ERROR'

export const SET_REVENUE_GROWTH_RATE_OVERRIDE =
  'SET_REVENUE_GROWTH_RATE_OVERRIDE'
export const SET_COST_OVER_REVENUE_OVERRIDE = 'SET_COST_OVER_REVENUE_OVERRIDE'

export const FETCH_ADVERTISEMENT = 'FETCH_ADVERTISEMENT'
export const FETCH_ADVERTISEMENT_SUCCESS = 'FETCH_ADVERTISEMENT_SUCCESS'
export const FETCH_ADVERTISEMENT_ERROR = 'FETCH_ADVERTISEMENT_ERROR'

export const ADD_ADVERTISEMENT_TO_FAVORITES = 'ADD_ADVERTISEMENT_TO_FAVORITES'
export const ADD_ADVERTISEMENT_TO_FAVORITES_SUCCESS =
  'ADD_ADVERTISEMENT_TO_FAVORITES_SUCCESS'
export const ADD_ADVERTISEMENT_TO_FAVORITES_ERROR =
  'ADD_ADVERTISEMENT_TO_FAVORITES_ERROR'

export const REMOVE_ADVERTISEMENT_FROM_FAVORITES =
  'REMOVE_ADVERTISEMENT_FROM_FAVORITES'
export const REMOVE_ADVERTISEMENT_FROM_FAVORITES_SUCCESS =
  'REMOVE_ADVERTISEMENT_FROM_FAVORITES_SUCCESS'
export const REMOVE_ADVERTISEMENT_FROM_FAVORITES_ERROR =
  'REMOVE_ADVERTISEMENT_FROM_FAVORITES_ERROR'

export const FETCH_FAVORITED_ADVERTISEMENTS = 'FETCH_FAVORITED_ADVERTISEMENTS'
export const FETCH_FAVORITED_ADVERTISEMENTS_SUCCESS =
  'FETCH_FAVORITED_ADVERTISEMENTS_SUCCESS'
export const FETCH_FAVORITED_ADVERTISEMENTS_ERROR =
  'FETCH_FAVORITED_ADVERTISEMENTS_ERROR'

export const ASK_FOR_ACCESS = 'ASK_FOR_ACCESS'
export const ASK_FOR_ACCESS_SUCCESS = 'ASK_FOR_ACCESS_SUCCESS'
export const ASK_FOR_ACCESS_ERROR = 'ASK_FOR_ACCESS_ERROR'

export const REQUEST_QUOTE = 'REQUEST_QUOTE'
export const REQUEST_QUOTE_SUCCESS = 'REQUEST_QUOTE_SUCCESS'
export const REQUEST_QUOTE_ERROR = 'REQUEST_QUOTE_ERROR'

export const BUY_SERVICE = 'BUY_SERVICE'
export const BUY_SERVICE_SUCCESS = 'BUY_SERVICE_SUCCESS'
export const BUY_SERVICE_ERROR = 'BUY_SERVICE_ERROR'

export const BUY_REPRESENTATION = 'BUY_REPRESENTATION'
export const BUY_REPRESENTATION_SUCCESS = 'BUY_REPRESENTATION_SUCCESS'
export const BUY_REPRESENTATION_ERROR = 'BUY_REPRESENTATION_ERROR'

export const INVITE_HELPER = 'INVITE_HELPER'
export const INVITE_HELPER_SUCCESS = 'INVITE_HELPER_SUCCESS'
export const INVITE_HELPER_ERROR = 'INVITE_HELPER_ERROR'

export const CONTACT_NOBEMA = 'CONTACT_NOBEMA'
export const CONTACT_NOBEMA_SUCCESS = 'CONTACT_NOBEMA_SUCCESS'
export const CONTACT_NOBEMA_ERROR = 'CONTACT_NOBEMA_ERROR'

export const CONTACT_SELLER = 'CONTACT_SELLER'
export const CONTACT_SELLER_SUCCESS = 'CONTACT_SELLER_SUCCESS'
export const CONTACT_SELLER_ERROR = 'CONTACT_SELLER_ERROR'

export const FETCH_COMPANY_IS_EDITABLE = 'FETCH_COMPANY_IS_EDITABLE'
export const FETCH_COMPANY_IS_EDITABLE_SUCCESS =
  'FETCH_COMPANY_IS_EDITABLE_SUCCESS'
export const FETCH_COMPANY_IS_EDITABLE_ERROR = 'FETCH_COMPANY_IS_EDITABLE_ERROR'

export const FETCH_COMPANY_IS_PUBLISHABLE = 'FETCH_COMPANY_IS_PUBLISHABLE'
export const FETCH_COMPANY_IS_PUBLISHABLE_SUCCESS =
  'FETCH_COMPANY_IS_PUBLISHABLE_SUCCESS'
export const FETCH_COMPANY_IS_PUBLISHABLE_ERROR =
  'FETCH_COMPANY_IS_PUBLISHABLE_ERROR'

export const PROSPECT_GENERATOR_UPDATE = 'PROSPECT_GENERATOR_UPDATE'
export const PROSPECT_GENERATOR_SEED = 'PROSPECT_GENERATOR_SEED'
export const PROSPECT_GENERATOR_CLEAR = 'PROSPECT_GENERATOR_CLEAR'

export const FETCH_PENDING_NDAS_SUCCESS = 'FETCH_PENDING_NDAS_SUCCESS'
export const FETCH_PENDING_NDAS_ERROR = 'FETCH_PENDING_NDAS_ERROR'
export const FETCH_PENDING_NDAS = 'FETCH_PENDING_NDAS'
export const FETCH_ACCESSIBLE_PROSPECTS = 'FETCH_ACCESSIBLE_PROSPECTS'
export const FETCH_ACCESSIBLE_PROSPECTS_SUCCESS =
  'FETCH_ACCESSIBLE_PROSPECTS_SUCCESS'
export const FETCH_ACCESSIBLE_PROSPECTS_ERROR =
  'FETCH_ACCESSIBLE_PROSPECTS_ERROR'

export const FETCH_BOUGHT_PRODUCTS = 'FETCH_BOUGHT_PRODUCTS'
export const FETCH_BOUGHT_PRODUCTS_SUCCESS = 'FETCH_BOUGHT_PRODUCTS_SUCCESS'
export const FETCH_BOUGHT_PRODUCTS_ERROR = 'FETCH_BOUGHT_PRODUCTS_ERROR'

export const FETCH_COMPANY_PROSPECT = 'FETCH_COMPANY_PROSPECT'
export const FETCH_COMPANY_PROSPECT_SUCCESS = 'FETCH_COMPANY_PROSPECT_SUCCESS'
export const FETCH_COMPANY_PROSPECT_ERROR = 'FETCH_COMPANY_PROSPECT_ERROR'

export const FETCH_COMPANY_ADVERTISEMENT_ANONYMIZED =
  'FETCH_COMPANY_ADVERTISEMENT_ANONYMIZED'
export const UPDATE_COMPANY_ADVERTISEMENT_ANONYMIZED =
  'UPDATE_COMPANY_ADVERTISEMENT_ANONYMIZED'
export const RESET_COMPANY_ADVERTISEMENT_ANONYMIZED =
  'RESET_COMPANY_ADVERTISEMENT_ANONYMIZED'

export const FETCH_EXCLUSIVITIES = 'FETCH_EXCLUSIVITIES'
export const FETCH_EXCLUSIVITIES_ERROR = 'FETCH_EXCLUSIVITIES_ERROR'
export const FETCH_EXCLUSIVITIES_SUCCESS = 'FETCH_EXCLUSIVITIES_SUCCESS'

export const UPDATE_TERMS_STATUS = 'UPDATE_TERMS_STATUS'
export const UPDATE_TERMS_STATUS_SUCCESS = 'UPDATE_TERMS_STATUS_SUCCESS'
export const UPDATE_TERMS_STATUS_ERROR = 'UPDATE_TERMS_STATUS_ERROR'

export const UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE =
  'UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE'
export const UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE_SUCCESS =
  'UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE_SUCCESS'
export const UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE_ERROR =
  'UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE_ERROR'

export const FETCH_MARKETING = 'FETCH_MARKETING'
export const FETCH_MARKETING_SUCCESS = 'FETCH_MARKETING_SUCCESS'
export const FETCH_MARKETING_ERROR = 'FETCH_MARKETING_ERROR'
