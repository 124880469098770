import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { colors } from 'styles'
import { h5 } from 'styles/text'

const menuItemStyle = `
  ${h5};
  font-size: 18px;
  color: ${colors.primaryGray};
  cursor: pointer;
  &.active {
    color: ${colors.action};
    text-decoration: underline;
  }
`

const LinkMenuItem = styled(NavLink)`
  ${menuItemStyle};
  text-decoration: none;
`

const StyledExternal = styled.a`
  ${menuItemStyle};
  text-decoration: none;
`

const ButtonMenuItem = styled.button`
  ${menuItemStyle};
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
`

const MenuItem = props => {
  if (props.to) {
    return <LinkMenuItem {...props} />
  } else if (props.href) {
    return <StyledExternal {...props} />
  } else {
    return <ButtonMenuItem {...props} />
  }
}

export default MenuItem
