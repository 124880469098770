import React from 'react'

import { Form, Buttons } from 'components/form'
import Button from 'components/Button'

class EditEconomicFigures extends React.Component {
  componentDidUpdate(prevProps) {
    if (!this.props.updateIsPending && prevProps.updateIsPending) {
      this.props.closeModal()
    }
  }

  render() {
    return (
      <Form onSubmit={this.props.onSubmit}>
        {this.props.children}
        <Buttons>
          <Button type="button" subtle onClick={this.props.closeModal}>
            Avbryt
          </Button>
          <Button pending={this.props.updateIsPending}>Lagre</Button>
        </Buttons>
      </Form>
    )
  }
}

export default EditEconomicFigures
