import { call, put, takeEvery } from 'redux-saga/effects'

import {
  UPDATE_TERMS_STATUS,
  UPDATE_TERMS_STATUS_SUCCESS,
  UPDATE_TERMS_STATUS_ERROR,
  UPDATE_USER_SETTINGS_ERROR,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE,
} from 'actionTypes'

import * as api from 'api'

function* updateTermsStatus({ status }) {
  try {
    yield call(api.updateTerms, status)
    //yield put({ type: UPDATE_TERMS_STATUS_SUCCESS, status })
  } catch ({ message }) {
    yield put({ type: UPDATE_TERMS_STATUS_ERROR, status: false, message })
  }
}

function* updateUserSettingsAndTerms({ settings, status }) {
  try {
    yield call(api.updateUserSettingsAndTermsOfService, [settings, status])
    yield put({ type: UPDATE_USER_SETTINGS_SUCCESS, settings })
    yield put({ type: UPDATE_TERMS_STATUS_SUCCESS, status })
  } catch ({ message }) {
    console.log('Error')
    yield put({ type: UPDATE_TERMS_STATUS_ERROR, status: false, message })
    yield put({ type: UPDATE_USER_SETTINGS_ERROR, settings, message })
  }
}

function* saga() {
  yield takeEvery(UPDATE_TERMS_STATUS, updateTermsStatus)
  yield takeEvery(
    UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE,
    updateUserSettingsAndTerms
  )
}

export default saga
