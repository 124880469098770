import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fetchSuggestions } from 'actions'
import { suggestionsSelector } from 'selectors'

import SuggestionList from './SuggestionList'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const withSuggestions = type => WrappedInput => {
  const mapStateToProps = (state, ownProps) => ({
    suggestions: suggestionsSelector(state, ownProps, type),
  })

  const mapDispatchToProps = { fetchSuggestions }

  class WithSuggestions extends React.Component {
    state = {
      active: undefined,
      hover: undefined,
      focus: false,
    }

    componentDidMount = () =>
      !this.props.suggestions && this.props.fetchSuggestions(this.props.value)

    onInputEnter = () => {
      if (this.state.active !== undefined) {
        this.props.select(
          this.props.suggestions[this.state.active.category].suggestions[
            this.state.active.suggestion
          ]
        )
      } else {
        this.props.select(this.props.value)
      }
      this.setState({
        active: undefined,
        hover: undefined,
      })
    }

    onChange = e => {
      this.props.onChange(e)
      const value = e.target.value
      clearTimeout(this.timeout)
      this.timeout = setTimeout(
        () => !this.props.suggestions && this.props.fetchSuggestions(value),
        100
      )
      this.setState({
        active: undefined,
        hover: undefined,
      })
    }

    nextSuggestion = ({ category, suggestion }) => {
      if (
        suggestion ===
        this.props.suggestions[category].suggestions.length - 1
      ) {
        return {
          category:
            category === this.props.suggestions.length - 1 ? 0 : category + 1,
          suggestion: 0,
        }
      } else {
        return {
          category,
          suggestion: suggestion + 1,
        }
      }
    }

    incrementActive = () =>
      this.setState(({ hover, active }) => ({
        hover: undefined,
        active:
          this.props.suggestions &&
          this.props.suggestions.length &&
          (hover || active)
            ? this.nextSuggestion(hover || active)
            : { category: 0, suggestion: 0 },
      }))

    lastSuggestion = () => {
      const lastCategory = this.props.suggestions.length - 1
      if (lastCategory !== -1) {
        return {
          category: lastCategory,
          suggestion:
            this.props.suggestions[lastCategory].suggestions.length - 1,
        }
      }
    }

    previousSuggestion = ({ category, suggestion }) => {
      if (suggestion === 0) {
        if (category === 0) {
          return this.lastSuggestion()
        } else {
          return {
            category: category - 1,
            suggestion:
              this.props.suggestions[category - 1].suggestions.length - 1,
          }
        }
      } else {
        return {
          category,
          suggestion: suggestion - 1,
        }
      }
    }

    decrementActive = () =>
      this.setState(({ hover, active }) => ({
        hover: undefined,
        active:
          this.props.suggestions &&
          this.props.suggestions.length &&
          (hover || active)
            ? this.previousSuggestion(hover || active)
            : this.lastSuggestion(),
      }))

    onFocus = () => this.setState({ focus: true })

    onBlur = () =>
      this.setState({ focus: false, active: undefined, hover: undefined })

    onKeyDown = e => {
      if (e.key === 'Enter') {
        e.preventDefault()
        this.onInputEnter()
      } else if (e.key === 'ArrowDown') {
        e.preventDefault()
        this.props.suggestions && this.incrementActive()
      } else if (e.key === 'ArrowUp') {
        e.preventDefault()
        this.props.suggestions && this.decrementActive()
      }
    }

    render() {
      return (
        <Wrapper>
          <WrappedInput
            {...this.props}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
          />
          {this.state.focus &&
            this.props.suggestions &&
            this.props.suggestions.length !== 0 && (
              <SuggestionList
                suggestions={this.props.suggestions}
                active={
                  this.state.active !== undefined
                    ? this.state.active
                    : this.state.hover
                }
                setHover={hover => this.setState({ hover, active: undefined })}
                unsetHover={() => this.setState({ hover: undefined })}
                select={this.props.select}
              />
            )}
        </Wrapper>
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithSuggestions)
}

export default withSuggestions
