import { createSelector } from 'reselect'
import qs from 'qs'

export * from './auth'
export * from './search'
export * from './companies'
export * from './content'
export * from './companyAdvertisements'
export * from './advertisements'
export * from './prospect'
export * from './agreements'

export * from './boughtProducts'
export const urlQuerySelector = createSelector(
  state => {
    return state.router.location.search
  },
  search => qs.parse(search.replace(/\?/, ''))
)

export const locationSelector = state => state.router.location.pathname
//+ state.router.location.search

export const overlaySelector = state => state.overlay

export const isMenuOpenSelector = state => state.menu.isOpen

export const userSettingsSelector = state => state.userSettings

export const userSettingsIsPendingSelector = state =>
  state.userSettings === 'pending'

export const calculatorOverridesSelector = (state, props) =>
  state.calculatorOverrides[props.companyId || props.advertisementId]

export const userSettingsErrorSelector = state => state.userSettings.error
