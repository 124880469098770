import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'
import { link } from 'styles/text'

import InfoBoxComponent, { Heading as HeadingComponent } from './InfoBox'

const InfoBox = styled(InfoBoxComponent)`
  background-color: ${colors.activeBackground};
`

const Heading = styled(HeadingComponent)``

const Link = styled.a`
  ${link};
  display: block;
  margin: 16px 0;
`

const ContactInfo = props => (
  <InfoBox>
    <Heading>Kontaktinformasjon</Heading>
    <p>{props.contactName}</p>
    <p>{props.contactPhone}</p>
  </InfoBox>
)

export default ContactInfo
