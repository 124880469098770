import {
  cashFlowValue,
  projectedNetProfit,
  costOverRevenue,
  revenueGrowthRate,
  projectedRevenue,
  projectedCost,
  projectedEBIT,
  projectedDepreciation,
  estimatedValue,
  equity,
} from 'valueCalculator'
import {
  authIsPendingSelector,
  isAuthenticatedSelector,
  advertisementSelector,
  calculatorOverridesSelector,
} from './index'

export const companySelector = (state, props) => {
  const company = state.companies[props.companyId]
  return company ? company : props
}

export const companyIsPendingSelector = (state, props) => {
  try {
    return state.companies[props.companyId].pending
  } catch (e) {
    return true
  }
}

export const companyProspectSelector = (state, props) => {
  const orgNr = props.companyId || props.orgNr

  return state.companiesProspect[orgNr] || {}
}

export const companyProspectIsPendingSelector = (state, props) => {
  const orgNr = props.companyId || props.orgNr
  try {
    return state.companiesProspect[orgNr].pending
  } catch (e) {
    return true
  }
}

export const companyIsFavoritedSelector = (state, props) =>
  !!state.favoritedCompanies[props.companyId]

export const favoritedCompaniesIsPendingSelector = state => {
  if (authIsPendingSelector(state)) {
    return true
  } else if (isAuthenticatedSelector(state)) {
    return state.favoritedCompanies.pending
  } else {
    return false
  }
}

export const favoritedCompanyIdsSelector = state =>
  Object.keys(state.favoritedCompanies)

export const claimedCompaniesIsPendingSelector = state => {
  if (authIsPendingSelector(state)) {
    return true
  } else if (isAuthenticatedSelector(state)) {
    return state.claimedCompanies.pending
  } else {
    return false
  }
}

export const claimedCompanyIdsSelector = state =>
  Object.keys(state.claimedCompanies)

export const companyIsClaimedSelector = (state, props) =>
  claimedCompanyIdsSelector(state).includes(props.companyId)

export const HistoricSalgsinntekterSelector = (state, props) =>
  companyEconomicHistorySelector(state, props)
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ RegnskapsAvAr, Salgsinntekter }) => ({
      x: RegnskapsAvAr,
      y: Salgsinntekter,
    }))

export const HistoricOrdResultatForSkattSelector = (state, props) =>
  companyEconomicHistorySelector(state, props)
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ RegnskapsAvAr, OrdResultatForSkatt }) => ({
      x: RegnskapsAvAr,
      y: OrdResultatForSkatt,
    }))

export const HistoricSumEgenkapitalSelector = (state, props) =>
  companyEconomicHistorySelector(state, props)
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ RegnskapsAvAr, SumEgenkapital }) => ({
      x: RegnskapsAvAr,
      y: SumEgenkapital,
    }))

export const companyEconomicHistorySelector = (state, props) => {
  let companyData = {}
  if (props.companyId) {
    const companyState = companySelector(state, props)
    companyData = companyState
      ? companyState
      : companyProspectSelector(state, props)
  } else {
    companyData = advertisementSelector(state, props)
  }

  const {
    okonomiSammendragForetak,
    okonomiDetaljerForetak,
    gjeldEgenkapitalForetak,
    eiendelerForetak,
  } = companyData

  let history = {}
  const addToHistory = year =>
    (history[year.RegnskapsAvAr] = {
      ...history[year.RegnskapsAvAr],
      ...year,
    })

  okonomiSammendragForetak && okonomiSammendragForetak.forEach(addToHistory)
  okonomiDetaljerForetak && okonomiDetaljerForetak.forEach(addToHistory)
  gjeldEgenkapitalForetak && gjeldEgenkapitalForetak.forEach(addToHistory)
  eiendelerForetak && eiendelerForetak.forEach(addToHistory)

  return Object.values(history)
}

export const cashFlowValueSelector = (state, props) =>
  Math.round(
    cashFlowValue(
      companyEconomicHistorySelector(state, props),
      calculatorOverridesSelector(state, props)
    ) * 1000
  )

const maxYear = o => Math.max(...o.map(({ RegnskapsAvAr }) => RegnskapsAvAr))

export const projectedResultsSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ RegnskapsAvAr, OrdResultat }) => ({
      x: RegnskapsAvAr,
      y: OrdResultat,
    }))
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
        .map(year => ({
          x: year,
          y:
            projectedNetProfit(
              history,
              year,
              calculatorOverridesSelector(state, props)
            ) * 1000,
        }))
    )
}

export const projectedRevenueSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ RegnskapsAvAr, Salgsinntekter }) => ({
      x: RegnskapsAvAr,
      y: Salgsinntekter,
    }))
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
        .map(year => ({
          x: year,
          y: projectedRevenue(
            history,
            year,
            calculatorOverridesSelector(state, props)
          ),
        }))
    )
}

export const projectedCostSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(
      ({
        RegnskapsAvAr,
        Varekostnad,
        Beholdningsendring,
        Lonnskostnad,
        AnnenDriftskostnad,
      }) => ({
        x: RegnskapsAvAr,
        y: Varekostnad + Beholdningsendring + Lonnskostnad + AnnenDriftskostnad,
      })
    )
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
        .map(year => ({
          x: year,
          y: projectedCost(
            history,
            year,
            calculatorOverridesSelector(state, props)
          ),
        }))
    )
}

export const costOverRevenueSelector = (state, props) =>
  costOverRevenue(companyEconomicHistorySelector(state, props)) * 100

export const revenueGrowthRateSelector = (state, props) =>
  revenueGrowthRate(companyEconomicHistorySelector(state, props)) * 100

export const balanceSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  if (!history || !history.length) return undefined

  const lastYear = Math.max(
    ...history.map(({ RegnskapsAvAr }) => RegnskapsAvAr)
  )

  const { Bankinnskudd, SumInvesteringer, SumGjeld } = history.find(
    ({ RegnskapsAvAr }) => RegnskapsAvAr === lastYear
  )

  return (Bankinnskudd - SumInvesteringer - SumGjeld) * 1000
}

// - Investeringer - Rentebærende gjeld
export const companyEquitySelector = (state, props) =>
  equity(companyEconomicHistorySelector(state, props))

export const companyEstimatedValueSelector = (state, props) =>
  estimatedValue(
    companyEconomicHistorySelector(state, props),
    calculatorOverridesSelector(state, props)
  ) * 1000

export const recordedAndProjectedYearsSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ RegnskapsAvAr }) => RegnskapsAvAr)
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
    )
}

export const recordedYearsSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ RegnskapsAvAr }) => RegnskapsAvAr)
}

export const recordedAndProjectedCostSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(
      ({ Varekostnad, Beholdningsendring, Lonnskostnad, AnnenDriftskostnad }) =>
        Varekostnad + Beholdningsendring + Lonnskostnad + AnnenDriftskostnad
    )
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
        .map(year =>
          Math.round(
            projectedCost(
              history,
              year,
              calculatorOverridesSelector(state, props)
            )
          )
        )
    )
}

export const recordedAndProjectedRevenueSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ Salgsinntekter }) => Salgsinntekter)
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
        .map(year =>
          Math.round(
            projectedRevenue(
              history,
              year,
              calculatorOverridesSelector(state, props)
            )
          )
        )
    )
}

export const recordedAndProjectedEBITSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ DriftsResultat }) => DriftsResultat)
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
        .map(year =>
          Math.round(
            projectedEBIT(
              history,
              year,
              calculatorOverridesSelector(state, props)
            )
          )
        )
    )
}

export const recordedAndProjectedDepreciationSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ Avskrivninger, Nedskrivninger }) => Avskrivninger + Nedskrivninger)
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
        .map(year =>
          Math.round(
            projectedDepreciation(
              history,
              year,
              calculatorOverridesSelector(state, props)
            )
          )
        )
    )
}

export const recordedAndProjectedMarginSelector = (state, props) => {
  const revenue = recordedAndProjectedRevenueSelector(state, props)
  const EBIT = recordedAndProjectedEBITSelector(state, props)
  return revenue.map((revenue, index) =>
    ((EBIT[index] / revenue) * 100).toFixed(1)
  )
}

export const recordedAndProjecteNetProfitSelector = (state, props) => {
  const history = companyEconomicHistorySelector(state, props)

  return history
    .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
    .map(({ ArsResultat }) => ArsResultat)
    .concat(
      Array(5)
        .fill()
        .map((_, index) => maxYear(history) + index + 1)
        .map(year =>
          Math.round(
            projectedNetProfit(
              history,
              year,
              calculatorOverridesSelector(state, props)
            )
          )
        )
    )
}

export const lastRecordedYearSelector = (state, props) =>
  Math.max(
    ...companyEconomicHistorySelector(state, props).map(
      ({ RegnskapsAvAr }) => RegnskapsAvAr
    )
  )

export const economicNumbersOfLastRecordedYearSelector = (state, props) =>
  companyEconomicHistorySelector(state, props).find(
    ({ RegnskapsAvAr }) =>
      Number(RegnskapsAvAr) === lastRecordedYearSelector(state, props)
  )

export const companyIsEditableSelector = (state, props) =>
  state.editableCompanies[props.companyId] === true

export const companyIsEditableIsPendingSelector = (state, props) =>
  state.editableCompanies[props.companyId] === 'pending'

export const companyIsPublishableSelector = (state, props) =>
  state.publishableCompanies[props.companyId] === true

export const companyIsPublishabeIsPendingSelector = (state, props) =>
  state.publishableCompanies[props.companyId] === 'pending'

export const marketingIsPendingSelector = state => {
  try {
    return state.marketing.pending
  } catch (e) {
    return true
  }
}

export const marketingSelector = state => state.marketing
