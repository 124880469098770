import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'

import { economicNumbersOfLastRecordedYearSelector } from 'selectors'
import { wrapper, padding } from '../styles'
import { h6, body } from 'styles/text'

import Large from './Large'
import Small from './Small'
import Anonymized from 'components/Anonymized'

const Wrapper = styled.div`
  ${wrapper};
  ${padding};
`

const Heading = styled.h6`
  ${h6};
  margin-top: 0;
`

const Content = styled.div`
  ${body}
`

const KeyFigures = props => {
  if (!props.okonomiSammendragForetak) {
    return (
      <Wrapper>
        <Heading>Nøkkeltall</Heading>
        <Content>Mangler datagrunnlag</Content>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <MediaQuery query="(min-width: 768px)">
          {matches =>
            matches ? (
              <Large {...props} {...props.okonomiSammendragForetak} />
            ) : (
              <Small {...props} {...props.okonomiSammendragForetak} />
            )
          }
        </MediaQuery>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...economicNumbersOfLastRecordedYearSelector(state, ownProps),
})

export default connect(mapStateToProps)(KeyFigures)
