import {
  FETCH_USER_SETTINGS,
  FETCH_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_ERROR,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_SETTINGS:
      return 'pending'
    case FETCH_USER_SETTINGS_SUCCESS:
      return action.settings
    case UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.settings,
      }
    case UPDATE_USER_SETTINGS_ERROR:
      let error = action.message
      if (error === 'The email address is already in use by another account.') {
        error = 'Eposten er i bruk av en annen konto.'
      } else if (
        error ===
        'This operation is sensitive and requires recent authentication. Log in again before retrying this request.'
      ) {
        error =
          'Å oppdatere epost er en sensitiv operasjon som krever nylig autentisering. Venligst logg ut og inn igjen for å få mulighet til å oppdatere epost.'
      }

      return {
        ...state,
        error,
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
