import styled from 'styled-components'
import Color from 'color'

const primary = Color('#296455')
const secondary = Color('#0047C1')
const secondaryHover = Color('#005BF7')
const secondaryPressed = Color('#003A9E')
const action = primary
const actionHover = Color('#34826E')
const actionPressed = Color('#225548')
const activeBackground = Color('#DBF3E3')
const infoBackground = activeBackground
const white = Color('#FFFEFB')
const primaryGray = Color('#484848')
const darkGreen = Color('#061708')
const black = Color('#000000')
const alabasterBackground = Color('#F4F2E7')
const ivoryLight = Color('#F9F8F3')
const subtleText = Color('#9D9D9D')
const borderColor = Color('#DBDBDB')
const error = Color('#D0021B')
const errorHover = Color('#E12940')
const errorBackground = Color('#FFF9F9')
const link = primary
const whiteHover = Color('#FCFCFC')
const whitePressed = borderColor
const disabled = alabasterBackground
const inputBackground = whiteHover
const inputBackgroundGray = Color('#465047')
const inputTextGray = Color('#E0DED4')
const overlay = primaryGray.fade(0.5)
const anonymize = Color('#3D0288')
const yellow = Color('#FFCC00')
const success = Color('#70B64D')
const blackHover = Color('#282828')
const blackPressed = Color('#000000')
const footer = Color('#0A170C')
const warning = Color('#FFB901')
const warning2 = Color('#F0BF5E')
const highlightBackground = Color('#E9CA8F')

export const colors = {
  primary: primary.string(),
  secondary: secondary.string(),
  secondaryHover: secondaryHover.string(),
  secondaryPressed: secondaryPressed.string(),
  action: action.string(),
  actionHover: actionHover.string(),
  actionPressed: actionPressed.string(),
  white: white.string(),
  whiteHover: whiteHover.string(),
  whitePressed: whitePressed.string(),
  primaryGray: primaryGray.string(),
  darkGreen: darkGreen.string(),
  black: black.string(),
  alabasterBackground: alabasterBackground.string(),
  ivoryLight: ivoryLight.string(),
  disabled: disabled.string(),
  subtleText: subtleText.string(),
  borderColor: borderColor.string(),
  error: error.string(),
  errorHover: errorHover.string(),
  errorBackground: errorBackground.string(),
  link: link.string(),
  infoBackground: infoBackground.string(),
  inputBackground: inputBackground.string(),
  activeBackground: activeBackground.string(),
  overlay: overlay.string(),
  anonymize: anonymize.string(),
  yellow: yellow.toString(),
  inputBackgroundGray: inputBackgroundGray.toString(),
  inputTextGray: inputTextGray.toString(),
  success: success.toString(),
  blackHover: blackHover.toString(),
  blackPressed: blackPressed.toString(),
  footer: footer.toString(),
  warning: warning.toString(),
  warning2: warning2.toString(),
  highlight: warning2.toString(),
  highlightBackground: highlightBackground.toString(),
}

export const border = `0.2rem solid ${colors.borderColor}`

export const contentWidth = '1075px'

export const ContentWrapper = styled.div`
  max-width: ${contentWidth};
  margin: 40px auto;
`
