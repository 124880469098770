import { authIsPendingSelector, isAuthenticatedSelector } from './index'

export const advertisementSelector = (state, props) =>
  state.advertisements[props.advertisementId] || {}

export const advertisementIsPendingSelector = (state, props) => {
  try {
    return state.advertisements[props.advertisementId].pending
  } catch (e) {
    return true
  }
}

export const advertisementIsFavoritedSelector = (state, props) =>
  !!state.favoritedAdvertisements[props.advertisementId]

export const favoritedAdvertisementsIsPendingSelector = state => {
  if (authIsPendingSelector(state)) {
    return true
  } else if (isAuthenticatedSelector(state)) {
    return state.favoritedAdvertisements.pending
  } else {
    return false
  }
}

export const favoritedAdvertisementIdsSelector = state =>
  Object.keys(state.favoritedAdvertisements)

export const claimedAdvertisementsIsPendingSelector = state => {
  if (authIsPendingSelector(state)) {
    return true
  } else if (isAuthenticatedSelector(state)) {
    return state.claimedAdvertisements.pending
  } else {
    return false
  }
}
