import React, { useEffect } from 'react'
import Spinner from 'components/Spinner'

import { H4 } from 'styles/text'
import Exclusivities from './Exclusivities'
import NDA from './NDA'
const Agreements = ({ ndas, exclusivities }) => {
  const handleAgreements = () => {
    const signed = []
    const toBeSigned = []

    // Handle NDA struct
    Object.keys(ndas.ndas || {}).map(nda => {
      if (ndas.ndas[nda].status === 'pending') {
        toBeSigned.push({ ...ndas.ndas[nda], type: 'NDA' })
      } else if (ndas.ndas[nda].status === 'accepted') {
        signed.push({ ...ndas.ndas[nda], type: 'NDA' })
      }
    })

    // Handle exclusivities
    Object.keys(exclusivities.exclusivities || {}).map(orgNr => {
      Object.keys(exclusivities.exclusivities[orgNr]).map(userId => {
        if (exclusivities.exclusivities[orgNr][userId].status) {
          signed.push({
            ...exclusivities.exclusivities[orgNr][userId],
            orgNr: orgNr,
            type: 'exclusive',
            buyer: userId,
          })
        } else {
          toBeSigned.push({
            ...exclusivities.exclusivities[orgNr][userId],
            orgNr: orgNr,
            type: 'exclusive',
            buyer: userId,
          })
        }
      })
    })

    return { toBeSigned: toBeSigned, signed: signed }
  }

  const agreements = handleAgreements()

  const renderAgreementType = agreement => {
    if (agreement.type === 'NDA') {
      return (
        <NDA
          key={`${agreement.type}_${agreement.companyId}_${agreement.seller}`}
          {...agreement}
        />
      )
    } else {
      return (
        <Exclusivities
          key={`${agreement.type}_${agreement.orgNr}_${agreement.buyer}`}
          {...agreement}
        />
      )
    }
  }

  return (
    <>
      {ndas.pending || exclusivities.pending ? (
        <Spinner />
      ) : (
        <div>
          <H4>Til Signering</H4>
          {agreements.toBeSigned.map(agreement =>
            renderAgreementType(agreement)
          )}
          <H4>Signert</H4>
          {agreements.signed.map(agreement => renderAgreementType(agreement))}
        </div>
      )}
    </>
  )
}

export default Agreements
