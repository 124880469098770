import React from 'react'

import {
  Field,
  Label,
  Textarea,
  RadioButtons,
  UploadFiles,
} from 'components/form'
import EditListOfLists, { mapToState } from './EditListOfLists'
import { uploadFilesFromList } from 'components/form/FileUploaderCore'
import { H6 } from 'styles/text'

export const lists = {
  longTermLoans: {
    heading: 'Langsiktige lån fra kredittinstitusjon',
    buttonLabel: '+ Legg til langsiktige lån fra kredittinstitusjon',
    fields: [
      { prop: 'name', label: 'Navn kredittinstitusjon' },
      { prop: 'amount', label: 'Lånebeløp' },
      { prop: 'maturity', label: 'Forfall', placeholder: 'dd.mm.åååå' },
      {
        prop: 'security',
        label: 'Sikkerhet',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Ingen sikkerhet',
              'Privat sikkerhetsstillelse',
              'Sikkerhet stilt av selskapet',
            ].map(value => ({ value, label: value }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'continuation',
        label: 'Videreføring',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Slettes av selger',
              'Overtas av kjøper',
              'Må erstattes/slettes av kjøper',
            ].map(value => ({ value, label: value }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  shortTermLoans: {
    heading: 'Kortsiktige lån og kreditter fra kredittinstitusjon',
    buttonLabel:
      '+ Legg til kortsiktige lån og kreditter fra kredittinstitusjon',
    fields: [
      { prop: 'name', label: 'Navn kredittinstitusjon' },
      { prop: 'amount', label: 'Lånebeløp' },
      { prop: 'maturity', label: 'Forfall', placeholder: 'dd.mm.åååå' },
      {
        prop: 'security',
        label: 'Sikkerhet',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Ingen sikkerhet',
              'Privat sikkerhetsstillelse',
              'Sikkerhet stilt av selskapet',
            ].map(value => ({ value, label: value }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'continuation',
        label: 'Videreføring',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Slettes av selger',
              'Overtas av kjøper',
              'Må erstattes/slettes av kjøper',
            ].map(value => ({ value, label: value }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  guarantees: {
    heading: 'Løpende garantileveranser fra kredittinstitusjon',
    buttonLabel: '+ Legg til løpende garantileveranser fra kredittinstitusjon',
    fields: [
      { prop: 'name', label: 'Navn kredittinstitusjon/garantist' },
      { prop: 'amount', label: 'Garantibeløp' },
      {
        prop: 'maturity',
        label: 'Utløp',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              {
                value: 'Løpende',
                label: 'Løpende',
              },
            ]}
            other="Dato"
            otherPlaceholder="dd.mm.åååå"
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'security',
        label: 'Sikkerhet',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Ingen sikkerhet',
              'Privat sikkerhetsstillelse',
              'Sikkerhet stilt av selskapet',
            ].map(value => ({ value, label: value }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'continuation',
        label: 'Videreføring',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Slettes av selger',
              'Overtas av kjøper',
              'Må erstattes/slettes av kjøper',
            ].map(value => ({ value, label: value }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  factoringfinances: {
    heading: 'Løpende factoringavtaler',
    buttonLabel: '+ Legg til løpende factoringavtaler',
    fields: [
      { prop: 'name', label: 'Navn kredittinstitusjon' },
      { prop: 'creditLimit', label: 'Kredittramme' },
      {
        prop: 'continuation',
        label: 'Videreføring',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Slettes av selger',
              'Overtas av kjøper',
              'Må erstattes/slettes av kjøper',
            ].map(value => ({ value, label: value }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  insurancefinances: {
    heading: 'Forsikringer',
    buttonLabel: '+ Legg til forsikring',
    fields: [
      { prop: 'insurer', label: 'Navn på forsikringsselskap' },
      { prop: 'insurnace', label: 'Navn på forsikring' },
      { prop: 'premie', label: 'Forsikringspremie' },
      {
        prop: 'continuation',
        label: 'Videreføring',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Slettes av selger',
              'Overtas av kjøper',
              'Må erstattes/slettes av kjøper',
            ].map(value => ({ value, label: value }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      { prop: 'comment', label: 'Kommentar', Component: Textarea },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
}

class EditFinancialStructure extends React.Component {
  componentDidMount() {
    if (this.props.formValues.finances) {
      this.setState({ ...this.props.formValues.finances })
    } else {
      this.props.dispacth('finances', {
        ...mapToState(lists, this.props),
        ...Object.keys(lists).reduce(
          (state, key) => ({
            ...state,
            [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
          }),
          {}
        ),
        assetsComment: this.props.assetsComment || '',
      })
    }
  }

  // Set initial state
  // Will be overwritten by componentDidMount() if finances are found in store
  state = {
    ...mapToState(lists, this.props),
    ...Object.keys(lists).reduce(
      (state, key) => ({
        ...state,
        [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
      }),
      {}
    ),
    financialStructureComment: this.props.financialStructureComment || '',
  }

  removeItem = (list, index) => {
    const updatedFinances = {
      [list]: this.state[list].filter((_, i) => i !== index),
    }
    this.setState(updatedFinances)
    this.props.dispacth('finances', { ...this.state, ...updatedFinances })
  }

  addItem = (list, item) =>
    this.setState({
      [list]: [...this.state[list], item],
    })

  setData = (property, index, key, value, rm) => {
    let oldFiles = []
    if (key === 'files' && this.state[property][index][key] && !rm) {
      oldFiles = this.state[property][index][key]
    }
    return {
      [property]: this.state[property].map((list, i) =>
        i === index
          ? {
              ...list,
              [key]: key === 'files' ? [...oldFiles, ...value] : value,
            }
          : list
      ),
    }
  }

  setValue = (property, index, key, value) => {
    if (
      key === 'files' &&
      value.length > 0 &&
      typeof value[0].name === 'string'
    ) {
      uploadFilesFromList(this.props.orgNumber, value).then(res => {
        const finances = this.setData(property, index, key, res)
        this.props.dispacth('finances', { ...this.state, ...finances })
        this.setState(finances)
      })
    } else if (
      key === 'files' &&
      (value.length === 0 || typeof value[0].name !== 'string')
    ) {
      const finances = this.setData(property, index, key, value, true)
      this.props.dispacth('finances', { ...this.state, ...finances })
      this.setState(finances)
    } else {
      const finances = this.setData(property, index, key, value)
      this.props.dispacth('finances', { ...this.state, ...finances })
      this.setState(finances)
    }
  }
  setComment = value => {
    this.setState({ financialStructureComment: value })
    this.props.dispacth('finances', {
      ...this.state,
      financialStructureComment: value,
    })
  }

  render() {
    return (
      <>
        <EditListOfLists
          {...this.props}
          lists={lists}
          state={this.state}
          removeItem={this.removeItem}
          addItem={this.addItem}
          setValue={this.setValue}
          setAnonymized={(key, value) => this.setState({ [key]: value })}
        />
        <Field>
          <H6>Ytterligere informasjon om Finans og Forsikring</H6>
          <Textarea
            value={this.state.financialStructureComment}
            onChange={e => this.setComment(e.target.value)}
            placeholder={'Eventuell tilleggsinformasjon'}
          />
        </Field>
      </>
    )
  }
}

export default EditFinancialStructure
