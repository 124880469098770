import React from 'react'
import styled from 'styled-components'

import connectAdvertisementForm from '../edit/connectAdvertisementForm'
import ButtonComponent from 'components/Button'
import Modal from 'components/Modal'

const Button = styled(ButtonComponent)`
  width: 100%;
  text-align: center;
`

class PublishButton extends React.Component {
  state = {
    showBuyPublishButton: false,
  }

  render() {
    let { companyId, published, updateCompanyAdvertisement } = this.props
    return published ? (
      <Button
        onClick={() =>
          window.confirm('Er du sikker på at du vil avpublisere annonsen?') &&
          updateCompanyAdvertisement(companyId, { published: false })
        }
      >
        Avpubliser annonsen
      </Button>
    ) : (
      <React.Fragment>
        <Button
          onClick={() => {
            if (this.props.publishable) {
              window.confirm(
                'Er du sikker på at du vil publisere annonsen?' +
                  (this.props.anonymized
                    ? ' (husk å dobbeltsjekke at du ikke røper bedriftens identitet i tekster, kommentarer eller andre felter)'
                    : '')
              ) && updateCompanyAdvertisement(companyId, { published: true })
            } else {
              this.setState({ showBuyPublishButton: true })
            }
          }}
        >
          Publiser annonsen
        </Button>
        <Modal
          isOpen={this.state.showBuyPublishButton}
          onRequestClose={() => this.setState({ showBuyPublishButton: false })}
          title="Kjøp tilgang til å publisere"
        >
          <div>
            <p>
              For å kunne publisere prospektet ditt må du først kjøpe en annonse
            </p>
            <Button to="/services">Gå til tjenester</Button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default connectAdvertisementForm(PublishButton)
