import React from 'react'
import styled from 'styled-components'

import { Tab, Tabs, TabList, TabPanel } from 'components/tabs'
import { border } from 'styles'
import { h6, h3, subtle2, subtitle } from 'styles/text'
import GraphComponent from 'components/Graph'
import Expandable from 'components/Expandable'
import Accounts from './Accounts'
import EditButton from 'components/EditButton'
import EditEconomicFigures from 'components/Company/edit/EditEconomicFigures'
import SellersComment from 'components/SellersComment'
import File from 'components/File'

const Heading = styled.h6`
  ${h6};
  margin-top: 0;
`

const TabTitle = styled.div`
  ${subtle2};
`

const TabValue = styled.div`
  margin-top: 1.2rem;
  ${subtitle};
`

const Graph = styled(GraphComponent)`
  margin-top: 20px;
`

const ContentHeading = styled.h3`
  ${h3};
  margin: 0;
`

const Header = styled.div`
  ${h6};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${border};
  border-bottom: ${props => (props.open ? 'none' : border)};
  padding: ${props => (props.open ? '30px 0 20px' : '30px 0')};

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const Content = styled.div`
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const EconomicFigures = ({
  HistoricSalgsinntekter,
  HistoricOrdResultatForSkatt,
  HistoricSumEgenkapital,
  ResultatForSkatt,
  SumEgenkapital,
  Salgsinntekter,
  RegnskapsAvAr,
  ...props
}) => (
  <React.Fragment>
    <Heading>Siste registrerte regnskap ({RegnskapsAvAr})</Heading>
    <Content>
      <div>
        <Tabs>
          <TabList>
            <Tab>
              <TabTitle>Driftsinntekter</TabTitle>
              <TabValue>
                {!isNaN(Salgsinntekter) &&
                  (Salgsinntekter * 1000).toLocaleString('nb')}
              </TabValue>
            </Tab>
            <Tab>
              <TabTitle>Resultat før skatt</TabTitle>
              <TabValue>
                {!isNaN(ResultatForSkatt) &&
                  (ResultatForSkatt * 1000).toLocaleString('nb')}
              </TabValue>
            </Tab>
            <Tab>
              <TabTitle>Egenkapital</TabTitle>
              <TabValue>
                {!isNaN(SumEgenkapital) &&
                  (SumEgenkapital * 1000).toLocaleString('nb')}
              </TabValue>
            </Tab>
          </TabList>

          <TabPanel>
            <Graph data={[{ dataset: HistoricSalgsinntekter, label: '' }]} />
          </TabPanel>
          <TabPanel>
            <Graph
              data={[{ dataset: HistoricOrdResultatForSkatt, label: '' }]}
            />
          </TabPanel>
          <TabPanel>
            <Graph data={[{ dataset: HistoricSumEgenkapital, label: '' }]} />
          </TabPanel>
        </Tabs>
      </div>
      <Expandable
        renderHeader={({ open }) => (
          <Header open={open}>
            <div>{open ? 'Skjul' : 'Se'} fullt regnskap</div>
            <img src="/toggle_down.svg" alt="" />
          </Header>
        )}
        renderContent={() => <Accounts {...props} />}
      />
      <SellersComment {...props}>{props.economicFiguresComment}</SellersComment>
      <EditButton
        {...props}
        title="Økonomiske hovedtall"
        formComponent={EditEconomicFigures}
      />
    </Content>
  </React.Fragment>
)

export default EconomicFigures
