import React from 'react'

import InfoBox, { Heading, Button } from './InfoBox'

const BuyersChecklist = () => (
  <InfoBox>
    <Heading>Kjøpers sjekkliste</Heading>
    <p>Hva bør en kjøper av bedrift se etter</p>
    <Button
      href="https://f.hubspotusercontent30.net/hubfs/8278034/Premiuminnhold/Punktvis%20sjekkliste%20due%20diligence.docx"
      subtle
      target="_blank"
    >
      Se sjekkliste
    </Button>
  </InfoBox>
)

export default BuyersChecklist
