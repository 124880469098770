import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import InfoBoxComponent, { Heading } from '../InfoBox'
import ModalButtonComponent from 'components/ModalButton'
import AskForAccessForm from './AskForAccessForm'

const InfoBox = styled(InfoBoxComponent)`
  background-color: ${colors.activeBackground};
`

const ModalButton = styled(ModalButtonComponent)`
  width: 100%;
  text-align: center;
  margin-top: 36px;
`

const AskForAccess = ({ advertisementId, projectLicence }) => {
  return (
    <InfoBox>
      <Heading>Salgsprospekt</Heading>
      <ModalButton
        title={'Vis interesse for salgsprospektet'}
        renderModalContent={props => (
          <AskForAccessForm
            {...props}
            advertisementId={advertisementId}
            projectLicence={projectLicence}
          />
        )}
      >
        Be om lesetilgang
      </ModalButton>
    </InfoBox>
  )
}
export default AskForAccess
