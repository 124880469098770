import React from 'react'
import { connect } from 'react-redux'

import { inviteHelper } from 'actions'
import { validateEmail } from 'utils'

import { Form, Field, Label, Input, Buttons } from 'components/form'
import Button from 'components/Button'

const validate = state => {
  let errors = {}
  if (!state.email) {
    errors.email = 'Epost er påkrevd'
  } else if (!validateEmail(state.email)) {
    errors.email = 'Eposten er ikke riktig formatert'
  }

  return errors
}

class InviteHelperForm extends React.Component {
  state = {
    email: '',
    errors: {},
    success: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.inviteHelperStatus === 'pending' &&
      this.props.inviteHelperStatus === 'success'
    ) {
      this.setState({ success: true })
    }
  }

  render() {
    return this.state.success ? (
      <div>
        <p>
          Personen du har invitert kommer til å få invitasjon til å hjelpe med
          med å fylle ut prospektet på mail
        </p>
        <Button onClick={this.props.closeModal} subtle>
          Lukk
        </Button>
      </div>
    ) : (
      <Form
        onSubmit={e => {
          e.preventDefault()

          let errors = validate(this.state)
          this.setState({ errors })

          if (Object.keys(errors).length !== 0) {
            return
          }

          this.props.inviteHelper({
            email: this.state.email,
            companyId: this.props.companyId,
          })
        }}
      >
        <p>
          Gi din medhjelper tilgang til prospekt og annonse. En medhjelper med
          tilgang kan se, redigere og publisere annonsen på dine vegne.
        </p>
        <Field>
          <Label>E-post</Label>
          <Input
            value={this.state.email}
            error={this.state.errors.email}
            onChange={e => this.setState({ email: e.target.value })}
            placeholder="E-post"
          />
        </Field>
        <Buttons>
          <Button>Inviter</Button>
        </Buttons>
      </Form>
    )
  }
}

const mapStateToProps = state => ({
  inviteHelperStatus: state.services.inviteHelper,
})

const mapDispatchToProps = { inviteHelper }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteHelperForm)
