import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import withSuggestions from 'components/withSuggestions'
import { Input } from 'components/form'
import SearchIcon from 'components/icons/Search'

const Form = styled.form`
  position: relative;
`
const Icon = styled.img`
  height: 16px;
  width: 16px;
`
const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
`

const CustomButton = styled(Button)`
  border: none;
  padding: 0;
  height: 95%;
  color: white;
  line-height: 0;
  width: 40px;
  padding: 0;
  cursor: pointer;
  background: transparent !important;
  &:disabled {
    background: transparent !important;
  }
`

const SearchField = props => {
  const [searchTerm, setSearchTerm] = useState(props.q)

  return (
    <Form
      className={props.className}
      onSubmit={e => {
        e.preventDefault()
        props.setQ(searchTerm)
      }}
    >
      <SearchInput
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        placeholder="Søk firmanavn, annonse, org.nr., bransje eller sted"
        select={q => {
          props.setQ(q)
        }}
      />
      <ButtonContainer>
        <CustomButton
          disabled={searchTerm === ''}
          secondary
          onClick={() => {
            setSearchTerm('')
            props.setQ('')
          }}
          type="reset"
        >
          <Icon src="/x.svg" />
        </CustomButton>
        <CustomButton disabled={searchTerm === ''}>
          <SearchIcon />
        </CustomButton>
      </ButtonContainer>
    </Form>
  )
}

const SearchInput = withSuggestions()(props => <Input {...props} />)

export default SearchField
