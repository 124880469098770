import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { companyAdvertisementSelector } from 'selectors'
import { h6, Body } from 'styles/text'
import { wrapper, padding, content } from './styles'

import Expandable from 'components/Expandable'
import SellersComment from 'components/SellersComment'
import { lists } from 'components/Company/edit/EditLeasing'
import ListOfLists from './ListOfLists'
import File from 'components/File'

const Wrapper = styled.div`
  ${wrapper};
`

const Heading = styled.h6`
  ${h6};
  ${padding};
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const Content = styled.div`
  ${content};

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Leasing = props => (
  <>
    {props.leases ||
    props.nonPropertyLeases ||
    props.leasingComment ||
    props.attatchments ? (
      <Wrapper>
        <Expandable
          renderHeader={({ open }) => (
            <Heading open={open}>
              <span>Leie- og leasingavtaler</span>
              <img src="/toggle_down.svg" alt="" />
            </Heading>
          )}
          renderContent={() => (
            <Content>
              <ListOfLists lists={lists} props={props} />
              <SellersComment {...props}>{props.leasingComment}</SellersComment>
              {props.attatchments && (
                <>
                  <Body>Andre vedlegg:</Body>
                  <ul>
                    {props.attatchments.map(attatchment => (
                      <li key={attatchment}>
                        <File
                          path={'prospect'}
                          name={attatchment}
                          orgNumber={props.orgNumber}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Content>
          )}
        />
      </Wrapper>
    ) : null}
  </>
)
const mapStateToProps = (state, ownProps) => ({
  ...companyAdvertisementSelector(state, ownProps),
})

export default connect(mapStateToProps)(Leasing)
