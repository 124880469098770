import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'

import App from './App'
import { createReduxStore } from 'store'
import 'index.css'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__
// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

const { store, history } = createReduxStore({ isClient: true, preloadedState })

const dehydratedState = window.__REACT_QUERY_STATE__
const queryClient = new QueryClient()

Sentry.init({
  dsn: 'https://1d8821495fcd4d329de8d581e3d55406@o402078.ingest.sentry.io/5619104',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

hydrate(
  <QueryClientProvider client={queryClient}>
    <Hydrate state={dehydratedState}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App isClient={true} />
        </ConnectedRouter>
      </Provider>
    </Hydrate>
  </QueryClientProvider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
