import React from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import styled from 'styled-components'

import LinkComponent from 'components/Link'

const pageSize = 72

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 4px;
  }
`

const Icon = styled.img``

const Link = styled(LinkComponent)`
  ${props => !props.active && 'text-decoration: none'};
`

const Pagination = ({ hits }) => {
  const history = useHistory()
  const query = qs.parse(history.location.search.replace(/\?/, ''))
  const from = isNaN(query.from) ? 0 : Number(query.from)
  if (hits.total.value <= pageSize) return null

  let links
  if (from - 2 * pageSize <= 0) {
    links = [0, pageSize, pageSize * 2, pageSize * 3, pageSize * 4]
  } else if (from + 2 * pageSize >= hits.total.value - pageSize) {
    links = [
      hits.total.value - pageSize * 5,
      hits.total.value - pageSize * 4,
      hits.total.value - pageSize * 3,
      hits.total.value - pageSize * 2,
      hits.total.value - pageSize,
    ]
  } else {
    links = [
      from - 2 * pageSize,
      from - pageSize,
      from,
      from + pageSize,
      from + 2 * pageSize,
    ]
  }
  links = links.filter(n => n < hits.total.value)
  return (
    <Wrapper>
      {links.some(n => n < from) && (
        <Link
          to={`${history.location.pathname}?${qs.stringify({
            ...query,
            from: from - pageSize < 0 ? 0 : from - pageSize,
          })}`}
        >
          <Icon src="/back.svg" />
        </Link>
      )}
      {links.map(start => (
        <Link
          active={start === from ? 'true' : undefined}
          key={start}
          to={`${history.location.pathname}?${qs.stringify({
            ...query,
            from: start,
          })}`}
        >
          {Math.floor(start / pageSize) + 1}
        </Link>
      ))}
      {links.some(n => n > from) && (
        <Link
          to={`${history.location.pathname}?${qs.stringify({
            ...query,
            from: from + pageSize,
          })}`}
        >
          <Icon src="/forward.svg" />
        </Link>
      )}
    </Wrapper>
  )
}

export default Pagination;
