import { replace } from 'connected-react-router'
import qs from 'qs'

import {
  REGISTER,
  AUTHENTICATE_WITH_GOOGLE,
  LOG_OUT,
  LOG_IN,
  LOG_IN_WITH_TOKEN,
  CHECK_AUTH_STATUS,
  FETCH_COMPANY,
  ADD_COMPANY_TO_FAVORITES,
  REMOVE_COMPANY_FROM_FAVORITES,
  FETCH_CONTENT,
  PUBLISH_COMPANY_ADVERTISEMENT,
  UPDATE_COMPANY_ADVERTISEMENT,
  FETCH_COMPANY_ADVERTISEMENT,
  FETCH_SUGGESTIONS,
  OPEN_MENU,
  CLOSE_MENU,
  UPDATE_USER_SETTINGS,
  FETCH_USER_SETTINGS,
  SET_REVENUE_GROWTH_RATE_OVERRIDE,
  SET_COST_OVER_REVENUE_OVERRIDE,
  FETCH_ADVERTISEMENT,
  ADD_ADVERTISEMENT_TO_FAVORITES,
  REMOVE_ADVERTISEMENT_FROM_FAVORITES,
  ASK_FOR_ACCESS,
  REQUEST_QUOTE,
  BUY_SERVICE,
  INVITE_HELPER,
  CONTACT_NOBEMA,
  CONTACT_SELLER,
  FETCH_COMPANY_IS_EDITABLE,
  FETCH_COMPANY_IS_PUBLISHABLE,
  PROSPECT_GENERATOR_UPDATE,
  PROSPECT_GENERATOR_SEED,
  PROSPECT_GENERATOR_CLEAR,
  BUY_REPRESENTATION,
  FETCH_PROSPECT_READ_REQUESTS,
  FETCH_PENDING_NDAS,
  FETCH_COMPANY_PROSPECT,
  FETCH_BOUGHT_PRODUCTS,
  FETCH_COMPANY_ADVERTISEMENT_ANONYMIZED,
  UPDATE_COMPANY_ADVERTISEMENT_ANONYMIZED,
  RESET_COMPANY_ADVERTISEMENT_ANONYMIZED,
  UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE,
  FETCH_MARKETING,
  UPDATE_TERMS_STATUS,
} from 'actionTypes'

export const register = ({ email, password }) => ({
  type: REGISTER,
  email,
  password,
})

export const authenticateWithGoogle = () => ({
  type: AUTHENTICATE_WITH_GOOGLE,
})

export const logOut = () => ({
  type: LOG_OUT,
})

export const logIn = ({ email, password }) => ({
  type: LOG_IN,
  email,
  password,
})

export const logInWithToken = ({ token }) => ({
  type: LOG_IN_WITH_TOKEN,
  token,
})

export const checkAuthStatus = () => ({
  type: CHECK_AUTH_STATUS,
})

export const fetchCompany = companyId => ({
  type: FETCH_COMPANY,
  companyId,
})

export const fetchCompanyProspect = companyId => ({
  type: FETCH_COMPANY_PROSPECT,
  companyId,
})

export const fetchProspectRequests = () => ({
  type: FETCH_PROSPECT_READ_REQUESTS,
})

export const setSort = (sort, query) =>
  replace({ search: qs.stringify({ ...query, sort }) })

export const setSearchFilter = (filter, query) =>
  replace({ search: qs.stringify({ ...query, filter }) })

export const setSearchQuery = q => replace({ search: qs.stringify({ q }) }) // Reset all filters when setting search query

export const addCompanyToFavorites = companyId => ({
  type: ADD_COMPANY_TO_FAVORITES,
  companyId,
})

export const removeCompanyFromFavorites = companyId => ({
  type: REMOVE_COMPANY_FROM_FAVORITES,
  companyId,
})

export const fetchContent = contentType => ({
  type: FETCH_CONTENT,
  contentType,
})

export const publishCompanyAdvertisement = (companyId, annonseData) => ({
  type: PUBLISH_COMPANY_ADVERTISEMENT,
  companyId,
  annonseData,
})

export const updateCompanyAdvertisement = (companyId, payload) => ({
  type: UPDATE_COMPANY_ADVERTISEMENT,
  companyId,
  payload,
})

export const fetchCompanyAdvertisement = companyId => ({
  type: FETCH_COMPANY_ADVERTISEMENT,
  companyId,
})

export const fetchSuggestions = query => ({
  type: FETCH_SUGGESTIONS,
  query,
})

export const openMenu = () => ({
  type: OPEN_MENU,
})

export const closeMenu = () => ({
  type: CLOSE_MENU,
})

export const updateUserSettings = settings => ({
  type: UPDATE_USER_SETTINGS,
  settings,
})

export const fetchUserSettings = () => ({
  type: FETCH_USER_SETTINGS,
})

export const setRevenueGrowthRateOverride = (id, value) => ({
  type: SET_REVENUE_GROWTH_RATE_OVERRIDE,
  id,
  value,
})

export const setCostOverRevenueOverride = (id, value) => ({
  type: SET_COST_OVER_REVENUE_OVERRIDE,
  id,
  value,
})

export const fetchAdvertisement = advertisementId => ({
  type: FETCH_ADVERTISEMENT,
  advertisementId,
})

export const addAdvertisementToFavorites = advertisementId => ({
  type: ADD_ADVERTISEMENT_TO_FAVORITES,
  advertisementId,
})

export const removeAdvertisementFromFavorites = advertisementId => ({
  type: REMOVE_ADVERTISEMENT_FROM_FAVORITES,
  advertisementId,
})

export const askForAccess = (advertisementId, data) => ({
  type: ASK_FOR_ACCESS,
  advertisementId,
  data,
})

export const requestQuote = form => ({
  type: REQUEST_QUOTE,
  form,
})

export const buyService = data => ({
  type: BUY_SERVICE,
  data,
})

export const buyRepresentation = data => ({
  type: BUY_REPRESENTATION,
  data,
})

export const inviteHelper = data => ({
  type: INVITE_HELPER,
  data,
})

export const contactNobema = data => ({
  type: CONTACT_NOBEMA,
  data,
})

export const contactSeller = data => ({
  type: CONTACT_SELLER,
  data,
})

export const fetchCompanyIsEditable = companyId => ({
  type: FETCH_COMPANY_IS_EDITABLE,
  companyId,
})

export const fetchCompanyIsPublishable = companyId => ({
  type: FETCH_COMPANY_IS_PUBLISHABLE,
  companyId,
})

export const prospectGeneratorUpdate = update => ({
  type: PROSPECT_GENERATOR_UPDATE,
  update: update,
})

export const prospectGeneratorSeed = update => ({
  type: PROSPECT_GENERATOR_SEED,
  update: update,
})

export const clearProspectGenerator = update => ({
  type: PROSPECT_GENERATOR_CLEAR,
  update: update,
})

export const fetchPendingNDAS = () => ({
  type: FETCH_PENDING_NDAS,
})

export const fetchBoughtProducts = () => ({ type: FETCH_BOUGHT_PRODUCTS })

export const fetchCompanyAdvertisementAnonymizeToggle = companyId => ({
  type: FETCH_COMPANY_ADVERTISEMENT_ANONYMIZED,
  companyId,
})

export const updateCompanyAdvertisementAnonymizeToggle = (
  companyId,
  anonymized
) => ({
  type: UPDATE_COMPANY_ADVERTISEMENT_ANONYMIZED,
  companyId,
  anonymized,
})

export const resetCompanyAdvertisementAnonymizeToggle = companyId => ({
  type: RESET_COMPANY_ADVERTISEMENT_ANONYMIZED,
  companyId,
})

export const updateTermsAndService = status => ({
  type: UPDATE_TERMS_STATUS,
  status,
})

export const updateUserSettingsAndTermsOfService = (settings, status) => ({
  type: UPDATE_USER_SETTINGS_AND_TERMS_OF_SERVICE,
  settings: settings,
  status: status,
})

export const fetchMarketing = () => ({
  type: FETCH_MARKETING,
})
