const firstPos = ` 2 / span 3`
const secondPos = `5 / span 3`
const spanAll = `2 / span 6`

const hintText = 'Se video med tips og råd om hvordan du bruker denne fanen.'

const prospectRibbonForm = {
  start: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo0',
      warning: '',
      entries: [
        {
          key: 'nobemaProspectVideo',
          field: 'video',
        },
      ],
    },
    {
      formLabel: 'INTRODUKSJON',
      entries: [
        {
          key: 'startHeader',
          label:
            'Et vellykket salg av en bedrift eller bedriftsandeler er avhengig av at kjøper får dekket sitt informasjonsbehov.',
          field: 'Information',
          content: ``,
          position: spanAll,
          useLarge: true,
        },
        {
          key: 'infoCollect',
          field: 'Information',
          label: 'Samle og organisere informasjon',
          content: `I dette verktøyet samler og organisere du relevante opplysninger om ditt foretak.
          Offentlig tilgjengelig selskapsinformasjon og regnskap er
          allerede lagt inn av oss. Øvrig relevant informasjon og dokumentasjon
          legges inn av deg`,
          position: spanAll,
          useLarge: true,
        },
        {
          key: 'shareInfo',
          field: 'Information',
          label: 'Informasjonsdeling med full kontroll',
          content: `Informasjonen som her registreres vises i salgsannonse og salgsprospekt. \n Du publiserer og avpubliserer annonsen, og velger om annonsen skal være åpen eller anonymisert. Lesetilgang til komplett salgsprospekt åpnes for interessenter kun etter identifisering med BankID og etter godkjennelse fra deg.`,
          position: spanAll,
          useLarge: true,
        },
        {
          key: 'helpBullets',
          label: 'Veiledning',
          field: 'list',
          listItems: [
            'All informasjon og dokumenter som legges inn vises i salgsprospektet.',
            'Den delen av informasjonen som også vises i annonse er merket med gult.',
            'Hver informasjonsfane inneholder en egen hjelpevideo med tips og råd.',
            'Har du spørsmål til bruk av systemet kan du alltid sende oss en melding via den grønne chatboblen nede i høyre hjørne.',
            'Har du behov for personlig bistand knyttet til innhold i annonse og salgsprospekt, samt gjennomføring av forhandlinger, kontraktutarbeidelse og transaksjon, kan dette kjøpes via Nobemas samarbeidspartnere. Du kan også benytte egne rådgiver.',
          ],
          position: spanAll,
        },
      ],
    },
  ],
  projectInformation: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo0',
      warning: '',
      entries: [],
    },
    {
      formLabel: 'PROSJEKTINFORMASJON',
      entries: [
        {
          key: 'logoUpload',
          field: 'Hero',
          label: 'Bedriftens logo',
          position: spanAll,
          path: 'public',
          //helptext: 'Bildet burde være minst 1035px bredt',
          required: true,
        },
        {
          key: 'headerImage',
          field: 'Hero',
          label: 'Annonsebilde',
          position: spanAll,
          path: 'public',
          required: true,
        },
        {
          key: 'advertisementCardLabel',
          label: 'Annonsetype',
          field: 'RadioButtons',
          horizontal: true,
          options: [
            'Til salgs',
            'Vurderes solgt',
            'Medeier / partner søkes',
            'Investeringsmulighet',
            'Solgt',
          ].map(label => ({ label, value: label })),
          position: spanAll,
          required: true,
        },
        {
          key: 'prospectTitle',
          label: 'Annonsetittel',
          field: 'Input',
          placeholder:
            'Her skriver du en interessevekkende tittel til salgsprospekt og annonse',
          position: spanAll,
          required: true,
        },
        {
          key: 'shareForSale',
          label: 'Andel til salgs',
          field: 'Input',
          placeholder: 'Fritekst for eks. 100%, 50%, Fra 20%-40%',
          position: spanAll,
          required: true,
        },

        {
          key: 'userValueEstimate',
          label: 'Prisantydning',
          field: 'Input',
          placeholder: 'Kun tall',
          position: firstPos,
          type: 'number',
          required: true,
        },
        {
          key: 'userValueInformation',
          label: 'Verdivurdering av foretak',
          field: 'Information',
          content: ``,
          link: 'http://www.nobema.no/verdivurdering',
          linktext: 'Få hjelp til verdsetting av bedriften din',
          position: secondPos,
        },
        {
          key: 'userValueEstimateComment',
          label: 'Kommentar til prisantydning',
          field: 'Textarea',
          placeholder: 'Eventuell kommentar til prisantydningen',
          position: spanAll,
        },
        {
          key: 'priceFiles',
          field: 'document',
          label: 'Last opp verdivurdering',
          header: '',
          path: 'prospect',
          position: spanAll,
        },
        {
          key: 'salesReason',
          label: 'Salgsårsak',
          field: 'Select',
          position: spanAll,
          options: [
            { value: 'Strategisk', label: 'Strategisk' },
            { value: 'Endret prioritet', label: 'Endret prioritet' },
            { value: 'Pensjon', label: 'Pensjon' },
            { value: 'Annet', label: 'Annet' },
          ],
        },
        {
          key: 'companyWebsite',
          field: 'Input',
          label: 'Link til bedriftens nettside',
          placeholder:
            'Linken til foretakets nettsted, eks: https://www.nobema.no',
          position: spanAll,
        },
        {
          key: 'projectNumber',
          field: 'Input',
          label: 'Prosjektnummer',
          position: spanAll,
          disabled: true,
        },
        {
          key: 'projectLicence',
          field: 'Input',
          label: 'Prosjektlisens',
          position: spanAll,
          disabled: true,
        },
      ],
    },
    {
      formLabel: 'Kontaktinformasjon',
      entries: [
        {
          key: 'showSellerInAd',
          label: 'Hvem er kontaktperson for dette prosjektet?',
          position: spanAll,
          field: 'SelectiveContent',
          required: true,
          options: [
            {
              label: 'Bedriftseier / Rolle- eller fullmaktshaver',
              value: false,
            },
            { label: 'Bedriftens representant / rådgiver', value: true },
          ],
          selectiveContentFalse: [
            {
              key: 'prospectOwnerInfo',
              field: 'Information',
              label: 'Prosjekteiers kontaktinformasjon',
              content: `Kontaktinformasjonen under vises i salgsprospektet og åpen annonse, men vises ikke på anonym annonse`,
              position: spanAll,
              useLarge: true,
            },
            {
              key: 'ownerFirstName',
              label: 'Fornavn',
              field: 'Input',
              placeholder: 'Kontaktens fornavn',
              //disabled: true,
              position: firstPos,
            },
            {
              key: 'ownerLastName',
              label: 'Etternavn',
              field: 'Input',
              placeholder: 'Kontaktens etternavn',
              //disabled: true,
              position: secondPos,
            },
            {
              key: 'ownerEmail',
              label: 'E-post',
              field: 'Input',
              position: firstPos,
              //disabled: true,
              placeholder: 'Kontaktens Epost',
            },
            {
              key: 'ownerPhone',
              label: 'Telefonnummer',
              field: 'Input',
              position: secondPos,
              //disabled: true,
              placeholder: 'Kontaktens telefonnummer',
            },
          ],
          selectiveContentTrue: [
            {
              key: 'contactPersonInfo',
              field: 'Information',
              label: 'Representantens kontaktinformasjon',
              content: `Informasjonen som registreres under vises på salgsprospekt, åpne og anonyme annonser`,
              position: spanAll,
              useLarge: true,
            },
            {
              key: 'contactLogo',
              label: 'Representantens/rådgivers logo',
              position: spanAll,
              path: 'public',
              helptext: 'Logo bør ikke være bredere enn 120px',
              field: 'Hero',
            },
            {
              key: 'contactCompany',
              label: 'Representantens firmanavn',
              field: 'Input',
              placeholder: 'Eks. Nobema AS',
              position: firstPos,
            },
            {
              key: 'contactTitle',
              label: 'Tittel',
              field: 'Input',
              position: secondPos,
            },
            {
              key: 'contactFirstName',
              label: 'Fornavn',
              field: 'Input',
              position: firstPos,
            },
            {
              key: 'contactLastName',
              label: 'Etternavn',
              field: 'Input',
              position: secondPos,
            },
            {
              key: 'contactEmail',
              label: 'E-post',
              field: 'Input',
              position: firstPos,
            },
            {
              key: 'contactPhone',
              label: 'Telefonnummer',
              field: 'Input',
              position: secondPos,
            },
            {
              key: 'contactWebsite',
              label: 'Representantens hjemmeside',
              field: 'Input',
              position: spanAll,
              placeholder: 'Eks. nobema.no',
            },
          ],
        },
      ],
    },
  ],
  companyInformation: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo1',
      entries: [],
    },
    {
      formLabel: 'SELSKAPSINFORMASJON',
      entries: [],
    },
    {
      formLabel: 'Nøkkelopplysninger',
      entries: [
        {
          key: 'brregInfo',
          field: 'Brreg',
          label: 'Data fra Enhetsregisteret',
          content: `Selskapsinformasjon som ikke legges til automatisk i systemet kan legges til manuelt. Nøkkelopplysninger for ditt foretak finner du i Enhetsregisteret.`,
          position: spanAll,
        },
        {
          key: 'orgnr',
          label: 'Organisasjonsnummer',
          field: 'Input',
          placeholder: 'Kun tall',
          position: firstPos,
          required: true,
          type: 'number',
        },
        {
          key: 'name',
          label: 'Foretaksnavn',
          field: 'Input',
          placeholder: 'Foretaksnavn',
          position: secondPos,
          required: true,
        },
        {
          key: 'SelskFormKode',
          label: 'Organisasjonsform',
          field: 'Input',
          disabled: true,
          position: spanAll,
        },
        {
          key: 'address',
          label: 'Adresse',
          field: 'Input',
          placeholder: 'Tekst',
          position: spanAll,
          required: true,
        },
        {
          key: 'postnumber',
          label: 'Postnummer',
          field: 'Input',
          placeholder: 'Kun tall',
          position: firstPos,
          required: true,
          type: 'number',
        },
        {
          key: 'postplace',
          label: 'Poststed',
          field: 'Input',
          placeholder: 'Tekst',
          position: secondPos,
          required: true,
        },
        {
          key: 'municipality',
          label: 'Kommune',
          field: 'Input',
          placeholder: 'Tekst',
          position: firstPos,
          required: true,
        },
        {
          key: 'fylke',
          label: 'Fylke',
          field: 'Input',
          placeholder: 'Tekst',
          position: secondPos,
          required: true,
        },
        {
          key: 'country',
          label: 'Land',
          field: 'Input',
          placeholder: 'Tekst',
          position: spanAll,
        },
        {
          key: 'registreringsdatoEnhetsregisteret',
          label: 'Registrert i enhetsregisteret',
          field: 'Input',
          placeholder: 'YYYY-MM-DD',
          position: firstPos,
        },
        {
          key: 'founded',
          label: 'Stiftelsesdato',
          field: 'Input',
          placeholder: 'YYYY-MM-DD',
          position: secondPos,
        },
        {
          key: 'dagligLeder',
          label: 'Daglig Leder / adm. direktør',
          field: 'Input',
          placeholder: 'Navn',
          position: firstPos,
        },
        {
          key: 'employeeBoolean',
          label: 'Har foretaket ansatte?',
          field: 'RadioButtonsEmployees',
          options: [
            { value: true, label: 'Ja' },
            { value: false, label: 'Nei' },
          ],
          position: secondPos,
          required: true,
        },
        {
          key: 'Formaal',
          label: 'Vedtektfestet formål',
          field: 'Textarea',
          placeholder: `Her skriver du inn foretakets vedtektfestet formål. Opplysninger om foretakets registrerte vedtektfestet formål finner du på Enhetsregisteret –Se link på toppen av denne fanen`,
          position: spanAll,
        },
        {
          key: 'industryCode',
          label: 'Bransje',
          field: 'Input',
          placeholder: 'Bransjekode og bransjenavn',
          position: spanAll,
          required: true,
        },
        {
          key: 'sektor',
          label: 'Sektor',
          field: 'Input',
          placeholder: 'Sektorkode og sektornavn',
          position: spanAll,
        },
        {
          key: 'companyDetails',
          label: 'Særlige Opplysninger',
          field: 'Textarea',
          placeholder: `Her skriver du inn særlige opplysninger om foretaket registrert på Enhetsregisteret –Se link på toppen av denne fanen.`,
          position: spanAll,
        },
      ],
    },
    {
      formLabel: 'Styre',
      entries: [
        {
          key: 'boardLeader',
          label: 'Styrets Leder',
          field: 'Input',
          placeholder: 'Navn',
          position: spanAll,
        },
        {
          key: 'boardMembers',
          label: 'Styremedlem',
          field: 'inputlist',
          buttonLabel: 'styremedlem',
          inputs: {
            name: { label: 'Navn', placeholder: 'Navn' },
          },
          placeholder: 'Navnene til foretakets styremedlemmer',
          position: spanAll,
        },
        {
          key: 'secondLeader',
          label: 'Varamedlem',
          field: 'inputlist',
          inputs: {
            name: { label: 'Navn', placeholder: 'Navn' },
          },
          buttonLabel: 'varamedlem',
          placeholder: 'Navnene til foretakets varamedlemmer til styre',
          position: spanAll,
        },
      ],
    },
    {
      formLabel: 'Signatur og prokura',
      entries: [
        {
          key: 'signedOff',
          label: 'Signatur',
          field: 'Textarea',
          placeholder:
            'Navnene og/eller rollene til de som har signaturrett i foretaket og om signaturretten er alene eller i fellesskap.',
          position: firstPos,
        },
        {
          key: 'prokura',
          label: 'Prokura',
          field: 'Textarea',
          placeholder:
            'Navnene og/eller rollene til de som har prokura i foretaket',
          position: secondPos,
        },
      ],
    },
    {
      formLabel: 'Eiere',
      entries: [
        {
          key: 'stockHolders',
          label: 'Virksomhetens aksjonær(er)',
          field: 'inputlist',
          inputs: {
            name: { label: 'Navn', placeholder: 'Navn' },
            amount: { label: 'andel', placeholder: 'Eierandel' },
          },
          buttonLabel: 'aksjonær',
          placeholder: '',
          position: spanAll,
        },
        {
          key: 'stockHolderFiles',
          field: 'document',
          label: 'Last opp aksjebok og aksjonæravtale',
          header: '',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
    {
      formLabel: 'Om Bedriften',
      entries: [
        {
          key: 'companyIngress',
          label: 'Ingress',
          field: 'Textarea',
          placeholder: 'Innledende informasjon som beskriver ditt foretak.',
          position: spanAll,
          required: true,
        },

        {
          key: 'companyDescriptionBody',
          field: 'Textarea',
          label: 'Brødtekst',
          placeholder: 'Utfyllende informasjon som beskriver ditt foretak.',
          position: spanAll,
        },
      ],
    },
    {
      formLabel: 'Eiendom',
      entries: [
        {
          key: 'propertyOwnedBoolean',
          label: 'Eier foretaket eiendom?',
          field: 'RadioButtons',
          horizontal: true,
          options: [
            { value: true, label: 'Ja' },
            { value: false, label: 'Nei' },
          ],
          position: firstPos,
          required: true,
        },
        {
          key: 'propertyRentedBoolean',
          label: 'Leier foretaket eiendom?',
          field: 'RadioButtons',
          horizontal: true,
          options: [
            { value: true, label: 'Ja' },
            { value: false, label: 'Nei' },
          ],
          position: secondPos,
          required: true,
        },
      ],
    },
    {
      formLabel: 'Livsfase og Struktur',
      entries: [
        {
          key: 'startupBoolean',
          label: 'Er selskapet en oppstartsbedrift?',
          field: 'RadioButtonsStartup',
          options: [
            { value: true, label: 'Ja' },
            { value: false, label: 'Nei' },
          ],
          position: firstPos,
          required: true,
        },
        {
          key: 'companyLifeCycle',
          label: 'Selskapets livsfase',
          field: 'Input',
          placeholder: 'Ungt selskap',
          position: secondPos,
          disabled: true,
        },
        {
          key: 'companyStructure',
          label: 'Konsernstruktur',
          field: 'Textarea',
          placeholder:
            'Kort beskrivelse av eventuell konsernstruktur og foretakets nåværende plassering i denne strukturen (morselskap, datterselskap, eierskap i andre foretak etc.)',
          position: firstPos,
        },
        {
          key: 'departmentStructure',
          label: 'Avdelingsstruktur:',
          field: 'Textarea',
          placeholder:
            'Kort beskrivelse av foretakets avdelinger og hvordan disse er organisert.',
          position: secondPos,
        },
      ],
    },
    {
      formLabel: 'Ytterligere Selskapsinformasjon',
      entries: [
        {
          key: 'companyInformationComment',
          label: '',
          field: 'Textarea',
          placeholder: 'Eventuell tilleggsinformasjon.',
          position: spanAll,
        },
        {
          key: 'companyInformation_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  products: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo2',
      entries: [],
    },
    {
      formLabel: 'PRODUKTER OG TJENESTER',
      entries: [
        {
          key: 'products',
          position: spanAll,
          field: 'products',
        },
        {
          key: 'products_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  markets: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo3',
      entries: [],
    },
    {
      formLabel: 'MARKED',
      entries: [
        {
          key: 'companyCategory',
          label: 'Markedsområde',
          field: 'Checkbox',
          horizontal: true,
          position: spanAll,
          options: [
            { value: 'Lokalt marked', label: 'Lokalt marked' },
            { value: 'Regionalt marked', label: 'Regionalt marked' },
            { value: 'Nasjonalt marked', label: 'Nasjonalt marked' },
            { value: 'Internasjonalt marked', label: 'Internasjonalt marked' },
          ],
        },
        {
          key: 'companyCategoryDescription',
          label: 'Beskrivelse av markedsområde',
          field: 'Textarea',
          position: spanAll,
          placeholder: 'Stedsnavn, regionnavn, land, verdensdel etc',
        },
      ],
    },
    {
      formLabel: 'Kunder',
      entries: [
        {
          key: 'customerTypes',
          label: 'Kundetyper',
          field: 'Checkbox',
          position: spanAll,
          other: 'Andre kunder',
          options: [
            { value: 'Kommune', label: 'Kommune' },
            { value: 'Fylkeskommune', label: 'Fylkeskommune' },
            { value: 'Staten', label: 'Staten' },
            { value: 'Offentlige foretak', label: 'Offentlige foretak' },
            { value: 'Privatpersoner', label: 'Privatpersoner' },
            { value: 'Bedrifter', label: 'Bedrifter' },
            {
              value: 'Lag/foreninger/sameier og borettslag',
              label: 'Lag/foreninger/sameier og borettslag',
            },
          ],
        },
        {
          key: 'customerInformation',
          label: 'Kundetilknytning',
          field: 'Textarea',
          position: spanAll,
          placeholder:
            'En kort beskrivelse av kundenes generelle tilknytning til ditt foretak. Som for eks. om kundelojalitet, enkeltstående eller tilbakevendende handel, emosjonell eller kalkulert tilknytning, fag eller personorientert tilknytning etc.',
        },
      ],
    },
    {
      formLabel: 'Kunde- og markedsinformasjon',
      entries: [
        {
          key: 'geographicArea',
          label: 'Geografisk markedsområde',
          field: 'Textarea',
          position: firstPos,
          placeholder:
            'Beskrivelse av de konkrete markedsområdene foretaket opererer i, som for eks. navn på sted, by, kommune, region, fylke, landsdel, land osv',
        },
        {
          key: 'marketPotential',
          label: 'Markedsgrunnlag og potensiale',
          field: 'Textarea',
          position: secondPos,
          placeholder:
            'Antall kunder i de aktuelle målgruppene og beskrivelse av forventet utvikling',
        },
        {
          key: 'demography',
          label: 'Demografi',
          field: 'Textarea',
          position: spanAll,
          placeholder:
            'Relevant demografisk karakteristika for selskapets kunde-og målgruppe . Som for eksempel alders-og kjønnsfordeling, utdannelse, inntekts-og formuesnivå, boligtype etc',
        },
      ],
    },
    {
      formLabel: 'Konkurrenter',
      entries: [
        {
          key: 'competitors',
          label: 'Konkurrenter',
          field: 'Textarea',
          position: spanAll,
          placeholder:
            'Her kan du gi en kort beskrivelse av virksomhetens konkurrenter og beskrive hvordan konkurransebildet påvirker drift og utvikling av ditt foretak.',
        },
      ],
    },
    {
      formLabel: 'Ytterligere informasjon om marked',
      entries: [
        {
          key: 'marketComment',
          field: 'Textarea',
          placeholder: 'Eventuell tilleggsinformasjon',
          position: spanAll,
        },
        {
          key: 'markets_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  employees: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo4',
      entries: [],
    },
    {
      formLabel: 'PERSONAL',
      entries: [
        {
          key: 'employees',
          label: 'Antall ansatte',
          field: 'Input',
          placeholder: 'Kun tall',
          position: firstPos,
        },
        {
          key: 'eployeeOverSixtyAmount',
          label: 'Hvorav ansatte over 60 år',
          field: 'Input',
          placeholder: 'Kun tall',
          position: secondPos,
        },
        {
          key: 'manYears',
          label: 'Antall årsverk',
          field: 'Input',
          placeholder: 'Kun tall',
          position: spanAll,
        },
        {
          key: 'dagligLeder',
          label: 'Daglig leder/ adm.direktør',
          field: 'Input',
          placeholder: 'Navn',
          position: spanAll,
        },
      ],
    },
    {
      formLabel: 'Lønnskostnader',
      entries: [
        {
          key: 'totalSalaryExpenditures',
          label: 'Totale lønnskostnader',
          field: 'Input',
          placeholder: 'Kun tall',
          position: spanAll,
        },
        {
          key: 'salary_div_turnover',
          label: 'Lønnskostnader i% av total omsetning',
          field: 'Input',
          placeholder: '%',
          position: firstPos,
        },
        {
          key: 'salary_div_ordResult',
          label: 'Lønnskostnader i% av totale kostnader',
          field: 'Input',
          placeholder: '%',
          position: secondPos,
        },
      ],
    },
    {
      formLabel: 'Pensjon',
      entries: [
        {
          key: 'pension',
          label: 'Pensjon',
          field: 'Checkbox',
          position: firstPos,
          options: [
            { value: 'Ytelsesbasert', label: 'Ytelsesbasert' },
            { value: 'Innskuddsbasert', label: 'Innskuddsbasert' },
            { value: 'Annet', label: 'Annet' },
            { value: 'Ikke aktuelt', label: 'Ikke aktuelt' },
          ],
        },
        {
          key: 'extraPersonalInfo',
          label: 'Særskilte pensjonsopplysninger',
          field: 'Textarea',
          placeholder:
            'Særskilte pensjonsopplysninger som for eks. valgt prosentsats av lønn, regler og kriterier for oppnådd ytelse etc.',
          position: spanAll,
        },
      ],
    },
    {
      formLabel: 'Ytterligere informasjon om personal',
      entries: [
        {
          key: 'employeeComment',
          field: 'Textarea',
          placeholder: 'Eventuell tilleggsinformasjon',
          position: spanAll,
        },
        {
          key: 'employees_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  agreements: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo5',
      entries: [],
    },
    {
      formLabel: 'AVTALER, RETTIGHETER OG FORPLIKTELSER',
      entries: [
        {
          key: 'agreement',
          field: 'agreement',
          position: spanAll,
        },
      ],
    },
    {
      formLabel: 'Garantier',
      entries: [
        {
          key: 'creditGuarantee',
          optionKey: 'creditGuarantee_check',
          label:
            'Foretaket har mottatt garanti fra andre (Garanti kreditor). Ja: ',
          field: 'optionalCheckbox',
          placeholder:
            'Ytterligere informasjon, kommentarer eller beskrivelser av mottatte garantier',
          position: spanAll,
          optional: true,
          options: [
            'Byggsikkerhet – Norsk Standard',
            'Utførelsesgaranti',
            'Reklamasjonstidsgaranti',
            'Skattetrekksgaranti',
            'Forskuddsgaranti',
            'Leiegaranti',
            'Betalingsgaranti',
          ].map(value => ({ value, label: value })),
        },

        {
          key: 'debitGuarantee',
          optionKey: 'debitGuarantee_check',
          label:
            'Foretaket har stilt garanti ovenfor andre (Garanti debitor). Ja: ',
          field: 'optionalCheckbox',
          position: spanAll,
          optional: true,
          placeholder: `Ytterligere informasjon, kommentarer eller beskrivelser av avgitte garantier`,
          options: [
            'Byggsikkerhet – Norsk Standard',
            'Byggsikkerhet – etter Bustadoppføringslova',
            'Utførelsesgaranti',
            'Reklamasjonstidsgaranti',
            'Skattetrekksgaranti',
            'Reisegaranti',
            'Anbudsgaranti',
            'Forskuddsgaranti',
            'Betalingsgaranti',
            'Leiegaranti',
            'Løyvegaranti',
            'Tollkredittgaranti',
          ].map(value => ({ value, label: value })),
        },
      ],
    },
    {
      formLabel: 'Ytterligere informasjon om Avtaler',
      entries: [
        {
          key: 'agreement_Comment',
          field: 'Textarea',
          placeholder: 'Eventuell tilleggsinformasjon',
          position: spanAll,
        },
        {
          key: 'agreements_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  properties: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo6',
      entries: [],
    },
    {
      formLabel: 'EIENDOM OG LOKALER',
      entries: [
        {
          key: 'property',
          field: 'property',
          position: spanAll,
        },
        {
          key: 'properties_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  assets: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo7',
      entries: [],
    },
    {
      formLabel: 'EIENDELER',
      entries: [
        {
          key: 'assets',
          field: 'assets',
          position: spanAll,
        },
        {
          key: 'assets_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  leases: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo8',
      entries: [],
    },
    {
      formLabel: 'LEIE- OG LEASINGAVTALER',
      entries: [
        {
          key: 'leasing',
          position: spanAll,
          field: 'leasing',
        },
        {
          key: 'leases_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  finances: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo9',
      entries: [],
    },
    {
      formLabel: 'FINANS OG FORSIKRING',
      entries: [
        {
          key: 'finance',
          position: spanAll,
          field: 'finance',
        },
        {
          key: 'finances_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  marketing: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo10',
      entries: [],
    },
    {
      formLabel: 'MARKEDSFØRING, SALG OG DISTRIBUSJON',
      entries: [
        {
          key: 'marketingMethods',
          field: 'Checkbox',
          label: 'Markedsføringsmetoder',
          position: spanAll,
          options: [
            'Postbasert reklame',
            'Annonsering i tidsskrifter og aviser',
            'Annonsering på boards og offentlig kommunikasjon',
            'Annonsering på nettaviser og nettsteder',
            'Annonsering via spill og apper',
            'Annonsering på sosiale medier',
            'Annonsering på YouTube og sosiale videokanaler',
            'Kjøpt plassering på søkemotorer',
            'Markedsføring via egne kanaler på sosiale medier',
            'Markedsføring via egne nettsider og blogg',
            'Radioreklame',
            'TV-reklame',
            'Kino-reklame',
            'Nettverksmarkedsføring',
            'Markedsføring via messer og arrangementer',
            'Innholdsmarkedsføring (content)',
          ].map(value => ({ value, label: value })),
          other: 'Annen markedsføring og PR',
        },
        {
          key: 'salesMethods',
          label: 'Salgsmetoder',
          field: 'Checkbox',
          position: spanAll,
          options: [
            'Salg via egen nettbutikk/nettside',
            'Salg via fysisk butikk',
            'Direktesalg via egne selgere',
            'Direktesalg via agenter',
            'Salg via eksterne forhandlere',
            'Nettverkssalg',
            'Salg via messer og arrangementer',
          ].map(value => ({ value, label: value })),
          other: 'Annen salgsmetode',
        },
        {
          key: 'distributionMethods',
          label: 'Distribusjonsmetoder',
          field: 'Checkbox',
          position: spanAll,
          options: [
            'Distribusjon fra eget lager',
            'Distribusjon fra eksternt lager',
            'Distribusjon via egen transportør',
            'Distribusjon via ekstern transportør',
            'Distribusjon via lokale budtjenester',
            'Digital distribusjon',
          ].map(value => ({ value, label: value })),
          other: 'Annen distribusjonsmetode',
        },
      ],
    },
    {
      formLabel:
        'Ytterligere informasjon om Markedsføring, Salg og Distribusjon',
      entries: [
        {
          key: 'marketingComment',
          field: 'Textarea',
          placeholder: 'Eventuell tilleggsinformasjon',
          position: spanAll,
        },
        {
          key: 'marketing_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  economy: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo11',
      entries: [],
    },
    {
      formLabel: 'ØKONOMI',
      entries: [
        {
          key: 'economyInfo',
          field: 'Information',
          label: 'Omsetning og egenkapital',
          content: `Omsetning (sum driftsinntekter) og egenkapital som her vises, er siste registrerte regnskapstall. Tallene som under kan redigeres dersom du har nyere tall som du ønsker å vise.`,
          position: spanAll,
          useLarge: true,
        },
        {
          key: 'customEconomyYear',
          label: 'År',
          field: 'Input',
          type: 'number',
          position: spanAll,
          required: true,
        },
        {
          key: 'turnover',
          label: 'Omsetning',
          field: 'Input',
          type: 'number',
          position: firstPos,
          required: true,
          placeholder: 'Hele tall',
        },
        {
          key: 'ek',
          label: 'Egenkapital',
          field: 'Input',
          type: 'number',
          position: secondPos,
          required: true,
          placeholder: 'Hele tall',
        },
        {
          key: 'customAccountYear',
          field: 'document',
          header: 'Last opp regnskap',
          label: 'Last opp',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },

    {
      formLabel: 'Ytterligere informasjon om Økonomi',
      entries: [
        {
          key: 'economyComment',
          field: 'Textarea',
          placeholder: 'Eventuell tilleggsinformasjon',
          position: spanAll,
        },
        {
          key: 'economy_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  purchaseArguments: [
    {
      hint: hintText,
      hintLink: 'http://www.nobema.no/hjelpevideo12',
      entries: [],
    },
    {
      formLabel: 'KJØPSARGUMENTER',
      entries: [
        {
          key: 'purchaseArugmentInfo',
          label: 'Informasjon',
          field: 'Information',
          content: `Her beskriver du de sterkeste og mest interessante sidene ved din bedrift. Fokuser på
          de elementene som kan antas å ha størst verdi for potensielle kjøpere.`,
          position: spanAll,
        },
        {
          key: 'arguments',
          position: spanAll,
          field: 'arguments',
        },
        {
          key: 'purchaseArguments_Documents',
          field: 'document',
          label: 'Last opp',
          header: 'Tilleggsdokumenter denne fane:',
          path: 'prospect',
          position: spanAll,
        },
      ],
    },
  ],
  dueDilligence: [
    {
      hint: '',
      warning: '',
      entries: [],
    },
    {
      formLabel: 'DOKUMENTOVERSIKT',
      entries: [
        {
          key: 'fileOverview',
          position: spanAll,
          field: 'overview',
          path: 'prospect',
        },
      ],
    },
  ],
}

export default prospectRibbonForm
