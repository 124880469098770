import { authIsPendingSelector, isAuthenticatedSelector } from './index'

export const pendingNDASelector = state => state.pendingNDAs
export const exclusivitiesSelector = state => {
  return state.exclusivities

}
export const exclusivitiesIsPendingSelector = state => {
  if (authIsPendingSelector(state)) {
    return true
  } else if (isAuthenticatedSelector(state)) {
    return state.exclusivities.pending
  } else {
    return false
  }
}