import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fetchCompany } from 'actions'
import { companySelector, companyIsPendingSelector } from 'selectors'
import { border, colors } from 'styles'
import { capitalize } from 'utils'

import withSpinner from 'components/withSpinner'
import withFetchedData from 'components/withFetchedData'
import Heart from 'components/icons/Heart'
import Link from 'components/Link'

const Wrapper = styled(Link)`
  border-radius: 4px;
  border: ${border};
  background-color: ${colors.white};
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FavoritedCompany = ({ companyId, name }) => (
  <Wrapper to={`/companies/${companyId}`}>
    <div>{capitalize(name)}</div>
    <Heart active={true} />
  </Wrapper>
)

const mapStateToProps = (state, ownProps) => ({
  ...companySelector(state, ownProps),
})

export default compose(
  withFetchedData(props => fetchCompany(props.companyId)),
  withSpinner(companyIsPendingSelector),
  connect(mapStateToProps)
)(FavoritedCompany)
