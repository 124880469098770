import React from 'react'
import styled, { css } from 'styled-components'
import { Overline } from 'styles/text'
import { colors } from 'styles'

const EmptyImage = styled.div`
  height: 16.8rem;
  width: 100%;
  ${props =>
    css`
      border: 4px ${props.required ? colors.highlight : '#000000'} dotted;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const EmptyImagePreview = props => {
  return (
    <EmptyImage required={props.required}>
      <div>
        <img src="/camera.svg" alt="" />
        <Overline>Legg til bilde</Overline>
      </div>
    </EmptyImage>
  )
}

export default EmptyImagePreview
