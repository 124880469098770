import {
  FETCH_ACCESSIBLE_PROSPECTS,
  FETCH_ACCESSIBLE_PROSPECTS_SUCCESS,
  FETCH_ACCESSIBLE_PROSPECTS_ERROR,
  LOG_OUT,
} from 'actionTypes'

const initialState = { pending: true }

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCESSIBLE_PROSPECTS:
      return {
        ...state,
        pending: true,
      }
    case FETCH_ACCESSIBLE_PROSPECTS_SUCCESS:
      return {
        ...state,
        accessibleProspects: action.accessibleProspectRequests,
        pending: false,
      }

    case FETCH_ACCESSIBLE_PROSPECTS_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
        message: action.message,
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
