import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { body } from 'styles/text'
import { colors, contentWidth } from 'styles'

const Wrapper = styled.div`
  background-color: ${colors.footer};
  padding: 20px;
  font-size: 1.6rem;
  margin-top: 32px;
  @media (min-width: 768px) {
    padding: 40px 40px 80px;
  }
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: ${contentWidth};
  display: flex;

  @media (max-width: ${contentWidth}) {
    flex-direction: column;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;

  @media (max-width: 512px) {
    flex-direction: column;
  }
`

const Logo = styled.a`
  margin-right: auto;

  & > img {
    width: 106px;

    @media (min-width: 768px) {
      width: 256px;
    }
  }
`

const Contact = styled.div`
  color: ${colors.white};
`

const SomeImg = styled.img`
  height: 24px;
  margin: 24px 24px 0 0;
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const LinkItem = styled.a`
  ${body};
  color: ${colors.white};
  margin-right: 24px;
  margin-bottom: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${colors.white};
  }
`

const StyledLink = styled(Link)`
  margin: 0 24px 12px 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${colors.white};
  }
`

const linkGroups = [
  [
    { label: 'Om oss', link: 'http://www.nobema.no/om_oss' },
    { label: 'Kontakt Oss', link: 'http://www.nobema.no/kontakt' },
  ],
  [
    { label: 'Personvern', link: 'http://www.nobema.no/personvern' },
    { label: 'Brukervilkår', link: 'http://www.nobema.no/brukervilkaar' },
  ],
  [
    { label: 'Markedplass', link: '/', internal: true },
    { label: 'Blogg', link: 'http://www.nobema.no/blogg' },
  ],
  [
    { label: 'Priser', link: 'http://www.nobema.no/priser' },
    { label: 'Min side', link: '/profile', internal: true },
  ],
]

const Footer = () => (
  <Wrapper>
    <ContentWrapper>
      <FlexWrapper>
        <div>
          <Logo href="http://www.nobema.no">
            <img src="/logo_white.svg" alt="nobema" />
          </Logo>
          <Contact>
            <a
              href="https://www.facebook.com/nobemaas"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SomeImg src="/fb_white.svg" alt="Facebook link" />
            </a>
            <a
              href="https://www.linkedin.com/company/nobema-as/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SomeImg src={'/linkedin_white.svg'} alt="LinkedIn link" />
            </a>
          </Contact>
        </div>
      </FlexWrapper>
      <FlexWrapper>
        {linkGroups.map((group, index) => (
          <LinkGroup key={index}>
            {group.map((item, index) =>
              item.internal ? (
                <StyledLink key={`${item.label}_${index}`} to={item.link}>
                  {item.label}
                </StyledLink>
              ) : (
                <LinkItem key={`${item.label}_${index}`} href={item.link}>
                  {item.label}
                </LinkItem>
              )
            )}
          </LinkGroup>
        ))}
      </FlexWrapper>
    </ContentWrapper>
  </Wrapper>
)

export default Footer
