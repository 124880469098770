import React from 'react'
import styled from 'styled-components'
import SellersComment from 'components/SellersComment'
import File from 'components/File'
import { body, h6, overline, subtitle2 } from 'styles/text'
import { wrapper, padding } from './styles'

const Wrapper = styled.div`
  ${wrapper};
  ${padding};
`

const Heading = styled.h6`
  ${h6};
  margin: 0;
`

const Label = styled.div`
  ${overline};
  letter-spacing: 0.1rem;
  font-weight: 550;
  font-size: 16px;
  margin: 24px 0 6px 0;
`

const SubLabel = styled.div`
  ${subtitle2}
`

const ArgumentList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Argument = styled.div`
  ${body}
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const SalesArguments = props => (
  <Wrapper>
    <Heading>Kjøpsargumenter</Heading>
    <ArgumentList>
      {(props.purchaseArguments.arguments || []).map((argument, index) => (
        <li key={index}>
          <Label>{argument.name}</Label>
          <Argument>{argument.body}</Argument>
          <br />
          {argument.files && argument.files && !props.previewAdvertisement && (
            <SubLabel>Vedlegg</SubLabel>
          )}
          {!props.previewAdvertisement &&
            argument.files &&
            argument.files.map(attatchment => (
              <File
                path={'prospect'}
                name={attatchment}
                orgNumber={props.orgnr}
              />
            ))}
        </li>
      ))}

      <SellersComment {...props}>
        {props.purchaseArguments.purchaseArgumentsComment}
      </SellersComment>
    </ArgumentList>
  </Wrapper>
)

export default SalesArguments
