import {
  database,
  timestamp,
  uploadFile,
  uploadImage,
  getIdToken,
} from './firebase'

import fetch from './fetch'

export const publishCompanyAdvertisement = (orgNr, annonseData) =>
  database
    .ref(`/company-advertisements/${orgNr}`)
    .set({ ...annonseData, published: true })

const fileProperties = [
  'files',
  'chainConnectionFiles',
  'strategyFiles',
  'keyFiguresFiles',
  'economicFiguresFiles',
  'swotFiles',
  'marketingFiles',
  'sectorFiles',
  'keyInformationFiles',
  'marketFiles',
  'priceFiles',
  'companyInfoFiles',
  'creditRatingFiles',
  'aboutFiles',
  'generalInfoFiles',
]

const uploadNewFiles = data =>
  typeof data === 'object'
    ? Promise.all(
        Object.entries(data).map(([key, value]) => {
          if (fileProperties.includes(key)) {
            return Promise.all(
              value.map(file => {
                if (!file.path) {
                  return uploadFile(file).then(path => ({
                    name: file.name,
                    path,
                  }))
                } else {
                  return Promise.resolve(file)
                }
              })
            ).then(files => [
              key,
              files.reduce(
                (object, { path, name }) => ({
                  ...object,
                  [path]: name,
                }),
                {}
              ),
            ])
          } else if (Array.isArray(value)) {
            return Promise.all(
              value.map(element => uploadNewFiles(element))
            ).then(values => [key, values])
          } else {
            return Promise.resolve([key, value])
          }
        })
      ).then(entries =>
        entries.reduce(
          (object, [key, value]) => ({
            ...object,
            [key]: value,
          }),
          {}
        )
      )
    : Promise.resolve(data)

const uploadNewImages = (companyId, data) => {
  if (data.headerImageChanged) {
    return uploadImage(data.headerImage).then(headerImage => ({
      ...data,
      headerImage,
    }))
  } else {
    return Promise.resolve(data)
  }
}

export const updateCompanyAdvertisement = (companyId, data) =>
  uploadNewFiles(data)
    .then(data => uploadNewImages(companyId, data))
    .then(data =>
      database
        .ref()
        .update(
          Object.entries(data).reduce(
            (newData, [key, value]) => ({
              ...newData,
              [`/company-advertisements/${companyId}/${key}`]: value,
            }),
            {}
          )
        )
        .then(() => data)
    )

export const fetchCompanyAdvertisement = companyId =>
  database
    .ref(`/company-advertisements/${companyId}`)
    .once('value')
    .then(snapshot => snapshot.val())

export const requestAdvertisementPublish = (orgNr, annonseData) =>
  getIdToken()
    .then(idToken =>
      fetch(`/api/publish/advertisementrequest`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          annonse: annonseData,
          token: idToken,
          orgNr: orgNr,
        }),
      })
    )
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error(
          `Request error in requestAdvertisementPublish, status code: ${res.status}`
        )
      }
      return res
    })

export const deleteAdvertisement = async orgNr => {
  database.ref(`/company-advertisements/${orgNr}`).remove()
  const idToken = await getIdToken()
  return await fetch(`/api/remove/advertisement/${orgNr}?token=${idToken}`, {
    mode: 'cors',
    method: 'POST',
  })
}
