import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Button from 'components/Button'
import {
  boughtProductsSelector,
  boughtProductsIsPendingSelector,
  favoritedCompanyIdsSelector,
  favoritedCompaniesIsPendingSelector,
  favoritedAdvertisementIdsSelector,
  favoritedAdvertisementsIsPendingSelector,
  claimedCompanyIdsSelector,
  claimedCompaniesIsPendingSelector,
  prospectRequestsIsPendingSelector,
  prospectRequestsSelector,
  accessibleProspectsIsPendingSelector,
  accessibleProspectsSelector,
  exclusivitiesIsPendingSelector,
  exclusivitiesSelector,
  marketingIsPendingSelector,
  marketingSelector,
} from 'selectors'
import { fetchMarketing, fetchPendingNDAS } from '../../actions'
import { contentWidth, colors } from 'styles'
import { H2, H3, H4, H6, body } from 'styles/text'
import withSpinner from 'components/withSpinner'
import withLogin from 'components/withLogin'
import ClaimedCompany from './ClaimedCompany'
import FavoritedCompany from './FavoritedCompany'
import FavoritedAdvertisement from './FavoritedAdvertisement'
import UserSettings from './UserSettings'
import ClaimCompanyInput from './ClaimCompanyInput'
import ProspectRequest from './ProspectRequest/ProspectRequest'
import ApprovedRequest from './ProspectRequest/ApprovedRequest'
import AccessibleProspect from './AccessibleProspect'
import Agreements from './Agreements'
import LicenceCard from './LicenceCard'
import { useLocation } from 'react-router-dom'
import withFetchedData from '../../components/withFetchedData'

const Wrapper = styled.div`
  max-width: ${contentWidth};
  margin: 0 auto;
`

const Section = styled.section``

const ClaimedCompanyList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Divider = styled.hr`
  border-top: 2px solid ${colors.primary};
  margin-top: 20px;
`
const Info = styled.div`
  ${body};
`

const FavoritedCompanyList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;

  & > * {
    width: calc(33% - 10px);
    margin-bottom: 20px;
    margin-right: 10px;
  }
`

const ReadRequestList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const ButtonGroup = styled.div`
  margin: 36px 0;
  display: flex;

  & > :first-child {
    margin: 0 12px 0 0;
  }

  & > :last-child {
    margin: 0 0 0 12px;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    * {
      margin: 0 0 20px 0 !important;
    }
  }
`

const MenuButton = styled(Button)`
  flex-grow: 1;
`

const Profile = ({
  favoritedCompanyIds,
  favoritedAdvertisementIds,
  claimedCompanyIds,
  prospectRequests,
  accessibleProspects,
  pendingNDAS,
  boughtProducts,
  exclusivities,
  marketing,
}) => {
  const unboughtCompanies = claimedCompanyIds
    .filter(
      claimedCompanyId =>
        ![
          ...Object.keys(boughtProducts.full),
          ...Object.keys(boughtProducts.basic),
        ].includes(claimedCompanyId)
    )
    .map(unboughtClaimedCompany => unboughtClaimedCompany)

  const { pathname } = useLocation()
  const menuStates = ['favorites', 'sales', 'agreements']
  let tab
  if (
    pathname.split('/').length === 3 &&
    menuStates.includes(pathname.split('/')[2])
  ) {
    tab = pathname.split('/')[2]
  }

  const [activeMenu, setActiveMenu] = useState(tab || 'sales')
  const haveBoughtFull = Object.keys(boughtProducts.full).length > 0
  const haveBoughtBasic = Object.keys(boughtProducts.basic).length > 0

  return (
    <Wrapper>
      <Section>
        <H2>Min profil</H2>
        <UserSettings />
      </Section>
      <ButtonGroup>
        <MenuButton
          subtle={activeMenu !== 'favorites'}
          onClick={() => setActiveMenu('favorites')}
        >
          Mine favoritter
        </MenuButton>
        <MenuButton
          subtle={activeMenu !== 'sales'}
          onClick={() => setActiveMenu('sales')}
        >
          Mine prosjekter
        </MenuButton>
        <MenuButton
          subtle={activeMenu !== 'agreements'}
          onClick={() => setActiveMenu('agreements')}
        >
          Mine avtaler
        </MenuButton>
      </ButtonGroup>
      {activeMenu === 'favorites' && (
        <Section>
          <H2>Mine favoritter</H2>
          <Info>
            Her finner du dine lagrede annonser og bedrifter, samt{' '}
            salgsprospektene du har fått tilgang til.
          </Info>
          <Section>
            <H3>Prospekter</H3>
            {accessibleProspects &&
              accessibleProspects.accessibleProspects &&
              accessibleProspects.accessibleProspects.map(company => (
                <AccessibleProspect
                  id={company.companyId}
                  key={company.companyId}
                  name={company.companyName}
                />
              ))}
          </Section>
          <H3>Annonser</H3>
          <FavoritedCompanyList>
            {favoritedAdvertisementIds.map(advertisementId => (
              <li key={advertisementId}>
                <FavoritedAdvertisement advertisementId={advertisementId} />
              </li>
            ))}
          </FavoritedCompanyList>
          <H3>Bedrifter</H3>
          <FavoritedCompanyList>
            {favoritedCompanyIds.map(companyId => (
              <li key={companyId}>
                <FavoritedCompany companyId={companyId} />
              </li>
            ))}
          </FavoritedCompanyList>
        </Section>
      )}

      {activeMenu === 'sales' && (
        <>
          <Section>
            <H2>Mine salgsprosjekter</H2>
            <H3>Bedrifter</H3>
            <ClaimedCompanyList>
              {claimedCompanyIds.map(companyId => (
                <li key={companyId}>
                  <ClaimedCompany
                    companyId={companyId}
                    unboughtCompanies={unboughtCompanies}
                  />
                </li>
              ))}
            </ClaimedCompanyList>
            <Divider />
            <ClaimCompanyInput />
            <Info>
              Få tilgang til bedriftene du ønsker å selge, helt eller delvis.
              Tilgang forutsetter at du har en aktiv rolle i foretaket som enten
              daglig leder, styreleder eller styremedlem. Har du ikke en aktiv
              rolle kan tilgang til bedriften likevel godkjennes av Nobema mot
              signert fullmakt fra en aktiv rollehaver.
            </Info>
            <Divider />
          </Section>
          <Section>
            {prospectRequests && (
              <>
                <H3>Tilganger til dine salgsprospekter</H3>
                <Info>
                  Her kan du se en oversikt over hvem som har og ønsker tilgang
                  til dine salgsprospekter. Ytterligere informasjon om hver
                  enkelt forespørsel har blitt sendt til deg på din epost.
                </Info>
                <H6>Ønsker tilgang</H6>
                {Object.keys(prospectRequests).map(companyId => (
                  <ReadRequestList key={companyId}>
                    <ProspectRequest
                      licence={() => {
                        if (boughtProducts.full[companyId]) {
                          return 'full'
                        } else if (boughtProducts.basic[companyId]) {
                          return 'basic'
                        }
                      }}
                      companyId={companyId}
                      buyerInfo={prospectRequests[companyId]}
                    />
                  </ReadRequestList>
                ))}
                <H6>Har tilgang</H6>
                {prospectRequests
                  ? Object.keys(prospectRequests).map(companyId => (
                      <ReadRequestList key={companyId}>
                        <ApprovedRequest
                          companyId={companyId}
                          buyerInfo={prospectRequests[companyId]}
                        />
                      </ReadRequestList>
                    ))
                  : null}
              </>
            )}
          </Section>
        </>
      )}
      {activeMenu === 'agreements' && (
        <Section>
          <H2>Avtaler og erklæringer</H2>
          <Info>
            Her finner du erklæringer og avtaler til signering med BankID og en
            oversikt over signerte dokumenter. Signerte dokumenter mottar du på
            epost.
          </Info>
          {haveBoughtBasic && (
            <>
              <H4>Basis lisens(er)</H4>
              {Object.keys(boughtProducts.basic).map((product, i) => (
                <LicenceCard
                  key={`basic_${i}`}
                  companyId={product}
                  licenceType={'Basis lisens'}
                />
              ))}
            </>
          )}
          {haveBoughtFull && (
            <>
              <H4>Full lisens</H4>
              {Object.keys(boughtProducts.full).map((product, i) => (
                <LicenceCard
                  marketing={marketing}
                  key={`Full_${i}`}
                  companyId={product}
                  licenceType={'Full lisens'}
                />
              ))}
            </>
          )}

          {((pendingNDAS && pendingNDAS.ndas) ||
            (exclusivities && exclusivities.exclusivities)) && (
            <Agreements ndas={pendingNDAS} exclusivities={exclusivities} />
          )}
        </Section>
      )}
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  favoritedCompanyIds: favoritedCompanyIdsSelector(state),
  favoritedAdvertisementIds: favoritedAdvertisementIdsSelector(state),
  claimedCompanyIds: claimedCompanyIdsSelector(state),
  prospectRequests: prospectRequestsSelector(state),
  accessibleProspects: accessibleProspectsSelector(state),
  pendingNDAS: state.agreements,
  boughtProducts: boughtProductsSelector(state),
  exclusivities: exclusivitiesSelector(state),
  marketing: marketingSelector(state),
})

const mapDispatchToProps = { fetchPendingNDAS }

export default compose(
  withLogin,
  withFetchedData(() => fetchPendingNDAS()),
  withFetchedData(() => fetchMarketing()),
  withSpinner(claimedCompaniesIsPendingSelector),
  withSpinner(favoritedCompaniesIsPendingSelector),
  withSpinner(favoritedAdvertisementsIsPendingSelector),
  withSpinner(prospectRequestsIsPendingSelector),
  withSpinner(accessibleProspectsIsPendingSelector),
  withSpinner(boughtProductsIsPendingSelector),
  withSpinner(exclusivitiesIsPendingSelector),
  withSpinner(marketingIsPendingSelector),
  connect(mapStateToProps, mapDispatchToProps)
)(Profile)
