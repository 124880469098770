import styled from 'styled-components'

import { colors } from 'styles'
import { petrona, openSans } from './fonts'

export const h1 = `
  ${petrona};
  color: ${colors.primaryGray};
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 11.2rem;

  &>strong {
    font-weight: bold;
  }
`

export const H1 = styled.h1`
  ${h1};
`

export const h2 = `
  ${petrona};
  font-weight: 600;
  color: ${colors.primaryGray};
  font-size: 3.6rem;
  line-height: 5rem;
  letter-spacing: -0.05rem;
`

export const H2 = styled.h2`
  ${h2};
`

export const h3 = `
  ${petrona};
  font-weight: 400;
  color: ${colors.primaryGray};
  font-size: 3rem;
  line-height: 3.5rem;
`

export const H3 = styled.h3`
  ${h3};
`

export const h4 = `
  ${petrona};
  font-size: 2.4rem;
  color: ${colors.primaryGray};
  line-height: 3.6rem;
  font-weight: 600;
`

export const H4 = styled.h4`
  ${h4};
`

export const h5 = `
  ${openSans};
  font-size: 2.4rem;
  color: ${colors.primaryGray};
  line-height: 2.8rem;
  font-weight: 600;
  letter-spacing: 0.018rem;
`

export const H5 = styled.h5`
  ${h5};
`

export const h6 = `
  ${openSans};
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.7rem;
  letter-spacing: 0.015rem;
`

export const H6 = styled.h6`
  ${h6};
`

export const body = `
  ${openSans};
  font-size: 1.6rem;
  color: ${colors.primaryGray};
  line-height: 2.3rem;
  font-weight: 400;
`

export const body2 = `
  ${openSans};
  font-size: 1.4rem;
  color: ${colors.primaryGray};
  line-height: 1.8rem;
  letter-spacing: 0.025rem;
  font-weight: 400;
`

export const Body2 = `
  ${body2};
`

export const link = `
  ${body};
  color: ${colors.link};
`

export const Body = styled.p`
  ${body};
`

export const subtle = `
  ${body};
  color: ${colors.subtleText};
`

export const subtle2 = `
  ${body2};
  color: ${colors.subtleText};
`

export const Subtle = styled.p`
  ${subtle};
`

export const small = `
  ${petrona}
  font-size: 1.2rem;
  color: ${colors.primaryGray};
  line-height: 2rem;
`

export const Small = styled.p`
  ${small};
`

export const label = `
  ${openSans};
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.04rem;
`

export const Label = styled.label`
  ${label};
`

export const subtitle = `
  ${openSans};
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.8rem;
  letter-spacing: 0.015rem;
`

export const Subtitle = styled.p`
  ${subtitle};
`

export const subtitle2 = `
  ${openSans};
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.01rem;
`

export const Subtitle2 = styled.p`
  ${subtitle};
`

export const buttonText = `
  ${openSans};
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 0.125rem;
  font-weight: 600;
  line-height: 2.0rem;
`

export const ribbonButtonText = `
  ${openSans};
  font-size: 1.0rem;
  letter-spacing: 0.025rem;
  line-height: 2.0rem;
`

export const overline = `
  ${openSans};
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.2rem;
  color: ${colors.primaryGray};
  line-height: 1.8rem;
  letter-spacing: 0.15rem;
`

export const Overline = styled.p`
  ${overline};
`

export const navButton = `
  ${openSans};
  font-size: 1.8rem;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 3px;
  text-align: left;
`

export const NavButton = styled.p`
  ${navButton}
`
