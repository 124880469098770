import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import withLogin from '../components/withLogin'
import NoAccess from '../components/NoAccess'
import Label from '../components/form/Label'
import Input from '../components/form/Input'
import Select from '../components/form/Select'
import Textarea from '../components/form/Textarea'
import {
  prospectGeneratorUpdate,
  prospectGeneratorSeed,
  clearProspectGenerator,
} from '../actions'
import { h6, Body, H2, link, H4 } from '../styles/text'
import { colors } from 'styles'
import Button from '../components/Button'
import {
  calculateLaborCostDivTotalCost,
  calculateCompanyLifePhase,
  calculateLaborCostsDivTotalIncome,
  formatDate,
  calculateTurnover,
  calculateEk,
} from '../utils'
import {
  addCompanyProspect,
  fetchCompanyProspect,
  fetchCompany,
  fetchClaimedCompanies,
  fetchBrregData,
  fetchNobemaVideo,
  getProspectOwner,
  getProjectNumber,
  getLicence,
  BASIC_LICENCE,
  FULL_LICENCE,
} from '../api/companies'
import prospectRibbonForm from './prospectGeneratorForm'
import RadioButtons from '../components/form/RadioButtons'
import FileUploader from '../components/form/FileUploader'
import AddItemShowcase from '../components/AddItemShowcase'
import DocumentUploader from '../components/form/DocumentUploader'
import Checkboxes from '../components/form/Checkboxes'
import Checkbox from '../components/form/Checkbox'
import EditProperty from '../components/Company/edit/EditProperty'
import Spinner from '../components/Spinner'
import EditAgreement from '../components/Company/edit/EditAgreements'
import EditAssets from '../components/Company/edit/EditAssets'
import EditFinancialStructure from '../components/Company/edit/EditFinancialStructure'
import EditLeasingStructure from '../components/Company/edit/EditLeasing'
import { currentUserId } from '../api/firebase'
import SideNavigationBar from '../components/SideNavigation'
import EditPurchaseArgumentsStructure from '../components/Company/edit/EditPurchaseArguments'
import FileOverview from '../components/form/FileOverview'
import KeyFigures from '../components/Company/Main/KeyFigures'
import EconomicFigures from '../components/Company/Main/EconomicFigures'
import EditProducts from '../components/Company/edit/EditProducts'

const Wrapper = styled.div`
  display: flex;
`
const marginCols = `32px`
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${marginCols} repeat(6, 8vw) ${marginCols};
  column-gap: 2.4em;
  grid-auto-flow: ${props =>
    props.theme.gridStyle ? props.theme.gridStyle : 'dense'};
`

const FormGroupTitle = styled.h6`
  ${h6};
  grid-column: 2 / span 4;
`

const ItemShowcaseWrapper = styled.div`
  grid-column: ${props => props.theme};
  margin-bottom: 1rem;
`

const FormEntryWrapper = styled.div`
  grid-column: ${props => props.theme};
  margin-bottom: 5rem;
  ${props =>
    props.textarea &&
    css`
      grid-row: span 2;
    `};

  ${props =>
    props.showcase &&
    css`
      grid-row: span 4;
    `};

  ${props =>
    props.required &&
    css`
      border: 2px ${colors.highlight} solid;
      border-radius: 5px;
      padding: 6px;
    `}

  @media (max-width: 768px) {
    grid-column: 2 / span 8;
  }
`

const ButtonWithMargins = styled(Button)`
  margin: 1rem 2rem 1rem 0;
`

const RibbonMenu = styled.div`
  > button {
    margin: 0 8px 0 8px;
  }
`

const Header = styled.div`
  overflow: auto;
  white-space: nowrap;
  background-color: #f8f8f8;

  h2 {
    padding-left: 48px;
  }
`

const RibbonSupport = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

const StickyBoi = styled.div`
  position: sticky;
  top: 120px;
`

const Link = styled.a`
  ${link};
  display: inline-block;
  color: inherit;
`

const SupportContainer = styled.div`
  height: 100%;
  margin-top: 64px;
`

const InputListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

const ProspectGenerator = ({
  formValues,
  prospectGeneratorUpdate,
  update,
  orgNumber,
  prospectGeneratorSeed,
  clearProspectGenerator,
}) => {
  const [activeRibbon, setActiveRibbon] = useState('start')
  const [activeForm, setActiveForm] = useState('start')
  const [dataLoaded, setDataLoaded] = useState(false)
  const [hasEmployees, setHasEmployees] = useState(true)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [haveNoAccess, setHaveNoAccess] = useState(false)
  const [isStartup, setIsStartUp] = useState(false)
  const [videoSrc, setVideoSrc] = useState('')
  const [licence, setLicence] = useState(BASIC_LICENCE)
  const [error, setError] = useState({ error: '', errorKey: '' })

  const ribbonMenuItems = [
    {
      key: 'start',
      label: 'Start',
      inAd: true,
    },
    {
      key: 'projectInformation',
      label: 'Prosjektinformasjon',
      inAd: true,
    },
    {
      key: 'companyInformation',
      label: 'Selskapsinformasjon',
      inAd: true,
    },
    {
      key: 'economy',
      label: 'Økonomi',
      inAd: true,
    },
    {
      key: 'purchaseArguments',
      label: 'Kjøpsargumenter',
      inAd: true,
    },
    {
      key: 'products',
      label: 'Produkter og tjenester',
    },
    {
      key: 'markets',
      label: 'Marked',
    },
    {
      key: 'employees',
      label: 'Personal',
    },
    {
      key: 'agreements',
      label: 'Avtaler',
    },
    {
      key: 'properties',
      label: 'Eiendom',
    },
    {
      key: 'assets',
      label: 'Eiendeler',
    },
    {
      key: 'leases',
      label: 'Leie- og leasingavtaler',
    },
    {
      key: 'finances',
      label: 'Finans og forsikring',
    },
    {
      key: 'marketing',
      label: 'Markedsføring og distribusjon',
    },

    {
      key: 'dueDilligence',
      label: 'Dokumenter',
    },
  ]

  const findKeyLabel = key => {
    try {
      let errorLabel = ''
      Object.values(prospectRibbonForm).map(ribbon => {
        ribbon.map(formGroup => {
          formGroup.entries.map(entry => {
            if (entry.key === key) {
              errorLabel = entry.label
            }
          })
        })
      })
      if (errorLabel === '') {
        return key
      }
      return errorLabel
    } catch (e) {
      console.log('Error', key)
      return key
    }
  }

  const restructureData = data => {
    if (!data || Object.keys(data).length === 0) {
      return {}
    }

    let address = ''
    let addressInfo = {}

    if (data.address && data.address.address) {
      address = data.address.address[0]

      delete data.address.address
      addressInfo = { ...data.address }
    }

    let companyLifeCycle = ''
    if (data.StiftetDato) {
      companyLifeCycle = calculateCompanyLifePhase(data.StiftetDato)
    }
    if (data.startupBoolean) {
      setIsStartUp(data.startupBoolean)
      companyLifeCycle = 'Oppstartsbedrift'
    }

    let bransjeData = {}
    let grunnfakta = {}

    if (data.grunnfakta && Object.keys(data.grunnfakta).length !== 0) {
      bransjeData = { ...data.grunnfakta.BransjeData }
      delete data.grunnfakta.BransjeData
      grunnfakta = { ...data.grunnfakta }
      delete data.grunnfakta
    }

    let employeeSalaryRatios = {}
    // Assume last item in okonomiDetaljerForetak is the latest data
    if (data.okonomiDetaljerForetak && data.okonomiDetaljerForetak.length > 0) {
      const lastestAccontingYear =
        data.okonomiDetaljerForetak[data.okonomiDetaljerForetak.length - 1]

      employeeSalaryRatios = {
        totalSalaryExpenditures: `${
          parseFloat(lastestAccontingYear['Lonnskostnad']) * 1000
        }kr`,
        salary_div_turnover:
          calculateLaborCostsDivTotalIncome(lastestAccontingYear),
        salary_div_ordResult:
          calculateLaborCostDivTotalCost(lastestAccontingYear),
      }
    }

    return {
      ...data,
      ...addressInfo,
      ...bransjeData,
      ...grunnfakta,
      ...employeeSalaryRatios,
      address: address,
      companyLifeCycle: companyLifeCycle,
    }
  }

  const firebaseData = data => {
    let newAddress = {}
    const addressArray = [data.address]

    newAddress = {
      postplace: data.postplace ?? '',
      country: data.country ?? '',
      postnumber: data.postnumber ?? '',
      municipality: data.municipality ?? '',
      address: addressArray ?? [],
    }

    let newGrunnfakta = {
      Aksjekapital: data.Aksjekapital ?? '',
      AksjekapitalStatus: data.AksjekapitalStatus ?? '',
      AnsatteData: data.AnsatteData ?? [],
      BankRegnr: data.BankRegnr ?? '',
      EtablertAr: data.EtablertAr ?? '',
      Formaal: data.Formaal ?? '',
      RegistrertDato: data.RegistrertDato ?? '',
      RegistrertMVA: data.RegistrertMVA ?? '',
      RegistrertSted: data.RegistrertSted ?? '',
      RevisorFravalgtDato: data.RevisorFravalgtDato ?? '',
      RevisorOrgnr: data.RevisorOrgnr ?? '',
      SektorKode: data.SektorKode ?? '',
      SektorTekst: data.SektorTekst ?? '',
      SelskFormKode: data.SelskFormKode ?? '',
      SelskFormTekst: data.SelskFormTekst ?? '',
      StiftetDato: data.StiftetDato ?? '',
    }

    return { ...data, ...newGrunnfakta, address: newAddress }
  }

  useEffect(() => {
    return () => {
      clearProspectGenerator({})
    }
  }, [])

  // Populate state
  useEffect(() => {
    // Clear
    clearProspectGenerator({})
    fetchClaimedCompanies(orgNumber).then(res => {
      if (res[orgNumber]) {
        Promise.all([
          fetchCompany(orgNumber),
          fetchCompanyProspect(orgNumber),
          fetchBrregData(orgNumber, 'signatur'),
          fetchBrregData(orgNumber, 'prokura'),
          fetchBrregData(orgNumber, 'enhet'),
          fetchNobemaVideo(),
          getProspectOwner(orgNumber),
          getProjectNumber(orgNumber),
          getLicence(orgNumber),
        ])
          .then(
            ([
              esData,
              firebaseData,
              signature,
              prokura,
              enhet,
              videoUrl,
              prospectOwner,
              projectNumber,
              projectLicence,
            ]) => {
              //Combine the data
              let bisnodeData = esData ? restructureData(esData) : {}
              let manualData = firebaseData ? restructureData(firebaseData) : {}
              const companyData = { ...bisnodeData }
              Object.keys(manualData).forEach(dataKey => {
                if (manualData[dataKey] !== '') {
                  companyData[dataKey] = manualData[dataKey]
                }
              })

              setLicence(projectLicence)
              setVideoSrc(videoUrl)
              setHasEmployees(firebaseData['employeeBoolean'])

              let brregData = {}
              if (prokura?.signeringsGrunnlag) {
                brregData = {
                  prokura:
                    prokura?.signeringsGrunnlag?.kode === 'SF'
                      ? prokura?.signeringsGrunnlag?.signaturProkuraRoller
                          ?.signaturProkuraFritekst
                      : '',
                  signedOff: signature?.signeringsGrunnlag
                    ?.signaturProkuraRoller
                    ? signature.signeringsGrunnlag?.signaturProkuraRoller
                        ?.signaturProkuraFritekst
                    : '',
                }
              } else {
                brregData = {
                  prokura: '',
                  signedOff: '',
                }
              }

              const stockHolders = []
              esData?.aksjonaerer?.map(stockHolder => {
                if (
                  stockHolders.findIndex(
                    sh =>
                      sh.name === stockHolder.Aksjonarnavn &&
                      sh.amount === (stockHolder.eierandel ?? '0')
                  ) === -1
                ) {
                  stockHolders.push({
                    name: stockHolder.Aksjonarnavn ?? 'Mangler verdi',
                    amount: stockHolder.eierandel ?? '0',
                  })
                }
              })

              const boardMembers = []
              const secondLeader = [] // Varamedlem
              let dagligLeder = '' // Daglig leder / adm. dir
              let boardLeader = '' // Styrets leder

              esData?.styreverv?.map(styremedlem => {
                switch (styremedlem.VervKode) {
                  case '1':
                    boardLeader = styremedlem.Navn
                    return
                  case '20':
                    dagligLeder = styremedlem.Navn
                    return
                  case '3':
                    boardMembers.push({ name: styremedlem.Navn })
                    return
                  case '5':
                    secondLeader.push({ name: styremedlem.Navn })
                    return
                  default:
                    break
                }
              })

              if (brregData.prokura === undefined) {
                brregData.prokura = ''
              }

              if (brregData.signedOff === undefined) {
                brregData.signedOff = ''
              }

              const economyData = {
                okonomiSammendragForetak:
                  (esData['okonomiSammendragForetak']
                    ? esData['okonomiSammendragForetak']
                    : manualData['okonomiSammendragForetak']) ?? [],
                okonomiDetaljerForetak:
                  (esData['okonomiDetaljerForetak']
                    ? esData['okonomiDetaljerForetak']
                    : manualData['okonomiDetaljerForetak']) ?? [],
                gjeldEgenkapitalForetak:
                  (esData['gjeldEgenkapitalForetak']
                    ? esData['gjeldEgenkapitalForetak']
                    : manualData['gjeldEgenkapitalForetak']) ?? [],
                eiendelerForetak:
                  (esData['eiendelerForetak']
                    ? esData['eiendelerForetak']
                    : manualData['eiendelerForetak']) ?? [],
              }

              let customEconomyYear = new Date().getFullYear()
              if (economyData?.okonomiDetaljerForetak?.length > 0) {
                customEconomyYear =
                  economyData.okonomiDetaljerForetak[
                    economyData.okonomiDetaljerForetak.length - 1
                  ]['RegnskapsAvAr']
              }

              let prospectOwnerData = {}
              if (prospectOwner !== 403 && prospectOwner.userId !== null) {
                prospectOwnerData.ownerFirstName =
                  prospectOwner.userinfo.given_name
                prospectOwnerData.ownerLastName =
                  prospectOwner.userinfo.family_name
                prospectOwnerData.ownerPhone = prospectOwner.phoneNumber
                prospectOwnerData.ownerEmail = prospectOwner.email
              }

              if (typeof companyData.stockHolders === 'string') {
                delete companyData.stockHolders
              }
              if (typeof companyData.secondLeader === 'string') {
                delete companyData.secondLeader
              }
              if (typeof companyData.boardMembers === 'string') {
                delete companyData.boardMembers
              }

              prospectGeneratorSeed({
                ...brregData,
                ...prospectOwnerData,
                projectLicence,
                boardMembers,
                secondLeader,
                dagligLeder,
                boardLeader,
                stockHolders,
                projectNumber: projectNumber.projectId,
                propertyOwnedBoolean: false,
                propertyRentedBoolean: false,
                customEconomyYear,
                ...companyData.grunnfakta,
                industryCode: enhet?.slettedato
                  ? ''
                  : `${enhet?.naeringskode1?.kode ?? ''} ${
                      enhet?.naeringskode1?.beskrivelse ?? ''
                    }`,
                sektor: enhet?.slettedato
                  ? ''
                  : `${enhet?.institusjonellSektorkode?.kode ?? ''} ${
                      enhet?.institusjonellSektorkode?.beskrivelse ?? ''
                    }`,
                registreringsdatoEnhetsregisteret: enhet?.slettedato
                  ? ''
                  : enhet?.registreringsdatoEnhetsregisteret
                  ? formatDate(enhet?.registreringsdatoEnhetsregisteret)
                  : '',
                ...companyData,
                keyFigures:
                  esData['okonomiSammendragForetak']?.length > 0
                    ? esData['okonomiSammendragForetak']?.[
                        esData['okonomiSammendragForetak']?.length - 1
                      ]
                    : 'Mangler Grunnlag',
                economicFigures: {
                  companyId: economyData,
                  ...economyData,
                },
                ...economyData,
              })
              setDataLoaded(true)
            }
          )
          .catch(e => console.error(e))
      } else {
        setHaveNoAccess(true)
        return
      }
    })
  }, [])

  const dispacth = (key, value, update = true) => {
    setUnsavedChanges(update)
    return prospectGeneratorUpdate({ [key]: value })
  }

  // Add userId to state
  useEffect(() => {
    dispacth('userId', currentUserId(), false)
  }, [])

  const updateItemShowCaseEntry = (storeKey, itemKey, index, value) => {
    const updatedFormValues = [
      ...formValues[`${storeKey}`].slice(0, index),
      {
        ...formValues[`${storeKey}`][index],
        [itemKey]: value,
      },
      ...formValues[`${storeKey}`].slice(index + 1),
    ]
    dispacth(storeKey, updatedFormValues)
  }

  const renderFormGroup = formGroup => {
    const current = formGroup.formLabel
    if (
      [...prospectRibbonForm.employees, { formLabel: 'Ansatte' }]
        .slice(1)
        .map(entry => entry.formLabel)
        .includes(formGroup.formLabel) &&
      !hasEmployees
    ) {
      return current === 'PERSONAL' ? (
        <GridWrapper key={formGroup.formLabel} theme={formGroup}>
          <FormGroupTitle>PERSONAL</FormGroupTitle>
          <FormEntryWrapper theme={'2 / span 6'} key={'No personal'}>
            <Label>Personal</Label>
            <Body>
              Det er registrert at bedriften ikke har ansatte. Denne fanen er
              derfor ikke i anvendelse
            </Body>
          </FormEntryWrapper>
        </GridWrapper>
      ) : null
    } else {
      return (
        <GridWrapper key={formGroup.formLabel} theme={formGroup}>
          <FormGroupTitle>{formGroup.formLabel}</FormGroupTitle>
          {formGroup.entries.map(field => selectField(field))}
        </GridWrapper>
      )
    }
  }

  const selectField = entry => {
    switch (entry.field) {
      case 'Input':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <Label helpText={entry.helptext}>{entry.label}</Label>
            <Input
              type={entry.type ?? 'text'}
              style={{ borderColor: entry.required ? colors.highlight : '' }}
              placeholder={entry.placeholder}
              value={formValues[entry.key]}
              onChange={e => dispacth(entry.key, e.target.value)}
              disabled={entry.disabled}
            />
          </FormEntryWrapper>
        )
      case 'list':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <H4 style={{ margin: 0 }} helpText={entry.helptext}>
              {entry.label}
            </H4>
            <ul>
              {entry.listItems.map((item, i) => (
                <li key={i}>
                  <Body>{item}</Body>
                </li>
              ))}
            </ul>
          </FormEntryWrapper>
        )
      case 'video':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <video
              style={{ width: '100%', height: 'auto', maxWidth: '400px' }}
              controls
            >
              <source src={videoSrc[0]} type="video/mp4" />
            </video>
          </FormEntryWrapper>
        )
      case 'Information':
        return (
          <FormEntryWrapper
            style={
              entry.background
                ? {
                    backgroundColor: colors[entry.background],
                    padding: '12px 6px',
                  }
                : {}
            }
            theme={entry.position}
            key={entry.key}
          >
            {entry.useLarge ? (
              <H4 style={{ margin: 0 }} helpText={entry.helptext}>
                {entry.label}
              </H4>
            ) : (
              <Label helpText={entry.helptext}>{entry.label}</Label>
            )}

            <Body style={entry.background ? { color: 'black' } : {}}>
              {entry.content}{' '}
              {entry.link && (
                <Link href={entry.link} target="_blank">
                  {entry.linktext}
                </Link>
              )}
            </Body>
          </FormEntryWrapper>
        )

      case 'Brreg':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <Label>{entry.label}</Label>
            <Body>{entry.content}</Body>
            <Link
              href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${orgNumber}`}
              target="_blank"
            >
              Se dine nøkkelopplysninger i enhetsregisteret
            </Link>
          </FormEntryWrapper>
        )
      case 'Select':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <Label helpText={entry.helptext}>{entry.label}</Label>
            <Select
              value={formValues ? formValues[`${entry.key}`] : ''}
              options={entry.options}
              onChange={value => dispacth(entry.key, value)}
            />
          </FormEntryWrapper>
        )
      case 'Textarea':
        return (
          <FormEntryWrapper theme={entry.position} textarea key={entry.key}>
            <Label helpText={entry.helptext}>{entry.label}</Label>
            <Textarea
              style={{ borderColor: entry.required ? colors.highlight : '' }}
              placeholder={entry.placeholder ? entry.placeholder : ''}
              value={formValues[`${entry.key}`]}
              onChange={e => dispacth(entry.key, e.target.value)}
              disabled={entry.disabled}
            />
          </FormEntryWrapper>
        )
      case 'RadioButtons':
        return (
          <FormEntryWrapper
            required={entry.required}
            theme={entry.position}
            key={entry.key}
          >
            <Label helpText={entry.helptext}>{entry.label}</Label>
            <RadioButtons
              horizontal={entry.horizontal}
              options={entry.options.map(rb => ({
                label: <Label>{rb.label}</Label>,
                value: rb.value,
              }))}
              value={formValues[entry.key]}
              onChange={value => dispacth(entry.key, value)}
            />
          </FormEntryWrapper>
        )
      case 'RadioButtonsEmployees':
        return (
          <>
            <FormEntryWrapper
              required={entry.required}
              theme={entry.position}
              key={entry.key}
            >
              <Label helpText={entry.helptext}>{entry.label}</Label>
              <RadioButtons
                horizontal
                options={entry.options.map(rb => ({
                  label: <Label>{rb.label}</Label>,
                  value: rb.value,
                }))}
                value={
                  typeof formValues[entry.key] !== 'undefined'
                    ? formValues[entry.key]
                    : hasEmployees
                }
                onChange={value => {
                  setHasEmployees(value)
                  dispacth(entry.key, value)
                }}
              />
            </FormEntryWrapper>
            {hasEmployees && (
              <FormEntryWrapper theme={`5 / span 3`} key={'employees'}>
                <Label>Antall ansatte</Label>
                <Input
                  style={{
                    borderColor: entry.required ? colors.highlight : '',
                  }}
                  value={formValues['employees']}
                  onChange={e => dispacth('employees', e.target.value)}
                />
              </FormEntryWrapper>
            )}
          </>
        )
      case 'RadioButtonsStartup':
        return (
          <FormEntryWrapper
            required={entry.required}
            theme={entry.position}
            key={entry.key}
          >
            <Label helpText={entry.helptext}>{entry.label}</Label>
            <RadioButtons
              horizontal
              options={entry.options.map(rb => ({
                label: <Label>{rb.label}</Label>,
                value: rb.value,
              }))}
              value={
                typeof formValues[entry.key] !== 'undefined'
                  ? formValues[entry.key]
                  : hasEmployees
              }
              onChange={value => {
                setIsStartUp(value)
                dispacth(entry.key, value)
                {
                  value
                    ? dispacth('companyLifeCycle', 'Oppstartselskap')
                    : dispacth(
                        'companyLifeCycle',
                        calculateCompanyLifePhase(formValues['StiftetDato'])
                      )
                }
              }}
            />
          </FormEntryWrapper>
        )
      case 'OfferShowCase':
        if (formValues[entry.key]) {
          const formEntries = formValues[entry.key]
          return (
            <>
              {formEntries.map((item, index) => {
                const gridPosition = (index % 3) * 2 + 2
                return (
                  <ItemShowcaseWrapper
                    theme={`${gridPosition} / span 2`}
                    key={entry.key + '_' + index}
                  >
                    <FileUploader
                      dispatcher={fp => {
                        if (fp === 'delete') {
                          dispacth(
                            entry.key,
                            formEntries.filter((_, i) => i !== index)
                          )
                        } else {
                          updateItemShowCaseEntry(
                            entry.key,
                            'images',
                            index,
                            fp
                          )
                        }
                      }}
                      orgNumber={orgNumber}
                      fpFromStore={item.images}
                    />
                    <Input
                      placeholder={'Tittel'}
                      onChange={e =>
                        updateItemShowCaseEntry(
                          entry.key,
                          'title',
                          index,
                          e.target.value
                        )
                      }
                      value={item.title}
                    />
                    <Textarea
                      placeholder={'Beskriv produktet'}
                      onChange={e =>
                        updateItemShowCaseEntry(
                          entry.key,
                          'description',
                          index,
                          e.target.value
                        )
                      }
                      value={item.description}
                    />
                    <Input
                      placeholder={'Link'}
                      onChange={e =>
                        updateItemShowCaseEntry(
                          entry.key,
                          'link',
                          index,
                          e.target.value
                        )
                      }
                      value={item.link}
                    />
                  </ItemShowcaseWrapper>
                )
              })}
              <ItemShowcaseWrapper
                key={entry.key + '_add'}
                theme={`${(formEntries.length % 3) * 2 + 2} / span 2`}
              >
                <AddItemShowcase
                  onClick={() =>
                    dispacth(entry.key, [
                      ...formValues[`${entry.key}`],
                      {
                        images: [],
                        title: '',
                        description: '',
                        link: '',
                        type: 'showcase',
                      },
                    ])
                  }
                />
              </ItemShowcaseWrapper>
            </>
          )
          // Hardcode first case
        } else {
          return (
            <ItemShowcaseWrapper
              theme={`2 / span 2`}
              key={entry.key + '_default'}
            >
              <AddItemShowcase
                onClick={() =>
                  dispacth(entry.key, [
                    {
                      images: [],
                      title: '',
                      description: '',
                      link: '',
                      type: 'showcase',
                    },
                  ])
                }
              />
            </ItemShowcaseWrapper>
          )
        }
      case 'document':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <Label helpText={entry.helptext}>{entry.header}</Label>
            <DocumentUploader
              label={entry.label}
              dispatcher={files => dispacth(entry.key, files)}
              orgNumber={orgNumber}
              fpFromStore={formValues[entry.key]}
              path={entry.path}
            />
          </FormEntryWrapper>
        )
      case 'Checkbox':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <Label helpText={entry.helptext}>{entry.label}</Label>
            <Checkboxes
              horizontal={entry.horizontal}
              value={
                formValues[`${entry.key}`] ? formValues[`${entry.key}`] : []
              }
              options={entry.options.map(option => ({
                value: option.value,
                label: option.label,
              }))}
              onChange={value => dispacth(entry.key, value)}
              other={entry.other}
              otherPlaceholder="Beskriv"
            />
          </FormEntryWrapper>
        )
      case 'optionalCheckbox':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <Label helpText={entry.helptext}>
              {entry.label}
              {'  '}
              <Checkbox
                checked={formValues[`${entry.optionKey}`]}
                onChange={() =>
                  dispacth(
                    `${entry.optionKey}`,
                    !formValues[`${entry.optionKey}`]
                  )
                }
              />
            </Label>
            {formValues[`${entry.optionKey}`] && (
              <>
                <Checkboxes
                  value={
                    formValues[`${entry.key}`] ? formValues[`${entry.key}`] : []
                  }
                  options={entry.options.map(option => ({
                    value: option.value,
                    label: option.label,
                  }))}
                  onChange={value => dispacth(entry.key, value)}
                  other={entry.other}
                  otherPlaceholder="Beskriv"
                />
                <Textarea
                  placeholder={`${entry.placeholder}`}
                  value={formValues[`${entry.key}_comment`]}
                  onChange={e =>
                    dispacth(`${entry.key}_comment`, e.target.value)
                  }
                />
              </>
            )}
          </FormEntryWrapper>
        )
      case 'arguments':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <EditPurchaseArgumentsStructure
              formValues={formValues}
              dispacth={dispacth}
              orgNumber={orgNumber}
            />
          </FormEntryWrapper>
        )
      case 'property':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <EditProperty
              formValues={formValues}
              dispacth={dispacth}
              orgNumber={orgNumber}
            />
          </FormEntryWrapper>
        )
      case 'agreement':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <EditAgreement
              formValues={formValues}
              dispacth={dispacth}
              orgNumber={orgNumber}
            />
          </FormEntryWrapper>
        )
      case 'assets':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <EditAssets
              formValues={formValues}
              dispacth={dispacth}
              orgNumber={orgNumber}
            />
          </FormEntryWrapper>
        )
      case 'finance':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <EditFinancialStructure
              formValues={formValues}
              dispacth={dispacth}
              orgNumber={orgNumber}
            />
          </FormEntryWrapper>
        )
      case 'products':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <EditProducts
              formValues={formValues}
              dispacth={dispacth}
              orgNumber={orgNumber}
            />
          </FormEntryWrapper>
        )
      case 'Hero':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <Label helpText={entry.helptext}>{entry.label}</Label>
            <FileUploader
              required={entry.required}
              dispatcher={images => dispacth(entry.key, images)}
              fpFromStore={formValues[entry.key]}
              orgNumber={orgNumber}
              single
              path={entry.path}
            />
          </FormEntryWrapper>
        )
      case 'leasing':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <EditLeasingStructure
              formValues={formValues}
              dispacth={dispacth}
              orgNumber={orgNumber}
            />
          </FormEntryWrapper>
        )
      case 'overview':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <FileOverview
              values={formValues}
              keys={ribbonMenuItems}
              orgNumber={orgNumber}
              dispatcher={dispacth}
              path={entry.path}
            />
          </FormEntryWrapper>
        )
      case 'companyNumbers':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <KeyFigures okonomiSammendragForetak={formValues[entry.key]} />
          </FormEntryWrapper>
        )
      case 'EconmicFigures':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <EconomicFigures {...formValues[entry.key]} />
          </FormEntryWrapper>
        )
      // Assume that top level node is radio buttons
      case 'SelectiveContent':
        return (
          <>
            <FormEntryWrapper
              required={entry.required}
              theme={entry.position}
              key={entry.key}
            >
              <Label helpText={entry.helptext}>{entry.label}</Label>
              <RadioButtons
                horizontal={entry.horizontal}
                options={entry.options.map(rb => ({
                  label: <Label>{rb.label}</Label>,
                  value: rb.value,
                }))}
                value={formValues[entry.key]}
                onChange={value => dispacth(entry.key, value)}
              />
            </FormEntryWrapper>
            {!!formValues[entry.key]
              ? entry.selectiveContentTrue.map(selectiveEntry =>
                  selectField(selectiveEntry)
                )
              : entry.selectiveContentFalse.map(selectiveEntry =>
                  selectField(selectiveEntry)
                )}
          </>
        )
      /*

      inputs: {
            name: { label: 'Navn', placeholder: 'Navn' },
            amount: { label: 'andel', placeholder: 'Eierandel' },
          },

      */

      case 'inputlist':
        // Entry should be another list
        const inputStruct = entry.inputs
        const emptyStruct = {}
        Object.keys(inputStruct).map(inputKey => {
          emptyStruct[inputKey] = ''
        })

        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <Label>{entry.label}</Label>
            {formValues[entry.key] &&
              formValues[entry.key].map((_, index) => (
                <InputListContainer key={index}>
                  {Object.keys(inputStruct).map((inputKey, i) => (
                    <Input
                      key={inputKey}
                      value={formValues[entry.key][index][inputKey]}
                      onChange={e => {
                        const copy = formValues[entry.key]
                        copy.splice(index, 1, {
                          ...formValues[entry.key][index],
                          [inputKey]: e.target.value,
                        })
                        dispacth(entry.key, copy)
                      }}
                      style={{
                        borderRadius: i === 0 ? '4px 0 0 4px' : '0px',
                        borderRight: '0',
                      }}
                      placeholder={entry.inputs[inputKey].placeholder}
                    />
                  ))}
                  <Button
                    style={{
                      padding: '12px 16px',
                      borderRadius: '0 4px 4px 0',
                    }}
                    onClick={() => {
                      const copy = formValues[entry.key]
                      copy.splice(index, 1)
                      dispacth(entry.key, copy)
                    }}
                  >
                    Fjern
                  </Button>
                </InputListContainer>
              ))}

            <Button
              subtle
              style={{ width: '100%' }}
              onClick={() =>
                dispacth(
                  entry.key,
                  formValues[entry.key]
                    ? [...formValues[entry.key], emptyStruct]
                    : [emptyStruct]
                )
              }
            >
              Legg til {entry.buttonLabel}
            </Button>
          </FormEntryWrapper>
        )
      case 'Empty':
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <div></div>
          </FormEntryWrapper>
        )
      default:
        return (
          <FormEntryWrapper theme={entry.position} key={entry.key}>
            <p>{entry.field} did not render</p>
          </FormEntryWrapper>
        )
    }
  }

  const renderSupportContent = supportContent => {
    return (
      <SupportContainer>
        <StickyBoi>
          {activeRibbon !== 'dueDilligence' && (
            <ButtonWithMargins
              onClick={async () => {
                setUnsavedChanges(false)
                setError({ error: '', errorKey: '' })
                const newFormValues = firebaseData(formValues)
                const res = await addCompanyProspect(orgNumber, newFormValues)
                setError(res)
              }}
            >
              Lagre endringer
            </ButtonWithMargins>
          )}
          {unsavedChanges && (
            <Body style={{ color: colors.warning }}>
              Du har ulagrede endringer!
            </Body>
          )}
          {error.error && (
            <Body style={{ color: colors.error }}>
              <b>En feil oppsto: </b>
              {error.error === 'PERMISSION_DENIED' &&
                'En feil oppsto ved lagring, forsøk å logg ut og inn igjen. Ta kontakt dersom feilen vedvarer.'}
              {error.error === 'UNDEFINED_VALUE' && (
                <>
                  En feil oppsto ved lagring,{' '}
                  <b>{findKeyLabel(error.errorKey)}</b> mangler gyldig verdi. Ta
                  kontakt dersom feilen vedvarer.
                </>
              )}
              {error.error === 'UNHANDLED_ERROR' &&
                `En feil oppsto ved lagring. Ta kontakt for veiledning dersom feilen vedvarer`}
            </Body>
          )}
          {supportContent.entries.map(field => selectField(field))}
        </StickyBoi>
      </SupportContainer>
    )
  }

  const updateActiveRibbon = item => {
    setActiveRibbon(item.key)
    setActiveForm(item.key)
  }

  const renderRibbonMenu = menuList => (
    <RibbonMenu>
      {menuList.map(item => (
        <Button
          onClick={() => updateActiveRibbon(item)}
          active={activeRibbon === item.key}
          ribbon
          disabled={
            (item.key === 'properties' &&
              formValues['propertyOwnedBoolean'] === false &&
              formValues['propertyRentedBoolean'] === false) ||
            (item.key === 'employees' &&
              formValues['employeeBoolean'] === false)
          }
          key={item.key}
          activeColor={item.inAd ? colors.highlight : colors.white}
          inactiveColor={
            item.inAd ? colors.highlightBackground : colors.alabasterBackground
          }
        >
          {item.label}
        </Button>
      ))}
    </RibbonMenu>
  )

  const activeRibbons = () => {
    if (licence === FULL_LICENCE) {
      return ribbonMenuItems
    } else {
      return ribbonMenuItems.filter(item => item.inAd)
    }
  }

  return (
    <>
      {dataLoaded ? (
        <SideNavigationBar active={'prospect'} orgNumber={orgNumber}>
          <Header>
            <H2>
              Rediger salgsinformasjon -{' '}
              {formValues['name'] ? formValues['name'] : orgNumber}
            </H2>
            {renderRibbonMenu(activeRibbons())}
          </Header>
          <Wrapper>
            <div>
              {prospectRibbonForm[activeRibbon]
                .slice(1)
                .map(entry => renderFormGroup(entry))}
            </div>
            <RibbonSupport>
              {renderSupportContent(prospectRibbonForm[activeRibbon][0])}
            </RibbonSupport>
          </Wrapper>
        </SideNavigationBar>
      ) : haveNoAccess ? (
        <NoAccess />
      ) : (
        <Spinner />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  formValues: state.prospectGenerator,
})

const mapDispatchToProps = {
  prospectGeneratorUpdate,
  prospectGeneratorSeed,
  clearProspectGenerator,
}

export default compose(
  withLogin,
  connect(mapStateToProps, mapDispatchToProps)
)(ProspectGenerator)
