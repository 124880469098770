import React from 'react'
import styled from 'styled-components'

import { h6 } from 'styles/text'
import { colors } from 'styles'

import {
  Field,
  Label,
  Input,
  Textarea,
  Checkbox,
  AnonLabel,
  UploadFiles,
} from 'components/form'
import EditListOfLists, { mapToState } from './EditListOfLists'
import { uploadFilesFromList } from 'components/form/FileUploaderCore'

const Heading = styled.h3`
  ${h6};
  margin-bottom: 12px;
`

const Divider = styled.hr`
  margin-bottom: 48px;
  border: 1px solid ${colors.primary};
`

const fields = [
  {
    prop: 'name',
    label: 'Navn/betegnelse',
    placeholder: 'Navn og/eller betegnelse',
  },
  {
    prop: 'description',
    label: 'Kommentar',
    Component: Textarea,
    placeholder:
      'Kommentar eller en beskrivelse av de viktigste punktene i avtalen',
  },
  {
    prop: 'files',
    label: 'Vedlegg',
    type: 'files',
    Component: ({ value = [], onChange }) => (
      <UploadFiles
        value={value}
        onChange={value => onChange({ target: { value } })}
      />
    ),
  },
]

export const lists = {
  procurementAndSupplierAgreements: {
    heading: 'Innkjøps- og leverandøravtaler',
    buttonLabel: '+ Legg til innkjøps- og leverandøravtaler',
    fields,
  },
  deliveryObligations: {
    heading: 'Leveranseforpliktelser',
    buttonLabel: '+ Legg til leveranseforpliktelser',
    fields,
  },
  agencies: {
    heading: 'Agenturer',
    buttonLabel: '+ Legg til agenturer',
    fields,
  },
  certificates: {
    heading: 'Lisenser, rettigheter, bevillinger og sertifikater',
    buttonLabel:
      '+ Legg til lisenser, rettigheter, bevillinger og sertifikater',
    fields,
  },
  otherAgreements: {
    heading: 'Andre avtaler, rettigheter og forpliktelser',
    buttonLabel: '+ Legg til andre avtaler, rettigheter og forpliktelser',
    fields,
  },
}

class EditAgreements extends React.Component {
  componentDidMount() {
    if (this.props.formValues.agreements) {
      this.setState({ ...this.props.formValues.agreements })
    } else {
      this.props.dispacth('agreements', {
        isChain: false,
        chainConnectionName: this.props.chainConnectionName || '',
        anonymize_chainConnectionName:
          this.props.anonymize_chainConnectionName || false,
        chainConnectionDescription: this.props.chainConnectionDescription || '',
        anonymize_chainConnectionDescription:
          this.props.anonymize_chainConnectionDescription || false,
        chainConnectionFiles: this.props.chainConnectionFiles
          ? Object.entries(this.props.chainConnectionFiles).map(
              ([path, name]) => ({
                path,
                name,
              })
            )
          : [],
        ...mapToState(lists, this.props),
        ...Object.keys(lists).reduce(
          (state, key) => ({
            ...state,
            [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
          }),
          {}
        ),
        agreementsComment: '',
      })
    }
  }

  // Set initial state
  // Will be overwritten by componentDidMount() if agreements are found in store
  state = {
    isChain: false,
    chainConnectionName: this.props.chainConnectionName || '',
    anonymize_chainConnectionName:
      this.props.anonymize_chainConnectionName || false,
    chainConnectionDescription: this.props.chainConnectionDescription || '',
    anonymize_chainConnectionDescription:
      this.props.anonymize_chainConnectionDescription || false,
    chainConnectionFiles: this.props.chainConnectionFiles
      ? Object.entries(this.props.chainConnectionFiles).map(([path, name]) => ({
          path,
          name,
        }))
      : [],
    ...mapToState(lists, this.props),
    ...Object.keys(lists).reduce(
      (state, key) => ({
        ...state,
        [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
      }),
      {}
    ),
    agreementsComment: '',
  }

  removeItem = (list, index) => {
    const updatedAgreements = {
      [list]: this.state[list].filter((_, i) => i !== index),
    }
    this.setState(updatedAgreements)
    this.props.dispacth('agreements', { ...this.state, ...updatedAgreements })
  }

  addItem = (list, item) =>
    this.setState({
      [list]: [...this.state[list], item],
    })

  setData = (property, index, key, value, rm) => {
    let oldFiles = []
    if (key === 'files' && this.state[property][index][key] && !rm) {
      oldFiles = this.state[property][index][key]
    }
    return {
      [property]: this.state[property].map((list, i) =>
        i === index
          ? {
              ...list,
              [key]: key === 'files' ? [...oldFiles, ...value] : value,
            }
          : list
      ),
    }
  }

  setValue = (property, index, key, value) => {
    if (
      key === 'files' &&
      value.length > 0 &&
      typeof value[0].name === 'string'
    ) {
      uploadFilesFromList(this.props.orgNumber, value).then(res => {
        const agreements = this.setData(property, index, key, res)
        this.props.dispacth('agreements', { ...this.state, ...agreements })
        this.setState(agreements)
      })
    } else if (
      key === 'files' &&
      (value.length === 0 || typeof value[0].name !== 'string')
    ) {
      const agreements = this.setData(property, index, key, value, true)
      this.props.dispacth('agreements', { ...this.state, ...agreements })
      this.setState(agreements)
    } else {
      const agreements = this.setData(property, index, key, value)
      this.props.dispacth('agreements', { ...this.state, ...agreements })
      this.setState(agreements)
    }
  }

  setComment = value => {
    this.setState({ agreementsComment: value })
    this.props.dispacth('agreements', {
      ...this.state,
      agreementsComment: value,
    })
  }

  render() {
    return (
      <>
        <Heading>
          Har virksomheten en kjedetilknytning? Ja:{' '}
          <Checkbox
            checked={this.state.isChain}
            onChange={e => {
              this.setState({ isChain: !this.state.isChain })
            }}
          />
        </Heading>
        <Divider />

        {this.state.isChain && (
          <>
            <Field>
              <Label>Navn/betegnelse</Label>
              <Input
                value={this.state.chainConnectionName}
                onChange={e =>
                  this.setState({ chainConnectionName: e.target.value })
                }
                placeholder={'Kjedens navn'}
              />
              {this.props.anonymized && (
                <AnonLabel>
                  <Checkbox
                    checked={this.state.anonymize_chainConnectionName}
                    onChange={e =>
                      this.setState({
                        anonymize_chainConnectionName: e.target.checked,
                      })
                    }
                  />
                  Feltet over skal anonymiseres i annonsen.
                </AnonLabel>
              )}
            </Field>
            <Field>
              <Label>Beskriv</Label>
              <Textarea
                value={this.state.chainConnectionDescription}
                onChange={e =>
                  this.setState({ chainConnectionDescription: e.target.value })
                }
                placeholder={
                  'Kort beskrivelse av kjeden og hvilke rettigheter og forpliktelser som følger av kjedetilknytningen.'
                }
              />
              {this.props.anonymized && (
                <AnonLabel>
                  <Checkbox
                    checked={this.state.anonymize_chainConnectionDescription}
                    onChange={e =>
                      this.setState({
                        anonymize_chainConnectionDescription: e.target.checked,
                      })
                    }
                  />
                  Feltet over skal anonymiseres i annonsen.
                </AnonLabel>
              )}
            </Field>
          </>
        )}
        <EditListOfLists
          {...this.props}
          lists={lists}
          state={this.state}
          removeItem={this.removeItem}
          addItem={this.addItem}
          setValue={this.setValue}
          setAnonymized={(key, value) => this.setState({ [key]: value })}
        />
      </>
    )
  }
}

export default EditAgreements
