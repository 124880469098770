import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { companyAdvertisementSelector } from 'selectors'
import { h6, subtitle, body } from 'styles/text'
import { wrapper, padding, content } from './styles'

import Expandable from 'components/Expandable'
import SellersComment from 'components/SellersComment'
import { lists } from 'components/Company/edit/EditAgreements'
import File from 'components/File'
import ListOfLists from './ListOfLists'
import AnonymizedField from 'components/AnonymizedField'

const Wrapper = styled.div`
  ${wrapper};
`

const Heading = styled.h6`
  ${h6};
  ${padding};
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const Content = styled.div`
  ${content};

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const ContentHeading = styled.h5`
  ${subtitle};
  font-size: 18px;
`

const ObjectHeading = styled.div`
  font-weight: bold;
`

const Value = styled.p`
  ${body}
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const Agreements = props => (
  <>
    {props.procurementAndSupplierAgreements ||
    props.deliveryObligations ||
    props.agencies ||
    props.certificates ||
    props.otherAgreements ||
    props.agreementsComment ||
    props.creditGurantee ||
    props.debitGuarantee ||
    props.attatchment ? (
      <Wrapper>
        <Expandable
          renderHeader={({ open }) => (
            <Heading open={open}>
              <span>Avtaler, rettigheter og forpliktelser</span>
              <img src="/toggle_down.svg" alt="" />
            </Heading>
          )}
          renderContent={() => (
            <Content>
              <div>
                {(props.chainConnectionName ||
                  (props.anonymized &&
                    props.anonymize_chainConnectionDescription &&
                    props.forSale)) && (
                  <div>
                    <ContentHeading>Kjedetilknyttning</ContentHeading>
                    <ObjectHeading>Navn/betegnelse</ObjectHeading>
                    <Value>
                      <AnonymizedField
                        {...props}
                        anonProp="anonymize_chainConnectionName"
                      >
                        {props.chainConnectionName}
                      </AnonymizedField>
                    </Value>
                  </div>
                )}
                {(props.chainConnectionDescription ||
                  (props.anonymized &&
                    props.anonymize_chainConnectionDescription &&
                    props.forSale)) && (
                  <div>
                    <ObjectHeading>Kommentar</ObjectHeading>
                    <Value>
                      <AnonymizedField
                        {...props}
                        anonProp="anonymize_chainConnectionDescription"
                      >
                        {props.chainConnectionDescription}
                      </AnonymizedField>
                    </Value>
                  </div>
                )}
                {props.chainConnectionFiles &&
                  Object.keys(props.chainConnectionFiles).length > 0 && (
                    <div>
                      <ObjectHeading>Vedlegg</ObjectHeading>
                      <ul>
                        {Object.entries(props.chainConnectionFiles || {}).map(
                          ([path, name]) => (
                            <li key={name}>
                              <File
                                path={'prospect'}
                                name={name}
                                orgNumber={props.orgNumber}
                              />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
              </div>
              <ListOfLists
                lists={lists}
                props={props}
                orgNumber={props.orgNumber}
              />
              {props.creditGurantee && (
                <>
                  <ObjectHeading>Har mottatt garanti (kreditor)</ObjectHeading>
                  <ul>
                    {props.creditGurantee.map(gurantee => (
                      <li>{gurantee}</li>
                    ))}
                  </ul>
                </>
              )}
              {props.debitGuarantee && (
                <>
                  <ObjectHeading>Har mottatt garanti (debitor)</ObjectHeading>
                  {
                    <ul>
                      {props.debitGuarantee.map(gurantee => (
                        <li>{gurantee}</li>
                      ))}
                    </ul>
                  }
                </>
              )}
              {props.attatchments && (
                <>
                  <ObjectHeading>Andre vedlegg:</ObjectHeading>
                  <ul>
                    {props.attatchments.map(attatchment => (
                      <li key={attatchment}>
                        <File
                          path={'prospect'}
                          name={attatchment}
                          orgNumber={props.orgNumber}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <SellersComment {...props}>{props.comment}</SellersComment>
            </Content>
          )}
        />
      </Wrapper>
    ) : null}
  </>
)

const mapStateToProps = (state, ownProps) => ({
  ...companyAdvertisementSelector(state, ownProps),
})

export default connect(mapStateToProps)(Agreements)
