import {
  FETCH_COMPANY_IS_PUBLISHABLE,
  FETCH_COMPANY_IS_PUBLISHABLE_SUCCESS,
  LOG_OUT,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_IS_PUBLISHABLE:
      return {
        ...state,
        [action.companyId]: 'pending',
      }
    case FETCH_COMPANY_IS_PUBLISHABLE_SUCCESS:
      return {
        ...state,
        [action.companyId]: action.companyIsPublishable,
      }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
