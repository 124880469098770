import { database, currentUserId, timestamp } from './firebase'
import fetch from './fetch'

export const fetchAdvertisement = advertisementId =>
  fetch(`/api/adinfo/${advertisementId}`, { mode: 'cors' }).then(res => {
    if (res.status === 404) {
      console.warn(`AdvertisementId: ${advertisementId} not found`)
      //throw new  Error('Not found')
    }
    return res.json()
  })

export const addAdvertisementToFavorites = id =>
  database
    .ref(`/user-favorited-advertisements/${currentUserId()}/${id}`)
    .set(timestamp)

export const removeAdvertisementFromFavorites = id =>
  database
    .ref(`/user-favorited-advertisements/${currentUserId()}/${id}`)
    .remove()

export const fetchFavoritedAdvertisements = advertisementId =>
  database
    .ref(`/user-favorited-advertisements/${currentUserId()}`)
    .once('value')
    .then(snapshot => snapshot.val() || {})
