import { average, median } from './utils'
import projectedRevenue from './projectedRevenue'

export const costOverRevenue = (data, options = {}) =>
  options.costOverRevenue !== undefined
    ? options.costOverRevenue
    : median(
        data
          .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
          .map(
            ({
              Salgsinntekter,
              Varekostnad,
              Beholdningsendring,
              Lonnskostnad,
              AnnenDriftskostnad,
            }) => ({
              revenue: Salgsinntekter,
              cost:
                Varekostnad +
                Beholdningsendring +
                Lonnskostnad +
                AnnenDriftskostnad,
            })
          )
          .map(({ cost, revenue }) => cost / revenue)
          .map((_, index, array) => average(array.slice(index)))
      )

export default (data, year, options) =>
  projectedRevenue(data, year, options) * costOverRevenue(data, options)
