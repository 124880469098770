import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { openMenu, closeMenu } from 'actions'
import { isMenuOpenSelector } from 'selectors'
import { border, colors, contentWidth } from 'styles'
import MenuItem from './MenuItem'

const height = '80px'

const Wrapper = styled.div`
  background-color: ${colors.white};
  border-bottom: ${border};
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
`

const Offset = styled.div`
  height: ${height};
`

const Content = styled.div`
  padding: 0 20px;
  height: ${height};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.a`
  width: 256px;
`

const MenuItems = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Header = ({ menuItems, isMenuOpen, closeMenu, openMenu }) => (
  <React.Fragment>
    <Wrapper>
      <Content>
        <Logo href="http://www.nobema.no">
          <img width="150" src="/logo.svg" alt="nobema" />
        </Logo>
        <MenuItems>
          {menuItems.map((menuItem, i) => (
            <MenuItem key={i} style={{ margin: '0 12px' }} {...menuItem} />
          ))}
        </MenuItems>
      </Content>
    </Wrapper>
    <Offset />
  </React.Fragment>
)

const mapStateToProps = state => ({
  isMenuOpen: isMenuOpenSelector(state),
})

const mapDispatchToProps = { openMenu, closeMenu }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
