import {
  PROSPECT_GENERATOR_UPDATE,
  PROSPECT_GENERATOR_SEED,
  PROSPECT_GENERATOR_CLEAR,
} from 'actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROSPECT_GENERATOR_UPDATE:
      return {
        ...state,
        ...action.update,
      }
    case PROSPECT_GENERATOR_SEED:
      return {
        ...state,
        ...action.update,
      }
    case PROSPECT_GENERATOR_CLEAR:
      return {}
    default:
      return state
  }
}
