import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { askForAccess, contactSeller } from 'actions'
import { link } from 'styles/text'

import withLogin from 'components/withLogin'
import ButtonComponent from 'components/Button'
import { Form, Checkboxes, Checkbox, Label, Field } from 'components/form'
import { BASIC_LICENCE } from '../../../../api/companies'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Cancel = styled.div`
  ${link};
  text-align: center;
  margin: 18px 0 0;
  cursor: pointer;
`

const Button = styled(ButtonComponent)`
  align-self: center;
`

const QUESTION_OPTIONS = [
  {
    label: 'Ja',
    value: 0,
  },
  {
    label: 'Nei',
    value: 1,
  },
]

const AskForAccessForm = ({
  closeModal,
  askForAccess,
  advertisementId,
  contactSeller,
  projectLicence,
}) => {
  const [checkedOptionsError, setCheckedOptionsError] = useState('')
  const [checkedOptions, setCheckedOptions] = useState([])
  const [termsError, setTermsError] = useState('')
  const [acceptTerms, setAcceptTerms] = useState([])
  const validateAnswers = () => {
    let validAnswers = true

    if (checkedOptions.length !== 0) {
      setCheckedOptionsError('')
    } else {
      setCheckedOptionsError('En eller flere alternativer må bli valgt!')
      validAnswers = false
    }

    if (acceptTerms.length !== 0) {
      setTermsError('')
    } else {
      setTermsError('Dette feltet må svares')
      validAnswers = false
    }

    return validAnswers
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()

        if (!validateAnswers()) {
          return
        }
        askForAccess(advertisementId, { answers: checkedOptions })
      }}
    >
      <Wrapper>
        <div style={{ marginBottom: 24, fontStyle: 'italic' }}>
          {projectLicence === BASIC_LICENCE &&
            'Denne annonsen har ikke tilhørende prospekt, men du kan likevel sende en melding til seller for å vise din interesse'}
        </div>
        <Field>
          <Label>
            Vennligst spesifiser bakgrunnen for din interesse i dette foretaket
          </Label>
          <Checkboxes
            error={checkedOptionsError}
            options={[
              'Egen operativ drift',
              'Utvide egen virksomhet',
              'Tilgang til geografisk markedsområde',
              'Bransjetilgang',
              'Finansiell investering',
            ].map(value => ({ value, label: value }))}
            value={checkedOptions}
            other="Annet"
            onChange={newAnswer => {
              setCheckedOptions(newAnswer)
            }}
          />
        </Field>
        <Field>
          <Label>Bekreftelse:</Label>
          <Checkboxes
            error={termsError}
            options={[
              'Denne besvarelsen blir sendt til selgeren av bedriften, sammen med ditt navn og epost adresse',
            ].map(value => ({
              value,
              label: value,
            }))}
            value={acceptTerms}
            onChange={newTerms => {
              setAcceptTerms(newTerms)
            }}
          />
        </Field>
        <Button>Be om tilgang</Button>
        <Cancel onClick={closeModal}>Avbryt</Cancel>
      </Wrapper>
    </Form>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = { askForAccess, contactSeller }

export default compose(
  withLogin,
  connect(mapStateToProps, mapDispatchToProps)
)(AskForAccessForm)
