import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fetchCompany } from 'actions'
import { companySelector, companyIsPendingSelector } from 'selectors'

import withSpinner from 'components/withSpinner'
import withFetchedData from 'components/withFetchedData'
import { h3, h4, Body } from '../../../styles/text'
import { border, colors } from 'styles'

import ModalButton from '../../../components/ModalButton'
import RequestModal from './RequestModal'
import Link from 'components/Link'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const RequestRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  border: ${border};
  padding: 3rem;
  border-radius: 0.5rem;
  height: 12rem;
  justify-content: space-between;
  background-color: ${colors.white};
  align-items: center;
  opacity: ${props => props.disabled ? "0.8" : "1"};
  filter: ${props => props.disabled ? "blur(1px)" : "blur(0)"};
`

const RequestButton = styled(ModalButton)`
  margin: 0 1rem;
`

const Heading = styled.h3`
  ${h3}
`

const BuyerName = styled.div`
  ${h4}
  width: 28rem;
`

const ProspectRequest = ({ companyId, buyerInfo, name, licence }) => {
  const numberOfRequests = Object.keys(buyerInfo).reduce((prev, buyer) => {
    if (!buyerInfo[buyer].accepted) {
      return prev + 1
    } else {
      return prev
    }
  }, 0)

  const companyLicence = licence()
  console.log(companyLicence)

  return (
    <Wrapper>
      {numberOfRequests > 0 ? <Heading>{name}</Heading> : null}
      {Object.keys(buyerInfo).map(buyer => {
        if (!buyerInfo[buyer].accepted) {
          return (
            <RequestRow
              disabled={companyLicence === 'basic'}
              key={`${buyer}_${companyId}`}
            >
              <BuyerName>{buyerInfo[buyer].buyerName}</BuyerName>
              {buyerInfo[buyer].pending && !buyerInfo[buyer].accepted ? (
                <Body>
                  <b>Forespørsel godkjent.</b> Lesetilgang åpnes etter at
                  interessent har signert Fortrolighetserklæring.
                </Body>
              ) : (
                <>
                  {companyLicence === 'basic' && (
                    <Body>Oppgrader lisens for å gi lesetilgang til prospekt</Body>
                  )}
                  <div>
                    <RequestButton
                      disabled={companyLicence === 'basic'}
                      title={`Godkjenn forespørsel for: ${buyerInfo[buyer].buyerName}`}
                      renderModalContent={props => (
                        <RequestModal
                          {...props}
                          companyId={companyId}
                          message={
                            'Ved godkjenning av forespørselen vil lesetilgang til ditt salgsprospekt åpnes for interessent, etter signering av fortrolighetserklæring.'
                          }
                          buttonText={'Godkjenn'}
                          buyerUserId={buyer}
                          status={true}
                          removed={false}
                        />
                      )}
                    >
                      Godkjenn
                    </RequestButton>
                    <RequestButton
                      disabled={companyLicence === 'basic'}
                      title={`Avslå forespørsel for: ${buyerInfo[buyer].buyerName}`}
                      renderModalContent={props => (
                        <RequestModal
                          {...props}
                          companyId={companyId}
                          message={`Ved avslag av forespørselen vil interessenten få 
                        en generell beskjed om at salgsprospektet ikke er tilgjengelig.`}
                          buttonText={'Avslå'}
                          buyerUserId={buyer}
                          status={false}
                          removed={false}
                        />
                      )}
                    >
                      Avslå
                    </RequestButton>
                  </div>
                </>
              )}
            </RequestRow>
          )
        } else {
          return null
        }
      })}
    </Wrapper>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...companySelector(state, ownProps),
})

export default compose(
  withFetchedData(props => fetchCompany(props.companyId)),
  withSpinner(companyIsPendingSelector),
  connect(mapStateToProps)
)(ProspectRequest)
