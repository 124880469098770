import { call, put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import * as api from 'api'
import {
  ASK_FOR_ACCESS,
  ASK_FOR_ACCESS_SUCCESS,
  ASK_FOR_ACCESS_ERROR,
  REQUEST_QUOTE_SUCCESS,
  REQUEST_QUOTE_ERROR,
  REQUEST_QUOTE,
  BUY_SERVICE,
  BUY_SERVICE_SUCCESS,
  BUY_SERVICE_ERROR,
  BUY_REPRESENTATION,
  BUY_REPRESENTATION_SUCCESS,
  BUY_REPRESENTATION_ERROR,
  INVITE_HELPER,
  INVITE_HELPER_SUCCESS,
  INVITE_HELPER_ERROR,
  CONTACT_NOBEMA,
  CONTACT_NOBEMA_SUCCESS,
  CONTACT_NOBEMA_ERROR,
  CONTACT_SELLER,
  CONTACT_SELLER_ERROR,
  CONTACT_SELLER_SUCCESS,
} from 'actionTypes'

function* askForAccess({ advertisementId, data }) {
  try {
    yield call(api.askForAccess, advertisementId, data)
    yield put({ type: ASK_FOR_ACCESS_SUCCESS, advertisementId, data })
  } catch ({ message }) {
    yield put({ type: ASK_FOR_ACCESS_ERROR, advertisementId, data, message })
  }
}

function* requestQuote({ form }) {
  try {
    yield call(api.requestQuote, form)
    yield put({ type: REQUEST_QUOTE_SUCCESS })
  } catch ({ message }) {
    yield put({ type: REQUEST_QUOTE_ERROR, message })
  }
}

function* buyService({ data }) {
  try {
    yield call(api.buyService, data)
    yield put({ type: BUY_SERVICE_SUCCESS })
  } catch ({ message }) {
    yield put({ type: BUY_SERVICE_ERROR, message })
  }
}

function* buyRepresentation({ data }) {
  try {
    yield call(api.buyRepresentation, data)
    yield put({ type: BUY_REPRESENTATION_SUCCESS })
  } catch ({ message }) {
    yield put({ type: BUY_REPRESENTATION_ERROR, message })
  }
}

function* inviteHelper({ data }) {
  try {
    yield call(api.inviteHelper, data)
    yield put({ type: INVITE_HELPER_SUCCESS, data })
  } catch ({ message }) {
    yield put({ type: INVITE_HELPER_ERROR, data, message })
  }
}

function* contactNobema({ data }) {
  try {
    yield call(api.contactNobema, data)
    yield put({ type: CONTACT_NOBEMA_SUCCESS, data })
  } catch ({ message }) {
    yield put({ type: CONTACT_NOBEMA_ERROR, data, message })
  }
}

function* contactSeller({ data }) {
  try {
    yield call(api.contactSeller, data)
    yield put({ type: CONTACT_SELLER_SUCCESS, data })
  } catch ({ message }) {
    yield put({ type: CONTACT_SELLER_ERROR, data, message })
  }
}

function* goToSuccessPage() {
  yield put(push('/success'))
}

function* saga() {
  yield takeEvery(
    [
      ASK_FOR_ACCESS_SUCCESS,
      REQUEST_QUOTE_SUCCESS,
      BUY_SERVICE_SUCCESS,
      BUY_REPRESENTATION_SUCCESS,
    ],
    goToSuccessPage
  )
  yield takeEvery(ASK_FOR_ACCESS, askForAccess)
  yield takeEvery(REQUEST_QUOTE, requestQuote)
  yield takeEvery(BUY_SERVICE, buyService)
  yield takeEvery(BUY_REPRESENTATION, buyRepresentation)
  yield takeEvery(INVITE_HELPER, inviteHelper)
  yield takeEvery(CONTACT_NOBEMA, contactNobema)
  yield takeEvery(CONTACT_SELLER, contactSeller)
}

export default saga
