import { call, put, takeEvery } from 'redux-saga/effects'

import * as api from 'api'
import {
  PUBLISH_COMPANY_ADVERTISEMENT,
  PUBLISH_COMPANY_ADVERTISEMENT_SUCCESS,
  PUBLISH_COMPANY_ADVERTISEMENT_ERROR,
  UPDATE_COMPANY_ADVERTISEMENT,
  UPDATE_COMPANY_ADVERTISEMENT_SUCCESS,
  UPDATE_COMPANY_ADVERTISEMENT_ERROR,
  FETCH_COMPANY_ADVERTISEMENT,
  FETCH_COMPANY_ADVERTISEMENT_SUCCESS,
  FETCH_COMPANY_ADVERTISEMENT_ERROR,
} from 'actionTypes'

function* publishCompanyAdvertisement({ companyId, annonseData }) {
  try {
    yield call(api.publishCompanyAdvertisement, companyId, annonseData)
    yield put({
      type: PUBLISH_COMPANY_ADVERTISEMENT_SUCCESS,
      companyId,
      annonseData,
    })
  } catch ({ message }) {
    yield put({ type: PUBLISH_COMPANY_ADVERTISEMENT_ERROR, message })
  }
}

function* updateCompanyAdvertisement({ companyId, payload }) {
  try {
    const values = yield call(
      api.updateCompanyAdvertisement,
      companyId,
      payload
    )
    yield put({
      type: UPDATE_COMPANY_ADVERTISEMENT_SUCCESS,
      companyId,
      payload: values,
    })
  } catch ({ message }) {
    yield put({
      type: UPDATE_COMPANY_ADVERTISEMENT_ERROR,
      companyId,
      payload,
      message,
    })
  }
}

function* fetchCompanyAdvertisement({ companyId }) {
  try {
    const companyAdvertisement = yield call(
      api.fetchCompanyAdvertisement,
      companyId
    )
    yield put({
      type: FETCH_COMPANY_ADVERTISEMENT_SUCCESS,
      companyId,
      companyAdvertisement,
    })
  } catch ({ message }) {
    yield put({ type: FETCH_COMPANY_ADVERTISEMENT_ERROR, companyId, message })
  }
}

function* saga() {
  yield takeEvery(PUBLISH_COMPANY_ADVERTISEMENT, publishCompanyAdvertisement)
  yield takeEvery(UPDATE_COMPANY_ADVERTISEMENT, updateCompanyAdvertisement)
  yield takeEvery(FETCH_COMPANY_ADVERTISEMENT, fetchCompanyAdvertisement)
}

export default saga
