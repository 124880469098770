import React from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'

import { colors } from 'styles'

const Wrapper = styled.div`
  width: 100px;
  margin: 100px auto 0;
`

const Spinner = () => (
  <Wrapper>
    <Loader type="Oval" color={colors.action} height="100" width="100" />
  </Wrapper>
)

export default Spinner
