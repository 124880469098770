import React from 'react'
import styled from 'styled-components'

import Anonymize from './Anonymize'
import ContactOwner from './ContactOwner'
import BuyersChecklist from './BuyersChecklist'
import Services from './Services'
import IsThisYourCompany from './IsThisYourCompany'
import NeedHelp from './NeedHelp'
import ButtonComponent from 'components/Button'
import PublishButton from './PublishButton'
import AskForAccess from './AskForAccess'
import ContactSeller from './ContactSeller'
import ContactInfo from './ContactInfo'
import ModalButtonComponent from 'components/ModalButton'
import InviteHelperForm from './InviteHelperForm'
import InitiateNegotiation from './InitiateNegotiation'
import BuyNobema from './BuyNobema'

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    & > :not(:last-child) {
      margin-bottom: 30px;
    }
  }
`

const Button = styled(ButtonComponent)`
  width: 100%;
  text-align: center;
`

const ModalButton = styled(ModalButtonComponent)`
  width: 100%;
  text-align: center;
`

const Sidebar = ({ className, ...props }) =>
  props.claimed ? (
    <Wrapper className={className}>
      <ContactInfo {...props} />
      <NeedHelp />
    </Wrapper>
  ) : (
    <Wrapper className={className}>
      {props.fullProspect || props.prospectPreview ? (
        <>
          <ContactSeller {...props} />
          <InitiateNegotiation {...props} />
          <BuyersChecklist {...props} />
        </>
      ) : props.advertisementForSale || props.previewAdvertisement ? (
        <>
          <ContactSeller anonymized={props.previewAnonymized} {...props} />
          <AskForAccess {...props} />
        </>
      ) : (
        <ContactOwner {...props} />
      )}
      <BuyNobema />
      <Services />
      {!props.advertisementForSale && <IsThisYourCompany {...props} />}
    </Wrapper>
  )

export default Sidebar
