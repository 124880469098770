import { call, put, takeEvery } from 'redux-saga/effects'

import * as api from 'api'
import {
  FETCH_ADVERTISEMENT,
  FETCH_ADVERTISEMENT_SUCCESS,
  FETCH_ADVERTISEMENT_ERROR,
  ADD_ADVERTISEMENT_TO_FAVORITES,
  ADD_ADVERTISEMENT_TO_FAVORITES_SUCCESS,
  ADD_ADVERTISEMENT_TO_FAVORITES_ERROR,
  REMOVE_ADVERTISEMENT_FROM_FAVORITES,
  REMOVE_ADVERTISEMENT_FROM_FAVORITES_SUCCESS,
  REMOVE_ADVERTISEMENT_FROM_FAVORITES_ERROR,
  FETCH_FAVORITED_ADVERTISEMENTS,
  FETCH_FAVORITED_ADVERTISEMENTS_SUCCESS,
  FETCH_FAVORITED_ADVERTISEMENTS_ERROR,
} from 'actionTypes'

function* fetchAdvertisement({ advertisementId }) {
  try {
    const advertisement = yield call(api.fetchAdvertisement, advertisementId)
    yield put({
      type: FETCH_ADVERTISEMENT_SUCCESS,
      advertisementId,
      advertisement,
    })
  } catch ({ message }) {
    yield put({ type: FETCH_ADVERTISEMENT_ERROR, advertisementId, message })
  }
}

function* addAdvertisementToFavorites({ advertisementId }) {
  try {
    yield call(api.addAdvertisementToFavorites, advertisementId)
    yield put({ type: ADD_ADVERTISEMENT_TO_FAVORITES_SUCCESS, advertisementId })
  } catch ({ message }) {
    yield put({
      type: ADD_ADVERTISEMENT_TO_FAVORITES_ERROR,
      advertisementId,
      message,
    })
  }
}

function* removeAdvertisementFromFavorites({ advertisementId }) {
  try {
    yield call(api.removeAdvertisementFromFavorites, advertisementId)
    yield put({
      type: REMOVE_ADVERTISEMENT_FROM_FAVORITES_SUCCESS,
      advertisementId,
    })
  } catch ({ message }) {
    yield put({
      type: REMOVE_ADVERTISEMENT_FROM_FAVORITES_ERROR,
      advertisementId,
      message,
    })
  }
}

function* fetchFavoritedAdvertisements() {
  try {
    const favoritedAdvertisements = yield call(api.fetchFavoritedAdvertisements)
    yield put({
      type: FETCH_FAVORITED_ADVERTISEMENTS_SUCCESS,
      favoritedAdvertisements,
    })
  } catch ({ message }) {
    yield put({ type: FETCH_FAVORITED_ADVERTISEMENTS_ERROR, message })
  }
}

function* saga() {
  yield takeEvery(FETCH_ADVERTISEMENT, fetchAdvertisement)
  yield takeEvery(ADD_ADVERTISEMENT_TO_FAVORITES, addAdvertisementToFavorites)
  yield takeEvery(
    REMOVE_ADVERTISEMENT_FROM_FAVORITES,
    removeAdvertisementFromFavorites
  )
  yield takeEvery(FETCH_FAVORITED_ADVERTISEMENTS, fetchFavoritedAdvertisements)
}

export default saga
