export const companyAdvertisementSelector = (state, props) =>
  state.companyAdvertisements[props.companyId]

export const companyAdvertisementForSaleSelector = (state, props) =>
  state.companyAdvertisements[props.companyId] &&
  state.companyAdvertisements[props.companyId].published

export const companyAdvertisementIsPendingSelector = (state, props) =>
  (companyAdvertisementSelector(state, props) || {}).pending

export const companyAdvertisementUpdateIsPendingSelector = (state, props) =>
  (companyAdvertisementSelector(state, props) || {}).updateIsPending

export const companyAdvertisementIsAnonymizedSelector = (state, props) =>
  (companyAdvertisementSelector(state, props) || {}).anonymized

export const companiesAdvertisementAnonymizeToggleSelector = (state, props) => {
  const orgNumber = props.orgNumber || props.companyId
  return (state.companiesAdvertisementAnonymizeToggle[orgNumber] || {})
    .anonymized
}
