import styled from 'styled-components'

import { colors } from 'styles'
import { h4, body } from 'styles/text'

import ButtonComponent from 'components/Button'

export const Heading = styled.div`
  ${h4};
  color: ${props => (props.beeStyling ? colors.black : colors.primary)};
`

export const Button = styled(ButtonComponent)`
  text-align: center;
  background: ${props => (props.beeStyling ? colors.black : '')};
  &:focus,
  &:hover {
    background-color: ${props => (props.beeStyling ? colors.blackHover : '')};
  }

  &:active {
    background-color: ${props => (props.beeStyling ? colors.blackPressed : '')};
  }
  width: 100%;
`

export default styled.div`
  ${body};
  background-color: ${props =>
    props.beeStyling ? colors.highlight : colors.alabasterBackground};
  padding: 15px;
  border-radius: 4px;

  @media (min-width: 768px) {
    padding: 40px;
  }
`
