import { median, average } from './utils'
import projectedRevenue from './projectedRevenue'

export const investmentOverRevenue = data =>
  median(
    data
      .sort((a, b) => a.RegnskapsAvAr - b.RegnskapsAvAr)
      .map(({ SumVarigeDriftsmidler, Salgsinntekter }, index, array) => ({
        investment:
          SumVarigeDriftsmidler -
          (array[index - 1] || {}).SumVarigeDriftsmidler,
        revenue: Salgsinntekter,
      }))
      .map(({ investment, revenue }, index, array) =>
        Math.max(investment / revenue, 0)
      )
      .map((_, index, array) =>
        average(array.slice(index).filter(n => !isNaN(n)))
      )
  )

export default (data, year, options) =>
  projectedRevenue(data, year, options) * investmentOverRevenue(data)
