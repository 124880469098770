import React from 'react'
import styled from 'styled-components'

import Expandable from 'components/Expandable'
import { border } from 'styles'
import { h5, h3 } from 'styles/text'
import GraphComponent from 'components/Graph'
import Accounts from './Accounts'
import SellersComment from 'components/SellersComment'
import File from 'components/File'

const Heading = styled.div`
  ${h5};
`

const Graph = styled(GraphComponent)`
  margin-top: 14px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${border};
  padding-top: 10px;
  margin-top: 10px;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const ContentHeading = styled.h3`
  ${h3};
`

const EconomicFigures = props => (
  <React.Fragment>
    <Heading>Siste registrerte offentlige regnskap ({props.RegnskapsAvAr})</Heading>
    <Expandable
      renderHeader={({ open }) => (
        <Header open={open}>
          <div>
            <div>Driftsinntekter</div>
            <div>
              {!isNaN(props.Salgsinntekter) &&
                (props.Salgsinntekter * 1000).toLocaleString('nb')}
            </div>
          </div>
          <img src="/toggle_down.svg" alt="" />
        </Header>
      )}
      renderContent={() => (
        <Graph data={[{ dataset: props.HistoricSalgsinntekter, label: '' }]} />
      )}
    />
    <Expandable
      renderHeader={({ open }) => (
        <Header open={open}>
          <div>
            <div>Resultat før skatt</div>
            <div>
              {!isNaN(props.OrdResultatForSkatt) &&
                (props.OrdResultatForSkatt * 1000).toLocaleString('nb')}
            </div>
          </div>
          <img src="/toggle_down.svg" alt="" />
        </Header>
      )}
      renderContent={() => (
        <Graph
          data={[{ dataset: props.HistoricOrdResultatForSkatt, label: '' }]}
        />
      )}
    />
    <Expandable
      renderHeader={({ open }) => (
        <Header open={open}>
          <div>
            <div>Egenkapital</div>
            <div>
              {!isNaN(props.SumEgenkapital) &&
                (props.SumEgenkapital * 1000).toLocaleString('nb')}
            </div>
          </div>
          <img src="/toggle_down.svg" alt="" />
        </Header>
      )}
      renderContent={() => (
        <Graph data={[{ dataset: props.HistoricSumEgenkapital, label: '' }]} />
      )}
    />
    <Expandable
      renderHeader={({ open }) => (
        <Header open={open}>
          <div>Se fullt regnskap</div>
          <img src="/toggle_down.svg" alt="" />
        </Header>
      )}
      renderContent={() => <Accounts {...props} />}
    />
    {props.keyFiguresFiles && Object.keys(props.keyFiguresFiles).length > 0 && (
      <div>
        <ContentHeading>Vedlegg</ContentHeading>
        <ul>
          {Object.entries(props.keyFiguresFiles || {}).map(([path, name]) => (
            <li key={path}>
              <File path={path} name={name} />
            </li>
          ))}
        </ul>
      </div>
    )}
    <SellersComment {...props}>{props.keyFiguresComment}</SellersComment>
  </React.Fragment>
)

export default EconomicFigures
