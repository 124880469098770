import React from 'react'
import styled from 'styled-components'

import { subtitle } from 'styles/text'

import File from 'components/File'
import Anonymized from 'components/Anonymized'

const Heading = styled.h4`
  ${subtitle};

  & > :not(:last-child) {
    margin-right: 4px;
  }
`

const List = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`

const Li = styled.li`
  list-style: none;
  overflow-wrap: break-word;
  margin-bottom: 4px;
`

const ListOfLists = ({ lists, props }) =>
  Object.entries(lists)
    .filter(([list]) => props[list])
    .map(([list, { heading, fields, alwaysAnonymized }]) => (
      <div key={list}>
        <Heading>
          {props.anonymized &&
            (props[`anonymize_${list}`] || alwaysAnonymized) &&
            props.claimed && <img src="/anonymized.svg" alt="" />}
          <span>{heading}</span>
        </Heading>
        {props.anonymized &&
        (props[`anonymize_${list}`] || alwaysAnonymized) &&
        props.forSale ? (
          <Anonymized />
        ) : (
          <List>
            {props[list].map((item, index) => (
              <li key={index}>
                <div>{index + 1}</div>
                <ul>
                  {fields
                    .map(field => field.prop)
                    .filter(key =>
                      key === 'files'
                        ? item[key] && item[key].length > 0
                        : item[key]
                    )
                    .map(key => (
                      <Li key={key}>
                        <b>{fields.find(field => field.prop === key).label}</b>:{' '}
                        {key === 'files' ? (
                          <ul>
                            {Object.entries(item[key]).map(([path, name]) => {
                              return (
                                <li key={name}>
                                  <File
                                    path={'prospect'}
                                    name={name}
                                    orgNumber={props.orgNumber}
                                  />
                                </li>
                              )
                            })}
                          </ul>
                        ) : (
                          item[key].replace(/other:/, '')
                        )}
                      </Li>
                    ))}
                </ul>
              </li>
            ))}
          </List>
        )}
      </div>
    ))

export default ListOfLists
