import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { h4 } from 'styles/text'
import Button from 'components/Button'
import InfoBoxComponent, { Heading as HeadingComponent } from './InfoBox'

const InfoBox = styled(InfoBoxComponent)`
  background-color: ${colors.white};
  color: ${colors.secondary};
  border: 1px solid ${colors.secondary};
`

const Heading = styled.h4`
  ${h4};
  color: ${colors.secondary};
`

const ContactOwner = ({ companyId }) => (
  <InfoBox>
    <Heading>Kjøpe denne bedriften?</Heading>
    <p>
      Nobema tar kontakt med bedriften og avklarer eventuell interesse for salg.
      Du er anonym inntil bedriften har akseptert ønsket om dialog.
    </p>
    <Button
      secondary
      href="http://www.nobema.no/representasjon"
      target="_blank"
    >
      Kjøp representasjon
    </Button>
  </InfoBox>
)

export default ContactOwner
