import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const Dropzone = styled.div`
  height: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  width: 100%;
`

const P = styled.p`
  color: white;
  font-size: 1.4rem;
  text-transform: uppercase;
`

const ImageUpload = ({ onChange }) => {
  const onDrop = useCallback(acceptedFiles => {
    return onChange(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Dropzone {...getRootProps()}>
      <input {...getInputProps()} />
      <P>
        {isDragActive
          ? 'Dropp filene her'
          : 'Last opp vedlegg til dette objektet +'}
      </P>
    </Dropzone>
  )
}

export default ImageUpload
