import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SideNavigationBar from '../components/SideNavigation'
import {
  prospectGeneratorUpdate,
  prospectGeneratorSeed,
  clearProspectGenerator,
  fetchCompanyAdvertisementAnonymizeToggle,
  updateCompanyAdvertisementAnonymizeToggle,
  fetchMarketing,
} from '../actions'
import {
  companiesAdvertisementAnonymizeToggleSelector,
  marketingSelector,
  marketingIsPendingSelector,
} from '../selectors'
import withFetchedData from '../components/withFetchedData'
import withLogin from '../components/withLogin'
import { H2, Body, body, h5 } from '../styles/text'
import AdvertisementCard from '../components/AdvertisementCard'
import Button from '../components/Button'
import Spinner from '../components/Spinner'
import { fetchCompanyProspect, fetchClaimedCompanies } from '../api/companies'
import {
  requestAdvertisementPublish,
  deleteAdvertisement,
  fetchCompanyAdvertisement,
  publishCompanyAdvertisement,
} from '../api/companyAdvertisements'
import { canUserPublishAdvertisement } from '../api/users'
import ModalButton from '../components/ModalButton'
import { link } from 'styles/text'
import { Label } from '../components/form'
import NoAccess from '../components/NoAccess'
import InfoMessage from '../components/InfoMessage'
import Link from 'components/Link'
import {
  MARKETING_NONE,
  MARKETING_LEVELS,
  prettyPrice,
  roundToInterval,
} from '../utils'
import Expandable from 'components/Expandable'
import { border, colors } from '../styles'
import { StatusDot } from '../components/StatusDot'
import { StyledSwitch } from '../components/form/Switch'
import withSpinner from 'components/withSpinner'

const Wrapper = styled.div`
  margin: 4rem;
`

const StyledLabel = styled(Label)`
  ${body};
  font-size: 2.6rem;
`

const StyledButton = styled(Button)`
  margin: 2rem;
  height: 5rem;
  text-align: center;
`

const PreviewButton = styled(Button)`
  height: 5rem;
  width: 100%;
`

const StyledModalButton = styled(ModalButton)`
  margin: 2rem;
  height: 5rem;
  text-align: center;
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ModalMessage = styled.p`
  text-align: center;
`

const Cancel = styled.div`
  ${link}
  text-align: center;
  margin: 18px 0 0;
  cursor: pointer;
`

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const H5 = styled.h5`
  ${h5};
  font-size: 2.6rem;
  line-height: 3.4rem;
  margin: 24px 0;
  color: ${colors.primary};
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 24px;
`

const Divider = styled.hr`
  border: 1px solid ${colors.primary};
`

const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  background: white;
  border-top: ${border};
  width: 100%;
`

const ExpandableWrapper = styled.div`
  /* border: 2px solid ${colors.primary};
  border-radius: 4px;
  margin: 4px 0 36px 0; */
`

const ExpandableHeading = styled.div`
  display: flex;
  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
    margin-left: 8px;
  }
`

const ExpandableWrapperContent = styled.div`
  //padding: 4rem;
`
const FlexItem = styled.div`
  margin-bottom: 36px;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`

const DeleteModal = props => {
  const handleDelete = async orgNr => {
    props.setSpinnerActive(true)
    props.setIsPublished(false)
    //await fetchCompanyAdvertisement(orgNr)
    await deleteAdvertisement(orgNr)
    props.setSpinnerActive(false)
  }

  return (
    <ModalWrapper>
      <ModalMessage>
        Du er på vei til å avpublisere denne annonsen. Er du sikker på dette?
      </ModalMessage>
      <Button onClick={() => handleDelete(props.orgNr)}>
        Avpubliser annonse
      </Button>
      <Cancel onClick={props.closeModal}>Avbryt</Cancel>
    </ModalWrapper>
  )
}

//Populate the state
const ProspectAdvertisement = ({
  clearProspectGenerator,
  orgNumber,
  prospectGeneratorSeed,
  anonymizedState,
  updateCompanyAdvertisementAnonymizeToggle,
  marketing,
}) => {
  const [spinnerActive, setSpinnerActive] = useState(true)
  const [publishRequest, setPublishRequest] = useState(false)
  const [canPublish, setCanPublish] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [anonymized, setIsAnonymized] = useState(true)
  const [haveNoAccess, setHaveNoAccess] = useState(false)
  const [currentMarketing] = useState(marketing[orgNumber] || MARKETING_NONE)
  const [prospectData, setProspectData] = useState({})

  useEffect(() => {
    return () => {
      clearProspectGenerator({})
    }
  }, [])

  useEffect(() => {
    clearProspectGenerator({})
    fetchClaimedCompanies(orgNumber).then(res => {
      if (res[orgNumber]) {
        Promise.all([
          fetchCompanyProspect(orgNumber),
          fetchCompanyAdvertisement(orgNumber),
          canUserPublishAdvertisement(orgNumber),
          //fetchBoughtProductByOrgNr(orgNumber),
        ])
          .then(
            ([
              manualData,
              companyAdvertisement,
              publishAccess /*boughtProduct*/,
            ]) => {
              const companyData = { ...manualData }
              prospectGeneratorSeed({
                ...companyData,
                ...companyData.grunnfakta,
                ...companyData.address,
              })
              setProspectData(companyData)
              setCanPublish(publishAccess)
              const published = companyAdvertisement
                ? companyAdvertisement.published
                : false

              let anonymizedToggle = companyAdvertisement
                ? companyAdvertisement.anonymized
                : true
              /*|| !boughtProduct*/
              if (typeof anonymizedState !== 'undefined') {
                anonymizedToggle = anonymizedState
              }
              setIsPublished(published)
              setIsAnonymized(anonymizedToggle)
              updateCompanyAdvertisementAnonymizeToggle(
                orgNumber,
                anonymizedToggle
              )
              // Bare relevant for å skille mellom graits brukerne og betalende kunder (Ikke noe vi tar hensyn til med en gang)
              //setHasFullService(boughtProduct)
            }
          )
          .then(() => {
            setSpinnerActive(false)
          })
          .catch(e => console.log(e))
      } else {
        setSpinnerActive(false)
        setHaveNoAccess(true)
      }
    })
  }, [])

  const handlePublishRequest = annonseData => {
    Promise.all([setSpinnerActive(true), setPublishRequest(true)])
      .then(() => {
        return requestAdvertisementPublish(orgNumber, annonseData)
      })
      .then(() => {
        setSpinnerActive(false)
      })
  }

  const handlePublish = annonseData => {
    Promise.all([
      setSpinnerActive(true),
      publishCompanyAdvertisement(orgNumber, annonseData),
    ])
      .then(() => {
        setIsPublished(true)
      })
      .then(() => {
        setSpinnerActive(false)
      })
  }

  const createAdvertData = () => {
    let advertAttributes = [
      'advertisementCardLabel',
      'contactFirstName',
      'contactLastName',
      'contactTitle',
      'contactPhone',
      'companyWebsite',
      'ownerFirstName',
      'ownerLastName',
      'ownerPhone',
      'shareForSale',
      'name',
      'prospectTitle',
      'employeeBoolean',
      'employees',
      'address',
      'fylke',
      'municipality',
      'postplace',
      'orgnr',
      'okonomiSammendragForetak',
      'EtablertAr',
      'BransjeKode',
      'BransjeTekst',
      'companyIngress',
      'prospectTitle',
      'headerImage',
      'companyLifeCycle',
      'eiendelerForetak',
      'salesReason',
      'okonomiDetaljerForetak',
      'gjeldEgenkapitalForetak',
      'purchaseArguments',
      'shareForSale',
      'userValueEstimate',
      'turnover',
      'ek',
      'logoUpload',
      'advertisementCardLabel',
      'customEconomyYear',
      'userValueEstimateComment',
      'projectNumber',
      'industryCode',
      'projectLicence',
    ]

    let anonymizedAdvertAttributes = [
      'headerImage',
      'prospectTitle',
      'shareForSale',
      'employeeBoolean',
      'employees',
      'EtablertAr',
      'BransjeKode',
      'BransjeTekst',
      'companyIngress',
      'companyLifeCycle',
      'eiendelerForetak',
      'salesReason',
      'companyIngres',
      'municipality',
      'purchaseArguments',
      'okonomiSammendragForetak',
      'userValueEstimate',
      'turnover',
      'ek',
      'fylke',
      'advertisementCardLabel',
      'customEconomyYear',
      'userValueEstimateComment',
      'projectNumber',
      'industryCode',
      'projectLicence',
    ]

    if (prospectData.showSellerInAd) {
      const sellerKeys = [
        'contactLogo',
        'contactTitle',
        'showSellerInAd',
        'contactFirstName',
        'contactLastName',
        'contactEmail',
        'contactPhone',
        'contactCompany',
        'contactWebsite',
      ]
      if (anonymized) {
        anonymizedAdvertAttributes = [
          ...anonymizedAdvertAttributes,
          ...sellerKeys,
        ]
      } else {
        advertAttributes = [...advertAttributes, ...sellerKeys]
      }
    }

    const returnData = {}
    const ekData = roundToInterval(prospectData['ek'])
    const turnoverData = roundToInterval(prospectData['turnover'])

    if (anonymized) {
      returnData['place'] = prospectData['fylke']

      anonymizedAdvertAttributes.forEach(key => {
        returnData[key] =
          prospectData[key] || !isNaN(prospectData[key])
            ? prospectData[key]
            : ''
      })

      returnData['ekString'] = ekData.label
      returnData['turnoverString'] = turnoverData.label

      if (turnoverData.lower) {
        returnData['turnoverLower'] = turnoverData.lower
      }
      turnoverData['turnoverUpper'] = turnoverData.upper
    } else {
      returnData[
        'place'
      ] = `${prospectData['fylke']}, ${prospectData['municipality']}`
      advertAttributes.forEach(key => {
        returnData[key] =
          prospectData[key] || !isNaN(prospectData[key])
            ? prospectData[key]
            : ''
      })

      returnData['ekString'] = prettyPrice(prospectData['ek'])
      returnData['turnoverString'] = prettyPrice(prospectData['turnover'])

      if (!prospectData.showSellerInAd) {
        returnData['contactFirstName'] = prospectData.ownerFirstName
        returnData['contactLastName'] = prospectData.ownerLastName
        returnData['contactTitle'] = ''
        returnData['contactPhone'] = prospectData.ownerPhone
      }
    }

    // Handle properties
    returnData['ownedProperty'] = prospectData.propertyOwnedBoolean || false
    returnData['rentedProperty'] = prospectData.propertyRentedBoolean || false

    if (!returnData['eiendelerForetak']) {
      returnData['eiendelerForetak'] = [{}]
    }

    if (prospectData.userValueEstimate) {
      returnData['userValueEstimateNum'] = parseInt(
        prospectData.userValueEstimate
      )
    }

    if (prospectData.turnover) {
      returnData['turnoverNum'] = parseInt(prospectData.turnover)
    }

    if (!anonymized && !returnData['gjeldEgenkapitalForetak']) {
      returnData['gjeldEgenkapitalForetak'] = [{}]
    }
    if (!returnData['okonomiDetaljerForetak']) {
      returnData['okonomiDetaljerForetak'] = [{}]
    }
    if (!returnData['okonomiSammendragForetak']) {
      returnData['okonomiSammendragForetak'] = [{}]
    }

    if (returnData['purchaseArguments'].arguments) {
      returnData['purchaseArguments'].arguments = returnData[
        'purchaseArguments'
      ].arguments.map(argument => ({
        name: argument.name,
        body: argument.body,
      }))
    } else if (!returnData['purchaseArguments']) {
      returnData['purchaseArguments'] = {
        anonymize_arguments: false,
        purchaseArgumentsComment: '',
      }
    }
    return returnData
  }

  return (
    <>
      {spinnerActive ? (
        <Spinner />
      ) : haveNoAccess ? (
        <NoAccess />
      ) : (
        <SideNavigationBar active={'annonse'} orgNumber={orgNumber}>
          <Wrapper>
            <H2>
              Annonse -{' '}
              {prospectData['name'] ? prospectData['name'] : orgNumber}
            </H2>
            {publishRequest && !canPublish && (
              <InfoMessage>
                Annonsen er nå sendt til gjennomgang. Du får en epost så snart
                annonsen er klar til publisering.
              </InfoMessage>
            )}
            <Content>
              <FlexItem style={{ marginRight: 56 }}>
                <StatusRow>
                  <StyledLabel>
                    {isPublished ? (
                      <>
                        <StatusDot status="active" /> Annonsen er publisert
                      </>
                    ) : (
                      <>
                        <StatusDot status="inactive" /> Annonsen er ikke
                        publisert
                      </>
                    )}
                  </StyledLabel>
                </StatusRow>
                <StatusRow style={{ marginBottom: 36 }}>
                  <StatusDot
                    status={
                      currentMarketing !== MARKETING_NONE ? 'active' : 'pending'
                    }
                  />
                  <Body style={{ marginLeft: 8 }}>
                    Annonsen er {currentMarketing === MARKETING_NONE && 'ikke '}
                    aktivt markedsført{' '}
                    {currentMarketing !== MARKETING_NONE &&
                      MARKETING_LEVELS[currentMarketing] &&
                      `(${MARKETING_LEVELS[currentMarketing]})`}
                  </Body>
                </StatusRow>
                <InfoMessage bgColor={'#F2F2F2'} style={{ maxWidth: 660 }}>
                  <div style={{ textAlign: 'center' }}>
                    <img height="160px" src="/marketing.png" alt="marketing" />
                  </div>
                  <H5>
                    Nå ut med ditt salgsbudskap til tusenvis av mulige
                    interessenter og potensielle kjøpere!
                  </H5>
                  <Body style={{ fontSize: '2.0rem', lineHeight: '3rem' }}>
                    <i>
                      Nobema tilbyr aktiv markedsføring av din annonse, via
                      norske nettaviser og populære nettsteder.
                    </i>
                  </Body>
                  <ButtonGroup>
                    <Button
                      href="https://www.nobema.no/priser"
                      target="_blank"
                      subtle
                    >
                      Se våre markedspriser
                    </Button>
                    <Button
                      href="https://www.nobema.no/kontakt"
                      target="_blank"
                      subtle
                    >
                      Kontakt oss for tilbud
                    </Button>
                  </ButtonGroup>
                </InfoMessage>
              </FlexItem>
              <FlexItem style={{ width: 330 }}>
                <StyledLabel>Forhåndsvisning</StyledLabel>
                <ExpandableWrapper>
                  <ExpandableWrapperContent>
                    <div
                      style={{
                        marginTop: 24,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Body style={{ fontSize: 20, margin: 0, marginRight: 8 }}>
                        Anonymiser annonse.
                      </Body>
                      <StyledSwitch
                        checked={anonymized}
                        onChange={() => {
                          setIsAnonymized(!anonymized)
                          updateCompanyAdvertisementAnonymizeToggle(
                            orgNumber,
                            !anonymized
                          )
                        }}
                      />
                    </div>
                    <Expandable
                      renderHeader={({ open }) => (
                        <ExpandableHeading open={open}>
                          <Body style={{ fontSize: 14 }}>
                            Hva medfører anonymisering{' '}
                          </Body>
                          <img src="/toggle_down.svg" alt="" />
                        </ExpandableHeading>
                      )}
                      renderContent={() => (
                        <>
                          <Divider style={{ width: 480 }} />
                          <div style={{ width: 480 }}>
                            <Body>
                              Du kan velge å publisere din annonse uten å
                              avsløre selskapets identitet. Ved å velge
                              anonymisering av annonsen skjuler vi automatisk de
                              informasjonslinjene som kan bidra til å
                              identifisere foretaket og selger. Dersom du ønsker
                              anonymitet må du også huske å fjerne
                              identifiserende informasjon i fritekstfelt og i
                              annonsebildet.
                            </Body>
                            <Body>
                              Med en anonymisert annonse vil interessenter finne
                              relevant informasjon om bedriften og ha mulighet
                              til å sende deg en melding. Interessent kan også
                              be om lesetilgang til hele salgsprospektet og få
                              fullt innsyn etter godkjennelse fra deg og etter
                              signering av konfidensialitetserklæring med BankID
                            </Body>
                          </div>
                        </>
                      )}
                    />
                  </ExpandableWrapperContent>
                </ExpandableWrapper>
                <StyledLink to={`/advertisement/${orgNumber}/preview`}>
                  <AdvertisementCard
                    forSale={true}
                    disabled={true}
                    anonymized={anonymized}
                    {...prospectData}
                    place={
                      anonymized
                        ? prospectData.fylke
                        : `${prospectData.fylke}, ${prospectData.municipality}`
                    }
                    turnoverString={
                      anonymized
                        ? roundToInterval(prospectData.turnover).label
                        : prettyPrice(prospectData.turnover)
                    }
                    preview={true}
                    contactLogo={
                      prospectData.showSellerInAd && prospectData.contactLogo
                        ? prospectData.contactLogo
                        : false
                    }
                  />
                </StyledLink>
                <PreviewButton to={`/advertisement/${orgNumber}/preview`}>
                  Forhåndsvis annonseside
                </PreviewButton>
              </FlexItem>
            </Content>
          </Wrapper>
          <Footer>
            {canPublish && (
              <StyledButton
                onClick={() => {
                  const advertisementData = createAdvertData()
                  advertisementData['published'] = true
                  advertisementData['anonymized'] = anonymized
                  handlePublish(advertisementData)
                }}
              >
                {isPublished ? 'Oppdater Annonse' : 'Publiser Annonse'}
              </StyledButton>
            )}
            {!publishRequest && !canPublish && (
              <StyledButton
                long
                onClick={() => {
                  const advertisementData = createAdvertData()
                  advertisementData['anonymized'] = anonymized
                  handlePublishRequest(advertisementData)
                }}
              >
                Send annonse til godkjenning
              </StyledButton>
            )}
            {isPublished && (
              <StyledModalButton
                warning
                title={`Avpubliser annonse: ${
                  prospectData.prospectTitle
                    ? prospectData.prospectTitle
                    : 'No title'
                }`}
                renderModalContent={props => (
                  <DeleteModal
                    {...props}
                    orgNr={orgNumber}
                    setSpinnerActive={setSpinnerActive}
                    setIsPublished={setIsPublished}
                  />
                )}
              >
                Avpubliser annonse
              </StyledModalButton>
            )}
          </Footer>
        </SideNavigationBar>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  anonymizedState: companiesAdvertisementAnonymizeToggleSelector(
    state,
    ownProps
  ),
  marketing: marketingSelector(state),
})

const mapDispatchToProps = {
  prospectGeneratorUpdate,
  prospectGeneratorSeed,
  clearProspectGenerator,
  updateCompanyAdvertisementAnonymizeToggle,
}

export default compose(
  withLogin,
  withFetchedData(() => fetchMarketing()),
  withFetchedData(props =>
    fetchCompanyAdvertisementAnonymizeToggle(props.orgNumber)
  ),
  withSpinner(marketingIsPendingSelector),
  connect(mapStateToProps, mapDispatchToProps)
)(ProspectAdvertisement)
