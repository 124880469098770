import React from 'react'

import connectAdvertisementForm from './connectAdvertisementForm'
import { Field, Textarea, RadioButtons, UploadFiles } from 'components/form'
import EditListOfLists, { mapToState } from './EditListOfLists'
import { uploadFilesFromList } from 'components/form/FileUploaderCore'
import { H6 } from 'styles/text'

export const lists = {
  products: {
    listHeading: 'Produkter',
    heading: 'Produkter',
    buttonLabel: '+ Legg til produkt',
    fields: [
      { prop: 'name', label: 'Produktnavn', placeholder: 'Produktets navn' },
      {
        prop: 'targetAudience',
        label: 'Målgruppe',
        placeholder:
          'Kort beskrivelse av produktets naturlige brukere/målgruppe.',
      },
      {
        prop: 'ownership',
        label: 'Egen/andres',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Egenprodusert/egenutviklet',
              'Distributør/forhandler',
            ].map(value => ({
              value,
              label: value,
            }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'comment',
        label: 'Kommentar',
        Component: Textarea,
        placeholder: 'Eventuell kommentar',
      },
      {
        prop: 'files',
        label: 'Vedlegg produktinformasjon',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  services: {
    listHeading: 'Tjenester',
    heading: 'Tjenester',
    buttonLabel: '+ Legg til tjeneste',
    fields: [
      { prop: 'name', label: 'Tjenestenavn', placeholder: 'Tjenestens navn.' },
      {
        prop: 'targetAudience',
        label: 'Målgruppe',
        placeholder:
          'Kort beskrivelse av tjenestens naturlige brukere/målgruppe.',
      },
      {
        prop: 'ownership',
        label: 'Egen/andres',
        Component: ({ value, onChange }) => (
          <RadioButtons
            options={[
              'Egenprodusert/egenutviklet',
              'Distributør/forhandler',
            ].map(value => ({
              value,
              label: value,
            }))}
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
      {
        prop: 'comment',
        label: 'Kommentar',
        Component: Textarea,
        placeholder: 'Eventuell kommentar',
      },
      {
        prop: 'files',
        label: 'Vedlegg tjenesteinformasjon',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
  specialistAreas: {
    listHeading: 'Spesialistområder',
    heading: 'Spesialistområder',
    buttonLabel: '+ Legg til spesialistområde',
    fields: [
      {
        prop: 'name',
        label: 'Navn/betegnelse',
        placeholder:
          'Navn eller betegnelse på et område foretaket spesialiserer seg på.',
      },
      {
        prop: 'description',
        label: 'Beskrivelse',
        placeholder: 'Kort beskrivelse av spesialistområdet',
      },
      {
        prop: 'comment',
        label: 'Kommentar',
        Component: Textarea,
        placeholder: 'Eventuell kommentar',
      },
      {
        prop: 'files',
        label: 'Vedlegg',
        type: 'files',
        Component: ({ value = [], onChange }) => (
          <UploadFiles
            value={value}
            onChange={value => onChange({ target: { value } })}
          />
        ),
      },
    ],
  },
}

class EditProducts extends React.Component {
  componentDidMount() {
    if (this.props.formValues.products) {
      this.setState({ ...this.props.formValues.products })
    } else {
      this.props.dispacth('products', {
        ...mapToState(lists, this.props),
        ...Object.keys(lists).reduce(
          (state, key) => ({
            ...state,
            [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
          }),
          {}
        ),
        productsComment: this.props.productsComment || '',
      })
    }
  }

  // Set initial state
  // Will be overwritten by componentDidMount() if products are found in store
  state = {
    ...Object.keys(lists).reduce(
      (state, key) => ({
        ...state,
        [key]: Object.values(this.props[key] || {}),
      }),
      {}
    ),
    ...Object.keys(lists).reduce(
      (state, key) => ({
        ...state,
        [`anonymize_${key}`]: this.props[`anonymize_${key}`] || false,
      }),
      {}
    ),
    productsComment: this.props.productsComment || '',
  }

  removeItem = (list, index) => {
    const updatedProducts = {
      [list]: this.state[list].filter((_, i) => i !== index),
    }
    this.setState(updatedProducts)
    this.props.dispacth('products', { ...this.state, ...updatedProducts })
  }

  addItem = (list, item) =>
    this.setState({
      [list]: [...this.state[list], item],
    })

  setData = (property, index, key, value, rm) => {
    let oldFiles = []
    if (key === 'files' && this.state[property][index][key] && !rm) {
      oldFiles = this.state[property][index][key]
    }
    return {
      [property]: this.state[property].map((list, i) =>
        i === index
          ? {
              ...list,
              [key]: key === 'files' ? [...oldFiles, ...value] : value,
            }
          : list
      ),
    }
  }

  setValue = (property, index, key, value) => {
    if (
      key === 'files' &&
      value.length > 0 &&
      typeof value[0].name === 'string'
    ) {
      uploadFilesFromList(this.props.orgNumber, value).then(res => {
        const products = this.setData(property, index, key, res)
        this.props.dispacth('products', { ...this.state, ...products })
        this.setState(products)
      })
    } else if (
      key === 'files' &&
      (value.length === 0 || typeof value[0].name !== 'string')
    ) {
      const products = this.setData(property, index, key, value, true)
      this.props.dispacth('products', { ...this.state, ...products })
      this.setState(products)
    } else {
      const products = this.setData(property, index, key, value)
      this.props.dispacth('products', { ...this.state, ...products })
      this.setState(products)
    }
  }

  setComment = value => {
    this.setState({ productsComment: value })
    this.props.dispacth('products', { ...this.state, productsComment: value })
  }

  render() {
    return (
      <>
        <EditListOfLists
          {...this.props}
          lists={lists}
          state={this.state}
          removeItem={this.removeItem}
          addItem={this.addItem}
          setValue={this.setValue}
          setAnonymized={(key, value) => this.setState({ [key]: value })}
        />
        <Field>
          <H6>Ytterligere informasjon om Produkter og Tjenester</H6>
          <Textarea
            value={this.state.productsComment}
            onChange={e => this.setComment(e.target.value)}
            placeholder={'Eventuell tilleggsinformasjon'}
          />
        </Field>
      </>
    )
  }
}

export default EditProducts
