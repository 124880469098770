import React from 'react'
import styled from 'styled-components'

import { small } from 'styles/text'

const Wrapper = styled.div`
  ${small};
  display: flex;
  align-items: center;
  color: #3d0288;
  font-weight: bold;

  & > :not(:last-child) {
    margin-right: 5px;
  }
`

const Anonymized = ({ children }) => (
  <Wrapper>
    <img src="/anonymized.svg" alt="" />
    <span>{children || 'Anonymisert'}</span>
  </Wrapper>
)

export default Anonymized
