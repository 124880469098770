import React from 'react'

import { colors } from 'styles'

const Search = ({ color = colors.subtleText }) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18">
    <path
      transform="translate(-808.000000, -31.000000)"
      fill={color}
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
      d="M821.125,41.96875 L820.9375,42.296875 L826,47.40625 L824.40625,49 L819.34375,43.890625 L819.015625,44.125 C817.765619,44.9062539 816.468757,45.296875 815.125,45.296875 C813.15624,45.296875 811.476569,44.593757 810.085938,43.1875 C808.695306,41.781243 808,40.0937598 808,38.125 C808,36.1562402 808.695306,34.4765695 810.085938,33.0859375 C811.476569,31.6953055 813.15624,31 815.125,31 C817.09376,31 818.773431,31.6953055 820.164062,33.0859375 C821.554694,34.4765695 822.25,36.1562402 822.25,38.125 C822.25,39.5000069 821.875004,40.7812441 821.125,41.96875 Z M819.109375,34.140625 C818.01562,33.0468695 816.687508,32.5 815.125,32.5 C813.562492,32.5 812.23438,33.0468695 811.140625,34.140625 C810.04687,35.2343805 809.5,36.5624922 809.5,38.125 C809.5,39.6875078 810.04687,41.0156195 811.140625,42.109375 C812.23438,43.2031305 813.562492,43.75 815.125,43.75 C816.687508,43.75 818.01562,43.2031305 819.109375,42.109375 C820.234381,40.9843694 820.796875,39.6562577 820.796875,38.125 C820.796875,36.5937423 820.234381,35.2656306 819.109375,34.140625 Z"
    />
  </svg>
)

export default Search
