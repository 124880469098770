import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { companyAdvertisementSelector } from 'selectors'
import { h6, subtitle, body } from 'styles/text'
import { wrapper, padding, content } from './styles'
import { decode } from 'utils'

import Expandable from 'components/Expandable'
import File from 'components/File'
import SellersComment from 'components/SellersComment'

const Wrapper = styled.div`
  ${wrapper};
`

const Heading = styled.h6`
  ${h6};
  ${padding};
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const Content = styled.div`
  ${content};

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const ObjectHeading = styled.h5`
  ${subtitle};
`

const Value = styled.p`
  ${body}
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const fields = [
  { prop: 'companyCategory', title: 'Markedsområde' },
  { prop: 'companyCategoryDescription', title: 'Beskrivelse' },
  { prop: 'customerTypes', title: 'Kundetyper' },
  { prop: 'customerInformation', title: 'Kundeinformasjon' },
  { prop: 'geographicArea', title: 'Geografisk markedsområde' },
  { prop: 'marketPotential', title: 'Markedsgrunnlag og potensiale' },
  { prop: 'demography', title: 'Demografi' },
  { prop: 'competitors', title: 'Konkurrenter' },
]

const Market = props => (
  <>
    {props.companyCategory ||
    props.companyCategoryDescription ||
    props.customerTypes ||
    props.customerInformation ||
    props.geographicArea ||
    props.marketPotential ||
    props.demography ||
    props.competitors ||
    props.marketComment ||
    props.markets_Documents ? (
      <Wrapper>
        <Expandable
          renderHeader={({ open }) => (
            <Heading open={open}>
              <span>Marked</span>
              <img src="/toggle_down.svg" alt="" />
            </Heading>
          )}
          renderContent={() => (
            <Content>
              {fields.map(
                ({ title, prop }) =>
                  (props[prop] || props[`anonymize_${prop}`]) && (
                    <div key={prop}>
                      <ObjectHeading>{title}</ObjectHeading>
                      <ul>
                        <li>
                          <Value>
                            {typeof props[prop] === 'object'
                              ? Object.values(props[prop])
                                  .map(s => decode(s))
                                  .map(s => s.replace(/other:/, ''))
                                  .join(', ')
                              : props[prop]}
                          </Value>
                        </li>
                      </ul>
                    </div>
                  )
              )}
              {props.markets_Documents && (
                <div>
                  <ObjectHeading>Vedlegg</ObjectHeading>
                  <ul>
                    {(props.markets_Documents || []).map(name => (
                      <li key={name}>
                        <File
                          path={'prospect'}
                          name={name}
                          orgNumber={props.orgnr}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <SellersComment {...props}>{props.marketComment}</SellersComment>
            </Content>
          )}
        />
      </Wrapper>
    ) : null}
  </>
)
const mapStateToProps = (state, ownProps) => ({
  ...companyAdvertisementSelector(state, ownProps),
})

export default connect(mapStateToProps)(Market)
