import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getIdToken } from 'api/firebase'
import { link } from 'styles/text'
import fetch from 'api/fetch'
import withLogin from 'components/withLogin'
import Button from 'components/Button'
import { fetchBoughtProducts } from 'actions'
import { body, h5, h4 } from 'styles/text'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import { colors } from 'styles'
import { MARKETING_NONE } from '../../utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

const Header = styled.p`
  ${h5};
  font-size: 3rem;
  line-height: 3.8rem;
  margin: 0;
  margin-bottom: 18px;
  font-weight: bold;
  color: ${colors.primary};
`

const Subtitle = styled.p`
  ${h4};
`
const Cancel = styled.div`
  ${link};
  text-align: center;
  margin: 18px 0 0;
  cursor: pointer;
`

const Ol = styled.ul`
  ${body};
  font-size: 1.8rem;
`

const Li = styled.li`
  margin-bottom: 6px;
`

const BuyModal = ({ closeModal, buttonText, orgNr, fetchBoughtProducts }) => {
  // legal params = {queryOrgnr: number, avtale: string, licence: string, code: string, marketing: string}
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const { pathname } = useLocation()
  const [code] = useState(params.code || '')
  const [pending, setPending] = useState(false)
  const [licence] = useState('fullService')
  const [marketing] = useState(MARKETING_NONE)
  const [selectedOrgnr] = useState(orgNr)
  const [partnershipDeal] = useState('')
  const [memberNumber] = useState('')

  let history = useHistory()

  const handleSubmit = async () => {
    setPending(true)
    const idToken = await getIdToken()

    const response = await fetch('/api/services/purchaseLicence', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        token: idToken,
        orgNr: selectedOrgnr,
        invoiceInformation: {
          code,
          licence,
          partnershipDeal,
          memberNumber: partnershipDeal ? '' : memberNumber,
          marketing,
        },
      }),
    })

    if (response.status === 201) {
      await fetchBoughtProducts()
    }
    history.push(`/prospect/${orgNr}`)
  }

  return (
    <Wrapper>
      <Header>
        Helt gratis og uten forpliktelser frem til publisering av din
        salgsannonse
      </Header>
      <Subtitle>
        Full tilgang til Nobemas plattform for salg av bedrift og
        bedriftsandeler
      </Subtitle>
      <Ol>
        <Li>Lag en åpen eller anonymisert salgsannonse</Li>
        <Li>Lag et komplett salgsprospekt</Li>
        <Li>Se forhåndsvisning av din annonse og prospekt</Li>
        <Li>Personlig support fra Nobema ved behov</Li>
        <Li>100% sikkerhet og diskresjon</Li>
        <Li>
          Kun etter publisering, og godkjennelse fra deg og Nobema, vil ditt
          registrerte innhold bli synlig/tilgjengelig for publikum/allmenheten
        </Li>
        <Li>
          Ved publisering velger du ønsket tjenestepakke eller enkel lisens, ut
          fra eget behov og budsjett
        </Li>
      </Ol>
      <Button pending={pending} onClick={() => handleSubmit()}>
        {buttonText}
      </Button>
      {pathname !== '/buy-licence' && (
        <Cancel onClick={closeModal}>Avbryt</Cancel>
      )}
    </Wrapper>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  fetchBoughtProducts,
}

export default compose(
  withLogin,
  connect(mapStateToProps, mapDispatchToProps)
)(BuyModal)
