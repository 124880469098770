import { database, currentUserId, timestamp, getIdToken } from './firebase'
import fetch, { fetchWithTimeOut } from './fetch'
import * as Sentry from '@sentry/react'
import { MARKETING_NONE } from '../utils'

export const fetchCompany = companyId =>
  fetch(`/api/companies/${companyId}`, { mode: 'cors' }).then(res => {
    if (res.status === 404) {
      console.log('No data received')
      return {}
    }
    return res.json()
  })

export const addCompanyToFavorites = id =>
  database
    .ref(`/user-favorited-companies/${currentUserId()}/${id}`)
    .set(timestamp)

export const removeCompanyFromFavorites = id =>
  database.ref(`/user-favorited-companies/${currentUserId()}/${id}`).remove()

export const fetchFavoritedCompanies = companyId =>
  database
    .ref(`/user-favorited-companies/${currentUserId()}`)
    .once('value')
    .then(snapshot => snapshot.val() || {})

export const claimCompany = companyId =>
  getIdToken().then(token =>
    fetch(`/api/companies/${companyId}/claim?token=${token}`, {
      mode: 'cors',
      method: 'POST',
    })
  )

export const fetchClaimedCompanies = companyId =>
  database
    .ref(`/users/${currentUserId()}/claimed-companies`)
    .once('value')
    .then(snapshot => snapshot.val() || {})

export const fetchCompanyIsEditable = companyId =>
  database
    .ref(`/company-editable/${companyId}`)
    .once('value')
    .then(snapshot => (snapshot.val() ? true : false))

export const fetchCompanyIsPublishable = companyId =>
  database
    .ref(`/company-publishable/${companyId}`)
    .once('value')
    .then(snapshot => (snapshot.val() ? true : false))

export const addCompanyProspect = async (orgNumber, companyData) => {
  const status = { error: '', errorKey: '' }

  try {
    const res = await database
      .ref(`/company-prospect/${orgNumber}`)
      .set(companyData)

    return status
  } catch (error) {
    Sentry.captureException(error, {
      path: `/company-prospect/${orgNumber}`,
      data: JSON.stringify(companyData),
    })
    if (error.message.includes('Reference.set failed')) {
      status.error = 'UNDEFINED_VALUE'
      status.errorKey = error.message.split("'")[1].split('.')[2]
    } else if (error.message.includes('PERMISSION_DENIED')) {
      status.error = 'PERMISSION_DENIED'
    } else {
      status.error = 'UNHANDLED_ERROR'
    }
    return status
  }
}

export const fetchCompanyProspect = orgNumber =>
  database
    .ref(`/company-prospect/${orgNumber}`)
    .once('value')
    .then(snapshot => snapshot.val() || {})

export const deleteCompanyProspect = orgNumber =>
  getIdToken().then(token => {
    fetch(`/api/delete-prospect/${orgNumber}?token=${token}`, {
      mode: 'cors',
      method: 'DELETE',
    })
  })

export const fetchProspectRequests = () =>
  database
    .ref(`/read-prospect-requests/${currentUserId()}`)
    .once('value')
    .then(snapshot => snapshot.val() || {})

// Its not a inner join, its key matching
export const fetchAccessibleProspects = () => {
  return database
    .ref(`/users/${currentUserId()}/accepted-prospects`)
    .once('value')
    .then(prospects =>
      database
        .ref(`/users/${currentUserId()}/ndaRequests`)
        .once('value')
        .then(nda => {
          if (!nda.val() || !prospects.val()) return []
          const accpetedProspects = []
          Object.keys(prospects.val()).map(companyId => {
            if (
              nda.val()[companyId].status === 'accepted' &&
              prospects.val()[companyId].status
            ) {
              const companyName = nda.val()[companyId].companyName || ''
              accpetedProspects.push({
                companyId: companyId,
                companyName: companyName,
              })
            }
          })
          return accpetedProspects
        })
    )
}

export const fetchIsProspectAccessible = advertisementId => {
  getIdToken()
    .then(token =>
      fetch(`/api/access-prospect/${advertisementId}?token=${token}`, {
        mode: 'cors',
        method: 'POST',
      })
    )
    .then(res => {
      res.json().then(orgNr => {
        window.location.assign(`/doc/prospect/${orgNr}`)
      })
    })
}

export const fetchBrregData = (orgNr, type) =>
  fetch(`/api/companies/brreg/${orgNr}/${type}`, {
    mode: 'cors',
    method: 'GET',
  }).then(response => response.json().then(res => res))

export const fetchNobemaVideo = () =>
  fetch(`/api/nobema-video`, { mode: 'cors', method: 'GET' }).then(response =>
    response.json().then(res => {
      return res.videoUrl
    })
  )

export const getProspectOwner = async orgnr => {
  const token = await getIdToken()
  const res = await fetch(`/api/prospect-owner/${orgnr}?token=${token}`)
  if (res.status === 200) {
    const jsonRes = await res.json()
    return jsonRes
  }
  return 403
}

export const getProjectNumber = async orgnr => {
  const token = await getIdToken()
  const res = await fetch(`/api/project-number/${orgnr}?token=${token}`)
  if (res.status === 200) {
    const jsonRes = await res.json()
    return jsonRes
  }
  return 403
}

export const BASIC_LICENCE = 'Basis lisens'
export const FULL_LICENCE = 'Full lisens'

export const getLicence = async orgnr => {
  const user = await database.ref(`/users/${currentUserId()}/`).once('value')
  const { basicLicence, fullService } = user.val()
  if (fullService?.[orgnr]) {
    return FULL_LICENCE
  } else if (basicLicence && basicLicence?.[orgnr]) {
    return BASIC_LICENCE
  } else {
    throw new Error(`No licence for ${orgnr} for user}`)
  }
}

export const updateLicence = async licenceInformation => {
  // orgnr: companyId, licence, marketing, marketingChanged, licenceChanged
  const token = await getIdToken()
  const res = await fetch(`/api/update/licence-and-marketing`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ ...licenceInformation, token }),
  })
  window.location.reload()
}

export const fetchMarketing = () =>
  database
    .ref(`/users/${currentUserId()}/marketing`)
    .once('value')
    .then(snapshot => snapshot.val() || MARKETING_NONE)
