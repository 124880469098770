import { colors, border } from 'styles'
import { body } from 'styles/text'

export const wrapper = `
  border: ${border};
  border-radius: 4px;
  background-color: #fcfcfc;
`

export const padding = `
  padding: 15px;

  @media (min-width: 768px) {
    padding: 40px;
  }
`

export const content = `
  ${body};

  padding: 15px;

  @media (min-width: 768px) {
    padding: 0 40px 40px;
  }
`
