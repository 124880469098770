import React from 'react'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'

import { border, contentWidth } from 'styles'

import Top from './Top'
import MainComponent from './Main'
import SidebarComponent from './Sidebar'
import NoAccess from '../NoAccess'
import Meta from './Meta'

const Wrapper = styled.div`
  max-width: ${contentWidth};
  margin: 0 auto;
  padding: 0 20px;
`

const Content = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`

const Main = styled(MainComponent)`
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 0;
  min-width: 0;

  @media (min-width: 768px) {
    padding-right: 30px;
  }
`

const Sidebar = styled(SidebarComponent)`
  flex-basis: 0;
  flex-grow: 2;
  flex-shrink: 0;
  min-width: 0;
  padding-bottom: 20px;
  border-bottom: ${border};
  margin-bottom: 20px;

  @media (min-width: 768px) {
    border-bottom: none;
  }
`

const Company = props => {
  if (props.error) {
    return (
      <Wrapper>
        <NoAccess />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Meta {...props} />
      <Top {...props} />
      <Content>
        <MediaQuery query="(min-width: 768px)">
          {matches => !matches && <Sidebar {...props} />}
        </MediaQuery>
        <Main {...props} />
        <Sidebar {...props} />
      </Content>
    </Wrapper>
  )
}

export default Company
