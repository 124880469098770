import React from 'react'
import { compose } from 'redux'
import { connect, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  fetchCompanyProspect,
  fetchCompanyIsPublishable,
  fetchCompanyAdvertisementAnonymizeToggle,
} from 'actions'
import {
  companyProspectSelector,
  companyProspectIsPendingSelector,
  companyAdvertisementSelector,
  companyAdvertisementIsPendingSelector,
  companyIsPublishableSelector,
  claimedCompaniesIsPendingSelector,
  claimedCompanyIdsSelector,
} from 'selectors'
import { colors, contentWidth } from 'styles'
import { Body } from 'styles/text'
import NoAccess from '../components/NoAccess'

import withFetchedData from 'components/withFetchedData'
import withSpinner from 'components/withSpinner'
import Company from 'components/Company'
import Link from 'components/Link'
import withLogin from '../components/withLogin'

const Wrapper = styled.div`
  background-color: ${colors.alabasterBackground};
  border-bottom: 2px solid ${colors.primary};
  margin-bottom: 12px;
`

const Content = styled.div`
  padding: 20px;
  max-width: ${contentWidth};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const PreviewBanner = props => (
  <Wrapper>
    <Content>
      <div>
        <Body>Prospektvisning</Body>
        <Link to={`/prospect/${props.orgNr}/information`}>
          Lukk salgsprospekt
        </Link>
      </div>
    </Content>
  </Wrapper>
)

const PreviewCompany = props => {
  const licenceType = useSelector(state => {
    const licences = state.boughtProducts
    if (licences.basic?.[props.companyId]) {
      return 'basis'
    } else if (licences.full?.[props.companyId]) {
      return 'full'
    } else {
      return 'pending'
    }
  })

  if (props.error) {
    return <NoAccess />
  } else if (licenceType === 'basis') {
    return <NoAccess invalidLicence />
  } else {
    return (
      <>
        <PreviewBanner orgNr={props.companyId} />
        <Company {...props} prospectPreview={true} previewAnonymized={false} />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  claimedCompanyIds: claimedCompanyIdsSelector(state),
  ...companyProspectSelector(state, ownProps),
  ...companyAdvertisementSelector(state, ownProps),
  publishable: companyIsPublishableSelector(state, ownProps),
  advertisementForSale: true,
  forSale: true,
})

export default compose(
  withLogin,
  withSpinner(claimedCompaniesIsPendingSelector),
  withFetchedData(props =>
    fetchCompanyAdvertisementAnonymizeToggle(props.companyId)
  ),
  withFetchedData(props => fetchCompanyProspect(props.companyId)),
  withFetchedData(props => fetchCompanyIsPublishable(props.companyId)),
  withSpinner(companyAdvertisementIsPendingSelector),
  withSpinner(companyProspectIsPendingSelector),
  connect(mapStateToProps)
)(PreviewCompany)
