import styled from 'styled-components'

import { colors } from 'styles'

import { body } from 'styles/text'

export default styled.div`
  ${body}
  background-color: ${props => props.bgColor ?? colors.infoBackground};
  padding: 20px;
  border-radius: 4px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`
