import React from 'react'
import { Helmet } from 'react-helmet'
import { imgixUrl } from '../../environment'

const Meta = props => {
  const imageUrl = `${imgixUrl}/${props.headerImage}`
  return (
    <Helmet>
      <title>{props.name ? props.name : props.prospectTitle}</title>
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={props.advertisementForSale ? props.prospectTitle : props.name}
      />
      {props.headerImage && <meta property="og:image" content={imageUrl} />}
    </Helmet>
  )
}

export default Meta
