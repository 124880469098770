import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import AnonymizedField from 'components/AnonymizedField'

import { colors } from 'styles'
import { body, h4, h6 } from 'styles/text'
import { prettyPrice, roundToInterval } from 'utils'
import Price from './Company/Main/Price'

import SalesArguments from './Company/Main/SalesArguments'
import { economicNumbersOfLastRecordedYearSelector } from 'selectors'

/*
const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`
*/

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 2.4em;
  padding-right: 30px;
  grid-auto-flow: dense;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`

const TitleName = styled.div`
  ${h4};
  grid-column: 1 / span 1;
`

const Label = styled.h6`
  margin: 0;
  ${h6};
`

const Value = styled.div`
  ${body}
  color: ${colors.primaryGray};
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const GridItem = styled.div`
  grid-column: ${props => (props.theme ? props.theme : '1 / span 2')};
`

const AdvertismentMain = props => {
  // Header, title and companyName are shown in top component
  // Fields not on this struct are added manually
  const fields = [
    {
      label: 'Foretaknavn',
      value: props.name,
      anonymize: true,
      position: '1 / span 2',
    },
    {
      label: 'Om bedriften',
      value: props.companyIngress,
      anonymize: false,
      position: '1 / span 1',
    },
    {
      label: 'purchaseArguments',
      value: props.purchaseArguments,
      position: '1 / span 1',
    },
    {
      label: 'Prisantydning andel',
      value: props.userValueEstimate,
      position: '1 / span 1',
    },
    {
      label: 'Bransje',
      value: props.industryCode
        ? props.industryCode
        : `${props.BransjeKode} ${props.BransjeTekst}`,
      position: '1 / span 1',
    },
    {
      label: 'Fylke',
      value: props.fylke,
      position: '2 / span 1',
    },
    {
      label: 'Omsetning',
      value: props.turnover,
      position: '1 / span 1',
    },
    {
      label: 'Egenkapital',
      value: props.ek,
      position: '2 / span 2',
    },
    {
      label: 'Org.nr',
      value: `${props.orgnr}`,
      anonymize: true,
      position: '1 / span 1',
    },
    {
      label: 'Selskapets livsfase',
      value: props.companyLifeCycle,
      position: '2 / span 1',
    },
    {
      label: 'Adresse',
      value: props.address ? props.address.address : 'no',
      anonymize: true,
      position: '1 / span 1',
    },
    {
      label: 'Poststed',
      value: props.postplace,
      anonymize: true,
      position: '2 / span 1',
    },
    {
      label: 'Kommune',
      value: props.municipality,
      anonymize: true,
      position: '1 / span 2',
    },
    {
      label: 'Antall ansatte',
      value: props.employeeBoolean ? props.employees : '0',
      position: '1 / span 1',
    },
  ]

  const AdvertismentField = ({ field }) => {
    if (field.label === 'Prisantydning andel') {
      return (
        <GridItem key={field.label} theme={'1 / span 2'}>
          <Price
            userValueEstimate={field.value}
            userValueEstimateComment={props.userValueEstimateComment}
          />
        </GridItem>
      )
    }

    if (field.label === 'Omsetning' || field.label === 'Egenkapital') {
      if (!field.value) return null
      else if (props.anonymized || props.previewAnonymized) {
        return (
          <GridItem theme={field.position}>
            <Label>
              {field.label}{' '}
              {props.customEconomyYear ? `(${props.customEconomyYear})` : ''}
            </Label>
            <Value>{roundToInterval(field.value).label} NOK</Value>
          </GridItem>
        )
      } else {
        return (
          <GridItem theme={field.position}>
            <Label>
              {field.label}{' '}
              {props.customEconomyYear ? `(${props.customEconomyYear})` : ''}
            </Label>
            <Value>{prettyPrice(field.value)} NOK</Value>
          </GridItem>
        )
      }
    }

    if (field.label === 'purchaseArguments') {
      return (
        <GridItem key={field.label} theme={'1 / span 2'}>
          {props.purchaseArguments && props.purchaseArguments.arguments && (
            <SalesArguments {...props} />
          )}
        </GridItem>
      )
    }

    if (field.label === 'Foretaknavn') {
      return (
        <GridItem key={field.label} theme={field.position}>
          <Label>Selskapsnavn</Label>
          <AnonymizedField {...props}>
            <TitleName>{field.value}</TitleName>
          </AnonymizedField>
        </GridItem>
      )
    }

    if (field.label === 'Om bedriften') {
      return (
        <GridItem theme={'1 / span 2'}>
          <Label>Om bedriften</Label>
          <Value>{props.companyIngress}</Value>
        </GridItem>
      )
    }

    if (
      (props.anonymized && field.anonymize) ||
      (props.previewAnonymized && field.anonymize)
    ) {
      return (
        <GridItem key={field.label} theme={field.position}>
          <Label>{field.label}</Label>
          <AnonymizedField {...props}>
            <Value>{props.value}</Value>
          </AnonymizedField>
        </GridItem>
      )
    } else {
      return (
        <GridItem key={field.label} theme={field.position}>
          <Label>{field.label}</Label>
          <Value>{field.value ? field.value : 'Mangler data'}</Value>
        </GridItem>
      )
    }
  }

  return (
    <Wrapper>
      {fields.map((field, i) => (
        <AdvertismentField key={i} field={field} />
      ))}
      <GridItem theme={'2 / span 1'}>
        <Label>Eiendom</Label>
        <Value>
          Eier eiendom:{' '}
          {props.ownedProperty || props.propertyOwnedBoolean ? 'Ja' : 'Nei'}
        </Value>
        <Value>
          Leier eiendom:{' '}
          {props.rentedProperty || props.propertyRentedBoolean ? 'Ja' : 'Nei'}
        </Value>
      </GridItem>

      {props.projectNumber && (
        <GridItem theme="1 / span 2">
          <Label>Prosjektnummer</Label>
          <Value>{props.projectNumber}</Value>
        </GridItem>
      )}
    </Wrapper>
  )
}

const mapStateToProps = (state, ownProps) => ({
  extraEcon: economicNumbersOfLastRecordedYearSelector(state, ownProps),
})

export default connect(mapStateToProps)(AdvertismentMain)
