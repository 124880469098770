import React from 'react'
import PersonalInfoForm from 'components/PersonalInfoForm'

const RegisterPage = () => (
  <div>
    <PersonalInfoForm />
  </div>
)

export default RegisterPage
