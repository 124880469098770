import { median, lastYear } from './utils'

export const revenueGrowthRate = (data, options = {}) =>
  options.revenueGrowthRate !== undefined
    ? options.revenueGrowthRate
    : median(
        data
          .sort((a, b) => b.RegnskapsAvAr - a.RegnskapsAvAr)
          .map(
            ({ Salgsinntekter }, index, array) =>
              Math.pow(array[0].Salgsinntekter / Salgsinntekter, 1 / index) - 1
          )
          .filter((_, index) => index !== 0)
      )

export default (data, year, options) => {
  if (data.length === 0) return

  const { RegnskapsAvAr, Salgsinntekter } = lastYear(data)

  return (
    Salgsinntekter *
    Math.pow(revenueGrowthRate(data, options) + 1, year - RegnskapsAvAr)
  )
}
