import {
  FETCH_PROSPECT_READ_REQUESTS,
  FETCH_PROSPECT_READ_REQUESTS_SUCCESS,
  FETCH_PROSPECT_READ_REQUESTS_ERROR,
  LOG_OUT,
} from 'actionTypes'

const initialState = { pending: true }

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROSPECT_READ_REQUESTS:
      return {
        pending: true,
      }
    case FETCH_PROSPECT_READ_REQUESTS_SUCCESS:
      return action.prospectRequests
    case FETCH_PROSPECT_READ_REQUESTS_ERROR:
      return { pending: false, error: true, message: action.message }
    case LOG_OUT:
      return initialState
    default:
      return state
  }
}
