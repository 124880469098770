import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { h4, body, Body, subtitle } from 'styles/text'
import Button from 'components/Button'
import { Field, Label, Input, Checkbox, RadioButtons } from 'components/form'
import { updateLicence } from '../../api/companies'
import { useSelector } from 'react-redux'

const Wrapper = styled.div``
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`
const StyledButton = styled(Button)`
  padding: 8px 24px;
  margin: 12px;
`
const ChangeText = styled.p`
  ${body};
  font-style: italic;
`

const EditLicence = props => {
  const PLUS = 'Markedspakke pluss'
  const BASIC = 'Basis markedspakke'
  const NONE = 'Ingen markedspakke'

  let initialLicence
  if (props.licenceType === 'Basis lisens') {
    initialLicence = 'basicLicence'
  } else if (props.licenceType === 'Full lisens') {
    initialLicence = 'fullService'
  }

  const oldMarketing = useSelector(state => state.marketing[props.companyId])
  const [licence, setLicence] = useState(initialLicence || '')
  const [marketing, setMarketing] = useState(oldMarketing || NONE)

  const [licenceChanged, toggleLicenceChange] = useState(false)
  const [marketingChanged, toggleChangeMarketing] = useState(false)

  useEffect(() => {
    console.log('Licence is changed!', licence, props.licenceType)
    if (licence !== initialLicence) {
      toggleLicenceChange(true)
    } else {
      toggleLicenceChange(false)
    }
  }, [licence])

  useEffect(() => {
    if (
      marketing !== oldMarketing &&
      !(oldMarketing === undefined && marketing === NONE)
    ) {
      toggleChangeMarketing(true)
    } else {
      toggleChangeMarketing(false)
    }
  }, [marketing])

  return (
    <Wrapper>
      <Field>
        <Label>Lisens</Label>
        <RadioButtons
          options={[
            { label: 'Annonse', value: 'basicLicence' },
            { label: 'Annonse og salgsprospekt', value: 'fullService' },
          ]}
          value={licence}
          onChange={value => setLicence(value)}
        />
      </Field>
      {licenceChanged && (
        <ChangeText>
          Du har endringer som vil medføre endringer i pris og funksjonalitet.
          Klikk{' '}
          <a href="https://nobema.no/priser" target="_blank">
            her
          </a>{' '}
          for å se nye priser
        </ChangeText>
      )}
      <ButtonGroup>
        <StyledButton subtle onClick={props.closeModal}>
          Avbryt
        </StyledButton>
        <StyledButton
          disabled={!licenceChanged}
          onClick={() => {
            updateLicence({
              orgnr: props.companyId,
              licence,
              marketingChanged: false,
              licenceChanged,
            })
            props.closeModal()
          }}
        >
          Lagre
        </StyledButton>
        {/* { <StyledButton warning>Avslutt lisens</StyledButton>} */}
      </ButtonGroup>
    </Wrapper>
  )
}

export default EditLicence
