import React from 'react'
import styled from 'styled-components'
import Tooltip from 'rc-tooltip'

import { label } from 'styles/text'

const StyledLabel = styled.label`
  ${label};
  margin-bottom: 10px;
  display: block;
`

const Icon = styled.img`
  padding: 4px;
  margin: -4px -4px -4px 4px;
`

const Label = props =>
  props.helpText ? (
    <StyledLabel {...props}>
      {props.children}
      <Tooltip placement="right" trigger={['hover']} overlay={props.helpText}>
        <Icon src="/info_blue.svg" alt="Hjelpetekst" />
      </Tooltip>
    </StyledLabel>
  ) : (
    <StyledLabel {...props} />
  )

export default Label
