import React from 'react'
import { connect } from 'react-redux'

import { logIn, authenticateWithGoogle } from 'actions'
import {
  logInErrorSelector,
  logInEmailErrorSelector,
  logInPasswordErrorSelector,
} from 'selectors'

import { Form, Field, Input } from 'components/form'
import Button from 'components/Button'
import Error from 'components/Error'

class LogIn extends React.Component {
  state = {
    email: '',
    password: '',
  }

  logIn = e => {
    e.preventDefault()
    this.props.logIn(this.state)
  }

  render() {
    return (
      <React.Fragment>
        <Form onSubmit={this.logIn}>
          {this.props.error && <Error>{this.props.error}</Error>}
          <Field>
            <Input
              placeholder="Epost"
              type="email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              error={this.props.emailError}
              autoFocus={!this.props.passwordError}
              required
            />
          </Field>
          <Field>
            <Input
              placeholder="Passord"
              type="password"
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
              error={this.props.passwordError}
              autoFocus={this.props.passwordError}
              required
            />
          </Field>
          <Button large type="submit">
            Logg inn
          </Button>
        </Form>
        <Button onClick={this.props.authenticateWithGoogle}>
          Logg inn med Google
        </Button>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  error: logInErrorSelector(state),
  emailError: logInEmailErrorSelector(state),
  passwordError: logInPasswordErrorSelector(state),
})

const mapDispatchToProps = { logIn, authenticateWithGoogle }

export default connect(mapStateToProps, mapDispatchToProps)(LogIn)
