import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import { colors, border } from 'styles'
import { h3, subtitle } from 'styles/text'
import { frontendUrl } from '../../../environment'
import FavoriteCompanyButton from 'components/FavoriteCompanyButton'
import FavoriteAdvertisementButton from 'components/FavoriteAdvertisementButton'
import HeaderImage from './HeaderImage'
import Image from '../../Image'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1.3rem;
  border-bottom: ${border};

  @media (min-width: 768px) {
    border-bottom: none;
  }
`

const Title = styled.div`
  ${h3};
  width: 100%;
  margin: 16px 0 8px;
  overflow-wrap: break-word;
`

const Name = styled.div`
  ${h3};
  width: 100%;
  margin-top: 10px;
`

const ForSale = styled.div`
  margin-top: 4rem;
  ${subtitle};
  color: ${colors.action};
`

const NotForSale = styled.div`
  margin-top: 4rem;
  ${subtitle};
  color: ${colors.subtleText};
`

const Social = styled.div`
  display: flex;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`

const ShareIcon = styled.img`
  height: 24px;
`

const CompanyNameRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 2 0 0;
`

const SocialAndFavButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex: 2 0 0;
`
const CompanyName = styled.div`
  width: 100%;
  flex: 3 0 0;
  padding-right: 3rem;
`

const Top = props => {
  const location = useLocation()
  const { preview, fullProspect } = props
  const currentUrl = `${frontendUrl()}${location.pathname}`
  return (
    <React.Fragment>
      {props.forSale ? <HeaderImage {...props} /> : null}
      <Wrapper>
        {props.forSale ? (
          props.shareForSale && props.advertisementCardLabel ? (
            <ForSale>
              {props.advertisementCardLabel} - Andel: {props.shareForSale}
              {props.shareForSale.includes('%') ? '' : '%'}
            </ForSale>
          ) : (
            <ForSale>Til salgs</ForSale>
          )
        ) : (
          <NotForSale>Bedriftsoppføring</NotForSale>
        )}
        <Name>
          <CompanyNameRow>
            <CompanyName>
              <Title>{props.prospectTitle}</Title>
            </CompanyName>
            {!preview && !fullProspect && (
              <SideContent>
                <SocialAndFavButtons>
                  <div>
                    {props.companyId ? (
                      !props.claimed && (
                        <FavoriteCompanyButton companyId={props.companyId} />
                      )
                    ) : (
                      <FavoriteAdvertisementButton
                        advertisementId={props.advertisementId}
                      />
                    )}
                  </div>
                  <Social>
                    {!props.claimed && (
                      <>
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                            currentUrl
                          )}`}
                          target="_blank"
                          title="Del på Facebook"
                        >
                          <ShareIcon src="/facebook.svg" alt="facebook" />
                        </a>
                        <a
                          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
                            currentUrl
                          )}`}
                          target="_blank"
                          title="Del på LinkedIn"
                        >
                          <ShareIcon src="/linkedin.svg" alt="linkedin" />
                        </a>
                      </>
                    )}
                  </Social>
                </SocialAndFavButtons>
              </SideContent>
            )}
          </CompanyNameRow>
        </Name>
      </Wrapper>
    </React.Fragment>
  )
}
export default Top
