import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { link } from 'styles/text'

import withLogin from 'components/withLogin'
import ButtonComponent from 'components/Button'

import { notifyBuyer } from '../../../api/services'
import { fetchProspectRequests } from '../../../actions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Cancel = styled.div`
  ${link};
  text-align: center;
  margin: 18px 0 0;
  cursor: pointer;
`

const Button = styled(ButtonComponent)`
  align-self: center;
`

const ModalMessage = styled.p`
  text-align: center;
`

const RequestModal = ({
  closeModal,
  message,
  buttonText,
  companyId,
  buyerUserId,
  status,
  fetchProspectRequests,
  removed,
}) => (
  <Wrapper>
    <ModalMessage>{message}</ModalMessage>
    <Button
      onClick={() => {
        notifyBuyer({
          buyerUserId: buyerUserId,
          orgNr: companyId,
          status: status,
          removed: removed,
        }).then(() => {
          fetchProspectRequests()
        })
      }}
    >
      {buttonText}
    </Button>
    <Cancel onClick={closeModal}>Avbryt</Cancel>
  </Wrapper>
)

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  fetchProspectRequests,
}

export default compose(
  withLogin,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestModal)
