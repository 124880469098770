import React, { useState } from 'react'
import { getIdToken } from '../../api/firebase'
import { colors, border } from 'styles'
import { subtitle, h4 } from 'styles/text'
import fetch from '../../api/fetch'
import styled from 'styled-components'
import Input from '../../components/form/Input'
import Button from '../../components/Button'

const Wrapper = styled.div`
  margin-top: 2rem;
  border-radius: 0.4rem;
  border: ${border};
  background-color: ${colors.white};
  padding: 0 3rem;
  height: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Name = styled.div`
  ${h4};
  width: 28rem;
`

const SubmitButton = styled(Button)`
  margin-left: 12px;
  height: 3rem;
  width: 18rem;
`
const ErrorText = styled.div`
  ${subtitle};
  color: ${colors.error};
`

const SuccessText = styled.div`
  ${subtitle};
  color: ${colors.success};
`

const ClaimCompanyInput = () => {
  const [orgNr, setOrgNr] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [pending, setPending] = useState(false)

  const handleClaim = async () => {
    setPending(true)
    setErrorMsg('')
    setSuccessMsg('')

    const idToken = await getIdToken()
    const res = await fetch(`/api/claim/${orgNr}`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        token: idToken,
      }),
    })

    if (res.status === 400) {
      if (!/[^a-zA-Z]/.test(orgNr)) {
        setErrorMsg(
          'Vi fant ikke bedriften du søkte på, prøv igjen med organisasjonsnummeret til bedriften'
        )
      } else {
        setErrorMsg('Vi fant ikke bedriften du søkte på')
      }
      setSuccessMsg('')
    } else if (res.status === 200 || res.status === 201) {
      const claimResponse = await res.json()
      setErrorMsg('')

      if (claimResponse.access === 'granted') {
        setSuccessMsg(
          'Tilgangsforespørsel godkjent. Last inn siden på nytt for å se din bedrift'
        )
        window.location.reload()
      } else {
        setSuccessMsg(
          'Tilgangsforespørsel mottatt. Vi sender deg en epost når din tilgang er klargjort.'
        )
      }
    } else if (res.status === 403) {
      setSuccessMsg('')
      setErrorMsg(
        'Det ser ut som bedriften du søkte på ikke er at AS. Bare AS kan selges på Nobemas markedsplass'
      )
    } else {
      setSuccessMsg('')
      setErrorMsg('Noe gikk galt')
    }
    setPending(false)
  }

  return (
    <>
      <Wrapper>
        <Name>Legg til bedrift</Name>
        <Input
          type="number"
          value={orgNr}
          onChange={e => setOrgNr(e.target.value)}
          placeholder="Skriv inn organisasjonsnummeret til aksjeselskapet ditt..."
        />
        <SubmitButton
          disabled={!orgNr}
          pending={pending}
          onClick={() => handleClaim()}
        >
          {pending ? 'Legger til' : 'Legg til'}
        </SubmitButton>
      </Wrapper>
      {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      {successMsg && <SuccessText>{successMsg}</SuccessText>}
    </>
  )
}

export default ClaimCompanyInput
