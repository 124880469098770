import { auth } from './firebase'
import { signicatLogOutUrl } from './fetch'
export { authenticateWithGoogle } from './firebase'

export const register = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password).then(({ user }) => user)

export const logIn = (email, password) =>
  auth.signInWithEmailAndPassword(email, password).then(({ user }) => user)

export const logInWithToken = token =>
  auth.signInWithCustomToken(token).then(({ user }) => user)

export const logOut = () => {
  auth.signOut()
  Promise.resolve(signicatLogOutUrl()).then(logOutUrl => {
    window.location.assign(logOutUrl)
  })
}

export const checkAuthStatus = () =>
  new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      resolve(user)
      unsubscribe()
    }, reject)
  })
