import { createSelector } from 'reselect'

export const isAuthenticatedSelector = state => state.auth.authenticated

export const authIsPendingSelector = state => state.auth.pending

export const registerErrorSelector = createSelector(
  state => state.auth.registerError,
  registerError => (registerError ? registerError.general : undefined)
)

export const registerEmailErrorSelector = createSelector(
  state => state.auth.registerError,
  registerError => (registerError ? registerError.email : undefined)
)

export const registerPasswordErrorSelector = createSelector(
  state => state.auth.registerError,
  registerError => (registerError ? registerError.password : undefined)
)

export const logInErrorSelector = createSelector(
  state => state.auth.logInError,
  logInError => (logInError ? logInError.general : undefined)
)

export const logInEmailErrorSelector = createSelector(
  state => state.auth.logInError,
  logInError => (logInError ? logInError.email : undefined)
)

export const logInPasswordErrorSelector = createSelector(
  state => state.auth.logInError,
  logInError => (logInError ? logInError.password : undefined)
)

export const userEmailSelector = state =>
  state.auth.user ? state.auth.user.email : undefined

export const userNameSelector = state =>
  state.auth.user ? state.auth.user.displayName : undefined

export const termsSelector = state => (state.terms ? state.terms : false)
