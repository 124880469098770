import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'
import { h3, h6, H3 } from 'styles/text'

import { Field, Label, Input, Checkbox, AnonLabel } from 'components/form'
import ButtonComponent from 'components/Button'

export const mapToState = (lists, props) =>
  Object.keys(lists).reduce(
    (state, key) => ({
      ...state,
      [key]: (props[key] || []).map(value =>
        Object.entries(value)
          .map(([key, value]) =>
            key === 'files'
              ? [
                  key,
                  Object.entries(value).map(([path, name]) => ({
                    path,
                    name,
                  })),
                ]
              : [key, value]
          )
          .reduce(
            (obj, [key, value]) => ({
              ...obj,
              [key]: value,
            }),
            {}
          )
      ),
    }),
    {}
  )

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const PropertyList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 92px;
  }
`

const PropertyHeading = styled.div`
  ${h6};
`

const Button = styled(ButtonComponent)`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  margin: 24px 0;
`

const DeleteButton = styled.button`
  ${h6};
  color: ${colors.subtleText};
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
`

const EditListOfLists = ({
  lists,
  state,
  removeItem,
  addItem,
  setValue,
  anonymized,
  setAnonymized,
  required,
}) =>
  Object.entries(lists).map(
    ([
      list,
      { heading, buttonLabel, fields, alwaysAnonymized, listHeading },
    ]) => (
      <div key={list}>
        {listHeading && <H3>{listHeading}</H3>}
        <PropertyList>
          {state[list].map((property, index) => (
            <li key={index}>
              <Header>
                <PropertyHeading>
                  {heading} {index + 1}
                </PropertyHeading>
                <DeleteButton
                  type="button"
                  onClick={() => removeItem(list, index)}
                >
                  Slett
                </DeleteButton>
              </Header>
              <React.Fragment>
                {fields.map(
                  ({ prop, label, placeholder, Component = Input }) => (
                    <Field key={prop}>
                      <Label>{label}</Label>
                      <Component
                        value={property[prop]}
                        onChange={e =>
                          setValue(list, index, prop, e.target.value)
                        }
                        placeholder={placeholder || label}
                      />
                    </Field>
                  )
                )}
              </React.Fragment>
            </li>
          ))}
        </PropertyList>
        {state[list] &&
          state[list].length > 0 &&
          !alwaysAnonymized &&
          anonymized && (
            <Field>
              <AnonLabel>
                <Checkbox
                  checked={state[`anonymize_${list}`]}
                  onChange={e =>
                    setAnonymized(`anonymize_${list}`, e.target.checked)
                  }
                />
                Anonymiser {heading.toLowerCase()}
              </AnonLabel>
            </Field>
          )}
        <Button
          subtle
          style={{
            borderColor: required ? colors.highlight : '',
            color: required ? colors.highlight : '',
          }}
          type="button"
          onClick={() =>
            addItem(
              list,
              fields.reduce(
                (props, field) => ({
                  ...props,
                  [field.prop]: field.type === 'files' ? [] : '',
                }),
                {}
              )
            )
          }
        >
          {buttonLabel}
        </Button>
      </div>
    )
  )

export default EditListOfLists
