import styled from 'styled-components'
import * as tabs from 'react-tabs'

import { colors, border } from 'styles'

export const Tabs = styled(tabs.Tabs)`
  border: ${border};
  border-radius: 4px;
  overflow: hidden;
`

export const TabList = styled(tabs.TabList)`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`

export const Tab = styled(tabs.Tab)`
  flex-grow: 1;
  flex-basis: 1px;
  background-color: ${colors.alabasterBackground};
  border-bottom: ${border};
  padding: 1.6rem;

  &:not(:last-child) {
    border-right: ${border};
  }

  &.react-tabs__tab--selected {
    background-color: ${colors.white};
    border-bottom: none;
  }
`

export const TabPanel = styled(tabs.TabPanel)``
