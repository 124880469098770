import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import SellersComment from 'components/SellersComment'

import {
  HistoricSalgsinntekterSelector,
  HistoricOrdResultatForSkattSelector,
  HistoricSumEgenkapitalSelector,
  economicNumbersOfLastRecordedYearSelector,
} from 'selectors'
import { wrapper, padding } from '../styles'
import { h6, body } from 'styles/text'

import Large from './Large'
import Small from './Small'
import Anonymized from 'components/Anonymized'
import File from 'components/File'

const Wrapper = styled.div`
  ${wrapper};
  ${padding};
`

const Heading = styled.h6`
  ${h6};
  margin-top: 0;
`

const Content = styled.div`
  ${body}
`

const EconomicFigures = props => {
  const anonymized =
    (props.anonymized && !props.preview) || props.previewAnonymized
  if (anonymized && props.forSale) {
    return (
      <Wrapper>
        <Heading>Økonomiske hovedtall</Heading>
        <Anonymized />
      </Wrapper>
    )
  } else if (
    !props.okonomiSammendragForetak ||
    !props.okonomiSammendragForetak.length > 0
  ) {
    return (
      <Wrapper>
        <Heading>Økonomiske hovedtall</Heading>
        <Content>Mangler datagrunnlag</Content>
        {props.comment && (
          <SellersComment {...props}>{props.comment}</SellersComment>
        )}
        {props.attatchments && (
          <div>
            <Content>Vedlegg</Content>
            <ul>
              {props.attatchments.map(name => (
                <li key={name}>
                  <File
                    path={'prospect'}
                    name={name}
                    orgNumber={props.orgNumber}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <MediaQuery query="(min-width: 768px)">
          {matches => (matches ? <Large {...props} /> : <Small {...props} />)}
        </MediaQuery>
        {props.comment && (
          <SellersComment {...props}>{props.comment}</SellersComment>
        )}
        {props.attatchments && (
          <div>
            <Content>Vedlegg</Content>
            <ul>
              {props.attatchments.map(name => (
                <li key={name}>
                  <File
                    path={'prospect'}
                    name={name}
                    orgNumber={props.orgNumber}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  HistoricSalgsinntekter: HistoricSalgsinntekterSelector(state, ownProps) || [],
  HistoricOrdResultatForSkatt:
    HistoricOrdResultatForSkattSelector(state, ownProps) || [],
  HistoricSumEgenkapital: HistoricSumEgenkapitalSelector(state, ownProps) || [],
  ...economicNumbersOfLastRecordedYearSelector(state, ownProps),
})

export default connect(mapStateToProps)(EconomicFigures)
