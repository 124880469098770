import React from 'react'
import styled, { css } from 'styled-components'
import Label from './Label'
import InputComponent from './Input'
import ErrorComponent from 'components/Error'

import { body } from '../../styles/text'

const Options = styled.div`
  ${props =>
    props.horizontal &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      flex-wrap: wrap;

      > div {
        margin: 0 8px;
      }
    `}
`

const Option = styled.div`
  display: flex;
  align-items: flex-start;
`

const RadioButton = styled.input`
  margin: 6px 10px 6px 0;
  position: absolute;
  width: 25px;
  height: 25px;
  opacity: 0;
  cursor: pointer;
`

const Rb = styled.span`
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;

  border-radius: 50%;
  border: 2px solid #2a322b;

  ${props =>
    props.checked &&
    css`
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #266152 71.88%,
        rgba(255, 255, 255, 0) 86.98%
      );
    `}
`

const StyledLabel = styled(Label)`
  flex-shrink: 0;
  margin: 1px 0 2px 12px;
  ${body};
`

const InputWrapper = styled.div`
  width: 100%;
`

const Input = styled(InputComponent)`
  margin-top: 10px;
`

const Error = styled(ErrorComponent)`
  margin-bottom: 5px;
`

class RadioButtons extends React.Component {
  state = {
    other: '',
  }

  render() {
    const {
      horizontal,
      options,
      other,
      otherPlaceholder,
      value,
      onChange = () => {},
      name = Math.random() + '',
    } = this.props

    const isOther = value => (value + '').startsWith('other:')
    return (
      <React.Fragment>
        {this.props.error && <Error>{this.props.error}</Error>}
        <Options horizontal={horizontal}>
          {options.map(option => (
            <Option key={option.value}>
              <RadioButton
                type="radio"
                id={option.value + name}
                name={name}
                checked={value === option.value}
                onClick={() => {
                  if (value === option.value) {
                    onChange('')
                  }
                }}
                onChange={() => onChange(option.value)}
              />
              <Rb checked={value === option.value} />
              <StyledLabel htmlFor={option.value + name}>
                {option.label}
              </StyledLabel>
            </Option>
          ))}
          {other && (
            <Option>
              <RadioButton
                type="radio"
                id={'other' + name}
                name={name}
                checked={isOther(value)}
                onChange={() => onChange(`other:${this.state.other}`)}
              />
              <InputWrapper>
                <StyledLabel htmlFor={'other' + name}>{other}</StyledLabel>
                <Input
                  placeholder={otherPlaceholder || other}
                  value={
                    isOther(value) ? value.split(':')[1] : this.state.other
                  }
                  onChange={e => {
                    if (isOther(value)) {
                      onChange(`other:${e.target.value}`)
                    }
                    this.setState({ other: e.target.value })
                  }}
                />
              </InputWrapper>
            </Option>
          )}
        </Options>
      </React.Fragment>
    )
  }
}
export default RadioButtons
