import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  companyIsEditableIsPendingSelector,
  companyIsEditableSelector,
} from 'selectors'
import ButtonComponent from 'components/Button'

import Spinner from 'components/Spinner'

const Button = styled(ButtonComponent)`
  width: 100%;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const withAccessToEdit = companyId => WrappedComponent => {
  const mapStateToProps = state => ({
    isPending: companyIsEditableIsPendingSelector(state, { companyId }),
    canEdit: companyIsEditableSelector(state, { companyId }),
  })

  const withAccessToEdit = ({ isPending, canEdit, ...rest }) => {
    if (isPending) {
      return <Spinner />
    } else if (canEdit) {
      return <WrappedComponent {...rest} />
    } else {
      return (
        <div>
          <p>
            For å kunne redigere prospektet ditt må du først kjøpe tilgang til
            prospektverktøyet
          </p>
          <Button to="/services">Gå til tjenester</Button>
        </div>
      )
    }
  }

  return connect(mapStateToProps)(withAccessToEdit)
}

export default withAccessToEdit
