import { call, put, takeEvery } from 'redux-saga/effects'

import * as api from 'api'
import {
  FETCH_SUGGESTIONS,
  FETCH_SUGGESTIONS_SUCCESS,
  FETCH_SUGGESTIONS_ERROR,
} from 'actionTypes'



function* fetchSuggestions({ query }) {
  try {
    const suggestions = yield call(api.fetchSuggestions, query)
    yield put({ type: FETCH_SUGGESTIONS_SUCCESS, query, suggestions })
  } catch ({ message }) {
    yield put({ type: FETCH_SUGGESTIONS_ERROR, query, message })
  }
}

function* saga() {
  yield takeEvery(FETCH_SUGGESTIONS, fetchSuggestions)
}

export default saga
