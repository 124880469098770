import React from 'react'

import styled from 'styled-components'

import { border, colors } from 'styles'
import { h4 } from 'styles/text'

import Button from 'components/Button'

import { fetchIsProspectAccessible } from '../../api/companies'

const Wrapper = styled.div`
  border-radius: 0.4rem;
  border: ${border};
  background-color: ${colors.white};
  padding: 0 3rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
`

const Name = styled.div`
  ${h4};
  width: 56rem;
`

const AccessibleProspect = ({ id, name }) => {
  return (
    <Wrapper>
      <Name>Prospekt: {name ? name : id}</Name>
      <Button
        onClick={() => {
          fetchIsProspectAccessible(id)
        }}
      >
        Se Prospekt
      </Button>
    </Wrapper>
  )
}

export default AccessibleProspect
