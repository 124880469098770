import React from 'react'
import styled from 'styled-components'

import { h6, subtitle } from 'styles/text'
import { formatDate, capitalize } from 'utils'
import { wrapper, padding, content } from './styles'
import { colors } from 'styles'

import Expandable from 'components/Expandable'
import SellersComment from 'components/SellersComment'
import File from 'components/File'

const Wrapper = styled.div`
  ${wrapper};
`

const Heading = styled.h6`
  ${h6};
  ${padding};
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    ${props => (props.open ? 'transform: rotate(180deg);' : '')};
  }
`

const SubHeading = styled.h6`
  ${h6};
  margin: 36px 0 0 0;
`

const Content = styled.div`
  ${content};

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Label = styled.span`
  font-weight: bold;
`

const ContentHeading = styled.h5`
  ${subtitle};
  margin: 0;
`
const KeyInfoList = styled.li`
  list-style-type: none;
  margin-bottom: 16px;
`
const KeyInfoItem = styled.div`
  margin: 0.5rem 0;
`

const checkEmployees = data => {
  if (data.employeeBoolean) {
    return data.employees
  }
  if (data.AnsatteData) {
    const lastIndex = data.AnsatteData.length - 1
    if (parseInt(data.AnsatteData[lastIndex].AnsatteAntall) > 1) {
      return parseInt(data.AnsatteData[lastIndex].AnsatteAntall)
    } else {
      return false
    }
  }
  return false
}

const fields = [
  { heading: 'Nøkkelinformasjon', value: null, label: '' },
  {
    label: 'Organisasjonsform',
    value: props => props.SelskFormKode,
  },
  {
    label: 'Adresse',
    value: props =>
      `${props.address.address}, ${props.postnumber} ${capitalize(
        props.postplace
      )}`,
  },
  {
    label: 'Kommune',
    value: props => props.municipality,
  },
  {
    label: 'Fylke',
    value: props => props.fylke,
  },
  {
    label: 'Registrert i Enhetsregisteret',
    value: props =>
      props.registreringsdatoEnhetsregisteret
        ? props.registreringsdatoEnhetsregisteret
        : formatDate(props.RegistrertDato),
  },
  {
    label: 'Stiftelsesdato',
    value: props => props.founded,
  },
  {
    label: 'Vedtektsfestet formål',
    value: props => props.Formaal,
  },
  {
    label: 'Virksomhet/art/bransje',
    value: props => props.industry,
  },
  {
    label: 'Sektor',
    value: props => props.sektor,
  },
  {
    label: 'Særlige Opplysninger',
    value: props => props.companyDetails,
  },
  { heading: 'Styre', value: null, label: '' },
  {
    label: 'Daglig leder / adm. direktør',
    value: props => props.dagligLeder,
  },
  {
    label: 'Styrets Leder',
    value: props => props.boardLeader,
  },
  {
    label: 'Styremedlem',
    value: props => props.boardMembers,
  },
  {
    label: 'Varamedlem',
    value: props => props.secondLeader,
  },
  { heading: 'Signatur og prokura', value: null, label: '' },
  {
    label: 'Signatur',
    value: props => props.signedOff,
  },
  {
    label: 'Prokura',
    value: props => props.prokura,
  },
  { heading: 'Eiere', value: null, label: '' },
  {
    label: 'Virksomhetens aksjonær(e)',
    value: props => props.stockHolders,
  },
  {
    label: 'Aksjebok og aksjeavtaler',
    value: props => props.stockHolderFiles,
    type: 'file',
  },
  { heading: 'Om bedriften', value: null, label: '' },
  {
    label: 'Ansatte',
    value: props => (checkEmployees(props) ? checkEmployees(props) : ''),
  },
  {
    label: 'Antall årsverk',
    value: props => (props.employeeBoolean ? props.manYears : ''),
  },
  {
    label: 'Selskapets Livsfase',
    value: props => props.companyLifeCycle,
  },
  {
    label: 'Konsernstruktur',
    value: props => props.companyStructure,
  },
  {
    label: 'Avdelingsstruktur',
    value: props => props.departmentStructure,
  },
]

const KeyInformation = props => {
  const handleRender = value => {
    if (typeof value(props) === 'string' && value(props).includes('\n')) {
      return (
        <ul>
          {value(props)
            .split('\n')
            .map(item => (
              <li style={{ listStyleType: 'none' }}>{item}</li>
            ))}
        </ul>
      )
      // Assume array of objects
    } else if (Array.isArray(value(props))) {
      const itemList = value(props)
      return (
        <ul>
          {itemList.map(item => (
            <li style={{ listStyleType: 'none' }}>
              {Object.values(item)
                .map(val => val)
                .join(', ')}
            </li>
          ))}
        </ul>
      )
    } else {
      return value(props)
    }
  }

  return (
    <Wrapper>
      <Expandable
        renderHeader={({ open }) => (
          <Heading open={open}>
            <span>Selskapsinformasjon</span>
            <img src="/toggle_down.svg" alt="" />
          </Heading>
        )}
        renderContent={() => (
          <Content>
            <ul>
              {fields.map(({ label, value, heading, type }) => {
                if (
                  type === 'file' &&
                  props.stockHolderFiles &&
                  props.stockHolderFiles.length > 0
                ) {
                  return (
                    <>
                      <Label>{label}</Label>
                      <ul>
                        {value(props).map(name => (
                          <li>
                            <File
                              path="prospect"
                              name={name}
                              orgNumber={props.orgnr}
                            />
                          </li>
                        ))}
                      </ul>
                    </>
                  )
                }
                if (heading) {
                  return (
                    <>
                      <SubHeading>{heading}</SubHeading>
                      <hr style={{ border: `1px ${colors.primary} solid` }} />
                    </>
                  )
                } else {
                  return value(props) || value(props) === 0 ? (
                    <KeyInfoList key={label}>
                      <KeyInfoItem>
                        <Label>{label}: </Label>
                        {handleRender(value)}
                      </KeyInfoItem>
                    </KeyInfoList>
                  ) : null
                }
              })}
            </ul>
            {props.companyInformation_Documents && (
              <div>
                <ContentHeading>Vedlegg</ContentHeading>
                <ul>
                  {(props.companyInformation_Documents || []).map(name => (
                    <KeyInfoList key={name}>
                      <KeyInfoItem>
                        <File
                          path={'prospect'}
                          name={name}
                          orgNumber={props.orgnr}
                        />
                      </KeyInfoItem>
                    </KeyInfoList>
                  ))}
                </ul>
              </div>
            )}
            <SellersComment {...props}>
              {props.companyInformationComment}
            </SellersComment>
          </Content>
        )}
      />
    </Wrapper>
  )
}

export default KeyInformation
