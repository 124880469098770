import {
  database,
  auth,
  currentUserId,
  getTermsStatus,
  setTermsStatus,
} from './firebase'

export const canUserPublishAdvertisement = orgNr =>
  database
    .ref(`/users/${currentUserId()}/can-publish-advertisement/${orgNr}`)
    .once('value')
    .then(snapshot => snapshot.val() || false)

export const fetchUserSettings = () =>
  database
    .ref(`/user-settings/${currentUserId()}`)
    .once('value')
    .then(snapshot => snapshot.val())

export const updateUserSettingProps = settings =>
  database.ref().update(
    Object.keys(settings).reduce((newValues, prop) => {
      return {
        ...newValues,
        [`/user-settings/${currentUserId()}/${prop}`]: settings[prop],
      }
    }, {})
  )

export const updateUserSettings = settings => {
  if (settings.email) {
    return auth.currentUser
      .updateEmail(settings.email)
      .then(() => updateUserSettingProps(settings))
  } else {
    return updateUserSettingProps(settings)
  }
}

export const updateTerms = status => {
  return setTermsStatus(status.status)
}

export const updateUserSettingsAndTermsOfService = async args => {
  const [settings, { status }] = args
  console.log(settings, status)
  const t = await auth.currentUser.updateEmail(settings.email).then(
    async user => {
      console.log('Success')
      await updateUserSettingProps(settings)
      await updateTerms(status)
      return { status: 'ok' }
    },
    error => {
      console.log('An error occured')
      return { error, status: 'error' }
    }
  )
  return t
}

export const fetchTermsStatus = async () => {
  if (!auth.currentUser) return false
  const res = await getTermsStatus()
  return res
}

export const isLoggedIn = async () => {
  if (!auth.currentUser) return false
  return true
}
