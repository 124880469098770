import React from 'react'

import Imgix from 'react-imgix'
import { imgixUrl } from '../environment'

const Image = ({ src, ...props }) => (
  <Imgix {...props} src={`${imgixUrl}/${src}`} />
)

export default Image
