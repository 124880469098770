import { call, put, takeEvery } from 'redux-saga/effects'

import * as api from 'api'
import {
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOG_IN,
  LOG_IN_WITH_TOKEN,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  LOG_OUT_ERROR,
  CHECK_AUTH_STATUS,
  CHECK_AUTH_STATUS_SUCCESS,
  CHECK_AUTH_STATUS_ERROR,
  FETCH_FAVORITED_ADVERTISEMENTS,
  FETCH_FAVORITED_COMPANIES,
  FETCH_CLAIMED_COMPANIES,
  AUTHENTICATE_WITH_GOOGLE,
  FETCH_PROSPECT_READ_REQUESTS,
  FETCH_ACCESSIBLE_PROSPECTS,
  FETCH_BOUGHT_PRODUCTS,
  FETCH_EXCLUSIVITIES,
} from 'actionTypes'

function* register({ email, password }) {
  try {
    const user = yield call(api.register, email, password)
    yield put({ type: REGISTER_SUCCESS, user })
  } catch ({ message }) {
    yield put({ type: REGISTER_ERROR, message })
  }
}

function* logIn({ email, password }) {
  try {
    const user = yield call(api.logIn, email, password)
    yield put({ type: LOG_IN_SUCCESS, user })
  } catch ({ message }) {
    yield put({ type: LOG_IN_ERROR, message })
  }
}

function* logInWithToken({ token }) {
  try {
    const user = yield call(api.logInWithToken, token)
    yield put({ type: LOG_IN_SUCCESS, user })
  } catch ({ message }) {
    yield put({ type: LOG_IN_ERROR, message })
  }
}

function* logOut() {
  try {
    yield call(api.logOut)
    yield put({ type: LOG_OUT_SUCCESS })
  } catch ({ message }) {
    yield put({ type: LOG_OUT_ERROR, message })
  }
}

function* checkAuthStatus() {
  try {
    const user = yield call(api.checkAuthStatus)
    yield put({ type: CHECK_AUTH_STATUS_SUCCESS, user })
  } catch ({ message }) {
    yield put({ type: CHECK_AUTH_STATUS_ERROR, message })
  }
}

function* fetchUserData() {
  yield put({ type: FETCH_FAVORITED_ADVERTISEMENTS })
  yield put({ type: FETCH_FAVORITED_COMPANIES })
  yield put({ type: FETCH_CLAIMED_COMPANIES })
  yield put({ type: FETCH_PROSPECT_READ_REQUESTS })
  yield put({ type: FETCH_ACCESSIBLE_PROSPECTS })
  yield put({ type: FETCH_BOUGHT_PRODUCTS })
  yield put({ type: FETCH_EXCLUSIVITIES })
}

function* authenticateWithGoogle() {
  yield call(api.authenticateWithGoogle)
}

function* saga() {
  yield takeEvery(REGISTER, register)
  yield takeEvery(LOG_IN, logIn)
  yield takeEvery(LOG_IN_WITH_TOKEN, logInWithToken)
  yield takeEvery(LOG_OUT, logOut)
  yield takeEvery(CHECK_AUTH_STATUS, checkAuthStatus)
  yield takeEvery(
    action =>
      action.type === LOG_IN_SUCCESS ||
      (action.type === CHECK_AUTH_STATUS_SUCCESS && action.user),
    fetchUserData
  )
  yield takeEvery(AUTHENTICATE_WITH_GOOGLE, authenticateWithGoogle)
}

export default saga
