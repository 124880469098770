import React from 'react'
import ReactSelect from 'react-select'

import styled from 'styled-components'

import { border, colors } from 'styles'
import { body } from 'styles/text'

const StyledSelect = styled(ReactSelect)`
  ${body};
  min-width: 100%;
  > div {
    padding: 6px;
    color: ${colors.primaryGray};
    border: ${border};
    border-radius: 4px;
  }
`

const Select = ({ value, options, onChange, ...rest }) => (
  <StyledSelect
    value={options.find(option => option.value === value) || null}
    onChange={option => onChange(option.value)}
    options={options}
    isSearchable={false}
    placeholder="Velg..."
    {...rest}
  />
)

export default Select
