import React from 'react'
import styled from 'styled-components'

import { h6, h3, subtle2, subtitle } from 'styles/text'
import { Tab, Tabs, TabList, TabPanel } from 'components/tabs'
import Likviditetsgrad1Table from './Likviditetsgrad1Table'
import OverskuddsprosentTable from './OverskuddsprosentTable'
import EgenkapitalandelTable from './EgenkapitalandelTable'
import SellersComment from 'components/SellersComment'
import File from 'components/File'

const Heading = styled.h6`
  ${h6};
  margin-top: 0;
`

const TabTitle = styled.div`
  ${subtle2};
`

const TabValue = styled.div`
  margin-top: 1.2rem;
  ${subtitle};
`

const TableWrapper = styled.div`
  padding: 20px;
`

const ContentHeading = styled.h3`
  ${h3};
  margin: 0;
`

const Content = styled.div`
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const KeyFigures = props => {
  return (
    <React.Fragment>
      <Heading>Nøkkeltall ({props.RegnskapsAvAr})</Heading>
      <Content>
        <div>
          <Tabs>
            <TabList>
              <Tab>
                <TabTitle>Likviditetsgrad 1</TabTitle>
                <TabValue>
                  {props.Likviditetsgrad1 &&
                    props.Likviditetsgrad1.toLocaleString('nb')}
                </TabValue>
              </Tab>
              <Tab>
                <TabTitle>Resultat av driften</TabTitle>
                <TabValue>
                  {props.Overskuddsprosent &&
                    props.Overskuddsprosent.toLocaleString('nb')}{' '}
                  %
                </TabValue>
              </Tab>
              <Tab>
                <TabTitle>Egenkapitalandel</TabTitle>
                <TabValue>
                  {props.Egenkapitalandel &&
                    props.Egenkapitalandel.toLocaleString('nb')}{' '}
                  %
                </TabValue>
              </Tab>
            </TabList>

            <TabPanel>
              <TableWrapper>
                <Likviditetsgrad1Table value={props.Likviditetsgrad1} />
              </TableWrapper>
            </TabPanel>
            <TabPanel>
              <TableWrapper>
                <OverskuddsprosentTable value={props.Overskuddsprosent} />
              </TableWrapper>
            </TabPanel>
            <TabPanel>
              <TableWrapper>
                <EgenkapitalandelTable value={props.Egenkapitalandel} />
              </TableWrapper>
            </TabPanel>
          </Tabs>
        </div>
        {props.keyFiguresFiles &&
          Object.keys(props.keyFiguresFiles).length > 0 && (
            <div>
              <ContentHeading>Vedlegg</ContentHeading>
              <ul>
                {Object.entries(props.keyFiguresFiles || {}).map(
                  ([path, name]) => (
                    <li key={path}>
                      <File path={path} name={name} />
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        <SellersComment {...props}>{props.keyFiguresComment}</SellersComment>
      </Content>
    </React.Fragment>
  )
}

export default KeyFigures
