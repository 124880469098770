import React from 'react'
import styled from 'styled-components'
import { border } from 'styles'
import SearchFieldComponent from '../../../components/SearchField'
import AdvertisementLabelFilter from './AdvertisementLabelFilter'

const Wrapper = styled.div`
  border-bottom: ${border};
  margin: 0px 24px;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`
const Content = styled.div`
  max-width: 1440px;
  margin: 16px auto 0px;
`

const SearchField = styled(SearchFieldComponent)`
  flex-grow: 1;
  margin-bottom: 12px;
`

const SearchFilter = ({ q, setQ, filter, setFilter }) => {
  return (
    <Wrapper>
      <Content>
        <SearchField q={q} setQ={setQ} />
        <AdvertisementLabelFilter filter={filter} setFilter={setFilter} />
      </Content>
    </Wrapper>
  )
}

export default SearchFilter
