import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fetchSuggestions } from 'actions'
import { suggestionsSelector } from 'selectors'
import { colors } from 'styles'
import { h4 } from 'styles/text'

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
  overflow-y: scroll;
`

const Heading = styled.div`
  ${h4};
  color: ${colors.subtleText};
  margin-left: 10px;
`

const Suggestion = styled.div`
  background-color: ${props =>
    props.active ? colors.infoBackground : colors.white};
  margin: 1px 0;
  padding: 8px 20px;
  cursor: pointer;
`

const categoryTitles = {
  name: 'Bedrift',
  industry: 'Bransje',
  location: 'Sted',
}

class SuggestionList extends React.Component {
  componentDidMount = () =>
    !this.props.suggestions && this.props.fetchSuggestions(this.props.value)

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) {
      const value = this.props.value
      clearTimeout(this.timeout)
      this.timeout = setTimeout(
        () => !this.props.suggestions && this.props.fetchSuggestions(value),
        100
      )
    }
  }

  render() {
    return (
      <Wrapper>
        {this.props.suggestions &&
          this.props.suggestions.map(({ name, suggestions }) => (
            <React.Fragment key={name}>
              <Heading>{categoryTitles[name]}</Heading>
              {suggestions.map(suggestion => (
                <Suggestion
                  key={suggestion}
                  onMouseDown={e => {
                    e.preventDefault()
                    this.props.select(suggestion)
                  }}
                >
                  {suggestion}
                </Suggestion>
              ))}
            </React.Fragment>
          ))}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  suggestions: suggestionsSelector(state, ownProps),
})

const mapDispatchToProps = { fetchSuggestions }

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionList)
