import React from 'react'
import styled from 'styled-components'
import { body } from 'styles/text'
import InfoBox, { Heading, Button } from './InfoBox'
import { colors } from 'styles'

const Info = styled.p`
  ${body};
  color: ${colors.primary};
`

const BuyersChecklist = () => (
  <InfoBox>
    <Heading>Trenger du hjelp fra en rådgiver i kjøpsprosessen?</Heading>
    <Button subtle href="https://www.nobema.no/personlig_bistand">
      Be om tilbud
    </Button>
  </InfoBox>
)

export default BuyersChecklist
