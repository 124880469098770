import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { useQuery } from 'react-query'

import {
  isAuthenticatedSelector,
  authIsPendingSelector,
  locationSelector,
  termsSelector,
} from 'selectors'
import { authUrl } from 'api/fetch'
import ButtonComponent from 'components/Button'
import withSpinner from 'components/withSpinner'
import Spinner from 'components/Spinner'
import PersonalInfoForm from 'components/PersonalInfoForm'
import { useLocation } from 'react-router-dom'
import { fetchTermsStatus } from '../api/users'

const Wrapper = styled.div`
  max-width: 600px;
  margin: 10px auto;
`

const Button = styled(ButtonComponent)`
  width: 100%;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const withLogin = WrappedComponent => {
  const mapStateToProps = state => ({
    isAuthenticated: isAuthenticatedSelector(state),
    authIsPending: authIsPendingSelector(state),
    location: locationSelector(state),
    terms: termsSelector(state),
  })

  const withLogin = ({ isAuthenticated, authIsPending, location, ...rest }) => {
    // TODO: Handle UPDATE_TERMS_STATUS_SUCCESS
    const termsRes = useQuery(
      'terms',
      async () => {
        return await fetchTermsStatus()
      },
      { useErrorBoundary: true }
    )

    const terms = termsRes.data

    const { search } = useLocation()
    if (authIsPending || termsRes.isLoading) {
      return <Spinner />
    } else if (isAuthenticated && terms) {
      return <WrappedComponent {...rest} />
    } else if (isAuthenticated && !terms) {
      return (
        <Wrapper>
          <PersonalInfoForm />
        </Wrapper>
      )
    } else {
      return (
        <Wrapper>
          <Button href={authUrl(location, search)}>
            Logg inn med Bank-ID
          </Button>
          <Button href={authUrl(location, search)} subtle>
            Registrer ny bruker med Bank-ID
          </Button>
        </Wrapper>
      )
    }
  }

  return compose(
    withSpinner(authIsPendingSelector),
    connect(mapStateToProps)
  )(withLogin)
}

export default withLogin
